import React from 'react';

export const ModalBreak: React.FC = () => 
{
  return (
    <div
      className="w-full h-[1px] bg-gray-300 dark:bg-gray-600 my-3"
    ></div>
  );
};
