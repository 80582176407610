import { getLocale, setLocale } from "../../ReactIntl/IntlConfig";
// import { userManager } from "../BeetClient";
import { apiDefinitions } from "../ApiConfig";

const definition = apiDefinitions.account;

export const accountController = {
  login: async (email: string, password: string) => 
  {
    const endpoint = definition.login;
    const response = await endpoint.invoke(endpoint.url, { email, password });
    // await userManager.signinRedirect();
    return response;
  },

  register: async (
    email: string,
    password: string,
    confirmPassword: string,
    firstName: string,
    lastName: string
  ) => 
  {
    const endpoint = definition.register;
    const response = await endpoint.invoke(endpoint.url, {
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
    });
    return response;
  },

  sendPasswordResetLink: async (email: string) => 
  {
    const endpoint = definition.sendPasswordResetLink;
    const response = await endpoint.invoke(endpoint.url, { email });
    return response;
  },

  changePassword: async (
    email: string,
    newPassword: string,
    resetCode: string
  ) => 
  {
    const endpoint = definition.changePassword;
    const response = await endpoint.invoke(endpoint.url, {
      email,
      newPassword,
      resetCode,
    });
    return response;
  },

  changeOldPassword: async (oldPassword: string, newPassword: string) => 
  {
    const endpoint = definition.changeOldPassword;
    const response = await endpoint.invoke(endpoint.url, {
      oldPassword,
      newPassword,
    });
    return response;
  },

  fetchExternalLoginProviders: async () => 
  {
    const endpoint = definition.fetchExternalLoginProvider;
    const response = await endpoint.invoke(endpoint.url);
    return response;
  },

  externalLogin: async (provider: string, _returnUrl: string) => 
  {
    const endpoint = definition.externalLogin;
    const response = await endpoint.invoke(`${endpoint.url}/${provider}`);
    // await userManager.signinRedirect({
    //   extraQueryParams: { provider: provider }
    // });
    return response;
  },

  logout: async () => 
  {
    const endpoint = definition.logout;
    const response = await endpoint.invoke(endpoint.url).finally(() => 
    {
      const locale = getLocale();
      // userManager.removeUser();
      // userManager.clearStaleState();
      localStorage.clear();
      sessionStorage.clear();
      if (locale !== null) 
      {
        setLocale(locale);
      }
      window.location.reload();
    });
    return response;
  },

  resendEmail: async (email: string) => 
  {
    const endpoint = definition.resendEmail;
    const response = await endpoint.invoke(endpoint.url, { email });
    return response;
  },
};
