import React from 'react';

import { IGenAIChatTemplate, IGenAIWriterTemplate, IGenAITemplate } from '../Api/Types/GenAIInterface';
import { MessageCircle, SettingsIcon, SquareArrowOutUpRight, Star, StarOff } from 'lucide-react';
import { RoleBasedElement } from '../../../Api/Roles/RoleBasedElement';
import { Role } from '../../../Api/Roles/RoleManager';
import { Button } from '../../../Components/Actions/Buttons/Buttons';

interface TemplateRowProps 
{
  template: IGenAIWriterTemplate | IGenAIChatTemplate;
  isWriter: boolean;
  onDelete: (id: string) => void;
  onEdit: (item: IGenAIWriterTemplate | IGenAIChatTemplate, number?: number) => void;
  onToggleFavorite: (id: string) => void;
  isFavorite: boolean;
  onChatbotSelect?: (chatbot: IGenAITemplate) => void;
  onWriterTemplateSelect?: (template: IGenAITemplate) => void;
}

export const GenAITemplateRow: React.FC<TemplateRowProps> = ({
  template,
  isWriter,
  onDelete,
  onEdit,
  onToggleFavorite,
  isFavorite,
  onChatbotSelect,
  onWriterTemplateSelect
}) => 
{
  const metadata = isWriter ? (template as IGenAIWriterTemplate).metadata : (template as IGenAIChatTemplate).metadata;
  //if it's a chatbot search for the floating setting, double check for metadata because it can be undefined based on the type
  const isFloatingChatbot = metadata ? isWriter ? false : JSON.parse(metadata).floatingSettings?.isFloatingChatbot : false;
  return (
    <tr onClick={!isWriter && onChatbotSelect? () => onChatbotSelect(template) : undefined}
      className= {`${!isWriter? 'cursor-pointer': ''}`}>
      <td className="px-2 py-3 text-center">
        <button
          onClick={(e?) =>{e?.stopPropagation(); onToggleFavorite(template.id);}}
          className="text-yellow-500 hover:text-yellow-600"
        >
          {isFavorite ? <Star size={24} fill="currentColor" /> : <StarOff size={24} />}
        </button>
      </td>
      <td className="px-2 py-3 text-center">{template.name}</td>
      <td className="px-2 py-3 text-center">{template.description}</td>
      <td className="px-2 py-3 text-center">{template.status}</td>
      <td className="px-2 py-3 text-center">{template.data.prompt}</td>
      {/* {isWriter && (
        <td className="px-2 py-3 text-center">
          {(template as IGenAIWriterTemplate).data.inputs.map(input => input.name).join(', ')}
        </td>
      )} */}
      <RoleBasedElement requiredRoles={Role.Admin} as={React.Fragment}>
        <td className="px-2 py-3">
          <span className="flex items-center justify-center gap-2">
            {!isWriter && (
              <Button
                onClick={() => console.log("Aprire la chat di prova, dove potere selezionare le temperature i modelli etc etc")}
                variant="secondary" size="l" icon={<SettingsIcon size={16.5} />} hideText />
            )}

            <Button
              onClick={(e?) => {e?.stopPropagation(); onEdit(template);}}
              variant="secondary"
              size="l"
              type="edit"
              hideText={true}

            />

            <Button
              onClick={(e?) => {e?.stopPropagation(); onDelete(template.id);}}
              variant="textDanger"
              size="l"
              type="delete"
              hideText={true}
            />
          </span>
        </td>
      </RoleBasedElement>
      <td className="px-2 py-3">
        {isWriter && onWriterTemplateSelect ? (
          <Button
            onClick={() => onWriterTemplateSelect(template)}
            variant="success"
            size="m"
            hideText={true}
            icon={<SquareArrowOutUpRight size={20} />}
            title="Open Generator"
            className='mx-auto'
          />
        ) : onChatbotSelect ? (
          <Button
            onClick={(e) =>{e?.stopPropagation(); onEdit(template, 3);}}
            variant={`${isFloatingChatbot ? 'success' : 'slate'}`} 
            size="m"
            hideText={true}
            icon={<MessageCircle size={20} />}
            title="This button takes you to Chatbot Settings"
            className='mx-auto'
          />
        ) : null}
      </td>
    </tr>
  );
};