import React, { useState, useCallback, useMemo } from 'react';
import { AccountSettingsPanelPswLocale, ChangePasswordLocale, PasswordRequirementsLocale } from '../../../../ReactIntl/LocaleInterfaces';
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { checkPasswordCriteria, doPasswordsMatch, getPasswordCriteriaMessages } from '../../../../Utils/PasswordUtils';
import { accountController } from '../../../../Api/Controller/ApiAccount';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

export const PanelPassword: React.FC = () => 
{
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [focusedInput, setFocusedInput] = useState<string>('');
  const [focusedInputOldPassword, setFocusedInputOldPassword] = useState<string>('');

  const intl = getIntl<AccountSettingsPanelPswLocale>('accountSettingsPanelPsw');
  const intlPsw = getIntl<PasswordRequirementsLocale>('password');
  const intlPswChg = getIntl<ChangePasswordLocale>('changePassword');

  const [passwordCriteria, setPasswordCriteria] = useState({
    hasSpecialChar: false,
    hasUpperCase: false,
    hasNumber: false,
    isLengthValid: false,
  });

  const [passwordMatch, setPasswordMatch] = useState<boolean>(true);
  
  const handleNewPasswordChange = useCallback((password: string) => 
  {
    setNewPassword(password);
    setPasswordCriteria(checkPasswordCriteria(password));
    setPasswordMatch(doPasswordsMatch(password, confirmPassword));
  }, [confirmPassword]);

  const handleConfirmPasswordChange = useCallback((password: string) => 
  {
    setConfirmPassword(password);
    setPasswordMatch(doPasswordsMatch(newPassword, password));
  }, [newPassword]);

  const handleChangePassword = useCallback(async () => 
  {
    if (oldPassword === newPassword) 
    {
      toast.error(intlPswChg.samePassword);
      return;
    }
    setIsLoading(true);
    try 
    {
      if (!doPasswordsMatch(newPassword, confirmPassword)) 
      {
        setPasswordMatch(false);
        return;
      }

      if (!oldPassword) 
      {
        return;
      }

      setPasswordMatch(true);
      await accountController.changeOldPassword(oldPassword, newPassword);

      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      toast.success(intlPswChg.passwordChanged);
    }
    catch (error) 
    {
      if(error instanceof AxiosError)
      {
        console.log(error.response);
        if(error.response?.data.errors.PasswordMismatch)
        {
          toast.error(intlPswChg.currPswMismatch);
        }
        else
        {
          toast.error(intlPswChg.changeFailed);
        }
      }
      console.error('Failed to change password:', error);
    }
    finally 
    {
      setIsLoading(false);
    }
  }, [oldPassword, newPassword, confirmPassword, intlPswChg]);

  const passwordCriteriaMessages = useMemo(
    () => getPasswordCriteriaMessages(passwordCriteria, intlPsw),
    [passwordCriteria, intlPsw] 
  );

  return (
    <section className='pb-3 border-b border-gray-300 dark:border-gray-700'>

      <section>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="sm:w-1/3">
            <label htmlFor="oldPassword" className="block text-sm font-medium mb-1 text-slate-700 dark:text-slate-300">
              {intl.currentPswLabel}
              <span className="text-rose-500">*</span>
            </label>
            <input
              type="password"
              id="oldPassword"
              className={`form-input w-full border-slate-200 dark:border-slate-700 ${oldPassword === '' ? 'border-red-500' : ''}`}
              value={oldPassword}
              onFocus={() => setFocusedInputOldPassword('oldPassword')}
              onBlur={() => setFocusedInputOldPassword('')}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
            {oldPassword.length === 0 && (
              <p className={`text-xs text-red-500 absolute z-50 ${focusedInputOldPassword === 'oldPassword' ? 'block' : 'hidden'}`}>
                {intl.currentPswErrMessage}
              </p>
            )}
          </div>
          <div className="sm:w-1/3">
            <label htmlFor="newPassword" className="block text-sm font-medium mb-1 text-slate-700 dark:text-slate-300">
              {intl.newPswLabel}
              <span className="text-rose-500">*</span>
            </label>
            <input
              type="password"
              id="newPassword"
              className="form-input w-full border-slate-200 dark:border-slate-700"
              value={newPassword}
              onFocus={() => setFocusedInput('newPassword')}
              onBlur={() => setFocusedInput('')}
              onChange={(e) => handleNewPasswordChange(e.target.value)}
              required
            />
            <div className={`mt-1 w-30  absolute z-50 bg-white dark:bg-slate-800 ${focusedInput === 'newPassword' ? 'block' : 'hidden'}`}>
              {Object.entries(passwordCriteriaMessages).map(([key, message]) => (
                <p key={key} className={`text-xs ${passwordCriteria[key as keyof typeof passwordCriteria] ? 'text-green-500' : 'text-red-500'}`}>
                  {message}
                </p>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="sm:w-1/3">
            <label htmlFor="confirmPassword" className="block text-sm font-medium mb-1 text-slate-700 dark:text-slate-300">
              {intl.confirmPswLabel}
              <span className="text-rose-500">*</span>
            </label>
            <input
              type="password"
              id="confirmPassword"
              className={`form-input w-full border-slate-200 dark:border-slate-700 ${passwordMatch ? '' : 'border-red-500'}`}
              value={confirmPassword}
              onChange={(e) => handleConfirmPasswordChange(e.target.value)}
              required
            />
            {!passwordMatch && <p className="text-xs text-red-500">{intlPsw.match}</p>}
          </div>
        </div>
      </section>
      <div className="flex flex-col px-6 py-5 border-slate-200 dark:border-slate-700">
        <div className="flex self-end">
          <button
            className={`btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 ${isLoading ||
              !oldPassword ||
              !passwordCriteria.hasSpecialChar ||
              !passwordCriteria.hasUpperCase ||
              !passwordCriteria.hasNumber ||
              !passwordCriteria.isLengthValid ||
              !passwordMatch
              ? 'cursor-not-allowed opacity-50'
              : ''
            }`}
            onClick={handleChangePassword}
            disabled={
              !oldPassword ||
              !passwordCriteria.hasSpecialChar ||
              !passwordCriteria.hasUpperCase ||
              !passwordCriteria.hasNumber ||
              !passwordCriteria.isLengthValid ||
              !passwordMatch ||
              isLoading
            }
          >
            {isLoading ? (
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
              </svg>
            ) : (
              intl.saveChangesButton
            )}
          </button>
        </div>
      </div>
    </section>
  );
};