import React, { useState, useEffect } from 'react';
import  ChatBotIcon  from '../../../Modules/WindTreTest/WindTreAssets/ChatbotIcon.png';
import { motion, AnimatePresence } from 'framer-motion';
import { ChatWindow } from './Partials/ChatWindow';
import { ChatbotStyles } from '../ChatbotLayout/CommonStyles/ChatbotStyles';

export const ChatbotIcon: React.FC<{ onClick: () => void }> = ({ onClick }) => (  <motion.div
  className="fixed bottom-4 right-4 hover:bg-blue-700 rounded-full cursor-pointer shadow-lg flex items-center justify-center transition-colors duration-300 ease-in-out w-20 h-20 z-50"
  onClick={(e: React.MouseEvent<HTMLDivElement>) => 
  {
    e.stopPropagation();
    onClick();
  }}
  initial={{ scale: 0.6, opacity: 0 }}
  animate={{ scale: 1, opacity: 1 }}
  transition={{ type: 'spring', stiffness: 200, damping: 25 }}
  whileHover={{ scale: 1.05 }}
  whileTap={{ scale: 0.95 }}
>
  <img src={ChatBotIcon} alt="Chatbot assistant icon" className="w-20 h-20" />
</motion.div>
);

export const WindChatbot: React.FC = () => 
{
  const [isOpen, setIsOpen] = useState(false);
  const [lastMessageContent, _setLastMessageContent] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => 
  {
    const checkMobile = () => 
    {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const setStyle = (style: typeof ChatbotStyles) =>
  {
    style.ChatWindow += "bg-gradient-to-b from-orange-50 to-white";

    return style;
  };

  const getStoreIdFromUrl = () =>
  {
    const match = location.pathname.match(/\/gen-ai\/chatbot\/windtre\/(.+)/);
    return match ? match[1] : '';
  };
  const storeId = getStoreIdFromUrl();
  
  //TODO: isBussines, showTypeButtons

  return (
    <>
      <AnimatePresence>
        {!isOpen && <ChatbotIcon onClick={() => setIsOpen(true)} />}
        {isOpen && (
          <motion.div
            className={`fixed ${isMobile ? 'inset-0' : 'bottom-0 right-0'} z-50`}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          >
            <ChatWindow
              onClose={() => setIsOpen(false)}
              lastMessageContent={lastMessageContent}
              chatbotIcon={ChatBotIcon}
              storeId={storeId}
              setStyle={setStyle}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};