export const validationMessages = {
  required: 'This field is required',
  minLength: (min: number) => `Must be at least ${min} characters`,
  maxLength: (max: number) => `Must be less than ${max} characters`,
  email: 'Must be a valid email address',
  password: 'Must be a valid password',
  oneUppercase: 'Must contain at least one uppercase letter',
  oneLowercase: 'Must contain at least one lowercase letter',
  oneNumber: 'Must contain at least one number',
  oneSpecialChar: 'Must contain at least one special character',
};