import React from 'react';

// This utility allows composing multiple providers without nesting them as HTML-like tags
// Taken and modified from https://gist.github.com/phatnguyenuit/68122170e317d13e7148c7563be021b6
interface Provider<TProps> 
{
  Component: ComponentTypeWithProps<TProps>;
  props?: Omit<TProps, "children">;
}

type AnyProvider = Provider<unknown>;
type ComponentTypeWithChildren = React.ComponentType<React.PropsWithChildren>;
type ComponentTypeWithProps<T> = React.ComponentType<React.PropsWithChildren<T>>;

export const buildProvider = <T,>(Component: ComponentTypeWithProps<T>, props?: Omit<T, "children">)
: AnyProvider => ({ Component, props }) as AnyProvider;

export const composeProviders = (providers: AnyProvider[])
: ComponentTypeWithChildren => 
{
  return ({ children }) => 
  {
    return providers
      .reduceRight<React.JSX.Element>(
        (prevJSX, { Component: CurrentProvider, props = {} }) =>
          (<CurrentProvider {...props}>{prevJSX}</CurrentProvider>),
        <>{children}</>
      );
  };
};