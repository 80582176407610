import React, { useState, useEffect } from 'react';
import { GenAITokenUsage } from '../../Api/Types/GenAIInterface';
import { windtreChatController } from '../../../../Api/Controller/GenAI/Chatbot/ApiWindTreChat';

interface TokenUsageDialogueProps 
{
  chatId: string;
  onClose: () => void;
  chatbotIcon: string;
}

export const TokenUsageDialogue: React.FC<TokenUsageDialogueProps> = ({
  chatId,
  onClose,
  chatbotIcon,
}) => 
{
  const [tokenUsage, setTokenUsage] = useState<GenAITokenUsage | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => 
  {
    fetchTokenUsage();
  });

  const fetchTokenUsage = async () => 
  {
    try 
    {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const response = await windtreChatController.getTokenUsage('demo-napoli', chatId);
      if (response && response.data && Array.isArray(response.data) && response.data.length > 0) 
      {
        setTokenUsage(response.data[0]);
      }
      else 
      {
        setTokenUsage(null);
      }
    }
    catch (err) 
    {
      console.error('Error fetching token usage:', err);
      setTokenUsage(null);
    }
    finally 
    {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex-grow overflow-y-auto p-6 bg-gradient-to-b from-orange-50">
      <h2 className="text-xl font-bold mb-4 text-orange-600 text-center">Token Usage</h2>
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <div className="relative flex items-center justify-center">
            <div className="lds-ring">
              <div></div>
            </div>
            <img src={chatbotIcon} alt="Chatbot Icon" className="absolute w-16 h-16" />
          </div>
        </div>
      ) : (
        <div className="space-y-4">
          {tokenUsage ? (
            <>
              <div>
                <span className="font-bold">Provider:</span> {tokenUsage.model?.provider}
              </div>
              <div>
                <span className="font-bold">Model ID:</span> {tokenUsage.model?.modelId}
              </div>
              <div>
                <span className="font-bold">Input Tokens:</span> {tokenUsage.inputTokens}
              </div>
              <div>
                <span className="font-bold">Output Tokens:</span> {tokenUsage.outputTokens}
              </div>
              <div>
                <span className="font-bold">Total Tokens:</span> {tokenUsage.totalTokens}
              </div>
            </>
          ) : (
            <div className="text-center text-orange-600">
              Non ci sono dati disponibili in questo momento 
            </div>
          )}
        </div>
      )}
      <div className="flex justify-end mt-4">
        <button
          onClick={onClose}
          className="px-4 py-2 bg-gradient-to-r from-orange-400 to-orange-600 text-white rounded-md shadow-md hover:from-orange-500 hover:to-orange-700 transition-all duration-300 flex items-center"
        >
          Chiudi
        </button>
      </div>
    </div>
  );
};
