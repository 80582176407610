import { Sidebar } from './Sidebar';
import { Header } from './Header';
import { useState } from 'react';
import React from 'react';
import { useError } from '../../Hooks/UseError';
import { Banner } from '../../Components/Banner';

export const CommonLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => 
{

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const { bannerOpen, bannerMessage, bannerType, bannerDuration, setBannerOpen } = useError();

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="  w-full flex flex-col flex-1 flow-hidden p-0">
        {/* Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* Banner */}
        <Banner
          open={bannerOpen}
          setOpen={setBannerOpen}
          type={bannerType}
          className="sticky top-0 left-0 right-0 z-50 w-full"
          duration={bannerDuration}
        >{bannerMessage}
        </Banner>

        {/* Main content with scroll */}
        < >
          {children}
        </>
        {/* Site footer */}
        
      </div>
    </div>
  );
};
