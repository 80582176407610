import React from 'react';
import { InfoProps } from './ModalTypes';

export const InfoIcon: React.FC<InfoProps> = ({ type, className = "" }) => 
{
  const styles = {
    success: {
      container: "w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-emerald-100 dark:bg-emerald-400/30",
      svg: "w-4 h-4 shrink-0 fill-current text-emerald-500",
      path: "M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"
    },
    danger: {
      container: "w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100 dark:bg-rose-500/30",
      svg: "w-4 h-4 shrink-0 fill-current text-rose-500",
      path: "M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
    },
    indigo: {
      container: "w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-indigo-100 dark:bg-indigo-500/30",
      svg: "w-4 h-4 shrink-0 fill-current text-indigo-500",
      path: "M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
    },
    caution: {
      container: "w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-orange-100 dark:bg-orange-500/30",
      svg: "w-4 h-4 shrink-0 fill-current text-orange-500",
      path: "M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
    }
  };

  const { container, svg, path } = styles[type];

  return (
    <div className={`${container} ${className}`}>
      <svg className={svg} viewBox="0 0 16 16">
        <path d={path}></path>
      </svg>
    </div>
  );
};
