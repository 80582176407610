type LanguageState = {
  language: string;
};
  
  type LanguageAction = {
    type: "SET_LANGUAGE";
    language: string;
  };

export function languageReducer(state: LanguageState, action: LanguageAction): LanguageState 
{
  switch (action.type) 
  {
    case "SET_LANGUAGE":
      return { ...state, language: action.language };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}