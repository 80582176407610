import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TrainingSource } from '../Api/Types/GenAIInterface';
import { WebsiteInput } from './WebsiteInput';
import { PDFInput } from './PDFInput';
import { TextInput } from './TextInput';
import { QAInput } from './QAInput';
import { Button } from '../../../Components/Actions/Buttons/Buttons';
import { IconPicker } from './IconPicker';
import { ImageIcon } from 'lucide-react';
import { InputField } from '../../../ReactHookForm/Components/InputField';
import { TextareaField } from '../../../ReactHookForm/Components/TextareaField';

interface TrainingInputFormProps 
{
  onSave: (data: {
    name: string;
    description: string;
    sources: TrainingSource[];
    icon?: string;
  }) => void;
  onBack: () => void;
}

export const TrainingInputForm: React.FC<TrainingInputFormProps> = ({ onSave, onBack }) => 
{
  const [activeTab, setActiveTab] = useState<TrainingSource['type']>('website');
  const [questions, setQuestions] = useState<Array<{ question: string; answer: string }>>([]);
  const [sources, setSources] = useState<TrainingSource[]>([]);
  const [showNaming, setShowNaming] = useState(false);
  const [iconSvg, setIconSvg] = useState<string>('');
  const [showIconPicker, setShowIconPicker] = useState(false);

  // Configurazione del form per la Naming Section
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<{ trainingName: string; trainingDescription: string }>({
    defaultValues: {
      trainingName: '',
      trainingDescription: '',
    },
  });

  const trainingNameValue = watch('trainingName');
  const trainingDescriptionValue = watch('trainingDescription');

  const handleWebsiteSubmit = (data: { url: string }) => 
  {
    const source: TrainingSource = {
      type: 'website',
      content: data.url,
      url: data.url,
    };
    setSources((prev) => [...prev, source]);
  };

  const handlePDFSubmit = (file: File) => 
  {
    const source: TrainingSource = {
      type: 'pdf',
      content: file.name,
    };
    setSources((prev) => [...prev, source]);
  };

  const handleTextSubmit = (data: { title: string; text: string }) => 
  {
    const source: TrainingSource = {
      type: 'text',
      content: data.text,
    };
    setSources((prev) => [...prev, source]);
  };

  const handleQASubmit = (data: { question: string; answer: string }) => 
  {
    const updatedQuestions = [...questions, data];
    setQuestions(updatedQuestions);

    const source: TrainingSource = {
      type: 'qa',
      content: 'Q&A Collection',
      questions: updatedQuestions,
    };
    setSources((prev) => [...prev, source]);
  };

  const handleRemoveSource = (index: number) => 
  {
    setSources((prev) => prev.filter((_, i) => i !== index));
  };

  const handleTrain = () => 
  {
    if (sources.length === 0) 
    {
      alert('Please add at least one source before training');
      return;
    }
    setShowNaming(true);
  };

  const onSubmitForm = handleSubmit((data) => 
  {
    onSave({
      name: data.trainingName,
      description: data.trainingDescription,
      sources,
      icon: iconSvg || undefined,
    });
    reset();
    setShowNaming(false);
  });

  const getSourceTypeLabel = (source: TrainingSource) => 
  {
    switch (source.type) 
    {
      case 'website':
        return 'Website';
      case 'pdf':
        return 'PDF';
      case 'text':
        return 'Text';
      case 'qa':
        return 'Q&A';
      default:
        return source.type;
    }
  };

  const renderContent = () => 
  {
    switch (activeTab) 
    {
      case 'website':
        return <WebsiteInput onSubmit={handleWebsiteSubmit} />;
      case 'pdf':
        return <PDFInput onSubmit={handlePDFSubmit} />;
      case 'text':
        return <TextInput onSubmit={handleTextSubmit} />;
      case 'qa':
        return <QAInput onSubmit={handleQASubmit} />;
    }
  };

  if (showNaming) 
  {
    return (
      <div className="max-w-3xl mx-auto">
        <div className="shadow-md border border-gray-100 dark:border-slate-700 dark:bg-slate-800 p-6 rounded-lg">
          <h2 className="text-xl font-semibold mb-4">Name Your Training</h2>
          <form onSubmit={onSubmitForm} className="space-y-4">
            <div className="flex gap-4">
              <div className="flex-1">
                <InputField
                  name="trainingName"
                  label="Training Name"
                  placeholder="Enter a name for your training"
                  type="text"
                  register={register}
                  errors={errors}
                  value={trainingNameValue}
                  onChange={register('trainingName').onChange}
                  className="w-full p-2 border rounded-md"
                />
              </div>
              <div className="w-10">
                <label className="block text-sm text-gray-500 dark:text-slate-300 mb-1">Icon</label>
                <button
                  type="button"
                  onClick={() => setShowIconPicker(true)}
                  className={`w-10 h-10 rounded-lg border-2 border-dashed flex items-center justify-center cursor-pointer ${iconSvg
                    ? 'border-indigo-500 hover:border-indigo-600 bg-gray-100 dark:bg-slate-800 text-indigo-500'
                    : 'border-gray-300 hover:border-gray-400 dark:border-slate-500 dark:hover:border-slate-400'
                  }`}
                >
                  {iconSvg ? (
                    <div dangerouslySetInnerHTML={{ __html: iconSvg }} className="w-5 h-5 mr-1 mb-1" />
                  ) : (
                    <ImageIcon className="w-5 h-5 text-gray-400 dark:text-slate-400" />
                  )}
                </button>
              </div>
            </div>
            <div>
              <TextareaField
                name="trainingDescription"
                label="Description (Optional)"
                placeholder="Enter a description for your training"
                rows={4}
                register={register}
                errors={errors}
                value={trainingDescriptionValue}
                onChange={register('trainingDescription').onChange}
                className="w-full p-2 border rounded-md min-h-[100px]"
              />
            </div>
            <div className="flex justify-end space-x-3 mt-6">
              <Button
                variant="success"
                buttonType="submit"
                disabled={!trainingNameValue.trim()}
              >
                Start Training
              </Button>
            </div>
          </form>
        </div>

        <IconPicker
          isOpen={showIconPicker}
          onClose={() => setShowIconPicker(false)}
          onSelect={setIconSvg}
        />
      </div>
    );
  }

  const tabs: TrainingSource['type'][] = ['website', 'pdf', 'text', 'qa'];

  return (
    <div className="max-w-3xl mx-auto">
      {/* Tabs */}
      <div className="bg-gray-200 dark:bg-slate-700 rounded-lg p-1 mb-8">
        <div className="flex">
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`
            flex-1 p-2 text-sm font-medium rounded-md transition-colors
            ${activeTab === tab
              ? 'bg-white dark:bg-slate-600 text-gray-900 dark:text-slate-100 shadow-sm'
              : 'text-gray-600 dark:text-slate-300'
            }`}
            >
              {tab === 'qa' ? 'Q&A' : tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
      </div>

      {/* Content */}
      {renderContent()}

      {/* Sources List */}
      {sources.length > 0 && (
        <div className="mt-4 shadow-md border border-gray-100 dark:border-slate-700 dark:bg-slate-800 rounded-lg p-4">
          <h3 className="text-lg font-medium dark:text-slate-100 mb-4">Added Sources</h3>
          <div className="space-y-3">
            {sources.map((source, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-3 bg-gray-50 dark:bg-slate-700 rounded-lg"
              >
                <div>
                  <span className="font-medium dark:text-slate-200">{getSourceTypeLabel(source)}</span>
                  <span className="ml-2 text-sm text-gray-500 dark:text-slate-400">
                    {source.type === 'website' ? source.url : source.content}
                  </span>
                </div>
                <button
                  onClick={() => handleRemoveSource(index)}
                  className="text-sm font-medium text-rose-500 hover:text-red-600"
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Action Buttons */}
      <div className="flex justify-end items-center mt-8">
        <div className="flex gap-3">
          <Button
            onClick={handleTrain}
            variant="success"
            type="rArrow"
            hideOnMobile="label"
            disabled={sources.length === 0}
          >
            Training
          </Button>
        </div>
      </div>
    </div>
  );
};