import React from 'react';

import { IGenAICategory } from '../Api/Types/GenAIInterface';
import { RoleBasedElement } from '../../../Api/Roles/RoleBasedElement';
import { Role } from '../../../Api/Roles/RoleManager';
import { Button } from '../../../Components/Actions/Buttons/Buttons';

interface CategoryRowProps 
{
  category: IGenAICategory;
  onDelete: (id: string) => void;
  onEdit: (item: IGenAICategory) => void;
}

export const GenAICategoryRow: React.FC<CategoryRowProps> = ({ category, onDelete, onEdit }) => 
{
  return (
    <tr>
      <td className="px-2 py-3 text-center">{category.name}</td>
      <td className="px-2 py-3 text-center">{category.description}</td>
      <RoleBasedElement requiredRoles={Role.Admin} as={React.Fragment}>
        <td className="px-2 py-3 flex justify-center ">
          <Button
            onClick={() => onEdit(category)}
            variant="primary"
            size="m"
            type="edit"
            hideText
            className="mr-2"
            title="Edit Category"
          >
            Edit Category
          </Button>

          <Button
            onClick={() => onDelete(category.id)}
            variant="danger"
            size="m"
            type="delete"
            hideText
            title="Delete Category"
          >
            Delete
          </Button>
        </td>
      </RoleBasedElement>
    </tr>
  );
};