import React, { useRef, useEffect, useContext } from 'react';
import { ContextMenuType } from './FileManager.types';
import { useUIState } from './Hooks/UseUiState';
import { useUIOperations } from './Hooks/UseUiOperations';
import { useContextMenuActions } from './Hooks/UseContextMenuActions';
import { getIntl } from '../../ReactIntl/IntlConfig';
import { ProgressContextActionsMenu } from '../../ReactIntl/LocaleInterfaces';
import {LanguageContext} from '../../Contexts/LanguageContext';

export const ContextMenuWrapper: React.FC = () => 
{
  const { contextMenuState } = useUIState();
  const { closeContextMenu } = useUIOperations();
  const handleContextMenuAction = useContextMenuActions();

  if (!contextMenuState) return null;

  return (
    <ContextMenu
      contextMenuState={contextMenuState}
      onClose={closeContextMenu}
      onAction={handleContextMenuAction}
    />
  );
};

interface ContextMenuProps 
{
  contextMenuState: ContextMenuType;
  onClose: () => void;
  onAction: (action: "rename" | "delete" | "addSubfolder" | "addFile", contextMenu: ContextMenuType) => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  contextMenuState,
  onClose,
  onAction
}) => 
{
  const _languageCtx = useContext(LanguageContext);
  const intl = getIntl<ProgressContextActionsMenu>("progressContextActionsMenu");
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => 
  {
    const handleClickOutside = (event: MouseEvent) => 
    {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) 
      {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => 
    {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={menuRef}
      className="fixed bg-white dark:bg-slate-800 border border-gray-300 dark:border-slate-500 shadow-md dark:shadow-slate-800 rounded-md overflow-hidden"
      style={{ top: contextMenuState!.y, left: contextMenuState!.x }}
    >
      {/* Bottone "Rename" */}
      {(contextMenuState!.type === 'file' || contextMenuState!.type === 'folder') && (
        <button
          className=" w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-slate-600 flex items-center"
          onClick={() => onAction("rename", contextMenuState)}
        >
          <svg className="w-4 h-4 fill-current text-slate-500 dark:text-slate-400 shrink-0" viewBox="0 0 16 16">
            <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
          </svg>
          <span className='ml-2'>{intl.rename}</span>
        </button>
      )}
      {/* Bottone "Add Subfolder" */}
      {(contextMenuState!.type === 'folder' || contextMenuState!.type === 'grid') && (
        <button
          className=" w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-slate-600 flex items-center"
          onClick={() => onAction("addSubfolder", contextMenuState)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-4 h-4 text-slate-500 dark:text-slate-400 shrink-0">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
          </svg>
          <span className='ml-2'>{intl.addNewFolder}</span>
        </button>
      )}
      {/* Bottone "Add File" */}
      {(contextMenuState!.type === 'folder' || contextMenuState!.type === 'grid') && (
        <button
          className=" w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-slate-600 flex items-center "
          onClick={() => onAction("addFile", contextMenuState)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="w-4 h-4 text-slate-500 dark:text-slate-400 shrink-0">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
          </svg>
          <span className='ml-2'>{intl.addNewFile}</span>
        </button>
      )}
      {/* Bottone "Delete" */}
      {(contextMenuState!.type === 'file' || contextMenuState!.type === 'folder') && (
        <button
          className=" w-full text-left px-4 py-2 hover:bg-gray-100 dark:hover:bg-slate-600 flex items-center"
          onClick={() => onAction("delete", contextMenuState)}
        >
          <svg className="w-4 h-4 fill-current text-slate-500 dark:text-slate-400 shrink-0" viewBox="0 0 18 18">
            <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
          </svg>
          <span className='ml-2'>{intl.delete}</span>
        </button>
      )}
    </div>
  );
};