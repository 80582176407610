import React from 'react';
import { User } from 'lucide-react';
import { MessageContent } from './MessageContent';
import { ChatbotStyles } from '../CommonStyles/ChatbotStyles';
import { GenAIChatMessage } from '../../Api/Types/GenAIInterface';
import { GenAIMessageRole } from '../../Api/Types/GenAIEnums';

interface MessageBubbleProps 
{
  message: GenAIChatMessage;
  chatbotIcon: string;
  loading: boolean;
  onSelection?: (selection: string) => void;
  messagesEndRef?: React.RefObject<HTMLDivElement>;
  chatContainerRef?: React.RefObject<HTMLDivElement>;
  style: typeof ChatbotStyles;
}

export const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  chatbotIcon,
  loading,
  onSelection,
  style
}) => 
{
  // console.log(chatbotIcon);
  const isAssistant = message.role !== GenAIMessageRole.Human;
  const bubbleClass = isAssistant
    // SPACE AFTER THE STYLE IS MANDATORY TO FORMAT THE TAILWIND STRING !!!! 🐈🐈🐈🐈🐈
    ? `${style.AIBubble} `
    : `${style.HumanBubble} `;

  // console.log(bubbleClass);

  return (
    <div className={`relative flex ${isAssistant ? 'justify-start' : 'justify-end'} mb-4`}>
      {isAssistant && (
        <img src={chatbotIcon} alt="Windy" className="w-8 h-8 rounded-full flex-shrink-0" />
      )}
      <div className={`max-w-[70%] p-3 ${bubbleClass} break-words`}>
        <div className="relative">
          <MessageContent
            message={message}
            isAssistant={isAssistant}
            loading={loading}
            onSelection={onSelection}
            style={style}
          />
        </div>
      </div>
      {!isAssistant && (
        <div className="w-8 h-8 rounded-full flex-shrink-0">
          <User size={32} />
        </div>
      )}
    </div>
  );
};
