import { ChatResponseWithoutSSE } from "../Features/GenAI/Windtre/WindTre.types";
import { apiFetch } from "./BeetClient";
import { RoleDto } from "./Roles/Role.types";
import { PhoneNumber } from "../Utils/PhoneNumber";
import {
  CouponDto,
  CouponResponse,
  FeatureDto,
  FeatureResponse,
  PriceDto,
  PriceResponse,
  ProductDto,
  ProductResponse,
  SubResponseDto,
} from "../Utils/Stripe.types";

import { UserDto } from "../Utils/User.types";

type ApiEndpointDefinition = Record<string, { url: string; invoke: unknown }>;

type Endpoints<T extends ApiEndpointDefinition> = {
  [K in keyof T]: { url: string; invoke: unknown };
};

class ApiControllerDef<T extends ApiEndpointDefinition> 
{
  public url: string;
  public endpoints: Endpoints<T>;

  constructor(url: string, endpoints: T) 
  {
    this.url = url;
    this.endpoints = this.prependBasePathToEndpoints(endpoints);
  }

  private prependBasePathToEndpoints(endpoints: T): Endpoints<T> 
  {
    const processedEndpoints: Partial<Endpoints<T>> = {};
    for (const key in endpoints) 
    {
      if (Object.prototype.hasOwnProperty.call(endpoints, key)) 
      {
        processedEndpoints[key] = {
          url: `${this.url}/${endpoints[key].url}`,
          invoke: endpoints[key].invoke,
        };
      }
    }

    return processedEndpoints as Endpoints<T>;
  }
}
export function createApiDefinition<T extends ApiEndpointDefinition>(
  url: string,
  endpoints: T
) 
{
  const result = new ApiControllerDef(`/${API_VERSION}/${url}`, endpoints)
    .endpoints as T & { _url: string };
  result._url = `/${API_VERSION}/${url}`;
  return result;
}

export const API_VERSION = "api";

export const apiDefinitions = {
  account: createApiDefinition("account", {
    login: { url: "login?useCookies=true", invoke: apiFetch.post<void> },
    logout: { url: "logout", invoke: apiFetch.post<{ statusCode: string }> },
    register: { url: "register", invoke: apiFetch.post<string> },
    sendPasswordResetLink: {
      url: "forgot-password",
      invoke: apiFetch.post<string>,
    },
    changePassword: { url: "reset-password", invoke: apiFetch.post<string> },
    changeOldPassword: {
      url: "change-password",
      invoke: apiFetch.post<string>,
    },
    fetchExternalLoginProvider: {
      url: "external-login-list",
      invoke: apiFetch.get<string[]>,
    },
    externalLogin: { url: "external-login", invoke: apiFetch.get<string> },
    externalLoginCallback: {
      url: "external-login-callback",
      invoke: apiFetch.get<string>,
    },
    resendEmail: {
      url: "resend-confirmation-email",
      invoke: apiFetch.post<string>,
    },
  }),

  user: createApiDefinition("user", {
    profileInfo: { url: "profile-info", invoke: apiFetch.patch<string> },
    profilePicture: {
      url: "profile-picture",
      invoke: apiFetch.put<{ imageUrl?: string }>,
    },
    phoneNumber: { url: "phone-number", invoke: apiFetch.put<PhoneNumber> },
  }),

  debug: createApiDefinition("debug", {
    ping: { url: "ping", invoke: apiFetch.get<string> },
    createRandomUsers: {
      url: "create-random-users",
      invoke: apiFetch.post<number>,
    },
  }),

  userList: createApiDefinition("entities/users", {
    getUsers: { url: "", invoke: apiFetch.get<UserDto[]> },
    getUserById: { url: "{id}", invoke: apiFetch.get<UserDto> },
    addUser: { url: "", invoke: apiFetch.post<UserDto> },
    editUser: { url: "{id}", invoke: apiFetch.put<UserDto> },
    deleteUser: { url: "{id}", invoke: apiFetch.delete<string> },
    count: { url: "count", invoke: apiFetch.get<number> },
  }),

  roleList: createApiDefinition("entities/roles", {
    getRoles: { url: "", invoke: apiFetch.get<RoleDto[]> },
    getRoleById: { url: "{id}", invoke: apiFetch.get<RoleDto> },
    editRole: { url: "{id}", invoke: apiFetch.put<RoleDto> },
    count: { url: "count", invoke: apiFetch.get<number> },
  }),

  connect: createApiDefinition("connect", {
    authorize: { url: "authorize", invoke: apiFetch.form<URLSearchParams> },
  }),

  products: createApiDefinition("stripe/products", {
    getAll: { url: "", invoke: apiFetch.get<ProductResponse> },
    getProduct: { url: "{id}", invoke: apiFetch.get<ProductDto> },
    createProduct: { url: "", invoke: apiFetch.post<ProductDto> },
    updateProduct: { url: "{id}", invoke: apiFetch.put<ProductDto> },
    deleteProduct: { url: "{id}", invoke: apiFetch.delete<string> },
    getSeries: { url: "series", invoke: apiFetch.get<string[]> },
  }),
  subscriptions: createApiDefinition("stripe/subscriptions", {
    getAll: { url: "", invoke: apiFetch.get<[]> },
    getSub: { url: "{id}", invoke: apiFetch.get<URL> },
    createSub: { url: "", invoke: apiFetch.post<SubResponseDto> },
    deleteSub: { url: "{id}", invoke: apiFetch.delete<string> },
  }),
  price: createApiDefinition("stripe/price", {
    getAll: { url: "", invoke: apiFetch.get<PriceResponse> },
    getPrice: { url: "", invoke: apiFetch.get<PriceResponse> },
    updatePrice: { url: "{id}", invoke: apiFetch.put<PriceDto> },
  }),
  coupon: createApiDefinition("stripe/coupons", {
    getUserEntitlements: { url: "{id}", invoke: apiFetch.get<string[]> },
    getCoupon: { url: "", invoke: apiFetch.get<CouponResponse> },
    createCoupon: { url: "", invoke: apiFetch.post<CouponDto> },
    deleteCoupon: { url: "{id}", invoke: apiFetch.delete<string> },
  }),
  feature: createApiDefinition("stripe/features", {
    getAll: { url: "", invoke: apiFetch.get<FeatureResponse> },
    getFeature: { url: "{id}", invoke: apiFetch.get<FeatureDto> },
    createFeature: { url: "", invoke: apiFetch.post<FeatureDto> },
    updateFeature: { url: "{id}", invoke: apiFetch.put<FeatureDto> },
    disableFeature: { url: "{id}", invoke: apiFetch.delete<FeatureDto> },
    attachFeature: { url: "{id}/attach", invoke: apiFetch.post<string> },
    detachFeature: { url: "{id}/detach", invoke: apiFetch.post<string> },
  }),

  genAIChat: createApiDefinition("gen-ai/chat", {
    configure: {
      url: "configure",
      invoke: apiFetch.put<{ statusCode: string }>,
    },
    send: { url: "send", invoke: apiFetch.post<string> },
  }),

  windTreChat: createApiDefinition("gen-ai/chatbots/windTre", {
    send: {
      url: "{storeId}/send",
      invoke: apiFetch.post<ChatResponseWithoutSSE>,
    },
    getInstructions: {
      url: "{storeId}/instructions",
      invoke: apiFetch.get<string>,
    },
    updateInstructions: {
      url: "{storeId}/instructions",
      invoke: apiFetch.put<string>,
    },
    getCategories: {
      url: "{storeId}/categories",
      invoke: apiFetch.get<string>,
    },
    getTokenUsage: { url: "{storeId}/usage", invoke: apiFetch.get<string> },
    getStoreIds: { url: "", invoke: apiFetch.get<string[]> },
  }),
};
