import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { ModalContext } from './ModalContext';
import { InfoIcon } from './InfoIcon';
import { Button } from '../Buttons/Buttons';
import { ModalProps } from './ModalTypes';

export const MainModal: React.FC<ModalProps> = ({ children, onConfirm, setOpen, variant = 'primary', title }) => 
{
  const [isConfirming, setIsConfirming] = useState(false);
  const [animationState, setAnimationState] = useState<'entering' | 'entered' | 'exiting'>('entering');
  const modalRef = useRef<HTMLDivElement>(null);

  // Funzione per chiudere la modale con animazione di uscita
  const close = useCallback(() => 
  {
    setAnimationState('exiting');
    setTimeout(() => 
    {
      setOpen(false);
    }, 200); // Durata dell'animazione di uscita (in millisecondi)
  }, [setOpen]);

  // Funzione di conferma della modale
  const confirm = async () => 
  {
    if (onConfirm) 
    {
      setIsConfirming(true);
      try 
      {
        await onConfirm();
      }
      catch (error) 
      {
        console.error('Errore durante la conferma:', error);
      }
      finally 
      {
        setIsConfirming(false);
      }
    }
    else 
    {
      close();
    }
  };

  // Gestore per la chiusura tramite tasto Esc
  useEffect(() => 
  {
    const handleKeyDown = (event: KeyboardEvent) => 
    {
      if (event.key === 'Escape') 
      {
        close();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => 
    {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [close]);

  // Funzione per la chiusura cliccando fuori dalla modale
  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => 
  {
    if (event.target === event.currentTarget) 
    {
      close();
    }
  };

  // stili di animazione
  const getAnimationStyle = () => 
  {
    switch (animationState) 
    {
      case 'entering':
        return {
          opacity: 0,
          transform: 'translateY(15px)',
          transition: 'opacity 0.1s ease-out, transform 0.1s ease-out',
        };
      case 'entered':
        return {
          opacity: 1,
          transform: 'translateY(0)',
          transition: 'opacity 0.1s ease-out, transform 0.1s ease-out',
        };
      case 'exiting':
        return {
          opacity: 0,
          transform: 'translateY(10px)',
          transition: 'opacity 0.2s ease-in, transform 0.2s ease-in',
        };
      default:
        return {};
    }
  };

  // Imposta lo stato su "entered" una volta che il componente è montato
  useEffect(() => 
  {
    const timer = setTimeout(() => 
    {
      setAnimationState('entered');
    }, 10); // Piccolo ritardo per innescare l'animazione

    return () => clearTimeout(timer);
  }, []);

  const renderIcon = () => 
  {
    if (variant === 'success') 
    {
      return <InfoIcon type="success" />;
    }
    if (variant === 'danger') 
    {
      return <InfoIcon type="danger" />;
    }
    return null; // Nessuna icona per la variant "primary"
  };

  return ReactDOM.createPortal(
    <ModalContext.Provider value={{ close, confirm, isConfirming, variant }}>
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
        onClick={handleOverlayClick}
        style={{ transition: 'opacity 0.3s ease-out', opacity: animationState === 'exiting' ? 0 : 1 }}
      >
        <div
          ref={modalRef}
          className="bg-slate-50 dark:bg-slate-800 p-5 rounded-lg shadow-lg w-full max-w-lg flex items-start"
          style={getAnimationStyle()}
        >
          <Button
            variant="noBorder"
            size="s"
            hideText
            type="cancel"
            className="absolute top-5.5 right-5" // Posiziona il pulsante
            onClick={close}
          />
          {renderIcon() && <div className="flex items-center justify-center mr-3">{renderIcon()}</div>}
          <div className="flex-1 text-sm mb-5 flex flex-col gap-3">
            {/* Aggiungi il titolo qui, se presente */}
            {title && <h2 className="text-lg font-semibold mb-3">{title}</h2>}
            {children}
          </div>
        </div>
      </div>
    </ModalContext.Provider>,
    document.body
  );
};