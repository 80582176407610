import { Tooltip } from '../../Components/Tooltip';
import { FieldValues, get, UseFormWatch } from 'react-hook-form';
import { BaseFieldProps } from '../Utils/FormFields.types';
import { ChangeEvent } from 'react';

export interface InputFieldProps<T extends FieldValues> extends BaseFieldProps<T> 
{
  type?: 'text' | 'number' | 'email' ;
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  watch?: UseFormWatch<T>;
}

export const InputField = <T extends FieldValues>({
  name,
  label,
  required,
  tooltip,
  register,
  errors,
  type = 'text',
  placeholder,
  value,
  className,
  onChange,
  watch,
}: InputFieldProps<T>) => 
{
  const error = get(errors, name);
  const errorMessage = error?.message as string;

  return (
    <div className="w-full">
      <div className="flex justify-center items-center gap-2 text-center mb-1">
        <label className="block text-sm font-medium" htmlFor={name}>
          {label}
          {required && <span className="text-rose-500">*</span>}
        </label>
        {tooltip && (
          <Tooltip position="top">
            <div className="text-xs whitespace-nowrap">{tooltip}</div>
          </Tooltip>
        )}
      </div>

      <div className="relative">
        <input
          {...register(name)}
          id={name}
          type={type}
          placeholder={placeholder}
          className={`mt-1 block w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'}
               rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
                bg-white dark:bg-slate-800 text-gray-900 dark:text-gray-100 ${className}`}
          onChange={onChange}
          value={value}
        />
      </div>
      {error && (
        <div className="text-xs mt-1 text-rose-500">{errorMessage}</div>
      )}
    </div>
  );
};
