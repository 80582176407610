import React, { useReducer, useEffect, FormEvent, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthImage from '../../Images/auth-image.jpg';
import AuthDecoration from '../../Images/auth-decoration.png';
import { Banner } from '../../Components/Banner';
import { Tooltip } from '../../Components/Tooltip';
import zxcvbn from 'zxcvbn';
import { ExternalLoginButtons } from '../../Api/ExternalLoginButtons';
import { getIntl, setLocale } from '../../ReactIntl/IntlConfig';
import { LanguageSelector } from '../../ReactIntl/LanguageSelector';
import { accountController } from '../../Api/Controller/ApiAccount';
import { PasswordRequirementsLocale, SignUpPageLocale } from '../../ReactIntl/LocaleInterfaces';
import { initialSignupState, signupReducer } from '../../Reducer/SignupReducer';
import { SignupActions } from '../../Reducer/SignupActions';
import { AxiosError } from 'axios';
import { useError } from '../../Hooks/UseError';
import { getPasswordCriteriaDisplay, doPasswordsMatch, checkPasswordCriteria, getPasswordCriteriaMessages, getPasswordStrengthBarColor } from '../../Utils/PasswordUtils';

export const Signup: React.FC = () =>
{
  const [state, dispatch] = useReducer(signupReducer, initialSignupState);
  const intl = getIntl<SignUpPageLocale>('signupPage');
  const intlPass = getIntl<PasswordRequirementsLocale>('password');
  const navigate = useNavigate();
  const { isBlocked, retrySeconds } = useError();

  const { containerClasses, criteriaElements } = getPasswordCriteriaDisplay(state.passwordCriteria, intlPass, state.focusedInput);

  useEffect(() => 
  { 
    if(retrySeconds && isBlocked)
    {
      dispatch(new SignupActions.SetErrorMessage(`Too many requests, wait ${retrySeconds} seconds and try again`));
      dispatch(new SignupActions.SetBannerOpen(true));
    }
  }, [isBlocked, retrySeconds]);

  useEffect(() =>
  {
    dispatch(new SignupActions.SetPasswordStrength(zxcvbn(state.password)));
  }, [state.password]);

  const handleSetBannerOpen = useCallback((open: boolean | ((prevState: boolean) => boolean)) =>
  {
    if (typeof open === 'function')
    {
      dispatch(new SignupActions.SetBannerOpen(open(state.bannerOpen)));
    }
    else
    {
      dispatch(new SignupActions.SetBannerOpen(open));
    }
  }, [state.bannerOpen]);

  const handleSignup = async (event: FormEvent) =>
  {
    event.preventDefault();
    dispatch(new SignupActions.SetIsLoading(true));

    if (!doPasswordsMatch(state.password, state.confirmPassword))
    {
      dispatch(new SignupActions.SetErrorMessage(intl.passwordMismatch));
      dispatch(new SignupActions.SetBannerOpen(true));
      dispatch(new SignupActions.SetIsLoading(false));
      return;
    }

    try
    {
      await accountController.register(state.email, state.password, state.confirmPassword, state.firstName, state.lastName);
      dispatch(new SignupActions.SetSuccessMessage(intl.confirmEmail));
      dispatch(new SignupActions.SetBannerOpen(true));
      dispatch(new SignupActions.SetEmail(''));
      dispatch(new SignupActions.SetFirstName(''));
      dispatch(new SignupActions.SetLastName(''));
      dispatch(new SignupActions.SetPassword(''));
      dispatch(new SignupActions.SetConfirmPassword(''));
      dispatch(new SignupActions.SetIsLoading(false));
      setTimeout(() =>
      {
        navigate('/account/login');
      }, 3000);
    }
    catch (error) 
    {
      dispatch(new SignupActions.SetIsLoading(false));
  
      if (error instanceof AxiosError && error.response)
      {
        if (error.response.data.errors && error.response.data.errors.DuplicateEmail) 
        {
          dispatch(new SignupActions.SetErrorMessage(intl.emailAlreadyUsed));
          dispatch(new SignupActions.SetBannerOpen(true));
          return;
        }
        else if(error.response.data.errors && error.response.data.errors.Email)
        {
          dispatch(new SignupActions.SetErrorMessage(intl.emailFormatError));
          dispatch(new SignupActions.SetBannerOpen(true));
          return;
        }
        dispatch(new SignupActions.SetErrorMessage(intl.genericError));
        dispatch(new SignupActions.SetBannerOpen(true));
      }
      else 
      {
        dispatch(new SignupActions.SetErrorMessage(intl.networkError));
        dispatch(new SignupActions.SetBannerOpen(true));
      }
    }
  };

  const handleLocaleChange = (newLocale: string) =>
  {
    dispatch(new SignupActions.SetSelectedLocale(newLocale));
    setLocale(newLocale);
  };

  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative md:flex">
        <div className="md:w-1/2">
          <div className="min-h-[100vh] h-full flex flex-col after:flex-1">
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                <Link className="block" to="/">
                  <img src="/beet-icon.svg" alt="Beet Root" className="mx-auto mb-4 my-16 w-16 h-16" />
                </Link>
                <div className='flex place-content-end w-40'>
                  <LanguageSelector selectedLanguage={state.selectedLocale} onLanguageChange={handleLocaleChange} />
                </div>
              </div>
            </div>

            <div className="max-w-sm mx-auto w-full px-4 py-8">
              <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">
                {intl.title}
              </h1>
              {state.errorMessage && (
                <Banner
                  className="mb-4"
                  type="error"
                  open={state.bannerOpen}
                  setOpen={handleSetBannerOpen}
                >
                  {state.errorMessage}
                </Banner>
              )}
              {state.successMessage && (
                <Banner
                  className="mb-4"
                  type="success"
                  open={state.bannerOpen}
                  setOpen={handleSetBannerOpen}
                >
                  {state.successMessage}
                </Banner>
              )}
              <form onSubmit={handleSignup}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">
                      {intl.emailLabel}{' '}
                      <span className="text-rose-500">*</span>
                    </label>
                    <input
                      id="email"
                      className="form-input w-full"
                      type="email"
                      value={state.email}
                      onChange={(e) => dispatch(new SignupActions.SetEmail(e.target.value))}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="firstName">
                      {intl.firstNameLabel}{' '}
                    </label>
                    <input
                      id="firstName"
                      className="form-input w-full"
                      type="text"
                      value={state.firstName}
                      onChange={(e) => dispatch(new SignupActions.SetFirstName(e.target.value))}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="lastName">
                      {intl.lastNameLabel}{' '}
                    </label>
                    <input
                      id="lastName"
                      className="form-input w-full"
                      type="text"
                      value={state.lastName}
                      onChange={(e) => dispatch(new SignupActions.SetLastName(e.target.value))}
                    />
                  </div>
                  <div className="relative">
                    <label className="block text-sm font-medium mb-1" htmlFor="password">
                      {intl.passwordLabel}{' '}
                      <span className="text-rose-500">*</span>
                    </label>
                    <div className="flex items-center">
                      <input
                        id="password"
                        className="form-input w-full"
                        type="password"
                        value={state.password}
                        onFocus={() =>
                        {
                          dispatch(new SignupActions.SetTooltipOpen(true));
                          dispatch(new SignupActions.SetFocusedInput('password'));
                        }}
                        onBlur={() =>
                        {
                          dispatch(new SignupActions.SetTooltipOpen(false));
                          dispatch(new SignupActions.SetFocusedInput(''));
                        }}
                        autoComplete="on"
                        required
                        onChange={(e) =>
                        {
                          dispatch(new SignupActions.SetPassword(e.target.value));
                          dispatch(new SignupActions.SetPasswordCriteria(checkPasswordCriteria(e.target.value)));
                          dispatch(new SignupActions.SetPasswordMatch(e.target.value === state.confirmPassword));
                        }}
                      />
                      {state.tooltipOpen && (
                        <Tooltip className="ml-2" position="right" size="sm" bg="dark" open={state.tooltipOpen}>
                          <ul>
                            {Object.entries(getPasswordCriteriaMessages(state.passwordCriteria, intlPass)).map(([key, value]) => (
                              <li key={key}>{value}</li>
                            ))}
                          </ul>
                        </Tooltip>
                      )}
                    </div>
                    <div className={containerClasses}>
                      {criteriaElements.map((element, index) => (
                        <p key={index} className={element.classes}>
                          {element.message}
                        </p>
                      ))}
                    </div>
                    {state.passwordStrength && (
                      <div className="mt-2">
                        <div className="flex">
                          {Array.from({ length: 5 }, (_, i) => (
                            <div
                              key={i}
                              className={`h-2 flex-1 mx-1 rounded ${i <= (state.passwordStrength?.score ?? -1)
                                ? getPasswordStrengthBarColor(state.passwordStrength?.score ?? 0)
                                : 'bg-gray-300'
                              }`}
                            ></div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="confirmPassword">
                      {intl.confirmPasswordLabel}{' '}
                      <span className="text-rose-500">*</span>
                    </label>
                    <input
                      id="confirmPassword"
                      className={`form-input w-full border-slate-200 dark:border-slate-700 ${state.passwordMatch ? '' : 'border-red-500'}`}
                      type="password"
                      value={state.confirmPassword}
                      onChange={(e) =>
                      {
                        dispatch(new SignupActions.SetConfirmPassword(e.target.value));
                        dispatch(new SignupActions.SetPasswordMatch(e.target.value === state.password));
                      }}
                      autoComplete="on"
                      required
                    />
                    {!state.passwordMatch && <p className="text-xs text-red-500">{intlPass.match}</p>}
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                  </div>
                  <button
                    type="submit"
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap"
                    disabled={state.isLoading}
                  >
                    {state.isLoading ?
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                      </svg>
                      : intl.signUpButton}
                  </button>
                </div>
              </form>
              <ExternalLoginButtons />
              <div className="pt-5 mt-6 border-t border-slate-200 dark:border-slate-700">
                <div className="text-sm">
                  {intl.haveAccountMessage}{' '}
                  <Link className="font-medium text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400" to="/account/login">
                    {intl.signInLink}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
          <img
            className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div>
      </div>
    </main>
  );
};