
import { Tooltip } from '../../Components/Tooltip';
import { FieldValues } from 'react-hook-form';
import { BaseFieldProps } from '../Utils/FormFields.types';
import { ChangeEvent } from 'react';

export interface ColorInputProps<T extends FieldValues> extends BaseFieldProps<T> 
{
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export const ColorInputField = <T extends FieldValues>({
  name,
  label,
  required,
  tooltip,
  register,
  errors,
  value,
  className,
  onChange,
}: ColorInputProps<T>) => 
{

  return (
    <div className="w-full">
      <div className="flex justify-center items-center gap-2 text-center mb-1">
        <label className="block text-sm font-medium" htmlFor={name}>
          {label}
          {required && <span className="text-rose-500">*</span>}
        </label>
        {tooltip && (
          <Tooltip position="top">
            <div className="text-xs whitespace-nowrap">{tooltip}</div>
          </Tooltip>
        )}
      </div>

      <div className="w-full form-input flex items-center gap-3">
        <div className="size-5 relative gap-4 overflow-hidden rounded-full border shadow-sm focus-within:ring focus-within:ring-secondary">
          <input
            {...register(name)}
            id={name}
            value={value}
            className={`${className} relative -start-1/2 -top-1/2 h-[200%] w-[200%] cursor-pointer appearance-none rounded-full border-none p-0`}
            type="color"
            onChange={onChange}
          />
          {errors.templateColor && typeof errors.templateColor.message === 'string' && (
            <div className="text-xs mt-1 text-rose-500">{errors.templateColor.message}</div>
          )}
        </div>
        <input
          id={`${name}-colorValue`}
          value={value}
          className="border-none bg-transparent text-inherit outline-none"
          type="text"
          readOnly
        />
      </div>

    </div>
  );
};
