import { useState, useCallback } from 'react';

import { WindTreCategoryDto, WindTreOfferDto } from './MoritzinoAssets/MoritzinoInterface';
import { windtreChatController } from '../../Api/Controller/GenAI/Chatbot/ApiWindTreChat';
import { GenAIMessageRole } from '../../Features/GenAI/Api/Types/GenAIEnums';
import { GenAIChatMessage } from '../../Features/GenAI/Api/Types/GenAIInterface';

interface WindChatbotProgrammaticProps 
{
  storeId: string;
  onMessagesUpdate: (newMessages: GenAIChatMessage[]) => void;
  onCategoriesUpdate: (categories: WindTreCategoryDto[]) => void;
  onCurrentCategoryUpdate: (category: WindTreCategoryDto | null) => void;
  onCurrentOfferUpdate: (offer: WindTreOfferDto | null) => void;
}

export const useMoritzinoChatbotProgrammatic = ({
  storeId,
  onMessagesUpdate,
  onCategoriesUpdate,
  onCurrentCategoryUpdate,
  onCurrentOfferUpdate,
}: WindChatbotProgrammaticProps) => 
{
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleTypeSelection = useCallback(async (isBusinessUser: boolean) => 
  {
    try 
    {
      const response = await windtreChatController.getCategories(storeId, { isBusiness: isBusinessUser });

      if (Array.isArray(response.data)) 
      {
        onCategoriesUpdate(response.data);
        const categoryNames = response.data.map(category => category.name);
        const aiMessage = ` Ecco le categorie disponibili per i clienti ${isBusinessUser ? 'business' : 'privati'}`;

        onMessagesUpdate([{
          message: aiMessage,
          role: GenAIMessageRole.Assistant,
          isSelectionPrompt: true,
          options: [...categoryNames, 'Cercavo altro']
        }]);
      }
    }
    catch (error) 
    {
      setErrorMessage("Si è verificato un errore nel recupero delle categorie. Riprova.");
    }
  }, [storeId, onCategoriesUpdate, onMessagesUpdate]);

  const handleCategorySelection = useCallback((categoryName: string, categories: WindTreCategoryDto[]) => 
  {
    if (categoryName === 'Cercavo altro') 
    {
      return null;
    }

    const selectedCategory = categories.find(cat => cat.name === categoryName);
    if (selectedCategory) 
    {
      onCurrentCategoryUpdate(selectedCategory);
      const offerNames = selectedCategory.offers.map(offer => offer.name);
      const aiMessage = `Che offerta stai cercando?`;

      onMessagesUpdate([{
        message: aiMessage,
        role: GenAIMessageRole.Assistant,
        isSelectionPrompt: true,
        options: [...offerNames, 'Cercavo altro']
      }]);
    }
    return selectedCategory;
  }, [onCurrentCategoryUpdate, onMessagesUpdate]);

  const handleOfferSelection = useCallback((offerName: string, currentCategory: WindTreCategoryDto | null) => 
  {
    if (offerName === 'Cercavo altro' || !currentCategory) 
    {
      return null;
    }

    const selectedOffer = currentCategory.offers.find(off => off.name === offerName);
    if (selectedOffer) 
    {
      onCurrentOfferUpdate(selectedOffer);
      const aiMessage = ` ${selectedOffer.name}: ${selectedOffer.description}`;

      onMessagesUpdate([{
        message: aiMessage,
        role: GenAIMessageRole.Assistant,
        isSelectionPrompt: true,
        options: ["Vai all'offerta",'Cercavo altro'], 
      }]);
    }
    return selectedOffer;

  }, [onCurrentOfferUpdate, onMessagesUpdate]);

  return {
    errorMessage,
    handleTypeSelection,
    handleCategorySelection,
    handleOfferSelection,
  };
};