import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import * as ChatbotComponents from './ChatbotUtils/ChatbotIndex';
import { Role } from '../../Api/Roles/RoleManager';
import { AiChatPage } from './TemplateSendRendering/AIChatPage';
import { ProtectedRoute } from '../../Routing/ProtectedRoute';

type ChatbotConfig = {
  path: string;
  componentName: keyof typeof ChatbotComponents;
  hasId?: boolean;
};

const chatbots: ChatbotConfig[] = [
  { path: 'windtre', componentName: 'WindChatbot', hasId: true },
  { path: 'windtretest', componentName: 'WindChatbotTest', hasId: true },
  { path: 'brissago', componentName: 'BrissagoChatbot' },
  { path: 'rimini', componentName: 'RiminiChatbot' },
  { path: 'moritzino', componentName: 'MoritzinoChatbot', hasId: true },
];

interface ChatbotComponentWithId 
{
  id?: string;
}

const ChatbotWrapper: React.FC<{ componentName: keyof typeof ChatbotComponents; hasId?: boolean }> = ({ componentName, hasId }) => 
{
  const Component = ChatbotComponents[componentName] as React.ComponentType<ChatbotComponentWithId>;
  return <Component id={hasId ? window.location.pathname.split('/').pop() : undefined} />;
};

export const ChatbotRoutes: React.FC = () => 
{
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <Routes>
      {chatbots.map(({ path, componentName, hasId }) => (
        <Route
          key={path}
          path={`${path}${hasId ? '/:id' : ''}`}
          element={
            isAdminRoute ? (
              <ProtectedRoute
                element={() => <ChatbotWrapper componentName={componentName} hasId={hasId} />}
                roles={[Role.Admin]}
              />
            ) : (
              <ChatbotWrapper componentName={componentName} hasId={hasId} />
            )
          }
        />
      ))}
      <Route
        path="ai-chat/:id"
        element={
          isAdminRoute ? (
            <ProtectedRoute element={AiChatPage} roles={[Role.Admin]} />
          ) : (
            <AiChatPage />
          )
        }
      />
    </Routes>
  );
};