import React, { useRef, useEffect, useCallback } from 'react';
import { useThemeProvider } from '../Utils/ThemeContext';
import { chartColors } from './ChartjsConfig';
import {
  Chart, PolarAreaController, RadialLinearScale, Tooltip, Legend,
} from 'chart.js';
import 'chartjs-adapter-moment';

// Import utilities
import { tailwindConfig } from '../Utils/Utils';
import { ChartProps } from './Charts';

Chart.register(PolarAreaController, RadialLinearScale, Tooltip, Legend);

export const PolarChart: React.FC<ChartProps> = ({ data, width, height }) => 
{
  const chartRef = useRef<Chart | null>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const legend = useRef<HTMLUListElement>(null);
  const { currentTheme } = useThemeProvider();
  const darkMode = currentTheme === 'dark';
  const {
    gridColor,
    textColor,
    backdropColor,
    tooltipTitleColor,
    tooltipBodyColor,
    tooltipBgColor,
    tooltipBorderColor,
  } = chartColors;

  // Usa useCallback per creare una versione stabile di createChart
  const createChart = useCallback(() => 
  {
    if (!canvas.current) return;

    const ctx = canvas.current;

    // Distruggi il grafico precedente se esiste
    if (chartRef.current) 
    {
      chartRef.current.destroy();
    }

    const newChart = new Chart(ctx, {
      type: 'polarArea',
      data: data,
      options: {
        layout: {
          padding: 24,
        },
        scales: {
          r: {
            grid: {
              color: darkMode ? gridColor.dark : gridColor.light,
            },
            ticks: {
              color: darkMode ? textColor.dark : textColor.light,
              backdropColor: darkMode ? backdropColor.dark : backdropColor.light,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            titleColor: darkMode ? tooltipTitleColor.dark : tooltipTitleColor.light,
            bodyColor: darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light,
            backgroundColor: darkMode ? tooltipBgColor.dark : tooltipBgColor.light,
            borderColor: darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        animation: {
          duration: 500,
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
      plugins: [
        {
          id: 'htmlLegend',
          afterUpdate(c, _args, _options) 
          {
            const ul = legend.current;
            if (!ul) return;

            while (ul.firstChild) 
            {
              ul.firstChild.remove();
            }

            const legendItems = c.options?.plugins?.legend?.labels?.generateLabels?.(c);
            if (!legendItems) return;

            legendItems.forEach((item) => 
            {
              const li = document.createElement('li');
              li.style.margin = tailwindConfig().theme.margin[1];

              const button = document.createElement('button');
              button.classList.add(
                'btn-xs',
                'bg-white',
                'dark:bg-slate-800',
                'text-slate-500',
                'dark:text-slate-400',
                'border',
                'border-slate-200',
                'dark:border-slate-700',
                'shadow-md'
              );
              button.style.opacity = item.hidden ? '.3' : '';
              button.onclick = () => 
              {
                if (typeof item.index !== 'undefined') 
                {
                  c.toggleDataVisibility(item.index);
                  c.update();
                }
              };

              const box = document.createElement('span');
              box.style.display = 'block';
              box.style.width = tailwindConfig().theme.width[2];
              box.style.height = tailwindConfig().theme.height[2];
              box.style.backgroundColor = typeof item.fillStyle === 'string' ? item.fillStyle : 'transparent';
              box.style.borderRadius = tailwindConfig().theme.borderRadius.sm;
              box.style.marginRight = tailwindConfig().theme.margin[1];
              box.style.pointerEvents = 'none';

              const label = document.createElement('span');
              label.style.display = 'flex';
              label.style.alignItems = 'center';
              const labelText = document.createTextNode(item.text);
              label.appendChild(labelText);
              li.appendChild(button);
              button.appendChild(box);
              button.appendChild(label);
              ul.appendChild(li);
            });
          },
        },
      ],
    });

    // Assegna il nuovo grafico al ref
    chartRef.current = newChart;
  }, [data, darkMode, gridColor, textColor, backdropColor, tooltipTitleColor, tooltipBodyColor, tooltipBgColor, tooltipBorderColor]);

  useEffect(() => 
  {
    createChart();

    // Pulizia del grafico quando il componente si smonta
    return () => 
    {
      if (chartRef.current) 
      {
        chartRef.current.destroy();
      }
    };
  }, [createChart, currentTheme, data]);

  return (
    <div className="grow flex flex-col justify-center">
      <div>
        <canvas ref={canvas} width={width} height={height}></canvas>
      </div>
      <div className="px-5 pt-2 pb-6">
        <ul ref={legend} className="flex flex-wrap justify-center -m-1"></ul>
      </div>
    </div>
  );
};
