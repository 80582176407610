import { useFileSystem } from "./UseFileSystem";
import { useProgress } from "./UseProgress";
import { Folder, FileItem } from "../FileManager.types";
import { generateId } from "../Utils";
import { copyItem } from "../Utils/CopyItem";

export const useFileSystemOperations = () => 
{
  const {
    rootFolder,
    setRootFolder,
    selectedFolder,
    setSelectedFolder,
    setBreadcrumb,
  } = useFileSystem();
  const { addOrShowProgressItem } = useProgress();

  const addFolder = (name: string, parentId: string) => 
  {
    const newFolder: Folder = {
      id: generateId(),
      name,
      subfolders: [],
      files: [],
      parentId,
    };
    setRootFolder((prevRoot) =>
      addItemToStructure(prevRoot, parentId, newFolder, "folder")
    );
    const parentFolder = findItem(rootFolder, parentId, "folder") as Folder;
    addOrShowProgressItem({
      type: "add",
      itemName: name,
      itemType: "folder",
      toLocation: parentFolder ? parentFolder.name : "Root",
      timestamp: new Date(),
    });
  };

  const addFile = (name: string, parentId: string) => 
  {
    const newFile: FileItem = { id: generateId(), name, parentId };
    setRootFolder((prevRoot) =>
      addItemToStructure(prevRoot, parentId, newFile, "file")
    );
    const parentFolder = findItem(rootFolder, parentId, "folder") as Folder;
    addOrShowProgressItem({
      type: "add",
      itemName: name,
      itemType: "file",
      toLocation: parentFolder ? parentFolder.name : "Root",
      timestamp: new Date(),
    });
  };

  const addExternalFile = (file: File) => 
  {
    const newFile: FileItem = {
      id: generateId(),
      name: file.name,
      parentId: selectedFolder.id,
    };
    setRootFolder((prevRoot) =>
      addItemToStructure(prevRoot, selectedFolder.id, newFile, "file")
    );

    const parentPath = findFolderPath(rootFolder, selectedFolder.id);
    addOrShowProgressItem({
      type: "add",
      itemName: file.name,
      itemType: "file",
      toLocation: parentPath,
      timestamp: new Date(),
    });
  };

  const deleteItem = (id: string, type: "folder" | "file" | "grid") => 
  {
    const item = findItem(rootFolder, id, type);
    const parentFolder = findItem(
      rootFolder,
      item!.parentId,
      "folder"
    ) as Folder;
    setRootFolder((prevRoot) => deleteItemFromStructure(prevRoot, id, type));
    addOrShowProgressItem({
      type: "delete",
      itemName: item!.name,
      itemType: type,
      fromLocation: parentFolder ? parentFolder.name : "Root",
      timestamp: new Date(),
    });
  };

  const renameItem = (
    id: string,
    newName: string,
    type: "folder" | "file" | "grid"
  ) => 
  {
    const item = findItem(rootFolder, id, type);
    const oldName = item!.name;
    setRootFolder((prevRoot) =>
      renameItemInStructure(prevRoot, id, newName, type)
    );
    addOrShowProgressItem({
      type: "rename",
      itemName: newName,
      itemType: type,
      fromLocation: oldName,
      timestamp: new Date(),
    });
  };

  const moveItem = (
    itemId: string,
    sourceId: string,
    targetId: string,
    type: "folder" | "file"
  ) => 
  {
    const item = findItem(rootFolder, itemId, type);
    const sourceFolder = findItem(rootFolder, sourceId, "folder") as Folder;
    const targetFolder = findItem(rootFolder, targetId, "folder") as Folder;
    setRootFolder((prevRoot) => 
    {
      const newRoot = deleteItemFromStructure(prevRoot, itemId, type);
      return addItemToStructure(newRoot, targetId, item!, type);
    });
    addOrShowProgressItem({
      type: "move",
      itemName: item!.name,
      itemType: type,
      fromLocation: sourceFolder ? sourceFolder.name : "Root",
      toLocation: targetFolder ? targetFolder.name : "Root",
      timestamp: new Date(),
    });
  };

  const copyItemTo = (
    itemId: string,
    targetFolderId: string,
    type: "folder" | "file"
  ) => 
  {
    const item = findItem(rootFolder, itemId, type);
    const targetFolder = findItem(
      rootFolder,
      targetFolderId,
      "folder"
    ) as Folder;
    setRootFolder((prevRoot) =>
      copyItem(prevRoot, itemId, targetFolderId, type)
    );
    addOrShowProgressItem({
      type: "copy",
      itemName: item!.name,
      itemType: type,
      toLocation: targetFolder ? targetFolder.name : "Root",
      timestamp: new Date(),
    });
  };

  const navigateToFolder = (folder: Folder) => 
  {
    setSelectedFolder(folder);
    const newBreadcrumb = [];
    let currentFolder: Folder | undefined = folder;
    while (currentFolder) 
    {
      newBreadcrumb.unshift(currentFolder);
      currentFolder = findParentFolder(rootFolder, currentFolder.id);
    }
    setBreadcrumb(newBreadcrumb);
  };

  // Helper functions go here

  const findItem = (
    folder: Folder,
    id: string,
    type: "folder" | "file" | "grid"
  ): Folder | FileItem | undefined => 
  {
    if (type === "folder") 
    {
      if (folder.id === id) return folder;
      for (const subfolder of folder.subfolders) 
      {
        const found = findItem(subfolder, id, type);
        if (found) return found;
      }
    }
    else 
    {
      const file = folder.files.find((f) => f.id === id);
      if (file) return file;
      for (const subfolder of folder.subfolders) 
      {
        const found = findItem(subfolder, id, type);
        if (found) return found;
      }
    }
  };

  const findParentFolder = (
    folder: Folder,
    childId: string
  ): Folder | undefined => 
  {
    if (folder.subfolders.some((sf) => sf.id === childId)) 
    {
      return folder;
    }
    for (const subfolder of folder.subfolders) 
    {
      const parent = findParentFolder(subfolder, childId);
      if (parent) return parent;
    }
    return undefined;
  };

  const findFolderPath = (folder: Folder, folderId: string): string => 
  {
    if (folder.id === folderId) return folder.name;
    for (const subfolder of folder.subfolders) 
    {
      const path = findFolderPath(subfolder, folderId);
      if (path) return `${folder.name}/${path}`;
    }
    return "";
  };

  const renameItemInStructure = (
    folder: Folder,
    id: string,
    newName: string,
    type: "folder" | "file" | "grid"
  ): Folder => 
  {
    if (type === "folder") 
    {
      if (folder.id === id) 
      {
        return { ...folder, name: newName };
      }
      return {
        ...folder,
        subfolders: folder.subfolders.map((subfolder) =>
          renameItemInStructure(subfolder, id, newName, type)
        ),
      };
    }
    else 
    {
      return {
        ...folder,
        files: folder.files.map((file) =>
          file.id === id ? { ...file, name: newName } : file
        ),
        subfolders: folder.subfolders.map((subfolder) =>
          renameItemInStructure(subfolder, id, newName, type)
        ),
      };
    }
  };

  const deleteItemFromStructure = (
    folder: Folder,
    id: string,
    type: "folder" | "file" | "grid"
  ): Folder => 
  {
    if (type === "folder") 
    {
      return {
        ...folder,
        subfolders: folder.subfolders
          .filter((subfolder) => subfolder.id !== id)
          .map((subfolder) => deleteItemFromStructure(subfolder, id, type)),
      };
    }
    else 
    {
      return {
        ...folder,
        files: folder.files.filter((file) => file.id !== id),
        subfolders: folder.subfolders.map((subfolder) =>
          deleteItemFromStructure(subfolder, id, type)
        ),
      };
    }
  };

  const addItemToStructure = (
    folder: Folder,
    parentId: string,
    newItem: Folder | FileItem,
    type: "folder" | "file"
  ): Folder => 
  {
    if (folder.id === parentId) 
    {
      return type === "folder"
        ? { ...folder, subfolders: [...folder.subfolders, newItem as Folder] }
        : { ...folder, files: [...folder.files, newItem as FileItem] };
    }
    return {
      ...folder,
      subfolders: folder.subfolders.map((subfolder) =>
        addItemToStructure(subfolder, parentId, newItem, type)
      ),
    };
  };

  return {
    addFolder,
    addFile,
    addExternalFile,
    deleteItem,
    renameItem,
    moveItem,
    copyItemTo,
    navigateToFolder,
    findFolderPath,
    findItem,
  };
};
