import { useContext } from "react";
import { FileSystemContext } from "../Contexts/FileSystemContext";

export const useFileSystem = () => 
{
  const context = useContext(FileSystemContext);
  if (context === undefined) 
  {
    throw new Error('useFileSystem must be used within a FileSystemProvider');
  }
  return context;
};