import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { AnimationCustomization, defaultIconAnimation, defaultWindowAnimation, WidgetChatbotProps } from '../ChatbotUtils/ChatbotTypes';
import { ChatbotStyles } from './CommonStyles/ChatbotStyles';
import { ChatWindow } from './Widget/ChatWindow';
import { FullScreenChatbotMain } from './FullScreenChatbotMain';

const ChatbotIcon: React.FC<{ onClick: () => void; icon: string; animation: AnimationCustomization }> = ({ onClick, icon, animation }) => (
  <motion.div
    className="fixed bottom-4 right-4 hover:bg-blue-700 rounded-full cursor-pointer shadow-lg flex items-center justify-center transition-colors duration-300 ease-in-out w-20 z-50"
    onClick={onClick}
    {...animation}
    whileHover={{ scale: 1.1 }}
  >
    <img src={icon} alt="Chatbot assistant icon" className="w-20 h-20" />
  </motion.div>
);

export const WidgetChatbotMain: React.FC<WidgetChatbotProps> = ({
  chatbotIcon,
  chatbotName,
  chatbotLogic,
  styleCustomization,
  animationCustomization = {},
  isFloatingChatbot

}) => 
{
  const [isOpen, setIsOpen] = useState(false);
  const [isAdminRoute, setIsAdminRoute] = useState(false);

  useEffect(() => 
  {
    const checkRoute = () => 
    {
      setIsAdminRoute(window.location.pathname.includes('admin/gen-ai/chatbot'));
    };

    checkRoute();
    window.addEventListener('adminRoute', checkRoute);

    return () => 
    {
      window.removeEventListener('adminRoute', checkRoute);
    };
  }, []);

  const setStyle = (style: typeof ChatbotStyles): typeof ChatbotStyles => ({
    ...style,
    ChatWindowHeader: `${style.ChatWindowHeader} ${styleCustomization.headerGradient}`,
    ChatWindowHeaderIcon: `${style.ChatWindowHeaderIcon} ${styleCustomization.headerIconHoverColor}`,
    ChatWindowHeaderTitle: `${style.ChatWindowHeaderTitle}`,
    ChatWindowBody: `${style.ChatWindowBody} ${styleCustomization.bodyBackgroundColor}`,
    ChatWindowFooter: `${style.ChatWindowFooter} ${styleCustomization.chatWindowFooter}`,
    ChatWindowFooterContainer: `${style.ChatWindowFooterContainer} ${styleCustomization.chatWindowFooterContainer}`,
    AIBubble: `${style.AIBubble} ${styleCustomization.aiBubbleColor}`,
    HumanBubble: `${style.HumanBubble} ${styleCustomization.humanBubbleColor}`,
    ChatWindowFooterTextarea: `${style.ChatWindowFooterTextarea} ${styleCustomization.footerTextareaFocusColor}`,
    ScrollButton: `${style.ScrollButton} ${styleCustomization.scrollButtonColor} ${styleCustomization.scrollButtonHoverColor}`,
    SpeechToTextButtonListeningColor: `${styleCustomization.speechToTextButtonListeningColor}`,
    SpeechToTextMicrophoneColor: `${style.SpeechToTextMicrophoneColor} ${styleCustomization.speechToTextMicrophoneColor}`,
    SpeechToTextButtonColor: `${style.SpeechToTextButtonColor} ${styleCustomization.speechToTextButtonColor}`,
    SpeakerColor: `${style.SpeakerColor} ${styleCustomization.speakerColor}`,
    SpeakerBackgroundColor: `${style.SpeakerBackgroundColor} ${styleCustomization.speakerBackgroundColor}`,
    SpeakerPlayingBackgroundColor: `${style.SpeakerPlayingBackgroundColor} ${styleCustomization.speakerPlayingBackgroundColor}`,
    ChatWindowFooterSendButton: `${style.ChatWindowFooterSendButton} ${styleCustomization.sendButtonColor} ${styleCustomization.sendButtonHoverColor}`,
    ThinkingBubbleBackgroundColor: `${style.ThinkingBubbleBackgroundColor} ${styleCustomization.thinkingBubbleBackgroundColor}`,
  });

  const style = setStyle(ChatbotStyles);

  useEffect(() => 
  {
    document.documentElement.style.setProperty('--typing-dot-color', styleCustomization.typingDotColor);
    document.documentElement.style.setProperty('--loading-ring-color', styleCustomization.loadingRingColor);
  }, [styleCustomization]);

  const {
    handleSend,
    handleSelection,
    errorMessage,
    messages,
    chatId,
    loading,
    handleResetChat,
    blockMessage,
  } = chatbotLogic;

  const iconAnimation = { ...defaultIconAnimation, ...animationCustomization.icon };
  const windowAnimation = { ...defaultWindowAnimation, ...animationCustomization.window };

  if (isAdminRoute) 
  {
    return (
      <FullScreenChatbotMain
        chatbotIcon={chatbotIcon}
        chatbotName={chatbotName}
        chatbotLogic={chatbotLogic}
        isFloatingChatbot={isFloatingChatbot}
      />
    );
  }
  
  return (
    <>
      <AnimatePresence>
        {!isOpen && (
          <ChatbotIcon 
            onClick={() => setIsOpen(true)} 
            icon={chatbotIcon} 
            animation={iconAnimation}
          />
        )}
        {isOpen && (
          <motion.div
            className="fixed inset-0 sm:inset-auto sm:bottom-4 sm:right-4 w-full sm:w-96 z-50" 
            {...windowAnimation}
          >
            <ChatWindow
              onClose={() => setIsOpen(false)}
              onSend={handleSend}
              onResetChat={handleResetChat}
              chatbotIcon={chatbotIcon}
              chatbotName={chatbotName}
              messages={messages}
              chatId={chatId}
              style={style}  
              loading={loading}
              errorMessage={errorMessage ?? ''}
              onSelection={handleSelection}  
              blockMessage={blockMessage}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};