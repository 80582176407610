import { FC, useCallback, memo, useMemo, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { InputField } from "../../../../ReactHookForm/Components/InputField";
import { ColorInputField } from "../../../../ReactHookForm/Components/ColorInputField";

interface FloatingColors 
{
  backgroundColor: string;
  userColor: string;
  answersColor: string;
  textColor: string;
}

//Custom color input component with memo for handling the 4 color inputs with better performance
const ColorInput = memo(({ 
  name, 
  label, 
  tooltip, 
  value, 
  onChange 
}: { 
  name: string;
  label: string;
  tooltip: string;
  value: string;
  onChange: (value: string) => void;
}) => 
{
  const { register, formState: { errors } } = useFormContext();
  
  return (
    <ColorInputField
      name={name}
      label={label}
      tooltip={tooltip}
      register={register}
      errors={errors}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="relative -start-1/2 -top-1/2 h-[200%] w-[200%] cursor-pointer appearance-none rounded-full border-none p-0"
    />
  );
});

export const FloatingChatbotStep: FC = () => 
{
  const { register, formState: { errors }, setValue, getValues } = useFormContext();

  //useWatch for reacting to form value changes when click on shortcut to step 3
  //This watches for changes in the floatingSettings form and re-render only the component that uses it
  //If I use watch it will re-render all the form instead, including the other steps
  const floatingSettings = useWatch({
    name: "floatingSettings",
  });

  // Memoization of calculated values for preventing unnecessary recalc
  const { isFloatingChatbot, floatingColors } = useMemo(() => 
  {
    return {
      isFloatingChatbot: floatingSettings?.isFloatingChatbot ?? false,
      floatingColors: {
        backgroundColor: floatingSettings?.backgroundColor || "#FFFFFF",
        userColor: floatingSettings?.userColor || "#FFFFFF",
        answersColor: floatingSettings?.answersColor || "#000000",
        textColor: floatingSettings?.textColor || "#000000",
      }
    };
  }, [floatingSettings]);

  useEffect(() => 
  {
    const currentMetadata = getValues("metadata");
    let parsedMetadata = {};
    // Parse current metadata
    if (currentMetadata) 
    {
      parsedMetadata = JSON.parse(currentMetadata);
    }
    else 
    {
      console.log("Does not exist");
    }

    // Update only floatingSettings but keep the rest (like templateIcon and templateColor)
    const newMetadata = {
      ...parsedMetadata,
      floatingSettings: {
        isFloatingChatbot: isFloatingChatbot,
      }
    };

    setValue("metadata", JSON.stringify(newMetadata));
  }, []); //TODO: fix this warning 

  //useCallback prevent unnecessary re-renders for the memoized values
  const handleMetadataChange = useCallback((
    field: keyof FloatingColors | "isFloatingChatbot" | "chatbotAvatar" | "backgroundImage",
    value: string | boolean
  ) => 
  {
    // update floatingSettings first
    const updatedFloatingSettings = {
      ...getValues("floatingSettings"),
      [field]: value,
    };
    
    setValue(`floatingSettings.${field}`, value, { shouldDirty: true });

    const currentMetadata = getValues("metadata");
    let parsedMetadata = {};
    // Parse current metadata
    if (currentMetadata) 
    {
      parsedMetadata = JSON.parse(currentMetadata);
    }
    else 
    {
      console.log("Does not exist");
    }

    // Update only floatingSettings but keep the rest (like templateIcon and templateColor)
    const newMetadata = {
      ...parsedMetadata,
      floatingSettings: updatedFloatingSettings
    };

    setValue("metadata", JSON.stringify(newMetadata));
  }, [getValues, setValue]);

  const handleColorChange = useCallback((field: keyof FloatingColors) => (value: string) => 
  {
    handleMetadataChange(field, value);
  }, [handleMetadataChange]);

  const handleFloatingToggle = useCallback(() => 
  {
    handleMetadataChange("isFloatingChatbot", !isFloatingChatbot);
  }, [handleMetadataChange, isFloatingChatbot]);

  return (
    <div className="space-y-4">
      <div className="flex flex-col gap-4 items-center">
        <p className="font-bold">
          Do you prefer a floating chatbot?
        </p>
        <div className="form-switch">
          <input
            id="floating-chatbot"
            type="checkbox"
            className="sr-only"
            checked={isFloatingChatbot}
            onChange={handleFloatingToggle}
          />
          <label className="bg-slate-400 dark:bg-slate-700" htmlFor="floating-chatbot">
            <span className="bg-white shadow-sm" aria-hidden="true"></span>
            <span className="sr-only">Switch label</span>
          </label>
          <div className="text-sm text-slate-400 dark:text-slate-500 italic">
            {isFloatingChatbot ? 'Floating' : 'Normal'}
          </div>
        </div>

        {isFloatingChatbot && (
          <>
            <InputField 
              name={"floatingSettings.chatbotAvatar"} //TODO: it's an image not svg
              label="Chatbot Avatar"
              tooltip="Insert your chatbot avatar using an svg code"
              placeholder="Paste the svg code you get from the Tabler Icons or any other icon sets.."
              register={register}
              errors={errors}
              onChange={(e) => handleMetadataChange("chatbotAvatar", e.target.value)}
            />

            <ColorInput
              name="floatingSettings.backgroundColor"
              label="Chatbot Background Color"
              tooltip="Pick a color for the background of your chatbot. Color is in HEX format."
              value={floatingColors.backgroundColor}
              onChange={handleColorChange("backgroundColor")}
            />

            <ColorInput
              name="floatingSettings.userColor"
              label="Your Chat Color"
              tooltip="Pick a color for your chat messages. Color is in HEX format."
              value={floatingColors.userColor}
              onChange={handleColorChange("userColor")}
            />

            <ColorInput
              name="floatingSettings.answersColor"
              label="Answers Color"
              tooltip="Pick a color for chatbot's answers. Color is in HEX format."
              value={floatingColors.answersColor}
              onChange={handleColorChange("answersColor")}
            />

            <ColorInput
              name="floatingSettings.textColor"
              label="Chat Text Color"
              tooltip="Pick a color for chatbot's answers. Color is in HEX format."
              value={floatingColors.textColor}
              onChange={handleColorChange("textColor")}
            />

            <InputField
              name={"floatingSettings.backgroundImage"}
              label="Background Image"
              tooltip="Insert an URL for background Image of your Floating Chatbot"
              placeholder="Paste an URL for background Image of your Floating Chatbot.."
              register={register}
              errors={errors}
              onChange={(e) => handleMetadataChange("backgroundImage", e.target.value)}
            />
          </>
        )}
      </div>
    </div>
  );
};
