import React, { useEffect } from 'react';
import flatpickr from 'flatpickr';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/l10n/es';
import 'flatpickr/dist/l10n/zh';
import 'flatpickr/dist/l10n/fr';
import 'flatpickr/dist/l10n/it';
import { getLanguage } from '../ReactIntl/IntlConfig';

interface DatepickerProps 
{
  align?: string | null;
  options?: flatpickr.Options.Options | null;
}

const localeMapping: Record<string, unknown> = {
  en: flatpickr.l10ns.en,
  es: flatpickr.l10ns.es,
  zh: flatpickr.l10ns.zh,
  fr: flatpickr.l10ns.fr,
  it: flatpickr.l10ns.it
};

export const Datepicker: React.FC<DatepickerProps> = ({ align, options }) => 
{
  const localeCode = getLanguage();
  const locale = (localeMapping[localeCode] as flatpickr.CustomLocale) || flatpickr.l10ns.en;

  useEffect(() => 
  {
    flatpickr.localize(locale);
  }, [locale]);

  const defaultOptions: flatpickr.Options.Options = {
    mode: 'range',
    static: true,
    locale: locale,
    monthSelectorType: 'static',
    dateFormat: 'M j, Y',
    defaultDate: [new Date().setDate(new Date().getDate() - 6), new Date()],
    prevArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    onReady: (_selectedDates, dateStr, instance) => 
    {
      instance.input.value = dateStr.replace('to', '-');
      const customClass = align ? align : '';
      instance.calendarContainer.classList.add(`flatpickr-${customClass}`);
    },
    onChange: (selectedDates, dateStr, instance) => 
    {
      instance.input.value = dateStr.replace('to', '-');
    },
  };

  options = { ...defaultOptions, ...options };

  return (
    <div className="relative">
      <Flatpickr className="form-input pl-9 py-2 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200 font-medium w-full" options={options} />
      <div className="absolute inset-0 right-auto flex items-center pointer-events-none">
        <svg className="w-4 h-4 fill-current text-slate-500 dark:text-slate-400 ml-3" viewBox="0 0 16 16">
          <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
        </svg>
      </div>
    </div>
  );
};