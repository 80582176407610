export class PhoneNumber 
{
  constructor(public prefix: string, public number: string) 
  {
    this.prefix = this.normalizePrefix(prefix);
    this.number = this.normalizeNumber(number);
  }

  private normalizePrefix(prefix: string): string 
  {
    if (!/^\+?\d+$/.test(prefix)) 
    {
      throw new Error('The prefix must consist only of digits ');
    }
    if (!prefix.startsWith('+')) 
    {
      prefix = '+' + prefix;
    }
    return prefix;
  }

  private normalizeNumber(number: string): string 
  {
    if (!/^\d+$/.test(number)) 
    {
      throw new Error('The number must consist only of digits.');
    }
    return number;
  }

  toString(): string 
  {
    return `${this.prefix}${this.number}`;
  }
}
