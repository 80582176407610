import React, { createContext, useReducer, ReactNode } from 'react';
import { GenAIAction as GenAIAction, GenAIContextType, GenAIState as GenAIState } from './GenAITypes';
import { GenAIProviderName } from '../Api/Types/GenAIEnums';

const initialState: GenAIState = {
  items: [],
  categories: [],
  userFavorites: [],
  isLoading: true,
  error: null,
  isCardView: false,
  selectedCategory: null,
  showFavorites: false,
  associatedCategories: {},
  deletionCheck: {
    id: '',
    canDelete: false,
    associatedNames: [],
  },
  providerModel: {
    provider: GenAIProviderName.Unspecified,
    models: []
  },
  writerResponse: null
};

export const GenAIContext = createContext<GenAIContextType | undefined>(undefined);

const genAIReducer = (state: GenAIState, action: GenAIAction): GenAIState => 
{
  switch (action.type) 
  {
    case 'FETCH_START':
      return { ...state, isLoading: true, error: null };
      
    case 'FETCH_SUCCESS':
      return { ...state, isLoading: false, items: action.payload };
      
    case 'FETCH_ERROR':
      return { ...state, isLoading: false, error: action.payload };
      
    case 'TOGGLE_VIEW':
      return { ...state, isCardView: !state.isCardView };
      
    case 'DELETE_ITEM':
      return { 
        ...state, 
        items: state.items.filter(item => 
          item && typeof item === 'object' && 'id' in item && item.id !== action.payload
        ) 
      };
   
    case 'UPDATE_ITEM':
      return {
        ...state,
        items: state.items.map(item => 
        {
          if (item && 
              typeof item === 'object' && 
              'id' in item && 
              action.payload && 
              typeof action.payload === 'object' && 
              'id' in action.payload && 
              item.id === action.payload.id) 
          {
            return {
              ...action.payload,
              id: item.id
            };
          }
          return item;
        })
      };
      
    case 'CREATE_ITEM':
      return { 
        ...state, 
        items: action.payload ? [...state.items, action.payload] : state.items 
      };
      
    case 'SET_CATEGORIES':
      return { ...state, categories: action.payload || [] };
      
    case 'SET_USER_FAVORITES':
      return { ...state, userFavorites: action.payload || [] };
      
    case 'UPDATE_USER_FAVORITE':
      return {
        ...state,
        userFavorites: state.userFavorites.map(fav =>
          fav && action.payload && fav.templateId === action.payload.templateId
            ? { ...fav, favorite: action.payload.isFavorite }
            : fav
        )
      };
      
    case 'SET_ASSOCIATED_CATEGORIES':
      return action.payload && action.payload.categoryId ? {
        ...state,
        associatedCategories: {
          ...state.associatedCategories,
          [action.payload.categoryId]: action.payload.associatedTemplates || []
        }
      } : state;
      
    case 'CLEAR_ERROR':
      return { ...state, error: null };
      
    case 'SET_SELECTED_CATEGORY':
      return { ...state, selectedCategory: action.payload };
      
    case 'SET_SHOW_FAVORITES':
      return { ...state, showFavorites: action.payload };
      
    case 'SET_DELETION_CHECK':
      return action.payload ? {
        ...state,
        deletionCheck: {
          id: action.payload.id || '',
          canDelete: action.payload.canDelete || false,
          associatedNames: action.payload.associatedNames || []
        }
      } : state;
      
    case 'CLEAR_DELETION_CHECK':
      return {
        ...state,
        deletionCheck: {
          id: '',
          canDelete: false,
          associatedNames: [],
        }
      };

    case 'SET_PROVIDER_MODEL':
      return {
        ...state,
        providerModel: action.payload
      };

    case 'SET_WRITER_RESPONSE':
      return {
        ...state,
        writerResponse: action.payload
      };
      
    default:
      return state;
  }
};

export const GenAIProvider: React.FC<{ children: ReactNode }> = ({ children }) => 
{
  const [state, dispatch] = useReducer(genAIReducer, initialState);

  return (
    <GenAIContext.Provider value={{ state, dispatch }}>
      {children}
    </GenAIContext.Provider>
  );
};
