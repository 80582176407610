import { validationMessages } from "../../../../../../ReactHookForm/Utils/Validation/Messages/ValidationMessages";

export const wizardValidationMessages = {
  ...validationMessages,
  hex: 'Must be a valid hex color code',
  categories: 'Must select at least one category',
  inputName: 'Input name must be unique and contain only letters and numbers',
  selectOptions: 'Select options must be unique and not empty',
  promptTemplate: 'Prompt must contain at least one input template (<<input>>)',
  minInputs: 'At least one input is required',
  uniqueInputName: 'Input name must be unique across all inputs',
  url: 'Must be a valid URL'
};