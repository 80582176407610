import React, { useState, useCallback } from 'react';
import { DropdownTransaction } from '../../Components/DropdownTransaction';
import { getIntl } from '../../ReactIntl/IntlConfig';
import { TransactionPageLocale } from '../../ReactIntl/LocaleInterfaces';
import { TransactionsTable } from './Partials/TransactionsTable';
import { DeleteButton } from '../../Components/Actions/DeleteButton';

export const Transactions: React.FC = () => 
{

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const intl = getIntl<TransactionPageLocale>("transactionPage");

  const handleSelectedItems = useCallback((selectedItems: string[]) => 
  {
    setSelectedItems([...selectedItems]);
  },[]);

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900">

        <main className="grow">

          {/* Content */}
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-4 md:mb-2">

              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">$47,347.09</h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                {/* Delete button */}
                <DeleteButton selectedItems={selectedItems} />

                {/* Search form */}
                <div className="hidden sm:block">

                </div>

                {/* Export button */}
                <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">{intl.exportTransactionsBtn}</button>

              </div>

            </div>

            <div className="mb-5">
              <span>{intl.transactionsFrom} </span>
              <DropdownTransaction align="right" />
            </div>

            {/* Filters */}
            <div className="mb-5">
              <ul className="flex flex-wrap -m-1">
                <li className="m-1">
                  <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out">
                    {intl.viewAllBtn}
                  </button>
                </li>
                <li className="m-1">
                  <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">
                    {intl.completedBtn}
                  </button>
                </li>
                <li className="m-1">
                  <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">
                    {intl.pendingBtn}
                  </button>
                </li>
                <li className="m-1">
                  <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">
                    {intl.canceledBtn}
                  </button>
                </li>
              </ul>
            </div>

            {/* Table */}
            <TransactionsTable selectedItems={handleSelectedItems} />

            {/* Pagination */}
            {/*}
           <div className="mt-8">
              <PaginationClassic />
            </div> 
            */}

          </div>

        </main>

      </div>

    </div>
  );
};
