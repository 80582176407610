import React, { useState, useContext } from "react";
import { ActionProps } from "./Actions.types";
import { getIntl } from "../../../ReactIntl/IntlConfig";
import { ProgressContextActionsMenu } from "../../../ReactIntl/LocaleInterfaces";
import {LanguageContext} from "../../../Contexts/LanguageContext";

export const AddFile: React.FC<ActionProps> = ({ onConfirm, onCancel }) => 
{
  const [fileName, setFileName] = useState('');
  const _languageCtx = useContext(LanguageContext);
  const intl = getIntl<ProgressContextActionsMenu>("progressContextActionsMenu");

  return (
    <div>
      <h2 className="text-xl mb-4">{intl.addNewFile}</h2>
      <input
        type="text"
        value={fileName}
        onChange={(e) => setFileName(e.target.value)}
        placeholder={`${intl.addNewFile}...`}
        className="form-input w-full"
      />
      <div className="flex justify-end">
        <div className="m-1.5">
          <button onClick={onCancel} className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300">{intl.cancel}</button>
        </div>
        <div className="m-1.5">
          <button onClick={() => onConfirm(fileName)} className="btn bg-indigo-500 hover:bg-indigo-600 text-white">{intl.add}</button>
        </div>
      </div>
    </div>
  );
};