import { useState, useEffect, useCallback } from 'react';
import { useWindChatbotProgrammatic } from './WindChatbotProgrammatic';
import { WindTreCategoryDto, WindTreOfferDto, WindTreSendRequest, WindChatResponse } from './WindTreAssets/WindTreInterface';
import { windtreController } from './WindTreApi/WindTreController';
import { GenAIMessageRole } from '../../Features/GenAI/Api/Types/GenAIEnums';
import { GenAIChatMessage } from '../../Features/GenAI/Api/Types/GenAIInterface';
import { GenAIChatSendRequest } from '../../Features/GenAI/Api/Types/GenAIRequest';

const initialMessage = "Ciao sono Windy! Come posso aiutarti?";

interface WindChatbotLogicProps 
{
  storeId: string;
}
export const useWindChatbotLogic = ({ storeId }: WindChatbotLogicProps) => 
{
    
  const LOCAL_STORAGE_KEY = `windChatState-${storeId}`;
  
  const [messages, setMessages] = useState<GenAIChatMessage[]>([
    { 
      message: initialMessage, 
      role: GenAIMessageRole.Assistant,
      isSelectionPrompt: true,
      options: ['Cliente Privato', 'Cliente Business']
    }
  ]);
  const [isBusiness, setIsBusiness] = useState<boolean | null>(null);
  const [showTypeButtons, setShowTypeButtons] = useState<boolean>(true);
  const [categories, setCategories] = useState<WindTreCategoryDto[]>([]);
  const [currentCategory, setCurrentCategory] = useState<WindTreCategoryDto | null>(null);
  const [currentOffer, setCurrentOffer] = useState<WindTreOfferDto | null>(null);
  const [chatId, setChatId] = useState<string>('');
  const [isFirstInteraction, setIsFirstInteraction] = useState<boolean>(true);
  const [pendingMessage, setPendingMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [chatbotInstructions, setChatbotInstructions] = useState<string>('');

  const programmaticFlow = useWindChatbotProgrammatic({
    storeId,
    onMessagesUpdate: (newMessages) => 
    {
      setMessages(prevMessages => [...prevMessages, ...newMessages]);
    },
    onCategoriesUpdate: setCategories,
    onCurrentCategoryUpdate: setCurrentCategory,
    onCurrentOfferUpdate: setCurrentOffer,
  });

  useEffect(() => 
  {
    const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedState) 
    {
      const parsedState = JSON.parse(savedState);
      setMessages(parsedState.messages || []);
      setIsBusiness(parsedState.isBusiness);
      setShowTypeButtons(parsedState.showTypeButtons);
      setCategories(parsedState.categories || []);
      setCurrentCategory(parsedState.currentCategory || null);
      setCurrentOffer(parsedState.currentOffer || null);
      setChatId(parsedState.chatId || '');
    }
  }, [LOCAL_STORAGE_KEY]);

  useEffect(() => 
  {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
      messages,
      isBusiness,
      showTypeButtons,
      categories,
      currentCategory,
      currentOffer,
      chatId,
    }));
  }, [messages, isBusiness, showTypeButtons, categories, currentCategory, currentOffer, chatId, LOCAL_STORAGE_KEY]);

  const sendMessage = useCallback(async (message: string, overrideIsBusiness?: boolean) => 
  {
    setLoading(true);

    const sendRequest: GenAIChatSendRequest<WindTreSendRequest> = {
      prompt: message,
      streamResponse: false,
      info: {
        isBusiness: overrideIsBusiness !== undefined ? overrideIsBusiness : isBusiness ?? false,
        chatId: chatId || null,
      }
    };

    try 
    {
      const response = await windtreController.send(storeId, sendRequest);

      if (response && response.data) 
      {
        const chatResponse = response.data as WindChatResponse;
        const newChatId = chatResponse.info.chatId;

        if (newChatId) 
        {
          setChatId(newChatId);
        }

        const newMessages = chatResponse.newMessages || [];
        const botMessages = newMessages
          .filter(msg => msg.role === GenAIMessageRole.Assistant)
          .map((msg: GenAIChatMessage) => ({
            message: msg.message ?? '',
            role: GenAIMessageRole.Assistant
          }));

        setMessages(prevMessages => [...prevMessages, ...botMessages]);
      }
    }
    catch (error) 
    {
      setErrorMessage("Error sending message: " + error);
    }
    finally 
    {
      setLoading(false);
    }
  }, [storeId, chatId, isBusiness]);

  const handleSend = useCallback(async (input: string) => 
  {
    setMessages(prevMessages => prevMessages.map(msg => ({
      ...msg,
      isSelectionPrompt: false,
      options: undefined
    })));

    if (isBusiness !== null) 
    {
      setMessages(prevMessages => [
        ...prevMessages,
        { message: input, role: GenAIMessageRole.Human }
      ]);
      await sendMessage(input);
      return;
    }

    setCurrentOffer(null);

    if (isFirstInteraction) 
    {
      setPendingMessage(input);
      setIsFirstInteraction(false);
      setShowTypeButtons(false);

      setMessages(prevMessages => [
        ...prevMessages,
        { message: input, role: GenAIMessageRole.Human },
        {
          message: "Sei un cliente business o privato?",
          role: GenAIMessageRole.Assistant,
          isSelectionPrompt: true,
          options: ['Privato', 'Business'],
          isTemporary: true
        }
      ]);
    }
    else 
    {
      setMessages(prevMessages => [
        ...prevMessages,
        { message: input, role: GenAIMessageRole.Human }
      ]);
      await sendMessage(input);
    }
  }, [isBusiness, isFirstInteraction, sendMessage]);

  const handleClientTypeSelection = useCallback(async (selection: string) => 
  {
    const isBusinessUser = selection === 'Cliente Business';
    setIsBusiness(isBusinessUser);
    setMessages(prevMessages => prevMessages.map(msg => ({
      ...msg,
      isSelectionPrompt: false,
      options: undefined
    })));

    setLoading(true);
    await programmaticFlow.handleTypeSelection(isBusinessUser);
    if (pendingMessage) 
    {
      await sendMessage(pendingMessage, isBusinessUser);
      setPendingMessage('');
    }
    setLoading(false);
  }, [programmaticFlow, pendingMessage, sendMessage]);

  const handleCercavoAltro = useCallback(() => 
  {
    if (currentOffer) 
    {
      setCurrentOffer(null);
      const categoryMessage: GenAIChatMessage = {
        message: `Ecco le offerte disponibili in ${currentCategory?.name}`,
        role: GenAIMessageRole.Assistant,
        isSelectionPrompt: true,
        options: [...(currentCategory?.offers.map(offer => offer.name) || []), 'Cercavo altro']
      };
      setMessages(prevMessages => [...prevMessages, categoryMessage]);
    }
    else if (currentCategory) 
    {
      setCurrentCategory(null);
      const categoriesMessage: GenAIChatMessage = {
        message: `Ecco le categorie disponibili per i clienti ${isBusiness ? 'business' : 'privati'}. Quale categoria ti interessa?`,
        role: GenAIMessageRole.Assistant,
        isSelectionPrompt: true,
        options: [...categories.map(category => category.name), 'Cercavo altro']
      };
      setMessages(prevMessages => [...prevMessages, categoriesMessage]);
    }
    else 
    {
      setIsBusiness(null);
      setCurrentCategory(null);
      setCurrentOffer(null);
      setCategories([]);
      setShowTypeButtons(true);
      setIsFirstInteraction(true);
      
      const clientTypeMessage: GenAIChatMessage = {
        message: initialMessage,
        role: GenAIMessageRole.Assistant,
        isSelectionPrompt: true,
        options: ['Cliente Privato', 'Cliente Business']
      };
      
      setMessages(prevMessages => [
        clientTypeMessage,
        ...prevMessages.filter(msg => msg.role === GenAIMessageRole.Human)
      ]);
    }
  }, [currentOffer, currentCategory, categories, isBusiness]);

  const handleGoToProduct = useCallback(() => 
  {
    if (currentOffer && currentOffer.slug) 
    {
      const productUrl = `https://${storeId}.windtre-store.it/${currentOffer.slug}`;
      window.open(productUrl, '_blank');
    }
  }, [currentOffer, storeId]);
  
  const handleSelection = useCallback(async (selection: string) => 
  {
    if (selection === 'Cercavo altro') 
    {
      handleCercavoAltro();
      return;
    }

    if (selection === 'Privato' || selection === 'Business') 
    {
      setMessages(prevMessages => prevMessages.filter(msg => !msg.isSelectionPrompt));      
      switch (selection) 
      {
        case 'Privato':
          setIsBusiness(false);
          sendMessage(pendingMessage, false);
          break;
        case 'Business':
          setIsBusiness(true);
          sendMessage(pendingMessage, true);
          break;
      }
      setPendingMessage('');
    }

    if ( selection === "Vai all'offerta") 
    {
      handleGoToProduct();
      return;
    }

    if (selection === 'Cliente Business' || selection === 'Cliente Privato') 
    {
      handleClientTypeSelection(selection);
    }
    else if (currentCategory) 
    {
      programmaticFlow.handleOfferSelection(selection, currentCategory);
    }
    else 
    {
      programmaticFlow.handleCategorySelection(selection, categories);
    }
  }, [currentCategory, handleCercavoAltro, sendMessage, pendingMessage, handleGoToProduct, handleClientTypeSelection, programmaticFlow, categories]);

  const handleResetChat = useCallback(() => 
  {
    setMessages([{ 
      message: initialMessage, 
      role: GenAIMessageRole.Assistant,
      isSelectionPrompt: true,
      options: ['Cliente Privato', 'Cliente Business']
    }]);
    setIsBusiness(null);
    setShowTypeButtons(true);
    setCategories([]);
    setCurrentCategory(null);
    setCurrentOffer(null);
    setChatId('');
    setIsFirstInteraction(true);
    setErrorMessage(null);
  }, []);

  const fetchChatbotInstructions = useCallback(async () => 
  {
    try 
    {
      const response = await windtreController.getInstructions(storeId);
      setChatbotInstructions(response.data);
    }
    catch (error) 
    {
      console.error('Error fetching chatbot instructions:', error);
      setErrorMessage("Error fetching chatbot instructions");
    }
  }, [storeId]);

  const updateChatbotInstructions = useCallback(async (newInstructions: string) => 
  {
    try 
    {
      await windtreController.updateInstructions(storeId, { instructions: newInstructions });
      setChatbotInstructions(newInstructions);
    }
    catch (error) 
    {
      console.error('Error updating chatbot instructions:', error);
      setErrorMessage("Error updating chatbot instructions");
    }
  }, [storeId]);

  const blockMessage = pendingMessage.length > 0;

  return {
    messages,
    chatId,
    loading,
    errorMessage,
    blockMessage,
    handleSend,
    handleSelection,
    handleResetChat,
    chatbotInstructions,
    fetchChatbotInstructions,
    updateChatbotInstructions,
  };
};