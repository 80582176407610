import { BarChart01 } from '../../../../Charts/BarChart01';
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { DashboardCards } from '../../../../ReactIntl/LocaleInterfaces';

// Import utilities
import { tailwindConfig } from '../../../../Utils/Utils';

export function DashboardCard04() 
{
  const intl = getIntl<DashboardCards>("dashboardCards");

  const chartData = {
    labels: [
      '12-01-2024', '01-01-2024', '02-01-2024',
      '03-01-2024', '04-01-2024', '05-01-2024',
    ],
    datasets: [
      // Light blue bars
      {
        label: 'Conversazioni',
        data: [
          800, 1600, 900, 1300, 1950, 1700,
        ],
        backgroundColor: tailwindConfig().theme.colors.purple[400],
        hoverBackgroundColor: tailwindConfig().theme.colors.purple[500],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
      // Blue bars
      {
        label: 'Risposte',
        data: [
          4900, 2600, 5350, 4800, 5200, 4800,
        ],
        backgroundColor: tailwindConfig().theme.colors.indigo[500],
        hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
        barPercentage: 0.66,
        categoryPercentage: 0.66,
      },
    ],
  };

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">{intl.h2Engagement}</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <BarChart01 data={chartData} width={595} height={304} />
    </div>
  );
}
