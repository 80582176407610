import React, { useRef } from 'react';

interface PDFInputProps 
{
  onSubmit: (file: File) => void;
}

export const PDFInput: React.FC<PDFInputProps> = ({ onSubmit }) => 
{
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => 
  {
    const file = event.target.files?.[0];
    if (file && file.size <= 25 * 1024 * 1024) 
    { // 25MB limit
      onSubmit(file);
    }
    else 
    {
      alert('File size must be less than 25MB');
    }
  };

  return (
    <div 
      className="flex flex-col bg-transparent dark:bg-slate-800 items-center justify-center h-60 border-2 border-dashed border-gray-200 rounded-lg cursor-pointer"
      onClick={() => fileInputRef.current?.click()}
    >
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept=".pdf"
        onChange={handleFileChange}
      />
      <div className="w-10 h-10 rounded-full border-2 border-gray-800 dark:border-gray-100 flex items-center justify-center mb-4">
        <span className="text-3xl text-gray-700 dark:text-gray-100 text-center mb-1.5">+</span>
      </div>
      <p className="text-sm font-medium text-gray-800 dark:text-gray-100">UPLOAD PDF</p>
      <p className="text-xs text-gray-500">Upload a PDF File (Max: 25Mb)</p>
    </div>
  );
};
