//https://github.com/supabase/auth-helpers/blob/main/packages/react/src/components/SessionContext.tsx
import { AuthError, Session, SupabaseClient } from '@supabase/supabase-js';
import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState
} from 'react';

export type SessionContextType =
  |
  {
    isLoading: true;
    session: null;
    error: null;
    supabaseClient: SupabaseClient;
  }
  |
  {
    isLoading: false;
    session: Session;
    error: null;
    supabaseClient: SupabaseClient;
  }
  |
  {
    isLoading: false;
    session: null;
    error: AuthError;
    supabaseClient: SupabaseClient;
  }
  |
  {
    isLoading: false;
    session: null;
    error: null;
    supabaseClient: SupabaseClient;
  };

export interface SessionContextProviderProps 
{
  supabaseClient: SupabaseClient;
  initialSession?: Session | null;
}

const SessionContext = createContext<SessionContextType>
({
  isLoading: true,
  session: null,
  error: null,
  supabaseClient: {} as SupabaseClient
});

const SessionContextProvider = (
  {
    supabaseClient,
    initialSession = null,
    children
  }
  : PropsWithChildren<SessionContextProviderProps>) => 
{
  const [session, setSession] = useState<Session | null>(null!);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<AuthError>();

  useEffect(() => 
  {
    if (!session && initialSession) 
    {
      setSession(initialSession);
    }
  },
  [initialSession, session]);

  useEffect(() => 
  {
    let mounted = true;

    async function refreshSession() 
    {
      console.log("refreshing session...");
      const { data, error } = await supabaseClient.auth.getSession();
      console.log("refreshSession data & error", data, error);

      // only update the react state if the component is still mounted
      if (mounted) 
      {
        if (error) 
        {
          setError(error);
          setIsLoading(false);
          return;
        }

        setSession(data.session);
        setIsLoading(false);
      }
    }

    refreshSession();

    return () => 
    {
      mounted = false;
    };
  },
  [supabaseClient.auth]);

  useEffect(() => 
  {
    const { data: { subscription } } = supabaseClient.auth.onAuthStateChange((event, newSession) => 
    {
      if (newSession && (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED' || event === 'USER_UPDATED')) 
      {
        setSession(newSession);
      }

      if (event === 'SIGNED_OUT') 
      {
        setSession(null);
      }
    });

    return () => 
    {
      subscription.unsubscribe();
    };
  },
  [supabaseClient.auth]);

  const value: SessionContextType = useMemo(() => 
  {
    if (isLoading) 
    {
      return {
        isLoading: true,
        session: null,
        error: null,
        supabaseClient
      };
    }

    if (error) 
    {
      return {
        isLoading: false,
        session: null,
        error,
        supabaseClient
      };
    }

    return {
      isLoading: false,
      session,
      error: null,
      supabaseClient
    };
  },
  [isLoading, session, error, supabaseClient]);

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
};

export { SessionContext, SessionContextProvider };