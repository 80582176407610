import { apiDefinitions } from "../ApiConfig";

const definition = apiDefinitions.debug;

export const debugController = 
{
  createRandomUsers: async (amount: number = 10) => 
  {
    const endpoint = definition.createRandomUsers;
    const response = await endpoint.invoke(endpoint.url, { amount }, {isUnique: false});

    return response;
  },

  ping: async () =>
  {
    const endpoint = definition.ping;
    const response = await endpoint.invoke(endpoint.url);

    return response;
  }
};
