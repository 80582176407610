import React, { useContext, useEffect, useState, useCallback } from "react";
import { getIntl } from "../../../ReactIntl/IntlConfig";
import { ProgressContextActions } from "../../../ReactIntl/LocaleInterfaces";
import {LanguageContext} from "../../../Contexts/LanguageContext";
import { motion } from "framer-motion";

export interface ProgressItem 
{
  type: "add" | "move" | "delete" | "copy" | "rename";
  itemName: string;
  fromLocation?: string;
  toLocation?: string;
  timestamp: Date;
}

interface ProgressSidebarProps 
{
  isOpen: boolean;
  onClose: () => void;
  progressItems: ProgressItem[];
  setMessage: (message: string | null) => void;
}

const containerVariants = {
  hidden: {
    opacity: 0,
    x: 300,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.1,
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1,
    },
  },
};

export const ProgressSidebar: React.FC<ProgressSidebarProps> = ({
  isOpen,
  onClose,
  progressItems,
  setMessage,
}) => 
{
  const _languageCtx = useContext(LanguageContext);
  const intl = getIntl<ProgressContextActions>("progressContextActions");
  const [latestItem, setLatestItem] = useState<ProgressItem | null>(null);

  const getMessage = useCallback((item: ProgressItem) => 
  {
    switch (item.type) 
    {
      case "add":
        return `${intl.added} ${item.itemName}`;
      case "move":
        return `${intl.moved} ${item.itemName} ${intl.from} ${item.fromLocation} ${intl.to} ${item.toLocation}`;
      case "delete":
        return `${intl.deleted} ${item.itemName}`;
      case "rename":
        return `${intl.renamed} ${item.itemName}`;
      case "copy":
        return `${intl.copied} ${item.itemName} ${intl.to} ${item.toLocation}`;
      default:
        return "";
    }
  }, [intl]);

  useEffect(() => 
  {
    if (latestItem) 
    {
      const message = getMessage(latestItem);
      setMessage(message);
    }
  }, [latestItem, setMessage, getMessage]);

  useEffect(() => 
  {
    if (progressItems.length > 0) 
    {
      setLatestItem(progressItems[progressItems.length - 1]);
    }
  }, [progressItems]);

  if (!isOpen) return null;

  return (
    <motion.div
      className={`
      absolute right-0 top-0 h-full w-64 bg-gray-200 dark:bg-slate-700 shadow-lg p-4 overflow-y-auto z-60
      transform transition-transform duration-300 ease-in-out
      ${isOpen ? "translate-x-0" : "translate-x-full"}
    `}
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div className="flex justify-between items-center mb-4"
        variants={itemVariants}>
        <h2 className="text-lg font-bold">{intl.progress}</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-400">
          <svg className="w-4 h-4 fill-current">
            <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
          </svg>
        </button>
      </motion.div>

      <motion.ul
        variants={containerVariants}>
        {progressItems.map((item, index) => (
          <motion.li
            key={index}
            className="mb-2 p-2 bg-gray-100 dark:bg-slate-700 rounded"
            variants={itemVariants}
          >
            <p className="text-xs">{getMessage(item)}</p>
            <p className="text-xs text-gray-500">
              {item.timestamp.toLocaleTimeString()}
            </p>
          </motion.li>
        ))}
      </motion.ul>
    </motion.div>
  );
};
