import React, { useEffect, useState, ComponentType, useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// import { userManager } from '../Api/BeetClient';
import { rolesManager } from '../Api/Roles/RoleManager';
import { BeetLoader } from '../Components/BeetLoader';
import { useSessionContext, useUser } from '../Features/Auth/Hooks/UseSession';
import toast from 'react-hot-toast';

interface ProtectedRouteProps 
{
  element: ComponentType<object>;
  roles?: string[];
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element: Component, roles, ...rest }) => 
{
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [hasRequiredRole, setHasRequiredRole] = useState<boolean>(true);
  const [checkedAuth, setCheckedAuth] = useState<boolean>(false);
  const location = useLocation();
  const supabaseClient = useSessionContext().supabaseClient;

  const checkSession = useCallback(async () => 
  {
    const { data: { session } } = await supabaseClient.auth.getSession();
    console.log("checkSession", session);
    return session;
  }, [supabaseClient]);

  useEffect(() => 
  {
    const checkAuth = async () => 
    {
      // const userLogged = await userManager.getUser();
      const isValidSession = await checkSession();
      if (isValidSession) 
      {
        setIsAuthenticated(true);
        if (roles) 
        {
          try 
          {
            // await rolesManager.fetchRoles();
            // const userRoles = rolesManager.getRoles();
            const userRoles = ["Admin"]; // Ora l'utente viene sempre visto come admin, così possiamo aprire anche le pagine autorizzate solo per questo ruolo e testare

            setHasRequiredRole(roles.some(role => userRoles.includes(role)));
          }
          catch (error)
          {
            setHasRequiredRole(false);
            console.log(error);
          }
        }
      }
      else 
      {
        setIsAuthenticated(false);
      }
      setCheckedAuth(true);
    };

    checkAuth();
  }, [roles, checkSession]);

  if (!checkedAuth) 
  {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <BeetLoader />
      </div>
    );
  }
  if (!isAuthenticated) 
  {
    return <Navigate to="/account/login/" replace state={{ from: location }} />;
  }

  if (!hasRequiredRole) 
  {
    return <Navigate to="/" replace />;
  }

  return <Component {...rest} />;
};
