import React from 'react';
import { GenAIChatMessage } from '../../Api/Types/GenAIInterface';
import { GenAIMessageRole } from '../../Api/Types/GenAIEnums';
import { TextToSpeech } from '../../BotTools/TextToSpeech';

interface ChatbotAdminBodyProps 
{
  messages: GenAIChatMessage[];
  loading: boolean;
  errorMessage: string | null;
}

export const ChatbotAdminBody: React.FC<ChatbotAdminBodyProps> = ({
  messages,
  loading,
  errorMessage,
}) => 
{
  return (
    <div className="grow px-4 sm:px-6 md:px-5 py-6 overflow-y-auto">
      {messages.map((message, index) => (
        <div
          key={index}
          className={`relative flex items-start mb-4 last:mb-0 ${message.role === GenAIMessageRole.Human ? 'justify-end' : ''}`}
        >
          {/* Wrapper per il messaggio e il pulsante */}
          <div className="relative flex items-center">
            {/* La bolla del messaggio */}
            <div
              className={`text-sm p-3 rounded-lg shadow-md ${message.role === GenAIMessageRole.Human
                ? 'bg-indigo-500 text-white'
                : 'bg-white dark:bg-slate-800 text-slate-800 dark:text-slate-100'
              }`}
            >
              {message.message}
            </div>

            {/* Aggiungere TextToSpeech solo ai messaggi del chatbot */}
            {message.role === GenAIMessageRole.Assistant && (
              <div className="ml-2">
                <TextToSpeech
                  text={message.message}
                  buttonBackgroundColor="bg-blue-600"
                  buttonPlayingBackgroundColor="bg-blue-700"
                  speakerColor="text-white"
                  buttonSize="p-2"
                />
              </div>
            )}
          </div>

        </div>
      ))}
      {loading && (
        <div className="flex justify-start mb-4">
          <div className="max-w-[70%] p-3 rounded-tr-xl rounded-tl-xl rounded-br-xl break-words relative shadow-md">
            <div className="text-gray-700">
              Sto pensando
              <span className="typing ml-1 inline-block">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className="absolute w-3 h-3 transform rotate-45 left-[-6px] top-3"></div>
          </div>
        </div>
      )}
      {errorMessage && <div className="text-red-500">{errorMessage}</div>}
    </div>
  );
};
