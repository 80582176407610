import React from 'react';
import { NavLink } from 'react-router-dom';
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { ClientSettingsSidebarLocale } from '../../../../ReactIntl/LocaleInterfaces';

export function ClientSettingsSidebar() 
{
  const { pathname } = location;

  const intl = getIntl<ClientSettingsSidebarLocale>('clientSettingsSidebar');

  return (
    <div className="flex flex-wrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-slate-200 dark:border-slate-700 min-w-[15rem] md:space-y-3">
      {/* Group 1 */}
      <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-3">{intl.appSettings}</div>
      <div>
        <ul className="flex flex-wrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/admin/app-settings/layout" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('admin/app-settings/layout') && 'bg-indigo-50 dark:bg-indigo-500/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/admin/app-settings/layout') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 16 16">
                <path d="M3 3h4v4H3V3zm6 0h4v4h-4V3zM3 9h4v4H3V9zm6 0h4v4h-4V9zM3 15h4v4H3v-4zm6 0h4v4h-4v-4z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/admin/app-settings/layout') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>{intl.liLayout}</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/admin/app-settings/external-login" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/admin/app-settings/external-login') && 'bg-indigo-50 dark:bg-indigo-500/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/admin/app-settings/external-login') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M13 16l-4-4 4-4m-6 4h7M21 12a9 9 0 10-9 9 9 9 0 009-9z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/admin/app-settings/external-login') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>{intl.liExternalLogin}</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/admin/app-settings/products" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/admin/app-settings/products') && 'bg-indigo-50 dark:bg-indigo-500/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/admin/app-settings/products') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M3 7h18v10H3z" />
                <path d="M3 7l9-4 9 4M3 17l9 4 9-4" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/admin/app-settings/products') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>{intl.liProducts}</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/admin/app-settings/marketing-feature" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/admin/app-settings/marketing-feature') && 'bg-indigo-50 dark:bg-indigo-500/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/admin/app-settings/marketing-feature') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M3 7h18v10H3z" />
                <path d="M3 7l9-4 9 4M3 17l9 4 9-4" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/admin/app-settings/marketing-feature') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>Marketing feature</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/admin/app-settings/coupons" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/admin/app-settings/coupons') && 'bg-indigo-50 dark:bg-indigo-500/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/admin/app-settings/coupons') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <rect x="2" y="6" width="20" height="12" rx="2" ry="2" /> 
                <path d="M2 9h20" /> 
                <path d="M2 15h20" /> 
                <circle cx="8" cy="12" r="2" /> 
                <circle cx="16" cy="12" r="2" /> 
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/admin/app-settings/coupons') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>Coupon</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}