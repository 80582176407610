import React from 'react';
import { createRoot } from 'react-dom/client';
import { Modal } from './Utils/ProgModalMain';
import { ProgrammaticModalOptions } from './Utils/ProgModalTypes';

// Funzione principale per la creazione della modale programmatica
export const createProgrammaticModal = (options: ProgrammaticModalOptions): Promise<boolean> => 
{
  return new Promise((resolve) => 
  {
    const modalRoot = document.createElement('div');
    document.body.appendChild(modalRoot);
    const root = createRoot(modalRoot);

    const closeModal = () => 
    {
      root.unmount();
      document.body.removeChild(modalRoot);
    };

    const modalProps = { ...options, resolve, closeModal };
    root.render(<Modal {...modalProps} />);
  });
};

// Funzioni di accesso per usare la modale con varianti specifiche
export const modal = Object.assign(
  (onConfirm?: () => Promise<void>) =>
    createProgrammaticModal({
      title: 'Conferma operazione',
      content: 'Sei sicuro di voler continuare?',
      confirmText: 'Conferma',
      cancelText: 'Annulla',
      onConfirm,
      variant: 'primary',
    }),
  {
    success: (onConfirm?: () => Promise<void>) =>
      createProgrammaticModal({
        title: 'Conferma salvataggio',
        content: 'Sei sicuro di voler salvare questo elemento?',
        confirmText: 'Salva',
        cancelText: 'Annulla',
        onConfirm,
        variant: 'success',
      }),
    caution: (onConfirm?: () => Promise<void>) =>
      createProgrammaticModal({
        title: 'Attenzione',
        content: 'Sei sicuro di voler eliminare questo elemento?',
        confirmText: 'Elimina',
        cancelText: 'Annulla',
        onConfirm,
        variant: 'caution',
      }),

    danger: (onConfirm?: () => Promise<void>) =>
      createProgrammaticModal({
        title: 'Attenzione',
        content: 'Sei sicuro di voler eliminare questo elemento?',
        confirmText: 'Elimina',
        cancelText: 'Annulla',
        onConfirm,
        variant: 'danger',
      }),

    custom: (options: ProgrammaticModalOptions) => createProgrammaticModal(options),
  }
);