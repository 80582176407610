import { Filter } from "../../../../Utils/Filter";
import { GenAICategoryRequest } from "../Types/GenAIRequest";
import { genAIApiDefinitions } from "../ApiConfig";

const definition = genAIApiDefinitions.aiWriterCategories;

export const genAICategoryController = {
  getAll: async (filter: Filter) => 
  {
    const endpoint = definition.getAll;
    const response = await endpoint.invoke(endpoint.url, { filter });
    return response;
  },
  getCategory: async (id: string) => 
  {
    const endpoint = definition.getCategory;
    const response = await endpoint.invoke(
      endpoint.url.replace("{categoryId}", id)
    );
    return response;
  },
  count: async () => 
  {
    const endpoint = definition.count;
    const response = await endpoint.invoke(endpoint.url);
    return response;
  },
  createCategory: async (category: GenAICategoryRequest) => 
  {
    const endpoint = definition.createCategory;
    const response = await endpoint.invoke(endpoint.url, category);
    return response;
  },
  editCategory: async (id: string, category: GenAICategoryRequest) => 
  {
    const endpoint = definition.updateCategory;
    const response = await endpoint.invoke(
      endpoint.url.replace("{categoryId}", id),
      category
    );
    return response;
  },
  deleteCategory: async (id: string) => 
  {
    const endpoint = definition.deleteCategory;
    const response = await endpoint.invoke(
      endpoint.url.replace("{categoryId}", id)
    );
    return response;
  },
};