import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import { SidebarLinkGroup } from "./SidebarLinkGroup";
import { RoleNavLink } from "../../Api/Roles/RoleNavLink";
import { DropdownHelp } from "../../Components/DropdownHelp";
import { LanguageContext } from "../../Contexts/LanguageContext";
import { RoleBasedElement } from "../../Api/Roles/RoleBasedElement";
import { Role } from "../../Api/Roles/RoleManager";
import { getIntl } from "../../ReactIntl/IntlConfig";
import { SidebarLocale } from "../../ReactIntl/LocaleInterfaces";
interface SidebarProps 
{
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Sidebar: React.FC<SidebarProps> = ({
  sidebarOpen,
  setSidebarOpen,
}) => 
{
  const location = useLocation();
  const { pathname } = location;
  const intl = getIntl<SidebarLocale>("sidebar");
  const trigger = useRef<HTMLButtonElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null); // Aggiunta del ref per la sidebar
  const _languageCtx = useContext(LanguageContext);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // Recupera il valore iniziale da sessionStorage per isVisible
  const storedIsVisible = sessionStorage.getItem("sidebar-visible");
  const [isVisible, setIsVisible] = useState(storedIsVisible === "true");

  // Effetto per scrollare fino all'elemento attivo
  useEffect(() => 
  {
    const activeLink = document.querySelector('.text-indigo-500');
    if (activeLink && sidebarRef.current) 
    {
      activeLink.scrollIntoView({ behavior: 'instant', block: 'center' });
    }

  }, [pathname]);

  // Click outside the sidebar
  useEffect(() => 
  {
    const clickHandler = (event: MouseEvent) => 
    {
      if (!sidebarRef.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebarRef.current.contains(event.target as Node) ||
        trigger.current.contains(event.target as Node)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // ESC handler
  useEffect(() => 
  {
    const keyHandler = (event: KeyboardEvent) => 
    {
      if (!sidebarOpen || event.key !== "Escape") return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // handle sidebar expansion
  useEffect(() => 
  {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    if (sidebarExpanded) 
    {
      document.querySelector("body")?.classList.add("sidebar-expanded");
    }
    else 
    {
      document.querySelector("body")?.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  // Effect to save isVisible in sessionStorage
  useEffect(() => 
  {
    sessionStorage.setItem("sidebar-visible", isVisible.toString());
  }, [isVisible]);

  return (
    <div className="min-w-fit lg:pr-64">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      <div
        id="sidebar"
        ref={sidebarRef}
        className={`fixed z-40 left-0 top-0  lg:left-auto lg:top-auto lg:translate-x-0 h-[100dvh] overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400 "
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-15 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <RoleNavLink end to="/" className="block">
            <img
              src="/beet-icon.svg"
              alt="Beet Root"
              className="mx-auto my-4 w-8 h-8"
            />
          </RoleNavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <ul className="mt-3">
              {/* USER MENU */}
              <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3 my-3">
                <RoleBasedElement as='span' requiredRoles={Role.Admin} className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                  {intl.user}
                </RoleBasedElement>
              </h3>

              {/* Dashboard */}
              <SidebarLinkGroup
                activecondition={
                  pathname === "/" || pathname.includes("dashboard")
                }
              >
                {(handleClick, open) => 
                {
                  return (
                    <React.Fragment>
                      <a
                        href="#0"
                        className={`block text-slate-200 truncate transition duration-150 ${pathname === "/" || pathname.includes("dashboard")
                          ? "hover:text-slate-200"
                          : "hover:text-white"
                        }`}
                        onClick={(e) => 
                        {
                          e.preventDefault();
                          handleClick();
                          setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <svg
                              className="shrink-0 h-6 w-6"
                              viewBox="0 0 24 24"
                            >
                              <path
                                className={`fill-current ${pathname === "/" ||
                                  pathname.includes("dashboard")
                                  ? "text-indigo-500"
                                  : "text-slate-400"
                                }`}
                                d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                              />
                              <path
                                className={`fill-current ${pathname === "/" ||
                                  pathname.includes("dashboard")
                                  ? "text-indigo-600"
                                  : "text-slate-600"
                                }`}
                                d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                              />
                              <path
                                className={`fill-current ${pathname === "/" ||
                                  pathname.includes("dashboard")
                                  ? "text-indigo-200"
                                  : "text-slate-400"
                                }`}
                                d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                              />
                            </svg>
                            <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              {intl.dashboard}
                            </span>
                          </div>
                          {/* Icon */}
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                          <li className="mb-1 last:mb-0">
                            <RoleNavLink
                              end
                              to="/"
                              className={({ isActive }) =>
                                "block transition duration-150 truncate " +
                                (isActive
                                  ? "text-indigo-500"
                                  : "text-slate-400 hover:text-slate-200")
                              }
                            >
                              <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                {intl.dashboardMain}
                              </span>
                            </RoleNavLink>
                          </li>
                          <>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                requiredRoles={Role.Admin}
                                end
                                to="/dashboard/analytics"
                                className={({ isActive }) =>
                                  `block transition duration-150 truncate ${isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200"
                                  }`
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {intl.dashboardAnalytics}
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                requiredRoles={Role.Admin}
                                end
                                to="/dashboard/fintech"
                                className={({ isActive }) =>
                                  `block transition duration-150 truncate ${isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200"
                                  }`
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {intl.dashboardFintech}
                                </span>
                              </RoleNavLink>
                            </li>
                          </>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>

              {/* WindTre chatbot*/}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("windtre") && "bg-slate-900"}`}
              >
                <RoleNavLink
                  requiredRoles={Role.Admin}
                  end
                  to="/gen-ai/chatbot/windtre"
                  className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("windtre")
                    ? "hover:text-slate-200"
                    : "hover:text-white"
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <div className="grow flex items-center">
                      <div className="relative h-8 w-8 -ml-1">
                        <img
                          src="https://upload.wikimedia.org/wikipedia/it/thumb/7/7d/Wind_Tre_logo_%282020%29.svg/645px-Wind_Tre_logo_%282020%29.svg.png?20231110170358"
                          alt="Wind Tre Logo"
                          className="absolute inset-0 w-full h-full object-fit"
                          style={{ objectFit: 'contain' }}
                        />
                      </div>
                      <span className="text-sm font-medium ml-2 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        WindTre Assistant
                      </span>
                    </div>
                  </div>
                </RoleNavLink>
              </li>

              {/* MORITZINO */}
              <>
                <SidebarLinkGroup
                  requiredRoles={[Role.Admin]}
                  activecondition={pathname.includes("sala/tables")}
                >
                  {(handleClick, open) => 
                  {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("sala/tables")
                            ? "hover:text-slate-200"
                            : "hover:text-white"
                          }`}
                          onClick={(e) => 
                          {
                            e.preventDefault();
                            handleClick();
                            setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <svg
                                className="shrink-0 h-6 w-6"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  className={`fill-current ${pathname.includes("sala/tables")
                                    ? "text-indigo-300"
                                    : "text-slate-400"
                                  }`}
                                  d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
                                />
                                <path
                                  className={`fill-current ${pathname.includes("sala/tables")
                                    ? "text-indigo-600"
                                    : "text-slate-700"
                                  }`}
                                  d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
                                />
                                <path
                                  className={`fill-current ${pathname.includes("sala/tables")
                                    ? "text-indigo-500"
                                    : "text-slate-600"
                                  }`}
                                  d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
                                />
                              </svg>
                              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Moritzino: Sala
                              </span>
                            </div>
                            {/* Icon */}
                            <div className="flex shrink-0 ml-2">
                              <svg
                                className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                }`}
                                viewBox="0 0 12 12"
                              >
                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                              </svg>
                            </div>
                          </div>
                        </a>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/sala/tables"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Tavoli
                                </span>
                              </RoleNavLink>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              </>

              {/* AI WRITERS */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === "/gen-ai/ai-writer" && "bg-slate-900"
                }`}
              >
                <RoleNavLink
                  end
                  to="/gen-ai/ai-writer"
                  className={`block text-slate-200 truncate transition duration-150 ${pathname.endsWith("ai-writer")
                    ? "hover:text-slate-200"
                    : "hover:text-white"
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <div className="grow flex items-center">
                      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                        <path
                          className={`fill-current ${pathname.endsWith("ai-writer")
                            ? "text-indigo-500"
                            : "text-slate-600"
                          }`}
                          d="M14.5 7c4.695 0 8.5 3.184 8.5 7.111 0 1.597-.638 3.067-1.7 4.253V23l-4.108-2.148a10 10 0 01-2.692.37c-4.695 0-8.5-3.184-8.5-7.11C6 10.183 9.805 7 14.5 7z"
                        />
                        <path
                          className={`fill-current ${pathname.endsWith("ai-writers")
                            ? "text-indigo-300"
                            : "text-slate-400"
                          }`}
                          d="M11 1C5.477 1 1 4.582 1 9c0 1.797.75 3.45 2 4.785V19l4.833-2.416C8.829 16.85 9.892 17 11 17c5.523 0 10-3.582 10-8s-4.477-8-10-8z"
                        />
                      </svg>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        AI Writer
                      </span>
                    </div>
                  </div>
                </RoleNavLink>
              </li>
              {/* AI Chat */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === "/gen-ai/ai-chatbot" && "bg-slate-900"
                }`}
              >
                <RoleNavLink
                  end
                  to="/gen-ai/ai-chatbot"
                  className={`block text-slate-200 truncate transition duration-150 ${pathname === "/gen-ai/ai-chatbot"
                    ? "hover:text-slate-200"
                    : "hover:text-white"
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <div className="grow flex items-center">
                      <svg
                        className="shrink-0 h-8 w-8 -ml-1"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="16"
                          height="12"
                          x="4"
                          y="6"
                          rx="2"
                          ry="2"
                          className={`fill-current ${pathname === "/gen-ai/ai-chatbot"
                            ? "text-indigo-500"
                            : "text-slate-600"
                          }`}
                        />
                        <circle
                          cx="8"
                          cy="10"
                          r="1.5"
                          className={`fill-current ${pathname === "/gen-ai/ai-chatbot"
                            ? "text-indigo-300"
                            : "text-slate-400"
                          }`}
                        />
                        <circle
                          cx="16"
                          cy="10"
                          r="1.5"
                          className={`fill-current ${pathname === "/gen-ai/ai-chatbot"
                            ? "text-indigo-300"
                            : "text-slate-400"
                          }`}
                        />
                        <rect
                          width="8"
                          height="1"
                          x="8"
                          y="13"
                          className={`fill-current ${pathname === "/gen-ai/ai-chatbot"
                            ? "text-indigo-300"
                            : "text-slate-400"
                          }`}
                        />
                      </svg>
                      <span className="text-sm font-medium ml-2 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        AI Chatbot
                      </span>
                    </div>
                  </div>
                </RoleNavLink>
              </li>

              {/* File Manager */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("file-manager") && "bg-slate-900"
                }`}
              >
                <RoleNavLink
                  end
                  to="/file-manager"
                  className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("file-manager")
                    ? "hover:text-slate-200"
                    : "hover:text-white"
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <div className="grow flex items-center">
                      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                        <path
                          className={`fill-current ${pathname.includes("file-manager")
                            ? "text-indigo-500"
                            : "text-slate-600"
                          }`}
                          d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"
                        />
                        <path
                          className={`fill-current ${pathname.includes("file-manager")
                            ? "text-indigo-300"
                            : "text-slate-400"
                          }`}
                          d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"
                        />
                      </svg>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        File
                      </span>
                    </div>
                  </div>
                </RoleNavLink>
              </li>
              <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3 my-3">
                <RoleBasedElement as='span' requiredRoles={Role.Admin} className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                  {intl.admin}
                </RoleBasedElement>
              </h3>

              {/* User management */}
              <>
                <SidebarLinkGroup
                  requiredRoles={[Role.Admin]}
                  activecondition={pathname.includes("/admin/user-list")}
                >
                  {(handleClick, open) => 
                  {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("/admin/user-list")
                            ? "hover:text-slate-200"
                            : "hover:text-white"
                          }`}
                          onClick={(e) => 
                          {
                            e.preventDefault();
                            handleClick();
                            setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path
                                  className={`fill-current ${pathname.includes("/admin/user-list")
                                    ? "text-indigo-300"
                                    : "text-slate-400"
                                  }`}
                                  d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
                                />
                                <path
                                  className={`fill-current ${pathname.includes("/admin/user-list")
                                    ? "text-indigo-600"
                                    : "text-slate-700"
                                  }`}
                                  d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
                                />
                                <path
                                  className={`fill-current ${pathname.includes("/admin/user-list")
                                    ? "text-indigo-500"
                                    : "text-slate-600"
                                  }`}
                                  d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
                                />
                              </svg>
                              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                {intl.users}
                              </span>
                            </div>
                            {/* Icon */}
                            <div className="flex shrink-0 ml-2">
                              <svg
                                className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                }`}
                                viewBox="0 0 12 12"
                              >
                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                              </svg>
                            </div>
                          </div>
                        </a>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/admin/user-list"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {intl.usersList}
                                </span>
                              </RoleNavLink>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              </>

              {/* CHATBOT */}
              {/* AI chat settings */}
              <>
                <SidebarLinkGroup
                  activecondition={
                    pathname.includes("/admin/gen-ai/chatbot") ||
                    pathname.includes("/admin/gen-ai/ai-chatbot") ||
                    pathname.includes("/admin/gen-ai/categories")
                  }
                >
                  {(handleClick, open) => 
                  {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("/admin/gen-ai/chatbot") ||
                              pathname.includes("/admin/gen-ai/ai-chatbot") ||
                              pathname.includes("/admin/gen-ai/categories")
                            ? "hover:text-slate-200"
                            : "hover:text-white"
                          }`}
                          onClick={(e) => 
                          {
                            e.preventDefault();
                            handleClick();
                            setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path
                                  className={`fill-current ${pathname.includes("/admin/gen-ai/chatbot") ||
                                      pathname.includes("/admin/gen-ai/ai-chatbot") ||
                                      pathname.includes("/admin/gen-ai/categories")
                                    ? "text-indigo-300"
                                    : "text-slate-400"
                                  }`}
                                  d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
                                />
                                <path
                                  className={`fill-current ${pathname.includes("/admin/gen-ai/chatbot") ||
                                      pathname.includes("/admin/gen-ai/ai-chatbot") ||
                                      pathname.includes("/admin/gen-ai/categories")
                                    ? "text-indigo-600"
                                    : "text-slate-700"
                                  }`}
                                  d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
                                />
                                <path
                                  className={`fill-current ${pathname.includes("/admin/gen-ai/chatbot") ||
                                      pathname.includes("/admin/gen-ai/ai-chatbot") ||
                                      pathname.includes("/admin/gen-ai/categories")
                                    ? "text-indigo-500"
                                    : "text-slate-600"
                                  }`}
                                  d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
                                />
                              </svg>
                              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                AI Chatbot Settings
                              </span>
                            </div>
                            {/* Icon */}
                            <div className="flex shrink-0 ml-2">
                              <svg
                                className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                }`}
                                viewBox="0 0 12 12"
                              >
                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                              </svg>
                            </div>
                          </div>
                        </a>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/admin/gen-ai/chatbot-training"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Training
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/admin/gen-ai/ai-chatbot"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Template
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/admin/gen-ai/categories"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Categories
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/admin/gen-ai/chatbot-analytics"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Analytics
                                </span>
                              </RoleNavLink>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              </>
              {/* AI Writers Templates */}
              <>
                <SidebarLinkGroup
                  requiredRoles={[Role.Admin]}
                  activecondition={pathname.startsWith("/admin/gen-ai/ai-writer")}
                >
                  {(handleClick, open) => 
                  {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={`block text-slate-200 truncate transition duration-150 ${pathname.startsWith("/admin/gen-ai/ai-writer")
                            ? "hover:text-slate-200"
                            : "hover:text-white"
                          }`}
                          onClick={(e) => 
                          {
                            e.preventDefault();
                            handleClick();
                            setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                                <path
                                  className={`fill-current ${pathname.startsWith("/admin/gen-ai/ai-writer")
                                    ? "text-indigo-300"
                                    : "text-slate-400"
                                  }`}
                                  d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
                                />
                                <path
                                  className={`fill-current ${pathname.startsWith("/admin/gen-ai/ai-writer")
                                    ? "text-indigo-600"
                                    : "text-slate-700"
                                  }`}
                                  d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
                                />
                                <path
                                  className={`fill-current ${pathname.startsWith("/admin/gen-ai/ai-writer")
                                    ? "text-indigo-500"
                                    : "text-slate-600"
                                  }`}
                                  d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
                                />
                              </svg>
                              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                AI Writer Settings
                              </span>
                            </div>
                            {/* Icon */}
                            <div className="flex shrink-0 ml-2">
                              <svg
                                className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                }`}
                                viewBox="0 0 12 12"
                              >
                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                              </svg>
                            </div>
                          </div>
                        </a>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/admin/gen-ai/ai-writer"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Templates
                                </span>
                              </RoleNavLink>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              </>

            </ul>
          </div>
          {/* More group */}

          <>
            <div>
              <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
                <RoleBasedElement as='span' requiredRoles={Role.Admin} className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                  {intl.more}
                </RoleBasedElement>
              </h3>
              <ul className="mt-3">
                {/* Authentication */}
                {/*<SidebarLinkGroup requiredRoles={['Admin']}*/}
                {/*  activecondition={false}>*/}
                {/*  {(handleClick, open: boolean) => */}
                {/*  {*/}
                {/*    return (*/}
                {/*      <React.Fragment>*/}
                {/*        <a*/}
                {/*          href="#0"*/}
                {/*          className={`block text-slate-200 truncate transition duration-150 ${open ? "hover:text-slate-200" : "hover:text-white"*/}
                {/*          }`}*/}
                {/*          onClick={(e) => */}
                {/*          {*/}
                {/*            e.preventDefault();*/}
                {/*            handleClick();*/}
                {/*            setSidebarExpanded(true);*/}
                {/*          }}*/}
                {/*        >*/}
                {/*          <div className="flex items-center justify-between">*/}
                {/*            <div className="flex items-center">*/}
                {/*              <svg*/}
                {/*                className="shrink-0 h-6 w-6"*/}
                {/*                viewBox="0 0 24 24"*/}
                {/*              >*/}
                {/*                <path*/}
                {/*                  className="fill-current text-slate-600"*/}
                {/*                  d="M8.07 16H10V8H8.07a8 8 0 110 8z"*/}
                {/*                />*/}
                {/*                <path*/}
                {/*                  className="fill-current text-slate-400"*/}
                {/*                  d="M15 12L8 6v5H0v2h8v5z"*/}
                {/*                />*/}
                {/*              </svg>*/}
                {/*              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">*/}
                {/*                Authentication*/}
                {/*              </span>*/}
                {/*            </div>*/}
                {/*            */}{/* Icon */}
                {/*            <div className="flex shrink-0 ml-2">*/}
                {/*              <svg*/}
                {/*                className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"*/}
                {/*                }`}*/}
                {/*                viewBox="0 0 12 12"*/}
                {/*              >*/}
                {/*                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />*/}
                {/*              </svg>*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        </a>*/}
                {/*        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">*/}
                {/*          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>*/}
                {/*            <li className="mb-1 last:mb-0">*/}
                {/*              <RoleNavLink*/}
                {/*                end*/}
                {/*                to="/account/login"*/}
                {/*                className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"*/}
                {/*              >*/}
                {/*                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">*/}
                {/*                  Sign in*/}
                {/*                </span>*/}
                {/*              </RoleNavLink>*/}
                {/*            </li>*/}
                {/*            <li className="mb-1 last:mb-0">*/}
                {/*              <RoleNavLink*/}
                {/*                end*/}
                {/*                to="/account/register"*/}
                {/*                className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"*/}
                {/*              >*/}
                {/*                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">*/}
                {/*                  Sign up*/}
                {/*                </span>*/}
                {/*              </RoleNavLink>*/}
                {/*            </li>*/}
                {/*            <li className="mb-1 last:mb-0">*/}
                {/*              <RoleNavLink*/}
                {/*                end*/}
                {/*                to="/account/reset-password"*/}
                {/*                className="block text-slate-400 hover:text-slate-200 transition duration-150 truncate"*/}
                {/*              >*/}
                {/*                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">*/}
                {/*                  Reset Password*/}
                {/*                </span>*/}
                {/*              </RoleNavLink>*/}
                {/*            </li>*/}
                {/*          </ul>*/}
                {/*        </div>*/}
                {/*      </React.Fragment>*/}
                {/*    );*/}
                {/*  }}*/}
                {/*</SidebarLinkGroup>*/}
                {/* Onboarding */}
                <>
                  <SidebarLinkGroup
                    requiredRoles={[Role.Admin]}
                    activecondition={
                      pathname.includes("/onboarding-01") ||
                      pathname.includes("/onboarding-02") ||
                      pathname.includes("/onboarding-03") ||
                      pathname.includes("/onboarding-04")
                    }
                  >
                    {(handleClick, open) => 
                    {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 truncate transition duration-150 ${open ? "hover:text-slate-200" : "hover:text-white"
                            }`}
                            onClick={(e) => 
                            {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <svg
                                  className="shrink-0 h-6 w-6"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    className={`fill-current ${pathname.includes('/onboarding-01') || pathname.includes('/onboarding-02') || pathname.includes('/onboarding-03') || pathname.includes('/onboarding-04') ? 'text-indigo-500' : 'text-slate-600'}`}

                                    d="M19 5h1v14h-2V7.414L5.707 19.707 5 19H4V5h2v11.586L18.293 4.293 19 5Z"
                                  />
                                  <path
                                    className={`fill-current ${pathname.includes('/onboarding-01') || pathname.includes('/onboarding-02') || pathname.includes('/onboarding-03') || pathname.includes('/onboarding-04') ? 'text-indigo-300' : 'text-slate-400'}`}

                                    d="M5 9a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm14 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8ZM5 23a4 4 0 1 1 0-8 4 4 0 0 1 0 8Zm14 0a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z"
                                  />
                                </svg>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Onboarding
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/onboarding-01"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Step 1
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/onboarding-02"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Step 2
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/onboarding-03"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Step 3
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/onboarding-04"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Step 4
                                  </span>
                                </RoleNavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                </>
                {/* Components */}
                <SidebarLinkGroup
                  requiredRoles={[Role.Admin]}
                  activecondition={pathname.includes("component")}
                >
                  {(handleClick, open) => 
                  {
                    return (
                      <React.Fragment>
                        <a
                          href="#0"
                          className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("component")
                            ? "hover:text-slate-200"
                            : "hover:text-white"
                          }`}
                          onClick={(e) => 
                          {
                            e.preventDefault();
                            handleClick();
                            setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <svg
                                className="shrink-0 h-6 w-6"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  className={`fill-current ${pathname.includes("component")
                                    ? "text-indigo-500"
                                    : "text-slate-600"
                                  }`}
                                  cx="16"
                                  cy="8"
                                  r="8"
                                />
                                <circle
                                  className={`fill-current ${pathname.includes("component")
                                    ? "text-indigo-300"
                                    : "text-slate-400"
                                  }`}
                                  cx="8"
                                  cy="16"
                                  r="8"
                                />
                              </svg>
                              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Components
                              </span>
                            </div>
                            {/* Icon */}
                            <div className="flex shrink-0 ml-2">
                              <svg
                                className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                }`}
                                viewBox="0 0 12 12"
                              >
                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                              </svg>
                            </div>
                          </div>
                        </a>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/button"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Button
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/form"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Input Form
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/dropdown"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Dropdown
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/alert"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Alert & Banner
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/modal"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Modal
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/placeholder"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Placeholder
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/pagination"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Pagination
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/tabs"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Tabs
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/breadcrumb"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Breadcrumb
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/badge"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Badge
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/avatar"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Avatar
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/tooltip"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Tooltip
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/accordion"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Accordion
                                </span>
                              </RoleNavLink>
                            </li>
                            <li className="mb-1 last:mb-0">
                              <RoleNavLink
                                end
                                to="/component/icons"
                                className={({ isActive }) =>
                                  "block transition duration-150 truncate " +
                                  (isActive
                                    ? "text-indigo-500"
                                    : "text-slate-400 hover:text-slate-200")
                                }
                              >
                                <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Icons
                                </span>
                              </RoleNavLink>
                            </li>
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
                {/* Debug */}
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("debug") && "bg-slate-900"
                  }`}
                >
                  <RoleNavLink
                    end
                    requiredRoles={Role.Admin}
                    to="/debug"
                    className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("debug")
                      ? "hover:text-slate-200"
                      : "hover:text-white"
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="grow flex items-center">
                        <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10 10 10 0 0 1-10-10A10 10 0 0 1 12 2zm0 18a8 8 0 0 0 8-8 8 8 0 0 0-8-8 8 8 0 0 0-8 8 8 8 0 0 0 8 8zM12 8v4m0 4h.01" />
                        </svg>
                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Debug
                        </span>
                      </div>
                    </div>

                  </RoleNavLink>
                </li>
              </ul>
            </div>
          </>
          {/* Unused Group */}
          <RoleBasedElement requiredRoles={Role.Admin}>
            {/* Toggle Switch */}
            <div className="flex items-center mb-4">
              {/* Testo "Unused" */}
              <h3 className="text-xs uppercase text-slate-500 font-semibold pr-3 flex-grow">
                <RoleBasedElement as='span' requiredRoles={Role.Admin} className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                  {intl.unused}
                </RoleBasedElement>
              </h3>

              {/* Toggle Switch */}
              <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                <div className="flex items-center">
                  <div className="form-switch ">
                    <input
                      type="checkbox"
                      id="sidebar-toggle"
                      className="sr-only"
                      checked={isVisible}
                      onChange={() => setIsVisible(!isVisible)}
                    />
                    <label className="bg-slate-400 dark:bg-slate-700" htmlFor="sidebar-toggle">
                      <span className="bg-white shadow-sm" aria-hidden="true"></span>
                      <span className="sr-only">Switch label</span>
                    </label>
                  </div>
                  <div className="text-sm text-slate-400 dark:text-slate-500 italic ml-2">
                    {isVisible ? `${intl.hide}` : `${intl.show}`}
                  </div>
                </div>
              </div>
            </div>
            {isVisible && (
              <ul className="mt-3">
                {/* Community */}
                <>
                  <SidebarLinkGroup
                    requiredRoles={[Role.Admin]}
                    activecondition={pathname.includes("community")}
                  >
                    {(handleClick, open) => 
                    {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("community")
                              ? "hover:text-slate-200"
                              : "hover:text-white"
                            }`}
                            onClick={(e) => 
                            {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <svg
                                  className="shrink-0 h-6 w-6"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    className={`fill-current ${pathname.includes("community")
                                      ? "text-indigo-500"
                                      : "text-slate-600"
                                    }`}
                                    d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
                                  />
                                  <path
                                    className={`fill-current ${pathname.includes("community")
                                      ? "text-indigo-300"
                                      : "text-slate-400"
                                    }`}
                                    d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
                                  />
                                </svg>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {intl.community}
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/community/users-tabs"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.communityUsersTabs}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/community/users-tiles"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.communityUsersTiles}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/community/profile"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.communityProfile}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/community/feed"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.communityFeed}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/community/forum"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.communityForum}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/community/forum-post"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.communityForumPost}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/community/meetups"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.communityMeetups}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/community/meetups-post"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.communityMeetupsPost}
                                  </span>
                                </RoleNavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                </>
                {/* Finance */}
                <>
                  <SidebarLinkGroup
                    requiredRoles={[Role.Admin]}
                    activecondition={pathname.includes("finance")}
                  >
                    {(handleClick, open) => 
                    {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("finance")
                              ? "hover:text-slate-200"
                              : "hover:text-white"
                            }`}
                            onClick={(e) => 
                            {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <svg
                                  className="shrink-0 h-6 w-6"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    className={`fill-current ${pathname.includes("finance")
                                      ? "text-indigo-300"
                                      : "text-slate-400"
                                    }`}
                                    d="M13 6.068a6.035 6.035 0 0 1 4.932 4.933H24c-.486-5.846-5.154-10.515-11-11v6.067Z"
                                  />
                                  <path
                                    className={`fill-current ${pathname.includes("finance")
                                      ? "text-indigo-500"
                                      : "text-slate-700"
                                    }`}
                                    d="M18.007 13c-.474 2.833-2.919 5-5.864 5a5.888 5.888 0 0 1-3.694-1.304L4 20.731C6.131 22.752 8.992 24 12.143 24c6.232 0 11.35-4.851 11.857-11h-5.993Z"
                                  />
                                  <path
                                    className={`fill-current ${pathname.includes("finance")
                                      ? "text-indigo-600"
                                      : "text-slate-600"
                                    }`}
                                    d="M6.939 15.007A5.861 5.861 0 0 1 6 11.829c0-2.937 2.167-5.376 5-5.85V0C4.85.507 0 5.614 0 11.83c0 2.695.922 5.174 2.456 7.17l4.483-3.993Z"
                                  />
                                </svg>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {intl.finance}
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/finance/cards"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.financeCards}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/finance/transactions"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.financeTransactions}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/finance/transaction-details"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.financeTransactionDetails}
                                  </span>
                                </RoleNavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                </>
                {/* Job Board */}
                <>
                  <SidebarLinkGroup requiredRoles={[Role.Admin]} activecondition={pathname.includes("job")}>
                    {(handleClick, open) => 
                    {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("job")
                              ? "hover:text-slate-200"
                              : "hover:text-white"
                            }`}
                            onClick={(e) => 
                            {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <svg
                                  className="shrink-0 h-6 w-6"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    className={`fill-current ${pathname.includes("job")
                                      ? "text-indigo-600"
                                      : "text-slate-700"
                                    }`}
                                    d="M4.418 19.612A9.092 9.092 0 0 1 2.59 17.03L.475 19.14c-.848.85-.536 2.395.743 3.673a4.413 4.413 0 0 0 1.677 1.082c.253.086.519.131.787.135.45.011.886-.16 1.208-.474L7 21.44a8.962 8.962 0 0 1-2.582-1.828Z"
                                  />
                                  <path
                                    className={`fill-current ${pathname.includes("job")
                                      ? "text-indigo-500"
                                      : "text-slate-600"
                                    }`}
                                    d="M10.034 13.997a11.011 11.011 0 0 1-2.551-3.862L4.595 13.02a2.513 2.513 0 0 0-.4 2.645 6.668 6.668 0 0 0 1.64 2.532 5.525 5.525 0 0 0 3.643 1.824 2.1 2.1 0 0 0 1.534-.587l2.883-2.882a11.156 11.156 0 0 1-3.861-2.556Z"
                                  />
                                  <path
                                    className={`fill-current ${pathname.includes("job")
                                      ? "text-indigo-300"
                                      : "text-slate-400"
                                    }`}
                                    d="M21.554 2.471A8.958 8.958 0 0 0 18.167.276a3.105 3.105 0 0 0-3.295.467L9.715 5.888c-1.41 1.408-.665 4.275 1.733 6.668a8.958 8.958 0 0 0 3.387 2.196c.459.157.94.24 1.425.246a2.559 2.559 0 0 0 1.87-.715l5.156-5.146c1.415-1.406.666-4.273-1.732-6.666Zm.318 5.257c-.148.147-.594.2-1.256-.018A7.037 7.037 0 0 1 18.016 6c-1.73-1.728-2.104-3.475-1.73-3.845a.671.671 0 0 1 .465-.129c.27.008.536.057.79.146a7.07 7.07 0 0 1 2.6 1.711c1.73 1.73 2.105 3.472 1.73 3.846Z"
                                  />
                                </svg>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {intl.jobBoard}
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/job/job-listing"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.jobListing}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/job/job-post"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.jobPost}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/job/company-profile"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.jobCompanyProfile}
                                  </span>
                                </RoleNavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                </>
                {/* Tasks */}
                <>
                  <SidebarLinkGroup
                    requiredRoles={[Role.Admin]}
                    activecondition={pathname.includes("tasks")}
                  >
                    {(handleClick, open) => 
                    {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("tasks")
                              ? "hover:text-slate-200"
                              : "hover:text-white"
                            }`}
                            onClick={(e) => 
                            {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <svg
                                  className="shrink-0 h-6 w-6"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    className={`fill-current ${pathname.includes("tasks")
                                      ? "text-indigo-500"
                                      : "text-slate-600"
                                    }`}
                                    d="M8 1v2H3v19h18V3h-5V1h7v23H1V1z"
                                  />
                                  <path
                                    className={`fill-current ${pathname.includes("tasks")
                                      ? "text-indigo-500"
                                      : "text-slate-600"
                                    }`}
                                    d="M1 1h22v23H1z"
                                  />
                                  <path
                                    className={`fill-current ${pathname.includes("tasks")
                                      ? "text-indigo-300"
                                      : "text-slate-400"
                                    }`}
                                    d="M15 10.586L16.414 12 11 17.414 7.586 14 9 12.586l2 2zM5 0h14v4H5z"
                                  />
                                </svg>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {intl.task}
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/tasks/kanban"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.taskKanban}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/tasks/list"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.taskList}
                                  </span>
                                </RoleNavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                </>
                {/* Messages */}
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("messages") && "bg-slate-900"
                  }`}
                >
                  <RoleNavLink
                    end
                    requiredRoles={Role.Admin}
                    to="/messages"
                    className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("messages")
                      ? "hover:text-slate-200"
                      : "hover:text-white"
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="grow flex items-center">
                        <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                          <path
                            className={`fill-current ${pathname.includes("messages")
                              ? "text-indigo-500"
                              : "text-slate-600"
                            }`}
                            d="M14.5 7c4.695 0 8.5 3.184 8.5 7.111 0 1.597-.638 3.067-1.7 4.253V23l-4.108-2.148a10 10 0 01-2.692.37c-4.695 0-8.5-3.184-8.5-7.11C6 10.183 9.805 7 14.5 7z"
                          />
                          <path
                            className={`fill-current ${pathname.includes("messages")
                              ? "text-indigo-300"
                              : "text-slate-400"
                            }`}
                            d="M11 1C5.477 1 1 4.582 1 9c0 1.797.75 3.45 2 4.785V19l4.833-2.416C8.829 16.85 9.892 17 11 17c5.523 0 10-3.582 10-8s-4.477-8-10-8z"
                          />
                        </svg>
                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          {intl.messages}
                        </span>
                      </div>
                      {/* Badge */}
                      <div className="flex flex-shrink-0 ml-2">
                        <span className="inline-flex items-center justify-center h-5 text-xs font-medium text-white bg-indigo-500 px-2 rounded">
                          4
                        </span>
                      </div>
                    </div>
                  </RoleNavLink>
                </li>
                {/* Inbox */}
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("inbox") && "bg-slate-900"
                  }`}
                >
                  <RoleNavLink
                    end
                    requiredRoles={Role.Admin}
                    to="/inbox"
                    className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("inbox")
                      ? "hover:text-slate-200"
                      : "hover:text-white"
                    }`}
                  >
                    <div className="flex items-center">
                      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                        <path
                          className={`fill-current ${pathname.includes("inbox")
                            ? "text-indigo-500"
                            : "text-slate-600"
                          }`}
                          d="M16 13v4H8v-4H0l3-9h18l3 9h-8Z"
                        />
                        <path
                          className={`fill-current ${pathname.includes("inbox")
                            ? "text-indigo-300"
                            : "text-slate-400"
                          }`}
                          d="m23.72 12 .229.686A.984.984 0 0 1 24 13v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-8c0-.107.017-.213.051-.314L.28 12H8v4h8v-4H23.72ZM13 0v7h3l-4 5-4-5h3V0h2Z"
                        />
                      </svg>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        Inbox
                      </span>
                    </div>
                  </RoleNavLink>
                </li>
                {/* Calendar */}
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("calendar") && "bg-slate-900"
                  }`}
                >
                  <RoleNavLink
                    end
                    requiredRoles={Role.Admin}
                    to="/calendar"
                    className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("calendar")
                      ? "hover:text-slate-200"
                      : "hover:text-white"
                    }`}
                  >
                    <div className="flex items-center">
                      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                        <path
                          className={`fill-current ${pathname.includes("calendar")
                            ? "text-indigo-500"
                            : "text-slate-600"
                          }`}
                          d="M1 3h22v20H1z"
                        />
                        <path
                          className={`fill-current ${pathname.includes("calendar")
                            ? "text-indigo-300"
                            : "text-slate-400"
                          }`}
                          d="M21 3h2v4H1V3h2V1h4v2h10V1h4v2Z"
                        />
                      </svg>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        {intl.calendar}
                      </span>
                    </div>
                  </RoleNavLink>
                </li>
                {/* Campaigns */}
                <>
                  <li
                    className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname.includes("campaigns") && "bg-slate-900"
                    }`}
                  >
                    <RoleNavLink
                      requiredRoles={Role.Admin}
                      end
                      to="/campaigns"
                      className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("campaigns")
                        ? "hover:text-slate-200"
                        : "hover:text-white"
                      }`}
                    >
                      <div className="flex items-center">
                        <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                          <path
                            className={`fill-current ${pathname.includes("campaigns")
                              ? "text-indigo-500"
                              : "text-slate-600"
                            }`}
                            d="M20 7a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 0120 7zM4 23a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 110 1.5 1.5 1.5 0 00-1.5 1.5A.75.75 0 014 23z"
                          />
                          <path
                            className={`fill-current ${pathname.includes("campaigns")
                              ? "text-indigo-300"
                              : "text-slate-400"
                            }`}
                            d="M17 23a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 010-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1zM7 13a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 112 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z"
                          />
                        </svg>
                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          {intl.campaigns}
                        </span>
                      </div>
                    </RoleNavLink>
                  </li>
                </>
                {/* Utility */}
                <>
                  <SidebarLinkGroup
                    requiredRoles={[Role.Admin]}
                    activecondition={pathname.includes("utility")}
                  >
                    {(handleClick, open) => 
                    {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("utility")
                              ? "hover:text-slate-200"
                              : "hover:text-white"
                            }`}
                            onClick={(e) => 
                            {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <svg
                                  className="shrink-0 h-6 w-6"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className={`fill-current ${pathname.includes("utility")
                                      ? "text-indigo-300"
                                      : "text-slate-400"
                                    }`}
                                    cx="18.5"
                                    cy="5.5"
                                    r="4.5"
                                  />
                                  <circle
                                    className={`fill-current ${pathname.includes("utility")
                                      ? "text-indigo-500"
                                      : "text-slate-600"
                                    }`}
                                    cx="5.5"
                                    cy="5.5"
                                    r="4.5"
                                  />
                                  <circle
                                    className={`fill-current ${pathname.includes("utility")
                                      ? "text-indigo-500"
                                      : "text-slate-600"
                                    }`}
                                    cx="18.5"
                                    cy="18.5"
                                    r="4.5"
                                  />
                                  <circle
                                    className={`fill-current ${pathname.includes("utility")
                                      ? "text-indigo-300"
                                      : "text-slate-400"
                                    }`}
                                    cx="5.5"
                                    cy="18.5"
                                    r="4.5"
                                  />
                                </svg>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {intl.utility}
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/utility/changelog"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.utilityChangelog}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/utility/roadmap"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.utilityRoadmap}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/utility/faqs"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.utilityFAQs}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/utility/empty-state"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.utilityEmptyState}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/utility/404"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    404
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/utility/knowledge-base"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.utilityKnowledgeBase}
                                  </span>
                                </RoleNavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                </>
                {/* Ecommerce */}
                <>
                  <SidebarLinkGroup
                    requiredRoles={[Role.Admin]}
                    activecondition={pathname.includes("ecommerce")}
                  >
                    {(handleClick, open) => 
                    {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 truncate transition duration-150 ${pathname.includes("ecommerce")
                              ? "hover:text-slate-200"
                              : "hover:text-white"
                            }`}
                            onClick={(e) => 
                            {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <svg
                                  className="shrink-0 h-6 w-6"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    className={`fill-current ${pathname.includes("ecommerce")
                                      ? "text-indigo-300"
                                      : "text-slate-400"
                                    }`}
                                    d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
                                  />
                                  <path
                                    className={`fill-current ${pathname.includes("ecommerce")
                                      ? "text-indigo-600"
                                      : "text-slate-700"
                                    }`}
                                    d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
                                  />
                                  <path
                                    className={`fill-current ${pathname.includes("ecommerce")
                                      ? "text-indigo-500"
                                      : "text-slate-600"
                                    }`}
                                    d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
                                  />
                                </svg>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Ecommerce
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/ecommerce/orders"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.usersOrders}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/ecommerce/invoices"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.usersInvoices}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/ecommerce/shop"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.usersShop}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/ecommerce/shop-2"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.usersShop2}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/ecommerce/product"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.usersSingleProduct}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/ecommerce/cart"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.usersCart}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/ecommerce/cart-2"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.usersCart2}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/ecommerce/cart-3"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.usersCart3}
                                  </span>
                                </RoleNavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <RoleNavLink
                                  end
                                  to="/ecommerce/pay"
                                  className={({ isActive }) =>
                                    "block transition duration-150 truncate " +
                                    (isActive
                                      ? "text-indigo-500"
                                      : "text-slate-400 hover:text-slate-200")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    {intl.usersPay}
                                  </span>
                                </RoleNavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                </>
              </ul>
            )}
          </RoleBasedElement>
        </div>

        {/* Expand / collapse button */}
        <div className="flex flex-wrap justify-between items-center w-full">
          <div className="px-3 py-2">
            <DropdownHelp align="left" />
          </div>

          <div className={`px-3 py-2 mt-auto ${sidebarExpanded ? 'lg:inline-flex' : 'lg:order-last w-full flex justify-center'} hidden lg:block 2xl:hidden`}>
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>

        </div>
      </div>
    </div >
  );
};
