import React, { useEffect, useState } from 'react';
import { getIntl } from '../../../ReactIntl/IntlConfig';
import { AccountSettingsPlansPanelLocale } from '../../../ReactIntl/LocaleInterfaces';
import { priceController } from '../../../Api/Controller/Stripe/ApiPrice';
import { productsController } from '../../../Api/Controller/Stripe/ApiProducts';
import { CheckoutModal } from '../../../Api/Controller/Stripe/Elements/CheckoutModal';
import { Filter, PageIndex } from '../../../Utils/Filter';
import { ProductDto, PriceDto, getCurrencySymbol } from '../../../Utils/Stripe.types';

export const PlansPanel: React.FC = () =>
{
  const [annual, setAnnual] = useState<boolean>(true);
  const [productsList, setProductsList] = useState<ProductDto[]>([]);
  const [prices, setPrices] = useState<Record<string, { monthly: PriceDto | null, yearly: PriceDto | null }>>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState<string | null>(null);
  const [priceDetails, setPriceDetails] = useState<PriceDto | null>(null);
  const [productDetails, setProductDetails] = useState<ProductDto | null>(null);

  const entitlements = "feat1";
  const intl = getIntl<AccountSettingsPlansPanelLocale>('accountSettingsPlansPanel');

  const fetchProductsAndPrices = async () =>
  {
    try
    {
      const productsResponse = await productsController.getAll(new Filter(new PageIndex(0, 100)));
      const activeProducts = productsResponse.data;
      setProductsList(activeProducts);

      const pricesResponse = await priceController.getAll(new Filter(new PageIndex(0, 100)));
      const activePrices = pricesResponse.data;

      const pricesByProduct: Record<string, { monthly: PriceDto | null, yearly: PriceDto | null }> = {};
      activePrices.forEach((price: PriceDto) =>
      {
        if (price.product)
        {
          if (!pricesByProduct[price.product])
          {
            pricesByProduct[price.product] = { monthly: null, yearly: null };
          }
          if (price.recurring && price.recurring.interval === 'month')
          {
            pricesByProduct[price.product].monthly = price;
          }
          else if (price.recurring && price.recurring.interval === 'year')
          {
            pricesByProduct[price.product].yearly = price;
          }
        }
      });

      setPrices(pricesByProduct);
    }
    catch (error)
    {
      console.error('Error fetching products or prices:', error);
    }
  };

  useEffect(() =>
  {
    fetchProductsAndPrices();
  }, []);

  const getCurrentPlan = () =>
  {
    return productsList.find((product: ProductDto) =>
      product.marketing_features?.every(feature => entitlements.includes(feature.name)) ?? false
    );
  };

  const currentPlan = getCurrentPlan();

  const handleCheckout = (priceId: string) =>
  {
    if (priceId)
    {
      setSelectedPriceId(priceId);
      const selectedPrice = Object.values(prices).flatMap(p => [p.monthly, p.yearly]).find(price => price?.id === priceId) || null;
      const selectedProduct = productsList.find(product =>
        selectedPrice?.product === product.id
      ) || null;
      setPriceDetails(selectedPrice);
      setProductDetails(selectedProduct);
      setIsModalOpen(true);
    }
  };

  const getButtonProps = (product: ProductDto) =>
  {
    if (!product.id) return { text: intl.basicPlanBtnCTA, action: () => { } };

    if (!currentPlan) return { text: intl.basicPlanBtnCTA, action: () => { } };

    const currentFeatures = currentPlan.marketing_features?.length ?? 0;
    const productFeatures = product.marketing_features?.length ?? 0;

    const priceId = annual ? prices[product.id]?.yearly?.id : prices[product.id]?.monthly?.id;
    if (product.id === currentPlan.id)
    {
      return { text: 'Your Plan', action: () => { } };
    }
    else if (productFeatures > currentFeatures)
    {
      return { text: 'Upgrade', action: () => priceId && handleCheckout(priceId) };
    }
    else
    {
      return { text: 'Downgrade', action: () => priceId && handleCheckout(priceId) };
    }
  };

  return (
    <div className="flex flex-col grow">
      <div className="p-6 space-y-6">
        <section>
          <div className="mb-8">
            <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-4">{intl.title}</h2>
            <div className="text-sm">
              {intl.plansDescStart} <strong className="font-medium">$34</strong>{intl.plansDescMid} <strong className="font-medium">July 9, 2021</strong>.
            </div>
          </div>
          <div>
            <div className="flex items-center space-x-3 mb-6">
              <div className="text-sm text-slate-500 font-medium">{intl.switchMonthlyLabel}</div>
              <div className="form-switch">
                <input type="checkbox" id="toggle" className="sr-only" checked={annual} onChange={() => setAnnual(!annual)} />
                <label className="bg-slate-400 dark:bg-slate-700" htmlFor="toggle">
                  <span className="bg-white shadow-sm" aria-hidden="true"></span>
                  <span className="sr-only">{intl.switchScreenReaderLabel}</span>
                </label>
              </div>
              <div className="text-sm text-slate-500 font-medium">{intl.switchAnnuallyLabel} <span className="text-emerald-500">(-20%)</span></div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {productsList.map((product, index) =>
              {
                if (!product.id)
                {
                  return null;
                }

                const productPrices = prices[product.id] || { monthly: null, yearly: null };
                const currentPrice = annual ? productPrices.yearly : productPrices.monthly;
                const buttonProps = getButtonProps(product);

                return (
                  <div
                    key={product.id}
                    className={`relative bg-white dark:bg-slate-800 shadow-md rounded-sm border border-slate-200 dark:border-slate-700`}
                  >
                    <div
                      className={`absolute top-0 left-0 right-0 h-0.5 ${index === 0 ? 'bg-emerald-500' : index === 1 ? 'bg-sky-500' : 'bg-indigo-500'
                      }`}
                      aria-hidden="true"
                    ></div>
                    <div className="px-5 pt-5 pb-6 border-b border-slate-200 dark:border-slate-700">
                      <header className="flex items-center mb-2">
                        <div
                          className={`w-6 h-6 rounded-full shrink-0 ${index === 0
                            ? 'bg-gradient-to-tr from-emerald-500 to-emerald-300'
                            : index === 1
                              ? 'bg-gradient-to-tr from-sky-500 to-sky-300'
                              : 'bg-gradient-to-tr from-indigo-500 to-indigo-300'
                          } mr-3`}
                        >
                          <svg className="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                            <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                          </svg>
                        </div>
                        <h3 className="text-lg text-slate-800 dark:text-slate-100 font-semibold">{product.name}</h3>
                      </header>
                      <div className="text-sm mb-2">{product.description}</div>
                      <div className="text-slate-800 dark:text-slate-100 font-bold mb-4">
                        <span className="text-2xl">{getCurrencySymbol(currentPrice?.currency ?? '')}</span>
                        <span className="text-3xl">{currentPrice ? (currentPrice.unit_amount / 100).toFixed(2) : 'N/A'}</span>
                        <span className="text-slate-500 font-medium text-sm">/{annual ? 'year' : 'mo'}</span>
                      </div>
                      <button
                        className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300 w-full"
                        onClick={buttonProps.action}
                        disabled={buttonProps.text === 'Your Plan'}
                      >
                        {buttonProps.text}
                      </button>
                    </div>
                    <div className="px-5 pt-4 pb-5">
                      <div className="text-xs text-slate-800 dark:text-slate-100 font-semibold uppercase mb-4">
                        {intl.basicPlanSubtitle}
                      </div>
                      <ul className="space-y-2">
                        {product.marketing_features?.map((feature, featureIndex) => (
                          <li key={featureIndex} className="flex items-center py-1">
                            <svg className="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2" viewBox="0 0 12 12">
                              <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                            </svg>
                            <div className="text-sm">{feature.name}</div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section>
          <div className="px-5 py-3 bg-indigo-50 dark:bg-indigo-500/30 border border-indigo-100 dark:border-transparent rounded-sm text-center xl:text-left xl:flex xl:flex-wrap xl:justify-between xl:items-center">
            <div className="text-slate-800 dark:text-slate-100 font-semibold mb-2 xl:mb-0">{intl.contactSalesTitle}</div>
            <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">{intl.contactSalesBtn}</button>
          </div>
        </section>

        <section>
          <div className="my-8">
            <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold">{intl.faqTitle}</h2>
          </div>
          <ul className="space-y-5">
            <li>
              <div className="font-semibold text-slate-800 dark:text-slate-100 mb-1">
                {intl.liFirstTitle}
              </div>
              <div className="text-sm">
                {intl.liFirstDesc}
              </div>
            </li>
            <li>
              <div className="font-semibold text-slate-800 dark:text-slate-100 mb-1">
                {intl.liSecondTitle}
              </div>
              <div className="text-sm">
                {intl.liSecondDesc}
              </div>
            </li>
            <li>
              <div className="font-semibold text-slate-800 dark:text-slate-100 mb-1">
                {intl.liThirdTitle}
              </div>
              <div className="text-sm">
                {intl.liThirdDesc} <a className="font-medium text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400" href="#0">{intl.liThirdLinkLabel}</a>.
              </div>
            </li>
          </ul>
        </section>
      </div>

      <footer className="border-t border-slate-200 dark:border-slate-700">
        <div className="flex flex-col px-6 py-5">
          <div className="flex self-end gap-3">
            <button className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300">{intl.cancelBtn}</button>
            <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">{intl.saveChangesBtn}</button>
          </div>
        </div>
      </footer>

      {/* Checkout Modal */}
      {isModalOpen ? (
        <CheckoutModal
          closeModal={() => setIsModalOpen(false)}
          priceId={selectedPriceId || ''}
          priceDetails={priceDetails}
          productDetails={productDetails}
        />
      ) : null}
    </div>
  );
};
