import React from 'react';
import ChatBotIcon from './WindTreAssets/ChatbotIcon.png';
import { useWindChatbotLogic } from './WindChatbotLogic';
import { BaseChatbot } from '../../Features/GenAI/ChatbotLayout/BaseChatbot';
import { animationCustomization, styleCustomization } from './WindTreAssets/WindChatboStyles';
import { ChatbotLogic } from '../../Features/GenAI/ChatbotUtils/ChatbotTypes';

const useWrappedChatbotLogic = (storeId: string): () => ChatbotLogic => 
{
  const logic = useWindChatbotLogic({ storeId });
  
  return React.useCallback(() => ({
    messages: logic.messages,
    chatId: logic.chatId,
    loading: logic.loading,
    errorMessage: logic.errorMessage,
    blockMessage: logic.blockMessage,
    handleSend: logic.handleSend,
    handleSelection: logic.handleSelection,
    handleResetChat: logic.handleResetChat,
    chatbotInstructions: logic.chatbotInstructions,
    fetchChatbotInstructions: logic.fetchChatbotInstructions,
    updateChatbotInstructions: logic.updateChatbotInstructions,
  }), [logic]);
};

export const WindChatbotTest: React.FC = () => 
{
  const getStoreIdFromUrl = () => 
  {
    const match = location.pathname.match(/\/gen-ai\/chatbot\/windtretest\/(.+)/);
    return match ? match[1] : '';
  };

  const storeId = getStoreIdFromUrl();
  const wrappedLogic = useWrappedChatbotLogic(storeId);
  const chatbotName = 'WindTreTest';

  return (
    <BaseChatbot
      chatbotIcon={ChatBotIcon}
      chatbotName={chatbotName}
      useChatbotLogic={wrappedLogic}
      styleCustomization={styleCustomization}
      animationCustomization={animationCustomization}
    />
  );
};