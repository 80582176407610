import { SigninAction, SigninActions } from './SigninActions';

export type SigninState = {
  email: string;
  password: string;
  rememberMe: boolean;
  errorMessage: string;
  bannerOpen: boolean;
  isLoading: boolean;
  resentButton: boolean;
  toastOpen: boolean;
  toastType: 'warning' | 'error' | 'success' | 'info';
  toastMessage: string;
};

export const initialSigninState: SigninState = {
  email: '',
  password: '',
  rememberMe: false,
  errorMessage: '',
  bannerOpen: false,
  isLoading: false,
  resentButton: false,
  toastOpen: false,
  toastType: 'info',
  toastMessage: ''
};

export function signinReducer(state: SigninState, action: SigninAction): SigninState 
{
  switch (action.constructor) 
  {
    case SigninActions.SetEmail:
      return { ...state, email: (action as InstanceType<typeof SigninActions.SetEmail>).email };
    case SigninActions.SetPassword:
      return { ...state, password: (action as InstanceType<typeof SigninActions.SetPassword>).password };
    case SigninActions.SetRememberMe:
      return { ...state, rememberMe: (action as InstanceType<typeof SigninActions.SetRememberMe>).rememberMe };
    case SigninActions.SetErrorMessage:
      return { ...state, errorMessage: (action as InstanceType<typeof SigninActions.SetErrorMessage>).errorMessage };
    case SigninActions.SetBannerOpen:
      return { ...state, bannerOpen: (action as InstanceType<typeof SigninActions.SetBannerOpen>).bannerOpen };
    case SigninActions.SetIsLoading:
      return { ...state, isLoading: (action as InstanceType<typeof SigninActions.SetIsLoading>).isLoading };
    case SigninActions.SetResentButton:
      return { ...state, resentButton: (action as InstanceType<typeof SigninActions.SetResentButton>).resentButton };
    case SigninActions.SetToastOpen:
      return { ...state, toastOpen: (action as InstanceType<typeof SigninActions.SetToastOpen>).toastOpen };
    case SigninActions.SetToastType:
      return { ...state, toastType: (action as InstanceType<typeof SigninActions.SetToastType>).toastType };
    case SigninActions.SetToastMessage:
      return { ...state, toastMessage: (action as InstanceType<typeof SigninActions.SetToastMessage>).toastMessage };
    default:
      return state;
  }
}