import React, { createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useUserInfo } from '../Hooks/UseUserInfo';
import { useUser } from '../Features/Auth/Hooks/UseSession';
interface RouteInfo 
{
  name: string;
  path: string;
}
export interface RoutesContextType 
{
  routes: RouteInfo[];
  search: (query: string) => void;
}

export const RoutesContext = createContext<RoutesContextType | undefined>(undefined);

export const RoutesProvider: React.FC<{ children: ReactNode }> = ({ children }) => 
{
  // const { isAdmin } = useUserInfo();
  const user = useUser();
  const isAdmin = user ? true : false;
  console.log(isAdmin);
  const navigate = useNavigate();

  const [routes] = useState<RouteInfo[]>([
    { name: 'Dashboard', path: '/' },
    { name: 'Analytics', path: '/dashboard/analytics' },
    { name: 'Fintech', path: '/dashboard/fintech' },
    { name: 'Account', path: '/settings/account' },
    { name: 'Notifications', path: '/settings/notifications' },
    { name: 'Apps', path: '/settings/apps' },
    { name: 'Authentication', path: '/settings/authentication' },
    { name: 'Plans', path: '/settings/plans' },
    { name: 'Billing', path: '/settings/billing' },
    { name: 'Feedback', path: '/settings/feedback' },
    { name: 'Products', path: '/admin/app-settings/product' },
  ].filter(route => isAdmin || !route.path.includes('/admin')));

  const search = (query: string) => 
  {
    const page = routes.find(route => route.name.toLowerCase().includes(query.toLowerCase()));
    if (page) 
    {
      navigate(page.path);
    }
  };

  return (
    <RoutesContext.Provider value={{ routes, search }}>
      {children}
    </RoutesContext.Provider>
  );
};
