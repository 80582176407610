import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../../../Components/Actions/Buttons/Buttons';
// import { useUserInfo } from '../../../../Hooks/UseUserInfo';
import { UserDto } from '../../../../Utils/User.types';
import { userListController } from '../../../../Api/Controller/Entities/ApiUserList';
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { MainModal } from '../../../../Components/Actions/Modals/MainModal';
import { ModalChoice } from '../../../../Components/Actions/Modals/ModalChoice';
import { ModalContent } from '../../../../Components/Actions/Modals/ModalContent';
import { ModalInputText } from '../../../../Components/Actions/Modals/ModalInputText';
import { ModalBreak } from '../../../../Components/Actions/Modals/ModalBreak';
import { formatDate, formatRoles } from '../../../../Utils/FormattedString';
import { RoleSelector } from '../../../../Api/Roles/RoleSelector';
import { UserListTableDeleteModalLocale, UserListTableItemEditModalLocale } from '../../../../ReactIntl/LocaleInterfaces';
import { useError } from '../../../../Hooks/UseError';
import toast from 'react-hot-toast';

export const UserListTableItems: React.FC<UserDto & {
  handleClick: (id: string, checked: boolean) => void;
  isChecked: boolean;
}> = (props) => 
{
  // const { refreshUserInfo } = useUserInfo();
  const [userToEdit, setUserToEdit] = useState<UserDto>({} as UserDto);
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [basicModalOpen, setBasicModalOpen] = useState(false);
  const [roles, setRoles] = useState<string[]>([]);
  const [modalContentType, setModalContentType] = useState<'edit' | 'details'>('edit');
  const [emailConfirmation, setEmailConfirmation] = useState('');
  const [_isDisabled, setIsDisabled] = useState(true);
  const [doNotAskAgain, setDoNotAskAgain] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isBlocked } = useError();

  useEffect(() => 
  {
    const cookieValue = document.cookie.split('; ').find(row => row.startsWith('doNotAskAgain='));
    const isDoNotAskAgain = cookieValue ? cookieValue.split('=')[1] === 'true' : false;
    setDoNotAskAgain(isDoNotAskAgain);
  }, []);

  const handleDoNotAskAgainChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => 
  {
    e.preventDefault();
    e.stopPropagation();
    const isChecked = e.target.checked;
    setDoNotAskAgain(isChecked);
    if (isChecked) 
    {
      const expirationDate = new Date();
      expirationDate.setMonth(expirationDate.getMonth() + 1);
      document.cookie = `doNotAskAgain=true; path=/; expires=${expirationDate.toUTCString()}`;
    }
    else 
    {
      document.cookie = 'doNotAskAgain=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  }, []);

  const openUserDetailsModal = useCallback(async () => 
  {
    const user = await userListController.getUserById(props.sub);
    setUserToEdit(user.data);
    setRoles(user.data.role || []);
    setModalContentType('details');
    setBasicModalOpen(true);
  }, [props.sub]);

  const openEditModal = useCallback(() => 
  {
    setModalContentType('edit');
    setBasicModalOpen(true);
  }, []);

  const editModalIntl = getIntl<UserListTableItemEditModalLocale>("userListTableItemEditModal");
  const deleteModalIntl = getIntl<UserListTableDeleteModalLocale>("userListTableDeleteModal");

  const dispatchCustomEvent = useCallback((action: 'update') => 
  {
    const event = new CustomEvent('userAction', {
      detail: {
        action,
        userId: props.sub,
        userData: action === 'update' ? userToEdit : null
      }
    });
    window.dispatchEvent(event);
  }, [props.sub, userToEdit]);

  useEffect(() => 
  {
    setIsDisabled(emailConfirmation !== props.email);
  }, [emailConfirmation, props.email]);

  const handleEditOpen = useCallback(async () => 
  {
    const user = await userListController.getUserById(props.sub);
    setUserToEdit(user.data);
    setRoles(user.data.role || []);
  }, [props.sub]);

  const handleEditConfirm = useCallback(async () => 
  {
    setIsLoading(true);
    if (isBlocked) return;
    if (userToEdit) 
    {
      toast.loading('Updating user...', { id: "updateUser" });
      try 
      {
        const updatedUserToEdit = { ...userToEdit, role: roles };
        await userListController.editUser(props.sub, updatedUserToEdit);
        // refreshUserInfo();
        dispatchCustomEvent('update');
        setBasicModalOpen(false);
        toast.success('User updated successfully');
      }
      catch (error) 
      {
        toast.error('Failed to update user');
      }
      finally 
      {
        toast.dismiss("updateUser");
      }
    }
    else 
    {
      toast.error('Failed to obtain user to edit');
    }
    setIsLoading(false);
  }, [userToEdit, roles, dispatchCustomEvent, isBlocked, props]);

  const handleDeleteConfirm = useCallback(async () => 
  {
    if (doNotAskAgain || emailConfirmation === props.email) 
    {
      toast.loading('Deleting user...', { id: "deleteUser" });
      try 
      {
        if (!doNotAskAgain) 
        {
          const expirationDate = new Date();
          expirationDate.setMonth(expirationDate.getMonth() + 1);
          document.cookie = `doNotAskAgain=true; path=/; expires=${expirationDate.toUTCString()}`;
          setDoNotAskAgain(true);
        }
        await userListController.deleteUser(props.sub);
        dispatchCustomEvent('update');
        setDangerModalOpen(false);
        // refreshUserInfo();
        toast.success('User deleted successfully');
      }
      catch 
      {
        toast.error('Failed to delete user');
      }
      finally 
      {
        toast.dismiss("deleteUser");
      }
    }
  }, [props, emailConfirmation, doNotAskAgain, dispatchCustomEvent]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => 
  {
    const { name, value } = e.target;
    setUserToEdit(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  }, []);

  const TableRow: React.FC<{ children: React.ReactNode, type: 'left' | 'right' | 'center', hideOnMobile?: boolean }> = ({ children, type, hideOnMobile = false }) => 
  {
    const className = type === 'left' ? 'text-left' : type === 'right' ? 'text-right' : 'center';
    const hiddenClass = hideOnMobile ? 'hidden sm:table-cell' : '';

    return (
      <th className={`px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap text-gray-500 ${hiddenClass}`}>
        <span className={className}> {/* TODO: This leads to semantic HTML errors, fix with this structure https://www.w3schools.com/html/html_tables.asp */}
          {children}
        </span>
      </th> /* TODO: This leads to semantic HTML errors, fix with this structure https://www.w3schools.com/html/html_tables.asp */
    );
  };

  const UserInfoRow: React.FC<{ label: string; value: string | null | undefined }> = ({ label, value }) => (
    <div className="flex justify-between py-2 border-b border-gray-200">
      <span className="font-medium text-gray-600">{label}:</span>
      <span className="text-gray-800">{value || '-'}</span>
    </div>
  );

  return (
    <>
      <tr>
        <TableRow type="left"> {/* TODO: This leads to semantic HTML errors, fix with this structure https://www.w3schools.com/html/html_tables.asp */}
          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
            <img className="rounded-full" src={props.picture} width="40" height="40" alt={props.fullName} />
          </div>
        </TableRow>
        <TableRow type="left">
          <span
            className="cursor-pointer hover:text-blue-800"
            onClick={(e) => { e.preventDefault(); openUserDetailsModal(); }}
          >
            {props.givenName || ''}
          </span>
        </TableRow>
        <TableRow type="left">
          <span
            className="cursor-pointer hover:text-blue-800"
            onClick={(e) => { e.preventDefault(); openUserDetailsModal(); }}
          >
            {props.familyName || ''}
          </span>
        </TableRow>
        <TableRow type="left">
          <div className="flex items-center justify-center">
            <span
              className="cursor-pointer hover:text-blue-800"
              onClick={(e) => { e.preventDefault(); openUserDetailsModal(); }}
            >
              {props.email || ''}
            </span>
            {props.role?.includes('ADMIN') && (
              <img className=" ml-2 h-4 w-4" src="/admin-logo.svg" alt="admin logo"></img>
            )}
          </div>
        </TableRow>
        <TableRow type="right">
          {[
            props.locality,
            props.region,
            props.country
          ].filter(Boolean).join(', ') || '-'}
        </TableRow>
        <TableRow type="left">
          <td className="m-1.5">
            <Button
              variant="tertiary"
              type="edit"
              hideText
              onClick={(e) => 
              {
                e?.stopPropagation();
                openEditModal();
                handleEditOpen();
              }}
              stopPropagation={true}
            />
          </td>
          <td className="m-1.5">
            <Button
              variant="textDanger"
              type="delete"
              hideText
              onClick={(e) => 
              {
                e?.stopPropagation();
                setDangerModalOpen(true);
              }}
              stopPropagation={true}
            />

          </td>
        </TableRow>
      </tr>

      {dangerModalOpen && (
        <MainModal
          title={`${deleteModalIntl.deleteAdminQuestion} ${props.email}`}
          setOpen={setDangerModalOpen}
          variant="danger"
        >
          <ModalContent>
            <p className="mb-3">{deleteModalIntl.description}</p>
            {!doNotAskAgain && (
              <>
                <ModalInputText
                  variant="email"
                  name="emailConfirmation"
                  label={deleteModalIntl.placeholderEnterEmail}
                  value={emailConfirmation}
                  onChange={(e) => setEmailConfirmation(e.target.value)}
                />
                <div className="mt-2 flex items-center">
                  <input
                    type="checkbox"
                    id="doNotAskAgain"
                    checked={doNotAskAgain}
                    onChange={handleDoNotAskAgainChange}
                  />
                  <label htmlFor="doNotAskAgain" className="ml-2">
                    {deleteModalIntl.doNotAskAgain}
                  </label>
                </div>
              </>
            )}
          </ModalContent>

          {/* Pulsanti di conferma e annulla */}
          <ModalChoice
            onConfirm={handleDeleteConfirm}
            confirmButtonText={deleteModalIntl.deleteBtn}
            confirmDisabled={!(doNotAskAgain || emailConfirmation === props.email)}
          />
        </MainModal>
      )}

      {basicModalOpen && (
        <MainModal
          title={modalContentType === 'edit' ? `${editModalIntl.edit} ${props.email}` : `Details for ${props.email}`}
          setOpen={setBasicModalOpen}
          variant="primary"
        >
          {modalContentType === 'edit' ? (
            <form onSubmit={(e) => { e.preventDefault(); handleEditConfirm(); }}>
              <ModalContent>
                <div className="flex w-full gap-6">
                  {/* Campo Nome */}
                  <ModalInputText
                    variant="name"
                    name="givenName"
                    label={editModalIntl.fistName}
                    value={userToEdit.givenName ?? ''}
                    onChange={handleChange}
                  />
                  {/* Campo Cognome */}
                  <ModalInputText
                    variant="surname"
                    name="familyName"
                    label={editModalIntl.lastName}
                    value={userToEdit.familyName ?? ''}
                    onChange={handleChange}
                  />
                </div>

                {/* Campo Email */}
                <ModalInputText
                  variant="email"
                  name="email"
                  label={editModalIntl.email}
                  value={userToEdit.email ?? ''}
                  onChange={handleChange}
                  validate={true}
                />

                {/* Selettore Ruoli */}
                <div className="flex w-full gap-6">
                  <RoleSelector
                    roles={roles}
                    onRolesChange={setRoles}
                  />
                </div>
              </ModalContent>

              {/* Pulsanti di conferma e annulla */}
              <ModalChoice onConfirm={handleEditConfirm} />
            </form>
          ) : (
            // Dettagli utente
            <div>
              <h2 className="text-lg font-bold">{`${props.givenName || ''} ${props.familyName || ''}`}</h2>
              <ModalBreak />
              <div className="py-4">
                <UserInfoRow label="Email" value={props.email} />
                <UserInfoRow label="Role" value={formatRoles(props.role)} />
                <UserInfoRow label="Gender" value={props.gender} />
                <UserInfoRow label="Birthdate" value={props.birthdate?.toLocaleDateString()} />
                <UserInfoRow label="Created At" value={formatDate(Number(props.createdAt))} />
                <UserInfoRow label="Country" value={props.country} />
                <UserInfoRow label="Region" value={props.region} />
                <UserInfoRow label="Street Address" value={props.streetAddress} />
                <UserInfoRow label="Postal Code" value={props.postalCode} />
                <UserInfoRow label="Zone info" value={props.zoneInfo} />
                <UserInfoRow label="Locale" value={props.locale} />
                <UserInfoRow label="Last order" value={props.lastOrder} />
                <UserInfoRow label="Total spent" value={props.spent} />
                <UserInfoRow label="Refunds" value={props.refunds} />
              </div>
            </div>
          )}
        </MainModal>
      )}
    </>
  );
};
