import React from 'react';
import { Button } from '../../../Components/Actions/Buttons/Buttons';
import { Link } from 'react-router-dom';

export const AnalyticsChatbotPage: React.FC = () => 
{
  return (
    <div className="min-h-screen">
      <div className="relative px-4 sm:px-6 lg:px-12 xl:px-14 py-12 lg:py-10 max-w-6xl mx-auto">
        <div className='absolute top-2 xl:top-11 left-2 sm:left-4 lg:left-10 xl:left-2'>
          <Link to="/">
            <Button
              variant='tertiary'
              type='lArrow'
              hideText
              title='Dashboard'
              className='border-slate-200 hover:border-slate-300 rounded-full dark:border-slate-700 dark:hover:border-slate-600'    
            />
          </Link>
        </div>
        {/* Header */}
        <div className='flex'>
          
          <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mb-3">
            Analisi dati *nome Chatbot*
          </h1>
        </div>
        <p className="text-sm text-slate-800 dark:text-slate-300">
          Ottieni il massimo analizzando le conversazioni e i dati relativi a *Nome Chatbot* e le rispettive sedi
        </p>

        {/* Main */}
        <div className="grid gap-4 mt-5 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-center">
          {['Firenze', 'Cagliari', 'Roma', 'Milano', 'Bologna', 'Napoli'].map((city, index) => (
            <div
              key={index}
              className="w-full p-6 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
            >
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                WindTre {city}
              </h5>
              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Via Roma, 1, {city}</p>
              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Orario: 9:00 - 18:00</p>
              <div className="flex justify-evenly mt-5">
                
                <Button type="rArrow" variant="secondary">
                  Leggi Chat
                </Button>
                <Link to="/admin/gen-ai/chatbot-analytics/data">
                  <Button type="rArrow">Analisi Dati</Button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
