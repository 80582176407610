
import React, { useContext, useEffect } from 'react';
import { getIntl } from "../../../ReactIntl/IntlConfig";
import { AccountSettingsPanelAuthenticationLocale } from "../../../ReactIntl/LocaleInterfaces";
import { PanelPassword } from "./AccountPanel/PanelPassword";
import { PanelTwoFactor } from "./AccountPanel/PanelTwoFactor";
import {LanguageContext} from '../../../Contexts/LanguageContext';
import { useError } from '../../../Hooks/UseError';
import toast from 'react-hot-toast';

export const AuthenticationPanel: React.FC = () => 
{
  const languageCtx = useContext(LanguageContext);
  const intl = getIntl<AccountSettingsPanelAuthenticationLocale>('accountSettingsPanelAuthentication');
  const { isBlocked, retrySeconds } = useError();
  
  useEffect(() => 
  {
    if(retrySeconds && isBlocked)
    {
      toast.loading(`Too many requests, wait ${retrySeconds} seconds and try again`, { id: 'tooManyRequests' });
    }
    else if(!isBlocked)
    {
      toast.dismiss('tooManyRequests');
    }
  }, [isBlocked, retrySeconds]);

  return (
    <>
      <div className="grow">
        {/* Panel body */}

        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">{intl.title}</h2>

          <PanelPassword key={languageCtx.language} /> 
          <PanelTwoFactor />
        </div>
      </div>

    </>
  );
};