import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TrainingInputForm } from './TrainingInputForm';
import { Training, TrainingSource } from '../Api/Types/GenAIInterface';
import { Button } from '../../../Components/Actions/Buttons/Buttons';
import { modal } from '../../../Components/Actions/ProgModal/ProgModal';

export const ChatbotTrainingPage: React.FC = () => 
{
  const navigate = useNavigate();
  const [trainings, setTrainings] = useState<Training[]>([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [currentTraining, setCurrentTraining] = useState<Training | null>(null);

  useEffect(() => 
  {
    loadTrainings();
  }, []);

  const loadTrainings = () => 
  {
    const storedTrainings = localStorage.getItem('chatbotTrainings');
    if (storedTrainings) 
    {
      setTrainings(JSON.parse(storedTrainings));
    }
  };

  const handleDelete = async (id: string) => 
  {
    try 
    {
      const confirmed = await modal.danger();

      if (confirmed) 
      {
        const updatedTrainings = trainings.filter(t => t.id !== id);
        localStorage.setItem('chatbotTrainings', JSON.stringify(updatedTrainings));
        setTrainings(updatedTrainings);
      }
    } 
    catch (error) 
    {
      console.error("Errore durante la conferma dell'eliminazione:", error);
    }
  };

  const handleEdit = (training: Training) => 
  {
    setCurrentTraining(training);
    setShowAddForm(true);
  };

  const handleAddNew = () => 
  {
    setCurrentTraining(null);
    setShowAddForm(true);
  };

  const handleSaveTraining = (data: {
    name: string;
    description: string;
    sources: TrainingSource[];
    icon?: string;
  }) => 
  {
    const timestamp = new Date().toISOString();
    let updatedTrainings: Training[];

    if (currentTraining) 
    {
      updatedTrainings = trainings.map(t =>
        t.id === currentTraining.id
          ? {
            ...currentTraining,
            name: data.name,
            description: data.description,
            icon: data.icon,
            sources: [...currentTraining.sources, ...data.sources],
            lastModified: timestamp
          }
          : t
      );
    }
    else 
    {
      const newTraining: Training = {
        id: Date.now().toString(),
        name: data.name,
        description: data.description,
        icon: data.icon,
        sources: data.sources,
        createdAt: timestamp,
        lastModified: timestamp
      };
      updatedTrainings = [...trainings, newTraining];
    }

    localStorage.setItem('chatbotTrainings', JSON.stringify(updatedTrainings));
    setTrainings(updatedTrainings);
    setShowAddForm(false);
    setCurrentTraining(null);
  };

  const getSourceTypeLabel = (source: TrainingSource) => 
  {
    switch (source.type) 
    {
      case 'website': return 'Website';
      case 'pdf': return 'PDF';
      case 'text': return 'Text';
      case 'qa': return 'Q&A';
      default: return source.type;
    }
  };

  if (showAddForm) 
  {
    return (
      <div className="min-h-screen">
        <div className="px-4 sm:px-6 lg:px-8 py-8 max-w-6xl mx-auto">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center">
              <button
                onClick={() => setShowAddForm(false)}
                className="mr-4 text-gray-500 hover:text-gray-700"
              >
                ←
              </button>
              <h1 className="text-2xl font-bold">
                {currentTraining ? 'Edit Training' : 'Add New Training'}
              </h1>
            </div>
          </div>
          <TrainingInputForm
            onSave={handleSaveTraining}
            onBack={() => setShowAddForm(false)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <div className="px-4 sm:px-6 lg:px-8 py-8 max-w-6xl mx-auto">
        {/* Header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-8">
          <div>
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              Chatbot Training
            </h1>
            <p className="text-sm text-slate-800 dark:text-slate-300 mt-1">
              Train your Chatbot on your own data (website or PDF) and make your AI content exclusive.
            </p>
          </div>
          <div>
            <Button
              onClick={handleAddNew}
              variant="primary"
              type="add"
              hideOnMobile="label"
            >
              Add Training
            </Button>
          </div>
        </div>

        {/* Table */}
        <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
          <table className="w-full table-auto">
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-4 py-3">NAME</th>
                <th className="px-4 py-3">STATUS</th>
                <th className="px-4 py-3">CREATED</th>
                <th className="px-4 py-3">MODEL</th>
                <th className="px-4 py-3">ACTIONS</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {trainings.map((training) => (
                <tr
                  key={training.id}
                  className="border-b border-slate-200 dark:border-slate-700 hover:bg-slate-50 dark:hover:bg-slate-900/20"
                >
                  <td className="px-4 py-3 ">
                    <div className="flex items-center justify-start">
                      <div className="w-8 h-8 mr-3 rounded-full bg-gray-100 dark:bg-slate-900 flex items-center justify-center overflow-hidden border border-rounded border-gray-200 dark:border-slate-700">
                        {training.icon ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: training.icon }}
                            className="w-6 h-6 text-indigo-500 "
                          />
                        ) : (
                          <span className="text-indigo-500 font-medium">
                            {training.name.substring(0, 2).toUpperCase()}
                          </span>
                        )}
                      </div>
                      <div>
                        <div className="font-medium text-slate-800 dark:text-slate-300">
                          {training.name}
                        </div>
                        <div className="text-xs text-slate-800 dark:text-slate-300">
                          {training.description ||
                            training.sources.map(s => getSourceTypeLabel(s)).join(', ')}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-3 text-center">
                    <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-gray-200 text-slate-800 dark:bg-gray-700 dark:text-slate-200">
                      Not Trained
                    </span>
                  </td>
                  <td className="px-4 py-3 text-center text-slate-800 dark:text-slate-300">
                    {new Date(training.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-3 text-center text-slate-800 dark:text-slate-300">
                    GPT-01-preview
                  </td>
                  <td className="px-4 py-3 text-center">
                    <div className="flex items-center justify-center space-x-2">
                      <Button
                        onClick={() => handleEdit(training)}
                        variant="secondary"
                        type="edit"
                        size="l"
                        hideText 
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => handleDelete(training.id)}
                        variant="textDanger"
                        type="delete"
                        size="l"
                        hideText
                      >
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {trainings.length === 0 && (
            <div className="text-center py-12">
              <p className="text-slate-500 dark:text-slate-400">
                No trainings yet. Add your first training to get started.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};