import React, { useCallback, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import countriesData from '../../../ReactIntl/Locales/CountryCodes.json';
import { getLanguage, getLocale, getLocaleRegion, getLocaleString, getTimezone, setTimezone, setLocale, getIntl } from '../../../ReactIntl/IntlConfig';
import { LanguageSelector } from '../../../ReactIntl/LanguageSelector';
// import { useUserInfo } from '../../../Hooks/UseUserInfo';
import timeZoneData from '../../../ReactIntl/Locales/TimeZone.json';
import { AccountSettingsSidebarLocale } from '../../../ReactIntl/LocaleInterfaces';
import { Tooltip } from '../../../Components/Tooltip';
import { userController } from '../../../Api/Controller/ApiUser';

export const SettingsSidebar = () => 
{

  const location = useLocation();
  // const { locale, zoneinfo, refreshUserInfo } = useUserInfo();
  // const { locale, zoneinfo } = useUserInfo();
  const defaultZoneInfo = "";
  const defaultLocale = "";
  const defaultRegion = getLocaleRegion(defaultLocale);
  const defaultLanguage = getLanguage(defaultLocale);

  const intl = getIntl<AccountSettingsSidebarLocale>('accountSettingsSidebar');

  const { pathname } = location;
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>(defaultZoneInfo);
  const [selectedRegion, setSelectedRegion] = useState<string>(defaultRegion);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(defaultLanguage);
  const [selectedLocale, setSelectedLocale] = useState<string>(defaultLocale);

  const handleLocaleChange = useCallback(async (newLanguage: string, newRegion: string) => 
  {
    const newLocale = getLocaleString(newLanguage, newRegion);

    setSelectedLocale(newLocale);
    setSelectedLanguage(newLanguage);
    setSelectedRegion(newRegion);
    setLocale(newLocale);
    userController.profileInfo({ locale: newLocale });
  }, []);

  const handleLanguageChange = useCallback((newLanguage: string) => 
  {
    handleLocaleChange(newLanguage, selectedRegion);
    // refreshUserInfo();
  }, [handleLocaleChange, selectedRegion]);

  const handleCountryChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => 
  {
    handleLocaleChange(selectedLanguage, event.target.value);
  }, [handleLocaleChange, selectedLanguage]);

  const handleTimeZoneChange = useCallback(async (newTimeZone: string) => 
  {
    setSelectedTimeZone(newTimeZone);
    setTimezone(newTimeZone);
    userController.profileInfo({ zoneInfo: newTimeZone });
  }, []);

  return (
    <div className="flex flex-wrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-slate-200 dark:border-slate-700 min-w-[15rem] md:space-y-3">
      {/* Group 1 */}
      <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-3">{intl.title}</div>
      <div>
        <ul className="flex flex-wrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/settings/account" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/settings/account') && 'bg-indigo-50 dark:bg-indigo-500/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/settings/account') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 16 16">
                <path d="M12.311 9.527c-1.161-.393-1.85-.825-2.143-1.175A3.991 3.991 0 0012 5V4c0-2.206-1.794-4-4-4S4 1.794 4 4v1c0 1.406.732 2.639 1.832 3.352-.292.35-.981.782-2.142 1.175A3.942 3.942 0 001 13.26V16h14v-2.74c0-1.69-1.081-3.19-2.689-3.733zM6 4c0-1.103.897-2 2-2s2 .897 2 2v1c0 1.103-.897 2-2 2s-2-.897-2-2V4zm7 10H3v-.74c0-.831.534-1.569 1.33-1.838 1.845-.624 3-1.436 3.452-2.422h.436c.452.986 1.607 1.798 3.453 2.422A1.943 1.943 0 0113 13.26V14z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/settings/account') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>{intl.myAccount}</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/settings/notifications" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/settings/notifications') && 'bg-indigo-50 dark:bg-indigo-500/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/settings/notifications') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 16 16">
                <path d="M14.3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-8 8c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l8-8zM15 7c.6 0 1 .4 1 1 0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8c.6 0 1 .4 1 1s-.4 1-1 1C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6c0-.6.4-1 1-1z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/settings/notifications') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>{intl.myNotifications}</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/settings/apps" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/settings/apps') && 'bg-indigo-50 dark:bg-indigo-500/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/settings/apps') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 16 16">
                <path d="M3.414 2L9 7.586V16H7V8.414l-5-5V6H0V1a1 1 0 011-1h5v2H3.414zM15 0a1 1 0 011 1v5h-2V3.414l-3.172 3.172-1.414-1.414L12.586 2H10V0h5z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/settings/apps') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>{intl.connectedApps}</span>
            </NavLink>
          </li>
          <li>
            {/* TODO: Finish to move Address Panel and Profile Panel to new Page and link with this NavLink  */}
            <NavLink end to="/settings/authentication" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/settings/authentication') && 'bg-indigo-50 dark:bg-indigo-500/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/settings/authentication') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-400 dark:text-slate-500'}`} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
              </svg>

              <span className={`text-sm font-medium ${pathname.includes('/settings/authentication') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>{intl.authentication}</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/settings/plans" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/settings/plans') && 'bg-indigo-50 dark:bg-indigo-500/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/settings/plans') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 16 16">
                <path d="M5 9h11v2H5V9zM0 9h3v2H0V9zm5 4h6v2H5v-2zm-5 0h3v2H0v-2zm5-8h7v2H5V5zM0 5h3v2H0V5zm5-4h11v2H5V1zM0 1h3v2H0V1z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/settings/plans') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>{intl.plans}</span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/settings/billing" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/settings/billing') && 'bg-indigo-50 dark:bg-indigo-500/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/settings/billing') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 16 16">
                <path d="M15 4c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3h7c.6 0 1 .4 1 1v3h4zM2 3v1h7V2H3c-.6 0-1 .4-1 1zm12 11V6H2v7c0 .6.4 1 1 1h11zm-3-5h2v2h-2V9z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/settings/billing') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>{intl.billingInvoices}</span>
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="max-w-xs mx-auto space-y-4">
        {/* Language & Time */}
        {/* Time Zone */}
        <div className="w-full">
          <div className='flex gap-2'>
            <label className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-1 block">{intl.timeZone}</label>
            <Tooltip position="right" size='sm'>
              {intl.timeZoneTooltip}
            </Tooltip>
          </div>
          <div className="flex items-center space-x-2">
            <div className="relative flex-1">
              <select
                defaultValue={selectedTimeZone}
                onChange={(e) => handleTimeZoneChange(e.target.value)}
                className="w-full dropdown dark:bg-slate-600 text-black py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-400 dark:border-gray-600 dark:focus:ring-gray-500 dark:text-white"
              >
                {timeZoneData.map((timezone, index) => (
                  <option key={index} value={timezone.zone}>
                    {`${timezone.utc} ${timezone.zone}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Country */}
        <div className="w-full">
          <div className='flex gap-2'>
            <label className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-1 block">{intl.country}</label>
            <Tooltip position="right" size='sm'>
              {intl.countryTooltip}
            </Tooltip>
          </div>
          <div className="flex items-center space-x-2">
            <div className="relative flex-1">
              <select
                value={selectedRegion}
                onChange={(e) => handleCountryChange(e)}
                className="w-full dropdown dark:bg-slate-600 text-black py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-400 dark:border-gray-600 dark:focus:ring-gray-500 dark:text-white"
              >
                <option value={selectedRegion}>{selectedRegion ? countriesData.find(country => country.code === selectedRegion)?.name : "Select a country"}</option>
                {countriesData.map((country, index) => (
                  <option key={index} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className='flex gap-2'>
            <label className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-3 block">{intl.language}</label>
            <Tooltip position="right" size='sm'>
              {intl.languageTooltip}
            </Tooltip>
          </div>
          <div className="flex items-center space-x-2">
            <div className="relative flex-1 ml-1">
              <LanguageSelector selectedLanguage={selectedLocale} onLanguageChange={handleLanguageChange} />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-3">{intl.experience}</div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink end to="/settings/feedback" className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${pathname.includes('/settings/feedback') && 'bg-indigo-50 dark:bg-indigo-500/30'}`}>
              <svg className={`w-4 h-4 shrink-0 fill-current mr-2 ${pathname.includes('/settings/feedback') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-400 dark:text-slate-500'}`} viewBox="0 0 16 16">
                <path d="M7.001 3h2v4h-2V3zm1 7a1 1 0 110-2 1 1 0 010 2zM15 16a1 1 0 01-.6-.2L10.667 13H1a1 1 0 01-1-1V1a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1zM2 11h9a1 1 0 01.6.2L14 13V2H2v9z" />
              </svg>
              <span className={`text-sm font-medium ${pathname.includes('/settings/feedback') ? 'text-indigo-500 dark:text-indigo-400' : 'text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200'}`}>{intl.giveFeedback}</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};