import React from 'react';
import { Tooltip } from '../../Components/Tooltip';
import { BaseFieldProps } from '../Utils/FormFields.types';
import { FieldValues, get } from 'react-hook-form';

export interface TextareaFieldProps<T extends FieldValues> extends BaseFieldProps<T> 
{
  placeholder?: string;
  rows?: number;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string
}

export const TextareaField = <T extends FieldValues>({
  name,
  label,
  required,
  tooltip,
  register,
  errors,
  placeholder,
  rows = 4,
  value,
  className,
  onChange
}: TextareaFieldProps<T>) => 
{
  const error = get(errors, name);
  const errorMessage = error?.message as string;

  return (
    <div className="w-full">
      <div className="flex justify-center items-center gap-2 text-center mb-1">
        <label className="block text-sm font-medium" htmlFor={name}>
          {label}
          {required && <span className="text-rose-500">*</span>}
        </label>
        {tooltip && (
          <Tooltip position="top">
            <div className="text-xs whitespace-nowrap">{tooltip}</div>
          </Tooltip>
        )}
      </div>
      <textarea
        {...register(name)}
        id={name}
        rows={rows}
        placeholder={placeholder}
        className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-slate-800 text-gray-900 dark:text-gray-100 ${error ? 'border-rose-500' : ''} ${className} `}
        value={value}
        onChange={onChange}
      />
      {error && (
        <div className="text-xs mt-1 text-rose-500">{errorMessage}</div>
      )}
    </div>
  );
};