import React from 'react';
import { User } from 'lucide-react';
import { WindTreChat, Offer, MessageRole } from '../WindTre.types';
import { MessageContent } from './MessageContent';
import { MessageButtons } from './MessageButtons';

interface MessageBubbleProps 
{
  message: WindTreChat;
  chatbotIcon: string;
  onSelectOption?: (option: string) => void;
  onCercavoAltro?: () => void;
  loading: boolean;
  isFirstMessage: boolean;
  isLastMessage: boolean;
  storeId: string;
  currentOffer: Offer | null;
}

export const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  chatbotIcon,
  onSelectOption,
  onCercavoAltro,
  loading,
  isFirstMessage,
  isLastMessage,
  storeId,
  currentOffer,
}) => 
{
  const isAi = message.role !== MessageRole.Human;
  const bubbleClass = isAi
    ? "bg-orange-100 rounded-tr-xl rounded-tl-xl rounded-br-xl ml-2"
    : "bg-orange-300 rounded-tl-xl rounded-tr-xl rounded-bl-xl mr-2";

  return (
    <div className={`relative flex ${isAi ? 'justify-start' : 'justify-end'} mb-4`}>
      {isAi && (
        <img src={chatbotIcon} alt="Windy" className="w-8 h-8 rounded-full flex-shrink-0" />
      )}
      <div className={`max-w-[70%] p-3 ${bubbleClass} break-words`}>
        <div className="relative">
          <MessageContent
            message={message}
            isAi={isAi}
            loading={loading}
            onSelectOption={onSelectOption}
          />
          <MessageButtons
            role={message.role}
            isLastMessage={isLastMessage}
            isFirstMessage={isFirstMessage}
            currentOffer={currentOffer}
            storeId={storeId}
            onCercavoAltro={onCercavoAltro}
            loading={loading}
          />
        </div>
      </div>
      {!isAi && (
        <div className="w-8 h-8 rounded-full flex-shrink-0">
          <User size={32} />
        </div>
      )}
    </div>
  );
};
