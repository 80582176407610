import { FC } from "react";
import { motion, AnimatePresence } from "framer-motion";

interface StepsTitleBarProps 
{
  titles: string[];
  currentStep: number;
}

export const StepsTitleBar: FC<StepsTitleBarProps> = ({ titles, currentStep }) => 
{
  
  return (
    <motion.div 
      className="w-full p-4 my-4 text-center bg-slate-800 dark:bg-slate-700 text-white font-bold"
    >
      <AnimatePresence mode="wait">
        {titles.map((title, index) => 
        {
          const step = index + 1;
          return currentStep === step && (
            <motion.div
              key={`step-${step}-${title}`}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -20, opacity: 0 }}
              transition={{ duration: 0.15 }}
            >
              {titles.length > 1 ? `${step}. ${title}` : title}
            </motion.div>
          );
        })}
      </AnimatePresence>
    </motion.div>
  );
};