import React, { useContext } from "react";
import { Button } from "../../../Components/Actions/Buttons/Buttons";
import { useFileSystem } from "../Hooks/UseFileSystem";
import { useToolbarAction } from "../Hooks/UseHandleToolbarAction";
import { getIntl } from "../../../ReactIntl/IntlConfig";
import { DynamicToolbarLocale } from "../../../ReactIntl/LocaleInterfaces";
import { LanguageContext } from "../../../Contexts/LanguageContext";
import { motion } from "framer-motion";

export const DynamicToolbar: React.FC = () => 
{
  const _languageCtx = useContext(LanguageContext);
  const intl = getIntl<DynamicToolbarLocale>("dynamicToolbarLocale");
  const { selectedItem, setSelectedItem } = useFileSystem();
  const handleToolbarAction = useToolbarAction();

  if (!selectedItem) return null;

  const isFile = !("subfolders" in selectedItem);

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="flex flex-wrap gap-3 items-center"
    >
      {isFile && (
        <motion.div variants={itemVariants}>
          <Button
            variant="primary"
            size="l"
            type="download"
            onClick={() => console.log('Download')}
          >
            {intl.download}
          </Button>
        </motion.div>
      )}

      <motion.div variants={itemVariants}>
        <Button

          variant="secondary"
          size="l"
          type="move"
          onClick={() => handleToolbarAction("moveTo")}
        >

          {intl.moveTo}
        </Button>
      </motion.div>

      <motion.div variants={itemVariants}>
        <Button
          variant="secondary"
          size="l"
          type="copy"
          onClick={() => handleToolbarAction("copyTo")}
        >
          {intl.copyTo}
        </Button>
      </motion.div>

      <motion.div variants={itemVariants}>
        <Button
          variant="secondary"
          size="l"
          type="edit"
          onClick={() => handleToolbarAction("rename")}
        >
          {intl.rename}
        </Button>
      </motion.div>

      <motion.div variants={itemVariants}>
        <Button
          variant="textDanger"
          size="l"
          type="delete"
          onClick={() => handleToolbarAction("delete")}
        >
          {intl.delete}
        </Button>
      </motion.div>

      <motion.div variants={itemVariants}>
        <Button
          variant="tertiary"
          size="l"
          type="cancel"
          onClick={() => setSelectedItem(null)}
        >
          {intl.clearSelection}
        </Button>
      </motion.div>
    </motion.div>
  );
};
