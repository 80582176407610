import React, { useState, useRef, useEffect } from 'react';
import { Button } from './Actions/Buttons/Buttons';
import Transition from '../Utils/Transition';
import { getIntl } from '../ReactIntl/IntlConfig';
import { DropdownFilterLocale } from '../ReactIntl/LocaleInterfaces';

interface DropdownFilterProps 
{
  align: 'left' | 'right';
  fields: { label: string }[];
}

export const DropdownFilter: React.FC<DropdownFilterProps> = ({ align, fields }) => 
{
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef<HTMLButtonElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);

  const intl = getIntl<DropdownFilterLocale>('dropdownFilter');

  // close on click outside
  useEffect(() => 
  {
    const clickHandler = ({ target }: MouseEvent) => 
    {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target as Node) || trigger.current?.contains(target as Node)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => 
  {
    const keyHandler = ({ key }: KeyboardEvent) => 
    {
      if (!dropdownOpen || key !== "Escape") return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative inline-flex">
      <Button
        ref={trigger}
        variant="tertiary"
        size="l"
        hideText
        type="filter"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-haspopup="true"
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">{intl.srOnly}</span>
      </Button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`origin-top-right z-10 absolute top-full left-0 right-auto min-w-56 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 pt-1.5 rounded shadow-lg overflow-hidden mt-1 ${align === 'right' ? 'md:left-auto md:right-0' : 'md:left-0 md:right-auto'}`}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div ref={dropdown}>
          <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">{intl.title}</div>
          <ul className="mb-4">
            {fields.map((field, index) => (
              <li key={index} className="py-1 px-3">
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="text-sm font-medium ml-2">{field.label}</span>
                </label>
              </li>
            ))}
          </ul>
          <div className="py-2 px-3 border-t border-slate-200 dark:border-slate-700 bg-slate-50 dark:bg-slate-700/20">
            <ul className="flex items-center justify-between">
              <li>
                <Button
                  variant="tertiary"
                  size="s"
                >
                  {intl.clearBtn}
                </Button>
              </li>
              <li>
                <Button
                  variant="primary"
                  size="s"
                  onClick={() => setDropdownOpen(false)}

                >
                  {intl.applyBtn}
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </Transition>
    </div>
  );
};
