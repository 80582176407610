import React, { useReducer, useEffect, useCallback, FormEvent, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthImage from '../../Images/auth-image.jpg';
import AuthDecoration from '../../Images/auth-decoration.png';
import { Banner } from '../../Components/Banner';
import { LanguageSelector } from '../../ReactIntl/LanguageSelector';
import { ExternalLoginButtons } from '../../Api/ExternalLoginButtons';
import { getIntl, getLocale, setLocale } from '../../ReactIntl/IntlConfig';
import { accountController } from '../../Api/Controller/ApiAccount';
import { Toast } from '../../Components/Toast';
import { SignInPageLocale, SignUpPageLocale } from '../../ReactIntl/LocaleInterfaces';
import { getRememberedUser, rememberUser } from '../../Utils/RememberUser';
import { initialSigninState, signinReducer } from '../../Reducer/SigninReducer';
import { SigninActions } from '../../Reducer/SigninActions';
import { AxiosError } from 'axios';
import { useError } from '../../Hooks/UseError';
import { useSupabaseClient } from '../../Features/Auth/Hooks/UseSession';

export const Signin: React.FC = () => 
{
  const [state, dispatch] = useReducer(signinReducer, initialSigninState);
  const [selectedLocale, setSelectedLocale] = React.useState<string>(getLocale());
  const intl = getIntl<SignInPageLocale>('signinPage');
  const intlSignup = getIntl<SignUpPageLocale>('signupPage');
  const { isBlocked, retrySeconds } = useError();

  useEffect(() => 
  {
    if (retrySeconds && isBlocked) 
    {
      dispatch(new SigninActions.SetErrorMessage(`Too many requests, wait ${retrySeconds} seconds and try again`));
      dispatch(new SigninActions.SetBannerOpen(true));
    }
  }, [isBlocked, retrySeconds]);

  useEffect(() => 
  {
    if (localStorage.getItem('authorizationDenied')) 
    {
      dispatch(new SigninActions.SetToastType('error'));
      dispatch(new SigninActions.SetErrorMessage(`${intl.authorizationError}`));
      dispatch(new SigninActions.SetBannerOpen(true));
    }
  }, [intl.authorizationError]);

  useEffect(() => 
  {
    const rememberedUser = getRememberedUser();
    if (rememberedUser) 
    {
      dispatch(new SigninActions.SetEmail(rememberedUser.email));
      dispatch(new SigninActions.SetPassword(rememberedUser.password));
      dispatch(new SigninActions.SetRememberMe(true));
    }
  }, []);

  const handleResend = useCallback(async () => 
  {
    dispatch(new SigninActions.SetIsLoading(true));
    try 
    {
      await accountController.resendEmail(state.email);
      dispatch(new SigninActions.SetToastType('success'));
      dispatch(new SigninActions.SetToastMessage(`${intl.emailToastSuccess} ${state.email}`));
      dispatch(new SigninActions.SetToastOpen(true));
      dispatch(new SigninActions.SetBannerOpen(false));
    }
    catch (error) 
    {
      dispatch(new SigninActions.SetToastType('error'));
      dispatch(new SigninActions.SetToastMessage(`${intl.emailToastError} ${state.email}`));
      dispatch(new SigninActions.SetToastOpen(true));
      dispatch(new SigninActions.SetBannerOpen(false));
    }
    finally 
    {
      dispatch(new SigninActions.SetIsLoading(false));
    }
  }, [state.email, intl]);

  // const handleSubmit = useCallback(async (event: FormEvent) => 
  // {
  //   event.preventDefault();
  //   dispatch(new SigninActions.SetIsLoading(true));

  //   try 
  //   {
  //     if (state.rememberMe) 
  //     {
  //       rememberUser(state.email, state.password);
  //     }
  //     else 
  //     {
  //       getRememberedUser();
  //     }

  //     await accountController.login(state.email, state.password);
  //   }
  //   catch (error) 
  //   {
  //     if (error instanceof AxiosError && error.response) 
  //     {
  //       if (error.response.data === 'NotAllowed') 
  //       {
  //         dispatch(new SigninActions.SetErrorMessage(intl.emailNotVerifiedMessage));
  //         dispatch(new SigninActions.SetResentButton(true));
  //       }
  //       else 
  //       {
  //         dispatch(new SigninActions.SetErrorMessage(intl.errorMessage));
  //         dispatch(new SigninActions.SetResentButton(false));
  //       }
  //       dispatch(new SigninActions.SetBannerOpen(true));
  //     }
  //   }
  //   finally 
  //   {
  //     dispatch(new SigninActions.SetIsLoading(false));
  //   }
  // }, [state.email, state.password, state.rememberMe, intl]);

  const handleLocaleChange = useCallback((newLocale: string) => 
  {
    setSelectedLocale(newLocale);
    setLocale(newLocale);
  }, []);

  const supabaseClient = useSupabaseClient();
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as { from?: Location })?.from?.pathname || '/';

  const handleLogin = async (loginMethod: () => Promise<{ error: Error | null }>) => 
  {
    try 
    {
      const { error } = await loginMethod();
      if (error) 
      {
        console.error('Error logging in:', error.message);
      }
      else 
      {
        const session = await supabaseClient.auth.getSession();
        console.log('Login successful:', session.data.session);
        navigate(from, { replace: true });
      }
    }
    catch (err) 
    {
      console.error('Unexpected error:', err);
    }
  };

  const handleEmailLogin = () => handleLogin(() => supabaseClient.auth.signInWithPassword({ email: state.email, password: state.password }));

  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100vh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src="/beet-icon.svg" alt="Beet Root" className="mx-auto mb-4 my-16 w-16 h-16" />
                </Link>
                {/* Language selector */}
                <div className='flex place-content-end w-40'>
                  <LanguageSelector selectedLanguage={selectedLocale} onLanguageChange={handleLocaleChange} />
                </div>
              </div>
            </div>

            <div className="max-w-sm mx-auto w-full px-4 py-8">
              <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">
                {intl.title}
              </h1>
              {/* Error banner */}
              <Banner className="mb-4" type="error" open={state.bannerOpen} setOpen={() => dispatch(new SigninActions.SetBannerOpen(false))}>
                {state.errorMessage}
              </Banner>
              <Toast type={state.toastType} open={state.toastOpen} setOpen={() => dispatch(new SigninActions.SetToastOpen(false))}>
                {state.toastMessage}
              </Toast>
              {state.resentButton && state.bannerOpen && (
                <div className="flex justify-center">
                  <button
                    className="btn bg-green-700 hover:bg-green-600 text-white ml-3"
                    onClick={handleResend}
                  >
                    {intl.resendButton}
                  </button>
                </div>
              )}
              {/* Form */}
              <form onSubmit={(e) => 
              {
                e.preventDefault();
                handleEmailLogin();
              }}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">
                      {intl.emailLabel}
                      <span className="text-rose-500">*</span>
                    </label>
                    <input
                      id="email"
                      className="form-input w-full"
                      type="email"
                      value={state.email}
                      onChange={(e) => dispatch(new SigninActions.SetEmail(e.target.value))}
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="password">
                      {intl.passwordLabel}
                      <span className="text-rose-500">*</span>
                    </label>
                    <input
                      id="password"
                      className="form-input w-full"
                      type="password"
                      value={state.password}
                      onChange={(e) => dispatch(new SigninActions.SetPassword(e.target.value))}
                      required
                    />
                  </div>
                </div>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    checked={state.rememberMe}
                    onChange={(e) => dispatch(new SigninActions.SetRememberMe(e.target.checked))}
                  />
                  <span className="text-sm ml-2 py-2">
                    {intl.rememberMe}
                  </span>
                </label>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <Link className="text-sm underline hover:no-underline" to="/account/reset-password">
                      {intl.forgotPasswordLink}
                    </Link>
                  </div>
                  <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3" type="submit" disabled={state.isLoading}>
                    {state.isLoading ?
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                      </svg>
                      :
                      intlSignup.signInLink}
                  </button>
                </div>
              </form>
              {/* <ExternalLoginButtons /> */}
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-slate-200 dark:border-slate-800">
                <div className="text-sm">
                  {intl.createNewAccount}{' '}
                  <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/account/register">
                    {intlSignup.signUpButton}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Image */}
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
          <img
            className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div>
      </div>
    </main>
  );
};