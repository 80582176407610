import React from 'react';
import { ChartPlaceholder, ImagePlaceholder, ListPlaceholder, TextPlaceholder } from '../Placeholder';

export const SkeletonPage: React.FC = () =>
{

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900">

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-8">
              <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Dropdown ✨</h1>
            </div>

            <div className="border-t border-slate-200 dark:border-slate-700">

              {/* Components */}
              <div className="space-y-8 mt-8 mb-80">

                <div className="w-80">
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Full-width Dropdown</h2>
                  <ImagePlaceholder />
                </div>

                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Filter</h2>
                  <ChartPlaceholder />
                </div>

                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">List</h2>
                  <ListPlaceholder />
                </div>

                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Switch Account</h2>
                  <TextPlaceholder />
                </div>

              </div>
            </div>
          </div>
        </main>

      </div>

    </div>
  );
};
