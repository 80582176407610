import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import { useThemeProvider } from '../Utils/ThemeContext';
import { chartColors } from './ChartjsConfig';
import {
  Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';

// Import utilities
import { formatThousands } from '../Utils/Utils';
import { ChartProps } from './Charts';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

export const LineChart04: React.FC<ChartProps> = ({ data, width, height }) => 
{
  const chartRef = useRef<Chart | null>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const { currentTheme } = useThemeProvider();
  const darkMode = currentTheme === 'dark';

  // Usa useMemo per stabilizzare textColor e gridColor
  const textColor = useMemo(() => ({
    light: '#000000',
    dark: '#FFFFFF',
  }), []);

  const gridColor = useMemo(() => ({
    light: '#D3D3D3',
    dark: '#4A4A4A',
  }), []);

  const { tooltipBodyColor, tooltipBgColor, tooltipBorderColor, chartAreaBg } = chartColors;

  // Plugin per impostare lo sfondo dell'area del grafico
  const backgroundPlugin = useMemo(() => ({
    id: 'backgroundColor',
    beforeDraw: (chart: Chart) => 
    {
      const ctx = chart.ctx;
      const { chartArea } = chart;

      ctx.save();
      ctx.fillStyle = darkMode ? chartAreaBg.dark : chartAreaBg.light;
      ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
      ctx.restore();
    }
  }), [darkMode, chartAreaBg]);

  // Usa useCallback per creare una versione stabile di createChart
  const createChart = useCallback(() => 
  {
    if (!canvas.current) return;

    const ctx = canvas.current;

    // Distruggi il grafico precedente se esiste
    if (chartRef.current) 
    {
      chartRef.current.destroy();
    }

    const newChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        layout: {
          padding: {
            left: 20,
            right: 20,
          },
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
            grid: {
              color: darkMode ? gridColor.dark : gridColor.light,
            },
            ticks: {
              color: darkMode ? textColor.dark : textColor.light,
            }
          },
          x: {
            type: 'time',
            time: {
              parser: 'MM-DD-YYYY',
              unit: 'month',
            },
            display: false,
            grid: {
              color: darkMode ? gridColor.dark : gridColor.light,
            },
            ticks: {
              color: darkMode ? textColor.dark : textColor.light,
            }
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: () => '', // Disable tooltip title
              label: (context) => formatThousands(context.parsed.y),
            },
            bodyColor: darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light,
            backgroundColor: darkMode ? tooltipBgColor.dark : tooltipBgColor.light,
            borderColor: darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light,
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        maintainAspectRatio: false,
      },
      plugins: [backgroundPlugin], // Usa il plugin per lo sfondo
    });

    // Assegna il nuovo grafico al ref
    chartRef.current = newChart;
  }, [data, darkMode, textColor, gridColor, backgroundPlugin, tooltipBodyColor, tooltipBgColor, tooltipBorderColor]);

  useEffect(() => 
  {
    createChart();

    // Pulizia del grafico quando il componente si smonta
    return () => 
    {
      if (chartRef.current) 
      {
        chartRef.current.destroy();
      }
    };
  }, [createChart, currentTheme, data]);

  return (
    <canvas ref={canvas} width={width} height={height}></canvas>
  );
};
