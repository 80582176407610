import Image01 from '../../../Images/user-28-01.jpg';
import Image02 from '../../../Images/user-28-02.jpg';
import Image03 from '../../../Images/user-28-03.jpg';
import { getIntl } from '../../../ReactIntl/IntlConfig';
import { AnalyticsCards } from '../../../ReactIntl/LocaleInterfaces';

export function AnalyticsCard11() 
{
  const intl = getIntl<AnalyticsCards>('analyticsCards');

  return (
    <div className="col-span-full bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">{intl.h2TopProducts}</h2>
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300">
            {/* Table header */}
            <thead className="text-xs uppercase text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">{intl.thProduct}</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">{intl.thCreatedBy}</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">{intl.thCategory}</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">{intl.thTotalImpressions}</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">{intl.thTopCountry}</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">{intl.thCR}</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">{intl.thValue}</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-100 dark:divide-slate-700">
              {/* Row */}
              <tr>
                <td className="p-2 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="shrink-0 rounded-full mr-2 sm:mr-3 bg-indigo-500">
                      <svg className="w-9 h-9 fill-current text-indigo-50" viewBox="0 0 36 36">
                        <path d="M24.446 19.335a2.5 2.5 0 00-3.522 3.194c-.845.63-1.87.97-2.924.971a4.979 4.979 0 01-1.113-.135 4.436 4.436 0 01-1.343 1.682 6.91 6.91 0 006.9-1.165 2.5 2.5 0 002-4.547h.002zM20.431 11.938a2.5 2.5 0 10-.4 2.014 5.027 5.027 0 012.723 3.078c.148-.018.297-.028.446-.03a4.5 4.5 0 011.7.334 7.023 7.023 0 00-4.469-5.396zM14.969 20.25a2.49 2.49 0 00-1.932-1.234A4.624 4.624 0 0113 18.5a4.97 4.97 0 011.348-3.391 4.456 4.456 0 01-.788-2.016A6.989 6.989 0 0011 18.5c.003.391.04.781.11 1.166a2.5 2.5 0 103.859.584z" />
                      </svg>
                    </div>
                    <div className="font-medium text-slate-800 dark:text-slate-100">Form Builder CP</div>
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="flex shrink-0 -space-x-3 -ml-px">
                    <a className="block" href="#0">
                      <img className="rounded-full border-2 border-white dark:border-slate-800 box-content" src={Image01} width="28" height="28" alt="User 01" />
                    </a>
                    <a className="block" href="#0">
                      <img className="rounded-full border-2 border-white dark:border-slate-800 box-content" src={Image02} width="28" height="28" alt="User 02" />
                    </a>
                    <a className="block" href="#0">
                      <img className="rounded-full border-2 border-white dark:border-slate-800 box-content" src={Image03} width="28" height="28" alt="User 03" />
                    </a>
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap">
                  <div className="flex items-center">
                    <svg className="w-4 h-4 fill-current text-slate-400 dark:text-slate-500 shrink-0 mr-2" viewBox="0 0 16 16">
                      <path d="M4.3 4.5c1.9-1.9 5.1-1.9 7 0 .7.7 1.2 1.7 1.4 2.7l2-.3c-.2-1.5-.9-2.8-1.9-3.8C10.1.4 5.7.4 2.9 3.1L.7.9 0 7.3l6.4-.7-2.1-2.1zM15.6 8.7l-6.4.7 2.1 2.1c-1.9 1.9-5.1 1.9-7 0-.7-.7-1.2-1.7-1.4-2.7l-2 .3c.2 1.5.9 2.8 1.9 3.8 1.4 1.4 3.3 2.1 5.2 2.1 1.9 0 3.7-.7 5.1-2.1l2.2 2.2.7-6.4z" />
                    </svg>
                    <span className="font-medium text-slate-800 dark:text-slate-100">Software</span>
                  </div>
                </td>
                <td className="p-2 whitespace-nowrap text-center font-medium text-slate-800 dark:text-slate-100">12.2k</td>
                <td className="p-2 whitespace-nowrap text-center font-medium text-slate-800 dark:text-slate-100">USA</td>
                <td className="p-2 whitespace-nowrap text-center font-medium text-slate-800 dark:text-slate-100">32%</td>
                <td className="p-2 whitespace-nowrap">
                  <div className="text-sm font-medium text-right text-indigo-500 dark:text-indigo-400">$2,450</div>
                </td>
              </tr>
              {/* More rows */}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        <div className="flex justify-between items-center mt-4">
          <div className="flex-1 flex justify-between items-center sm:hidden">
            <a className="relative inline-flex items-center px-4 py-2 rounded-md shadow-sm font-medium text-slate-800 dark:text-slate-100 bg-slate-100 dark:bg-slate-600 hover:bg-slate-200 dark:hover:bg-slate-700" href="#0">
              {intl.previousAnchor}
            </a>
            <a className="relative inline-flex items-center px-4 py-2 rounded-md shadow-sm font-medium text-slate-800 dark:text-slate-100 bg-slate-100 dark:bg-slate-600 hover:bg-slate-200 dark:hover:bg-slate-700" href="#0">
              {intl.nextAnchor}
            </a>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-center">
            <div>
              <p className="text-sm text-slate-800 dark:text-slate-100">
                {intl.showingN}{""}
                <span className="font-medium">1</span>
                {" "}{intl.toN}{" "}
                <span className="font-medium">10</span>
                {" "}{intl.ofN}{" "}
                <span className="font-medium">97</span>
                {" "}{intl.results}
              </p>
            </div>
          </div>
          <div className="flex-1 flex justify-between items-center sm:hidden">
            <span>1 {intl.ofN} 10</span>
            <button className="rounded-full p-1 bg-indigo-500 text-white">
              <svg className="w-4 h-4 fill-current" viewBox="0 0 16 16">
                <path d="M8 14a6 6 0 100-12 6 6 0 000 12zM8 15a7 7 0 100-14 7 7 0 000 14zm0-8a1 1 0 011-1h1a1 1 0 010 2h-2a1 1 0 011-1zm-.5 2a.5.5 0 00-.5.5v2a.5.5 0 001 0V9a.5.5 0 00-.5-.5zM8 7a1 1 0 011-1h1a1 1 0 010 2H9a1 1 0 01-1-1zm-4.5 2a.5.5 0 00-.5.5v2a.5.5 0 001 0V9a.5.5 0 00-.5-.5zM5 7a1 1 0 011-1h1a1 1 0 010 2H6a1 1 0 01-1-1zm-2.5 2a.5.5 0 00-.5.5v2a.5.5 0 001 0V9a.5.5 0 00-.5-.5z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
