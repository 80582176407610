import { getIntl } from "../../../ReactIntl/IntlConfig";
import { ShopSidebarLocale } from "../../../ReactIntl/LocaleInterfaces";

export function ShopSidebar() 
{
  const intl = getIntl<ShopSidebarLocale>("shopSidebar");

  return (
    <div>
      <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-5 min-w-60">
        <div className="grid md:grid-cols-2 xl:grid-cols-1 gap-6">
          {/* Group 1 */}
          <div>
            <div className="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">{intl.discover}</div>
            <ul className="text-sm font-medium space-y-2">
              <li>
                <a className="text-indigo-500" href="#0">{intl.liViewAllAnchor}</a>
              </li>
              <li>
                <a className="text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200" href="#0">{intl.liAppsSoftwareAnchor}</a>
              </li>
              <li>
                <a className="text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200" href="#0">{intl.liDesignTechProdsAnchor}</a>
              </li>
              <li>
                <a className="text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200" href="#0">{intl.liBooksWritingAnchor}</a>
              </li>
              <li>
                <a className="text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200" href="#0">{intl.liEducationAnchor}</a>
              </li>
              <li>
                <a className="text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200" href="#0">{intl.liDrawingPaintingAnchor}</a>
              </li>
            </ul>
          </div>
          {/* Price Range */}
          <div>
            <div className="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">{intl.priceRange}</div>
            <label className="sr-only">{intl.srOnlyPrice}</label>
            <select className="form-select w-full">
              <option>{intl.optionLess20} $20</option>
              <option>$20 - $40</option>
              <option>$40 - $80</option>
              <option>{intl.optionMore80} $80</option>
            </select>
          </div>
          {/* Group 3 */}
          <div>
            <div className="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">{intl.multiSelect}</div>
            <ul className="space-y-2">
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">{intl.liAppsSoftware}</span>
                </label>
              </li>
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">{intl.liEducation}</span>
                </label>
              </li>
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">{intl.liBooksWriting}</span>
                </label>
              </li>
              <li>
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="text-sm text-slate-600 dark:text-slate-300 font-medium ml-2">{intl.liDrawingPainting}</span>
                </label>
              </li>
            </ul>
          </div>
          {/* Group 4 */}
          <div>
            <div className="text-sm text-slate-800 dark:text-slate-100 font-semibold mb-3">{intl.sortByRating}</div>
            <ul className="space-y-2">
              <li>
                {/* Rating button */}
                <button className="flex items-center space-x-2 mr-2">
                  {/* Stars */}
                  <div className="flex space-x-1">
                    <svg className="w-4 h-4 fill-current text-amber-500" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-amber-500" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-amber-500" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-amber-500" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-slate-300 dark:text-slate-600" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                  </div>
                  <div className="inline-flex text-sm text-slate-500 dark:text-slate-400 italic"><span className="sr-only">4 {intl.srOnlyStarsStart}</span> {intl.srOnlyStarsEnd}</div>
                </button>
              </li>
              <li>
                {/* Rating button */}
                <button className="flex items-center space-x-2 mr-2">
                  {/* Stars */}
                  <div className="flex space-x-1">
                    <svg className="w-4 h-4 fill-current text-amber-500" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-amber-500" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-amber-500" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-slate-300 dark:text-slate-600" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-slate-300 dark:text-slate-600" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                  </div>
                  <div className="inline-flex text-sm text-slate-500 dark:text-slate-400 italic"><span className="sr-only">3 {intl.srOnlyStarsStart}</span> {intl.srOnlyStarsEnd}</div>
                </button>
              </li>
              <li>
                {/* Rating button */}
                <button className="flex items-center space-x-2 mr-2">
                  {/* Stars */}
                  <div className="flex space-x-1">
                    <svg className="w-4 h-4 fill-current text-amber-500" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-amber-500" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-slate-300 dark:text-slate-600" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-slate-300 dark:text-slate-600" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-slate-300 dark:text-slate-600" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                  </div>
                  <div className="inline-flex text-sm text-slate-500 dark:text-slate-400 italic"><span className="sr-only">2 {intl.srOnlyStarsStart}</span> {intl.srOnlyStarsEnd}</div>
                </button>
              </li>
              <li>
                {/* Rating button */}
                <button className="flex items-center space-x-2 mr-2">
                  {/* Stars */}
                  <div className="flex space-x-1">
                    <svg className="w-4 h-4 fill-current text-amber-500" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-slate-300 dark:text-slate-600" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-slate-300 dark:text-slate-600" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-slate-300 dark:text-slate-600" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                    <svg className="w-4 h-4 fill-current text-slate-300 dark:text-slate-600" viewBox="0 0 16 16">
                      <path d="M10 5.934L8 0 6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934z" />
                    </svg>
                  </div>
                  <div className="inline-flex text-sm text-slate-500 dark:text-slate-400 italic"><span className="sr-only">1 {intl.srOnlyStarsStart}</span> {intl.srOnlyStarsEnd}</div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}