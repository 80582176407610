import React, { useState, useEffect } from 'react';
import { API_VERSION } from './ApiConfig';
import { accountController } from './Controller/ApiAccount';
import { getIntl } from '../ReactIntl/IntlConfig';
import { ExternalLoginButtonsLocale } from '../ReactIntl/LocaleInterfaces';

export const ExternalLoginButtons: React.FC = () => 
{
  const [providers, setProviders] = useState<string[]>([]);

  const intl = getIntl<ExternalLoginButtonsLocale>('externalLoginButtons');

  /*   useEffect(() => 
  {
    const fetchProviders = async () => 
    {
      try 
      {
        const providers = await accountController.fetchExternalLoginProviders(); //TODO: this no longer works
        setProviders(providers.data);
      }
      catch (error) 
      {
        console.error('Error fetching providers:', error);
      }
    };

    fetchProviders();
  }, []); */

  return (
    <div className="flex flex-col items-center my-4">
      <div className="flex items-center w-full max-w-xs my-4">
        <div className="flex-grow border-t border-gray-300"></div>
        <span className="mx-2 text-sm text-gray-500">{intl.signInWith}</span>
        <div className="flex-grow border-t border-gray-300"></div>
      </div>
      {providers.map((provider, index) => (
        <a
          key={index}
          href={`/${API_VERSION}/account/external-login/${provider}?returnUrl=/account/login-callback?provider=${provider}`}
          className="flex items-center justify-center px-3 py-2 my-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full max-w-xs"
        >
          <img
            src={`/OAuthLogos/${provider}.svg`}
            alt={`${provider} logo`}
            className="w-5 h-5 mr-2" />
          <span className="text-sm font-medium">{provider}</span>
        </a>
      ))}
    </div>
  );
};
