import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { PanelPhoto } from './AccountPanel/PanelPhoto';
import { PanelName } from './AccountPanel/PanelName';
import { PanelAddress } from './AccountPanel/PanelAddress';
// import { useUserInfo } from '../../../Hooks/UseUserInfo';
import { OidcAddressClaim } from 'oidc-client-ts';
import { getIntl } from '../../../ReactIntl/IntlConfig';
import { AccountPanelLocale } from '../../../ReactIntl/LocaleInterfaces';
import { LanguageContext } from '../../../Contexts/LanguageContext';
import toast from 'react-hot-toast';
import { userController } from '../../../Api/Controller/ApiUser';
import { useUser } from '../../../Features/Auth/Hooks/UseSession';

export interface UserSettings 
{
  given_name: string | undefined;
  family_name: string | undefined;
  gender: string | undefined;
  address: OidcAddressClaim | undefined;
  birthdate: string | undefined;
}

export interface AccountPanelChildProps 
{
  userData: UserSettings;
  setUserData: React.Dispatch<React.SetStateAction<UserSettings>>;
  onFieldChange: () => void;
}

interface UpdatedUserData 
{
  [key: string]: string | undefined;
  givenName?: string;
  familyName?: string;
  gender?: string;
  birthdate?: string;
  streetAddress?: string;
  locality?: string;
  region?: string;
  postalCode?: string;
  country?: string;
}

export const AccountPanel: React.FC = () => 
{
  // const { given_name, family_name, gender, address, birthdate } = useUserInfo();
  const _languageCtx = useContext(LanguageContext);
  const intl = getIntl<AccountPanelLocale>('accountPanel');
  const user = useUser();

  const [userData, setUserData] = useState<UserSettings>({
    given_name: "admin",
    family_name: '',
    gender: 'Male',
    address: undefined,
    birthdate: undefined
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  useEffect(() => 
  {
    setUserData({
      given_name: 'admin',
      family_name: '',
      gender: 'Male',
      address: undefined,
      birthdate: undefined
    });
  }, []);

  // useEffect(() => {
  //   const hasChanges =
  //     userData.given_name !== given_name ||
  //     userData.family_name !== family_name ||
  //     userData.gender !== gender ||
  //     userData.birthdate !== birthdate ||
  //     JSON.stringify(userData.address) !== JSON.stringify(address);
  //   setHasUnsavedChanges(hasChanges);
  // }, [userData, given_name, family_name, gender, address, birthdate]);

  const handleFieldChange = useCallback(() => 
  {
    if (hasUnsavedChanges) 
    {
      toast.loading("You have unsaved changes.", { id: 'unsavedChanges', duration: 5000 });
    }
  }, [hasUnsavedChanges]);

  // const saveUserData = useCallback(async () => {
  //   setIsLoading(true);
  //   try {
  //     const updatedUserData: UpdatedUserData = {};

  //     if (userData.given_name !== given_name) updatedUserData.givenName = userData.given_name;
  //     if (userData.family_name !== family_name) updatedUserData.familyName = userData.family_name;
  //     if (userData.gender !== gender) updatedUserData.gender = userData.gender;
  //     if (userData.birthdate !== birthdate) updatedUserData.birthdate = userData.birthdate;

  //     if (userData.address) {
  //       if (userData.address.street_address !== address?.street_address) updatedUserData.streetAddress = userData.address.street_address;
  //       if (userData.address.locality !== address?.locality) updatedUserData.locality = userData.address.locality;
  //       if (userData.address.region !== address?.region) updatedUserData.region = userData.address.region;
  //       if (userData.address.postal_code !== address?.postal_code) updatedUserData.postalCode = userData.address.postal_code;
  //       if (userData.address.country !== address?.country) updatedUserData.country = userData.address.country;
  //     }

  //     if (Object.keys(updatedUserData).length === 0) {
  //       toast.error('No changes detected');
  //       setIsLoading(false);
  //       return;
  //     }

  //     await userController.profileInfo(updatedUserData);
  //     toast.success('Changes saved successfully!');
  //     // refreshUserInfo();
  //     setHasUnsavedChanges(false);
  //     toast.dismiss('unsavedChanges');
  //   }
  //   catch (error) {
  //     toast.error('Error saving changes');
  //     console.error("Error: ", error);
  //   }
  //   finally {
  //     setIsLoading(false);
  //   }
  // }, [userData, given_name, family_name, gender, birthdate, address]);

  const childProps = useMemo<AccountPanelChildProps>(() => ({
    userData,
    setUserData,
    onFieldChange: handleFieldChange
  }), [userData, handleFieldChange]);

  return (
    <div className="grow">
      <div className="p-6 space-y-6">
        <PanelPhoto />
        <PanelName {...childProps} />
        <PanelAddress {...childProps} />
      </div>

      <div className="flex flex-col px-6 py-5 border-slate-200 dark:border-slate-700 sticky bottom-0">
        <div className="flex self-end">
          <button
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
            // onClick={saveUserData}
            onClick={() => alert("Not implemented yet")}
            disabled={!hasUnsavedChanges || isLoading}
          >
            {isLoading ? (
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
              </svg>
            ) : (
              intl.saveChangesButton
            )}
          </button>
        </div>
      </div>
    </div>
  );
};