import { GenAIAction, GenAIItem } from './GenAITypes';
import { GenAIProviderModel, IGenAICategory, IUserGenAITemplate } from '../Api/Types/GenAIInterface';
import React from 'react';
import { GenAIMessageResponse } from '../Api/Types/GenAIResponse';

export class GenAIActions
{
  private dispatch: React.Dispatch<GenAIAction>;

  constructor(dispatch: React.Dispatch<GenAIAction>) 
  {
    this.dispatch = dispatch;
  }

  fetchStart() 
  {
    this.dispatch({ type: 'FETCH_START' });
  }

  fetchSuccess(items: GenAIItem[]) 
  {
    this.dispatch({ type: 'FETCH_SUCCESS', payload: items });
  }

  fetchError(error: string) 
  {
    this.dispatch({ type: 'FETCH_ERROR', payload: error });
  }

  toggleView() 
  {
    this.dispatch({ type: 'TOGGLE_VIEW' });
  }

  deleteItem(id: string) 
  {
    this.dispatch({ type: 'DELETE_ITEM', payload: id });
  }

  updateItem(item: GenAIItem) 
  {
    this.dispatch({ type: 'UPDATE_ITEM', payload: item });
  }

  createItem(item: GenAIItem) 
  {
    this.dispatch({ type: 'CREATE_ITEM', payload: item });
  }

  setCategories(categories: IGenAICategory[]) 
  {
    this.dispatch({ type: 'SET_CATEGORIES', payload: categories });
  }

  setUserFavorites(favorites: IUserGenAITemplate[]) 
  {
    this.dispatch({ type: 'SET_USER_FAVORITES', payload: favorites });
  }

  updateUserFavorite(templateId: string, isFavorite: boolean) 
  {
    this.dispatch({ type: 'UPDATE_USER_FAVORITE', payload: { templateId, isFavorite } });
  }

  clearError() 
  {
    this.dispatch({ type: 'CLEAR_ERROR' });
  }

  setSelectedCategory(categoryId: string | null) 
  {
    this.dispatch({ type: 'SET_SELECTED_CATEGORY', payload: categoryId });
  }

  setShowFavorites(show: boolean) 
  {
    this.dispatch({ type: 'SET_SHOW_FAVORITES', payload: show });
  }

  setDeletionCheck(id: string, canDelete: boolean, associatedNames: string[]) 
  {
    this.dispatch({ type: 'SET_DELETION_CHECK', payload: { id, canDelete, associatedNames } });
  }

  clearDeletionCheck() 
  {
    this.dispatch({ type: 'CLEAR_DELETION_CHECK' });
  }

  setProviderModel(providerModel: GenAIProviderModel) 
  {
    this.dispatch({ type: 'SET_PROVIDER_MODEL', payload: providerModel });
  }

  setWriterResponse(response: GenAIMessageResponse) 
  {
    this.dispatch({ type: 'SET_WRITER_RESPONSE', payload: response });
  }

}