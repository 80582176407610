// import React, { createContext, useState, useEffect, ReactNode } from 'react';
// import { ClientConfig, getConfig } from '../ClientConfig';
// import { BeetLoader } from '../Components/BeetLoader';
// import { initializeClient } from '../Api/BeetClient';

// export interface AppContextProps 
// {
//   clientConfig: ClientConfig;
// }

// export const AppContext = createContext<AppContextProps>({ clientConfig: null! });

// export const AppContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => 
// {
//   const [clientConfig, setClientConfig] = useState<ClientConfig>(null!);

//   useEffect(() => 
//   {
//     getConfig().then(config => 
//     {
//       initializeClient(config.oidc);
//       setClientConfig(config);
//     })
//       .catch(error => 
//       {
//         console.error('Error initializing client:', error);
//       });
//   },
//   []);

//   if (!clientConfig) 
//   {
//     return (
//       <div className="flex flex-col items-center justify-center h-screen">
//         <BeetLoader />
//       </div>
//     );
//   }

//   return (
//     <AppContext.Provider value={{ clientConfig }}>
//       {children}
//     </AppContext.Provider>
//   );
// };

// /* export const useAppContext = () => 
// {
//   const context = useContext(AppContext);
//   if (!context) 
//   {
//     throw new Error('useAppContext must be used within an AppContextProvider');
//   }

//   return context;
// }; */

import React, { createContext } from 'react';
import { ClientConfig } from '../Config/ClientConfig';

type AppContextType =
  {
    config: ClientConfig | null;
  };

const AppContext = createContext<AppContextType>({ config: null });

const AppContextProvider: React.FC<{ children: React.ReactNode; config: ClientConfig }> = ({ children, config }) => 
{
  return (
    <AppContext.Provider value={{ config }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };