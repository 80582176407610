import React, { createContext, useState, useEffect } from 'react';
import { Folder, FileItem } from '../FileManager.types';

export interface FileSystemContextType 
{
  rootFolder: Folder;
  setRootFolder: React.Dispatch<React.SetStateAction<Folder>>;
  selectedFolder: Folder;
  setSelectedFolder: React.Dispatch<React.SetStateAction<Folder>>;
  selectedItem: Folder | FileItem | null;
  setSelectedItem: React.Dispatch<React.SetStateAction<Folder | FileItem | null>>;
  breadcrumb: Folder[];
  setBreadcrumb: React.Dispatch<React.SetStateAction<Folder[]>>;
}

export const FileSystemContext = createContext<FileSystemContextType | undefined>(undefined);

export const FileSystemProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => 
{
  const [rootFolder, setRootFolder] = useState<Folder>(() => 
  {
    const savedData = localStorage.getItem('fileManagerData');
    return savedData ? JSON.parse(savedData) : {
      id: 'root',
      name: 'Root',
      subfolders: [],
      files: [],
      parentId: ''
    };
  });
  const [selectedFolder, setSelectedFolder] = useState<Folder>(rootFolder);
  const [selectedItem, setSelectedItem] = useState<Folder | FileItem | null>(null);
  const [breadcrumb, setBreadcrumb] = useState<Folder[]>([rootFolder]);

  useEffect(() => 
  {
    localStorage.setItem('fileManagerData', JSON.stringify(rootFolder));
  }, [rootFolder]);

  return (
    <FileSystemContext.Provider value={{
      rootFolder, setRootFolder,
      selectedFolder, setSelectedFolder,
      selectedItem, setSelectedItem,
      breadcrumb, setBreadcrumb
    }}>
      {children}
    </FileSystemContext.Provider>
  );
};
