import UserImage from '../../../Images/user-64-14.jpg';
import FintechIcon01 from '../../../Images/company-icon-01.svg';
import FintechIcon02 from '../../../Images/company-icon-02.svg';
import FintechIcon03 from '../../../Images/company-icon-03.svg';
import FintechIcon04 from '../../../Images/company-icon-04.svg';
import { Button } from '../../../Components/Actions/Buttons/Buttons';
import { getIntl } from '../../../ReactIntl/IntlConfig';
import { FintechIntroLocale } from '../../../ReactIntl/LocaleInterfaces';

export function FintechIntro() 
{
  const intl = getIntl<FintechIntroLocale>('fintechIntro');

  return (
    <div className="flex flex-col col-span-full bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <div className="px-5 py-6">
        <div className="md:flex md:justify-between md:items-center">
          {/* Left side */}
          <div className="flex items-center mb-4 md:mb-0">
            {/* Avatar */}
            <div className="mr-4">
              <img className="inline-flex rounded-full" src={UserImage} width="64" height="64" alt="User" />
            </div>
            {/* User info */}
            <div>
              <div className="mb-2">
                {intl.hey} <strong className="font-medium text-slate-800 dark:text-slate-100">Mary</strong> 👋, {intl.yourCurrentBalance}:
              </div>
              <div className="text-3xl font-bold text-emerald-500">$47,347.09</div>
            </div>
          </div>
          {/* Right side */}
          <ul className="shrink-0 flex flex-wrap justify-end md:justify-start -space-x-3 -ml-px">
            <li>
              <a className="block" href="#0">
                <img className="w-9 h-9 rounded-full" src={FintechIcon01} width="36" height="36" alt="Account 01" />
              </a>
            </li>
            <li>
              <a className="block" href="#0">
                <img className="w-9 h-9 rounded-full" src={FintechIcon02} width="36" height="36" alt="Account 02" />
              </a>
            </li>
            <li>
              <a className="block" href="#0">
                <img className="w-9 h-9 rounded-full" src={FintechIcon03} width="36" height="36" alt="Account 03" />
              </a>
            </li>
            <li>
              <a className="block" href="#0">
                <img className="w-9 h-9 rounded-full" src={FintechIcon04} width="36" height="36" alt="Account 04" />
              </a>
            </li>
            <li>
              <Button
                variant="secondary"
                size="m"
                type="add"
                className="w-9 h-9 rounded-full bg-white"
                hideText
              >
                <span className="sr-only">{intl.srOnlyaddNewUserBtn}</span>
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
