export enum GenAITemplateInputKind 
  {
    Unknown = "Unknown",
    Text = "Text",
    TextArea = "TextArea",
    Number = "Number",
    Dropdown = "Dropdown",
  }

export enum GenAITemplateKind 
  {
    Unspecified = "Unspecified",
    AIWriter = "AIWriter",
    AIChat = "AIChat",
  }

export enum GenAITemplateStatus 
  {
    Enabled = "Enabled",
    Disabled = "Disabled",
    Unlisted = "Unlisted",
  }

export enum GenAIMessageRole 
  {
    System = "System",
    Human = "Human",
    Assistant = "Assistant",
    Bot = "Bot",
  }

export enum GenAIProviderName 
  {
    Unspecified = "Unspecified",
    OpenAI = "OpenAI",
    Anthropic = "Anthropic",
    HuggingFace = "HuggingFace",
    AzureOpenAI = "AzureOpenAI",
  }