import React from 'react';
import { TabsLiProps } from './Utils/TabsTypes';
import { getTabStyle } from './Utils/TabsService';

export const TabsLi: React.FC<TabsLiProps> = ({ label, icon, isActive = false, onClick, variant = "default" }) => 
{
  return (
    <li className={variant === "pill" ? "m-1" : "mr-6 last:mr-0"}>
      <button
        onClick={() => 
        {
          if (onClick) 
          {
            onClick(); // Assicurati che `onClick` sia chiamato solo se esiste
          }
        }}
        className={`${variant === "pill"
          ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm duration-150 ease-in-out " +
          (isActive
            ? "bg-indigo-500 text-white"
            : "bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600")
          : `flex items-center pb-3 whitespace-nowrap ${getTabStyle(isActive)}`
        }`}
      >
        {icon && (
          <span className="mr-2">
            {typeof icon === 'string' ? (
              <svg className="w-4 h-4 fill-current" viewBox="0 0 16 16">
                <path d={icon} />
              </svg>
            ) : (
              React.isValidElement(icon) && React.cloneElement(icon as React.ReactElement, { className: "w-4 h-4" })
            )}
          </span>
        )}
        <span>{label}</span>
      </button>
    </li>
  );
};
