import { UserDto } from "../Utils/User.types";

export const UserListActions = {
  SetState: class 
  {
    constructor(public readonly payload: Partial<UserListState>) {}
  },
  SetError: class 
  {
    constructor(public readonly payload: string | null) {}
  },
  ResetForm: class {},
  SetSubmitting: class 
  {
    constructor(public readonly payload: boolean) {}
  }
};

export type UserListAction = InstanceType<typeof UserListActions[keyof typeof UserListActions]>;

export interface UserListState 
{
  basicModalOpen: boolean;
  selectedItems: string[];
  currentPage: number;
  itemsPerPage: number;
  totalUsers: number;
  usersList: UserDto[];
  searchQuery: string;
  searchMode: 'email' | 'givenName' | 'familyName';
  sortField: string;
  sortOrder: 'asc' | 'desc';
  formData: {
    email: string;
    givenName: string;
    familyName: string;
    streetAddress: string;
    locality: string;
    region: string;
    postalCode: string;
    country: string;
    locale: string;
    timeZone: string;
  };
  dropdownFields: DropdownField[];
  newUserRoles: string[];
  isSubmitting: boolean;
  error: string | null;
  isLoading: boolean;
}

export interface DropdownField 
{
  label: string;
  value: string;
}