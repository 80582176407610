import { createContext, useReducer, ReactNode } from "react";
import { languageReducer } from "./LanguageReducer";
import { getLanguage, setLanguage as updateLanguage } from "../ReactIntl/IntlConfig";

type LanguageContextType = {
  language: string;
  setLanguage: (language: string) => void;
};

interface LanguageContextProviderProps 
{
  children: ReactNode;
}

export const LanguageContext = createContext<LanguageContextType>({
  language: getLanguage(),
  setLanguage: () => {},
});

export function LanguageContextProvider({ children }: LanguageContextProviderProps) 
{
  const [languageState, dispatchLanguageAction] = useReducer(languageReducer, {
    language: getLanguage(),
  });

  function setLanguage(language: string) 
  {
    updateLanguage(language); 
    dispatchLanguageAction({ type: "SET_LANGUAGE", language });
  }

  const languageContext: LanguageContextType = {
    language: languageState.language,
    setLanguage, 
  };

  return (
    <LanguageContext.Provider value={languageContext}>
      {children}
    </LanguageContext.Provider>
  );
}