import React, { useState, useEffect, useRef } from 'react';
import countriesData from '../../../../ReactIntl/Locales/CountryCodes.json';
// import { useUserInfo } from '../../../../Hooks/UseUserInfo';
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { AccountSettingsPanelTwoFactorLocale } from '../../../../ReactIntl/LocaleInterfaces';
import { PhoneNumber } from '../../../../Utils/PhoneNumber';
import toast from 'react-hot-toast';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { userController } from '../../../../Api/Controller/ApiUser';

const getCountryCodeByPrefix = (prefix: string): string | undefined => countriesData.find((country) => country.dial_code === prefix)?.code.toLowerCase();

const setDialCodeCookie = (dialCode: string) => 
{
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 30);
  document.cookie = `dialCode=${encodeURIComponent(dialCode)}; expires=${expirationDate.toUTCString()}; path=/; secure; samesite=strict`;
};

const getDialCodeCookie = () => 
{
  const cookieValue = document.cookie.match('(^|;)\\s*dialCode\\s*=\\s*([^;]+)');
  const dialCode = cookieValue ? cookieValue.pop() : '';
  return cookieValue ? decodeURIComponent(dialCode!) : '';
};

export const PanelTwoFactor: React.FC = () => 
{
  // const { phone_number } = useUserInfo();

  const [phoneNumber, setPhoneNumber] = useState('');
  // const [initialPhoneNumber, setInitialPhoneNumber] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prefixInput, setPrefixInput] = useState<string>('');
  const [showSuggestions, setShowSuggestions] = useState<boolean>(true);
  const [invalidPrefix, setInvalidPrefix] = useState<boolean>(false);

  const intl = getIntl<AccountSettingsPanelTwoFactorLocale>('accountSettingsPanelTwoFactor');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => 
  {
    // const dialCode = localStorage.getItem("dialCode");
    const dialCode = getDialCodeCookie();
    if (dialCode) 
    {
      setPrefixInput(dialCode);
    }

  }, []);

  const flagShipIcon = prefixInput !== '' && <div className='pointers-events-none absolute inset-y-0 left-0 pl-3 flex items-center'>
    <span className={`fi fi-${getCountryCodeByPrefix(prefixInput)}`} />
  </div>;

  // useEffect(() => {
  //   if (isEditMode) {
  //     setInitialPhoneNumber(phone_number ?? '');
  //   }
  // }, [isEditMode, phone_number]);

  const handleSavePhoneNumber = async () => 
  {
    setIsLoading(true);
    if (!prefixInput || !phoneNumber) 
    {
      toast.error(intl.toastWarning);
      setIsLoading(false);
      return;
    }

    try 
    {
      const newNumber = new PhoneNumber(prefixInput, phoneNumber);
      const updatedPhoneNumber = await userController.phoneNumber(newNumber);

      const dialCode = getDialCodeCookie();

      toast.success(`${intl.toastSuccess}: ${updatedPhoneNumber.data.toString()}`);
      setIsLoading(false);
      // refreshUserInfo();
      setIsEditMode(false);
      // setPrefixInput(localStorage.getItem('dialCode') ?? '');
      setPrefixInput(dialCode ?? '');
      setPhoneNumber('');
      setInvalidPrefix(false);
    }
    catch (error) 
    {
      console.error('Error updating phone number:', error);
      toast.error(intl.toastFail);
      setIsLoading(false);
    }
  };

  const handleCancelEdit = () => 
  {
    const dialCode = getDialCodeCookie();
    setIsEditMode(false);
    // setPrefixInput(localStorage.getItem('dialCode') ?? '');
    setPrefixInput(dialCode ?? '');
    setPhoneNumber("");
    setInvalidPrefix(false);
  };

  const filteredOptions = countriesData.filter(country =>
    country.dial_code.includes(prefixInput)
  );

  const handleSuggestionClick = (dialCode: string) => 
  {
    setPrefixInput(dialCode);
    setShowSuggestions(false);
    setInvalidPrefix(false);
    // localStorage.setItem('dialCode', dialCode);
    setDialCodeCookie(dialCode);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => 
  {
    const input = e.target.value;
    setPrefixInput(input);
    setInvalidPrefix(!countriesData.some(country => country.dial_code.includes(input)));
    setShowSuggestions(true);
  };

  const handleBlur = () => 
  {
    setTimeout(() => 
    {
      setShowSuggestions(false);
    }, 100);
  };

  const handleFocus = () => 
  {
    setShowSuggestions(true);
  };

  return (
    <>
      {/* <h2 className="text-xl text-slate-800 dark:text-slate-100 font-bold mb-5">{intl.title}</h2> */}
      <section>
        {!isEditMode ? (
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-6 mt-5">
            <div>
              <label className="block text-sm font-medium mb-1 dark:text-slate-300" htmlFor="phone-number">{intl.phoneNum}</label>
              <div className="flex items-center">

                <div className='relative mr-4'>
                  <input id='phone-number-view' className="form-input w-full border-slate-200 dark:border-slate-700 pl-10" value={"+3933412431"} readOnly />
                  {flagShipIcon}
                </div>

                {/* edit btn  */}
                <button aria-label="Edit" className="dark:text-slate-300 btn bg-indigo-500 hover:bg-indigo-600 text-white py-0 px-1" onClick={() => 
                {
                  setPrefixInput("");
                  setIsEditMode(true);
                }}>
                  <svg className="w-9 h-9 fill-current" viewBox="0 0 32 32"><path d="M19.7 8.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM12.6 22H10v-2.6l6-6 2.6 2.6-6 6zm7.4-7.4L17.4 12l1.6-1.6 2.6 2.6-1.6 1.6z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-6 mt-5">
            <div>
              <label className="block text-sm font-medium mb-1 dark:text-slate-300" htmlFor="phone-number">
                {intl.phoneNum}
              </label>
              {/* telephone number input container */}
              <div className="flex items-center relative border rounded shadow-sm dark:border-slate-700 dark:bg-slate-900/30 hover:border-slate-300 focus-within:border-slate-300 dark:hover:border-slate-600">
                {/* Prefix */}
                <input
                  id="prefix-input"
                  className={`w-20 bg-transparent p-2 border-none rounded-l-md focus:ring-0 focus:outline-none ${invalidPrefix ? 'border-red-500' : ''}`}
                  type="text"
                  value={prefixInput}
                  onChange={handleInputChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  placeholder={intl.prefixPlaceholder}
                  ref={inputRef}
                />
                {/* Phone Number */}
                <input
                  id="phone-number"
                  className="w-full bg-transparent p-2 border-none rounded-r-md focus:ring-0 focus:outline-none"
                  type="tel"
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  placeholder={intl.phoneNum}
                />
                {/* Dropdown */}
                {showSuggestions && prefixInput && !invalidPrefix && (
                  <div
                    className="absolute bg-white border border-slate-200 dark:border-slate-500 dark:bg-slate-700 rounded mt-1 w-50 z-10 max-h-40 overflow-y-auto min-w-24"
                    style={{
                      top: inputRef.current ? `${inputRef.current.clientHeight + inputRef.current.offsetTop}px` : 'initial',
                      left: inputRef.current ? `${inputRef.current.offsetLeft}px` : 'initial'
                    }}
                  >
                    {filteredOptions.map((country, index) => (
                      <div
                        key={index}
                        className="cursor-pointer p-2 hover:bg-slate-100 dark:hover:bg-slate-600 flex items-center"
                        onClick={() => handleSuggestionClick(country.dial_code)}
                      >
                        <span className={`fi fi-${country.code.toLowerCase()} w-4 h-auto mr-2`}></span>
                        <span>{country.dial_code}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* Invalid Prefix Message */}
              {invalidPrefix && (
                <div className="text-red-500 text-sm mt-1">{intl.validPrefixError}</div>
              )}
            </div>
          </div>
        )}
      </section>
      {isEditMode && (
        <div className="flex flex-col px-6 py-5 border-slate-200 dark:border-slate-700">
          <div className="flex self-end space-x-3">
            <button className="btn bg-gray-300 hover:bg-gray-400 text-gray-800" onClick={handleCancelEdit}>{intl.discardBtn}</button>
            <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white" onClick={handleSavePhoneNumber}>
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                intl.saveChangesButton
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
