import {
  $createCodeNode,
  $isCodeNode,
  getDefaultCodeLanguage,
} from '@lexical/code';
import {
  $createParagraphNode,
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
  RangeSelection,
} from 'lexical';
import { $setBlocksType } from '@lexical/selection';
import {
  $isHeadingNode,
  $createHeadingNode,
  HeadingTagType,
  $createQuoteNode
} from '@lexical/rich-text';

export const createCodeBlock = (editor: LexicalEditor) => 
{
  editor.update(() => 
  {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) 
    {
      const anchorNode = selection.anchor.getNode();
      const activeElement = anchorNode.getParent();

      if ($isCodeNode(activeElement)) 
      {
        return;
      }

      if (selection.isCollapsed()) 
      {
        const codeNode = $createCodeNode(getDefaultCodeLanguage())
          .append($createTextNode(''));
        selection.insertNodes([codeNode]);
      }
      else 
      {
        $setBlocksType(selection, () => $createCodeNode());
      }
    }
  });
};

export const getSelectedBlockType = (selection: RangeSelection): string => 
{
  const anchorNode = selection.anchor.getNode();
  const parentNode = anchorNode.getParent();

  if ($isCodeNode(parentNode)) 
  {
    return 'code';
  }

  if ($isHeadingNode(parentNode)) 
  {
    return parentNode.getTag();
  }

  return 'paragraph';
};

export const convertToNode = (
  editor: LexicalEditor,
  blockType: HeadingTagType | 'paragraph' | 'quote' | 'code'
) => 
{
  editor.update(() => 
  {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) 
    {
      switch (blockType) 
      {
        case 'paragraph':
          $setBlocksType(selection, () => $createParagraphNode());
          break;
        case 'quote':
          $setBlocksType(selection, () => $createQuoteNode());
          break;
        case 'code':
          $setBlocksType(selection, () => $createCodeNode("plaintext"));
          break;
        default:
          $setBlocksType(selection, () => $createHeadingNode(blockType as HeadingTagType));
      }
    }
  });
};