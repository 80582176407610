import axios, { AxiosError } from 'axios';
import { Filter, PageIndex } from '../../../Utils/Filter';
import { genAICategoryController } from '../Api/Controller/GenAICategory';
import { genAIWriterTemplateController } from '../Api/Controller/GenAITemplate_Writer';
import { genAIChatTemplateController } from '../Api/Controller/GenAITemplate_Chat';
import { GenAIItem } from './GenAITypes';
import { IGenAICategory, IGenAIWriterTemplate, IGenAIChatTemplate, IUserGenAITemplate, IUserGenAITemplateConfig, IGenAITemplate, GenAIModelInfo, GenAIProviderModel } from '../Api/Types/GenAIInterface';
import { genAIUserController } from '../Api/Controller/GenAIUser';
import { GenAICategoryRequest, GenAITemplateSendRequest, IGenAIChatTemplateRequest, IGenAIWriterTemplateRequest } from '../Api/Types/GenAIRequest';
import { GenAIProviderName, GenAITemplateKind, GenAITemplateStatus } from '../Api/Types/GenAIEnums';
import { GenAIMessageResponse } from '../Api/Types/GenAIResponse';

export class GenAIService 
{
  private categories: IGenAICategory[] = [];

  async fetchGenAIData(isCategories: boolean, isWriterPage: boolean, isChatPage: boolean) 
  {
    try 
    {
      let response;
      if (isCategories) 
      {
        response = await genAICategoryController.getAll(new Filter(new PageIndex(0, 100)));
        this.categories = response.data;
      }
      else if (isWriterPage) 
      {
        response = await genAIWriterTemplateController.getAll(new Filter(new PageIndex(0, 100)));
      }
      else if (isChatPage) 
      {
        response = await genAIChatTemplateController.getAll(new Filter(new PageIndex(0, 100)));
      }
      
      return response?.data || [];
    }
    catch (err: unknown) 
    {
      this.handleError(err, 'fetching data');
      return [];
    }
  }

  getCategories(): IGenAICategory[] 
  {
    return this.categories;
  }

  async fetchCategoriesIfNeeded() 
  {
    if (this.categories.length === 0) 
    {
      try 
      {
        const response = await genAICategoryController.getAll(new Filter(new PageIndex(0, 100)));
        this.categories = response.data;
        return this.categories;
      }
      catch (err) 
      {
        this.handleError(err, 'fetching categories');
        return [];
      }
    }
    return this.categories;
  }

  async deleteGenAIItem(id: string, isCategories: boolean, isWriterPage: boolean, isChatPage: boolean) 
  {
    try 
    {
      if (isCategories) 
      {
        await genAICategoryController.deleteCategory(id);
      }
      else if (isWriterPage) 
      {
        await genAIWriterTemplateController.deleteTemplate(id);
      }
      else if (isChatPage) 
      {
        await genAIChatTemplateController.deleteTemplate(id);
      }
      else 
      {
        throw new Error('Invalid item type');
      }
      return id;
    }
    catch (err: unknown) 
    {
      this.handleError(err, 'deleting item');
      throw err;
    }
  }

  async createGenAIItem(item: GenAIItem, isCategories: boolean, isWriterPage: boolean, isChatPage: boolean) 
  {
    try 
    {
      let response;
      
      if (isCategories && this.isCategory(item)) 
      {
        const request = this.createCategoryRequest(item);
        response = await genAICategoryController.createCategory(request);
      }
      else if (isWriterPage && this.isWriterTemplate(item)) 
      {
        const request = this.createTemplateRequest<IGenAIWriterTemplateRequest>(item, GenAITemplateKind.AIWriter);
        response = await genAIWriterTemplateController.createTemplate(request);
      }
      else if (isChatPage && this.isChatTemplate(item)) 
      {
        const request = this.createTemplateRequest<IGenAIChatTemplateRequest>(item, GenAITemplateKind.AIChat);
        response = await genAIChatTemplateController.createTemplate(request);
      }
      else 
      {
        throw new Error(`Invalid item type. Item: ${JSON.stringify(item)}, isCategories: ${isCategories}, isWriterPage: ${isWriterPage}, isChatPage: ${isChatPage}`);
      }
      
      return response?.data;
    }
    catch (err: unknown) 
    {
      this.handleError(err, 'creating item');
      throw err;
    }
  }

  async updateGenAIItem(item: GenAIItem, isCategories: boolean, isWriterPage: boolean, isChatPage: boolean) 
  {
    try 
    {
      if (!('id' in item)) 
      {
        throw new Error('Cannot update item without id');
      }
  
      let response;
      if (isCategories && this.isCategory(item)) 
      {
        const request = this.createCategoryRequest(item);
        response = await genAICategoryController.editCategory(item.id, request);
      }
      else if (isWriterPage && this.isWriterTemplate(item)) 
      {
        const request = this.createTemplateRequest<IGenAIWriterTemplateRequest>(item, GenAITemplateKind.AIWriter);
        response = await genAIWriterTemplateController.editTemplate(item.id, request);
      }
      else if (isChatPage && this.isChatTemplate(item)) 
      {
        const request = this.createTemplateRequest<IGenAIChatTemplateRequest>(item, GenAITemplateKind.AIChat);
        response = await genAIChatTemplateController.editTemplate(item.id, request);
      }
      else 
      {
        throw new Error('Invalid item type or mismatch between item type and page');
      }
      
      const updatedItem = {
        ...response.data,
        id: item.id
      };
      
      return updatedItem;
    }
    catch (err: unknown) 
    {
      this.handleError(err, 'updating item');
      throw err;
    }
  }

  async fetchUserFavorites(): Promise<IUserGenAITemplate[]> 
  {
    try 
    {
      const response = await genAIUserController.getTemplatesConfig();
      return response?.data || [];
    }
    catch (err) 
    {
      this.handleError(err, 'fetching user favorites');
      return [];
    }
  }

  async updateUserFavorites(favorites: IUserGenAITemplate[]): Promise<void> 
  {
    try 
    {
      const templatesConfig: IUserGenAITemplateConfig = { templates: favorites };
      await genAIUserController.updateTemplateFavorite(templatesConfig);
    }
    catch (err) 
    {
      this.handleError(err, 'updating user favorites');
      throw err;
    }
  }

  async getAssociatedNames(id: string, isCategory: boolean, items: GenAIItem[], categories: IGenAICategory[]): Promise<string[]> 
  {
    if (isCategory) 
    {
      return items
        .filter((item): item is IGenAITemplate => 
          'categoriesId' in item && item.categoriesId.includes(id)
        )
        .map(item => item.name);
    } 
    else 
    {
      return categories
        .filter(category => 
          items.some((item): item is IGenAITemplate => 
            'categoriesId' in item && item.categoriesId.includes(category.id) && 'id' in item && item.id === id
          )
        )
        .map(category => category.name);
    }
  }

  async canDeleteCategory(id: string, items: GenAIItem[]): Promise<{ canDelete: boolean; associatedNames: string[] }> 
  {
    const associatedTemplates = items.filter((item): item is IGenAITemplate => 
      'categoriesId' in item && item.categoriesId.includes(id)
    );

    const associatedNames = associatedTemplates.map(template => template.name);

    return {
      canDelete: associatedNames.length === 0,
      associatedNames
    };
  }

  private createCategoryRequest(item: Omit<IGenAICategory, 'id'> | IGenAICategory): GenAICategoryRequest 
  {
    return {
      name: item.name,
      description: item.description,
      metadata: item.metadata
    };
  }

  private createTemplateRequest<T extends IGenAIChatTemplateRequest | IGenAIWriterTemplateRequest>(
    item: GenAIItem, 
    kind: GenAITemplateKind
  ): T 
  {
    return {
      kind,
      status: 'status' in item ? item.status : GenAITemplateStatus.Enabled,
      name: item.name,
      description: item.description,
      metadata: item.metadata,
      categoriesId: 'categoriesId' in item ? item.categoriesId : [],
      data: 'data' in item ? item.data : { prompt: '' }
    } as T;
  }

  async getProviderModels(): Promise<GenAIProviderModel[]> 
  {
    try 
    {
      const response = await genAIUserController.getModels(new Filter(new PageIndex(0, 20)));
      if (!response?.data) 
      {
        return [{ provider: GenAIProviderName.Unspecified, models: [] }];
      }
      return response.data;
    }
    catch (err) 
    {
      this.handleError(err, 'getting provider models');
      return [{ provider: GenAIProviderName.Unspecified, models: [] }];
    }
  }
  
  async getProviderSettings(): Promise<GenAIModelInfo> 
  {
    try 
    {
      const response = await genAIUserController.getProviderSettings(new Filter(new PageIndex(0, 20)));
      if (!response?.data) 
      {
        return { provider: GenAIProviderName.Unspecified, modelId: '' };
      }
      return response.data;
    }
    catch (err) 
    {
      this.handleError(err, 'getting provider settings');
      throw err;
    }
  }
  
  async updateProviderSettings(provider: string, model: string): Promise<GenAIModelInfo> 
  {
    try 
    {
      const response = await genAIUserController.updateProviderSettings(provider, model);
      if (!response?.data) 
      {
        throw new Error('No data received from provider settings update');
      }
      
      const modelInfo: GenAIModelInfo = {
        provider: response.data.provider as GenAIProviderName,
        modelId: model
      };
      
      return modelInfo;
    }
    catch (err) 
    {
      this.handleError(err, 'updating provider settings');
      throw err;
    }
  }
  
  async sendWriter(
    request: GenAITemplateSendRequest,
    onMessage?: (message: string) => void,
    onComplete?: (data: GenAIMessageResponse) => void
  ): Promise<GenAIMessageResponse> 
  {
    try 
    {
      const response = await genAIUserController.sendWriter<GenAIMessageResponse>(
        request,
        (message) => onMessage?.(message),
        (data) => 
        {
          if (data) 
          {
            onComplete?.(data);
          }
        },
        (error) => { throw error; }
      );
  
      if (!response?.data) 
      {
        throw new Error('No data received from writer');
      }
  
      return response.data;
    }
    catch (err) 
    {
      this.handleError(err, 'sending writer template');
      throw err;
    }
  }

  async getWriterTemplate(templateId: string) 
  {
    try 
    {
      const response = await genAIWriterTemplateController.getTemplate(templateId);
      return response;
    }
    catch (err) 
    {
      this.handleError(err, 'getting writer template');
      throw err;
    }
  }
  
  async getChatTemplate(id: string): Promise<{ data: IGenAIChatTemplate }> 
  {
    try 
    {
      const response = await genAIChatTemplateController.getTemplate(id);
      return response;
    }
    catch (err) 
    {
      this.handleError(err, 'getting chat template');
      throw err;
    }
  }
  
  private isCategory(item: GenAIItem): item is Omit<IGenAICategory, 'id'> | IGenAICategory 
  {
    return 'name' in item && 
           'description' in item && 
           !('data' in item);
  }

  private isWriterTemplate(item: GenAIItem): item is Omit<IGenAIWriterTemplate, 'id'> | IGenAIWriterTemplate 
  {
    return 'data' in item && 
           'inputs' in item.data;
  }

  private isChatTemplate(item: GenAIItem): item is Omit<IGenAIChatTemplate, 'id'> | IGenAIChatTemplate 
  {
    return 'data' in item && 
           'prompt' in item.data && 
           !('inputs' in item.data);
  }

  private handleError(err: unknown, context: string) 
  {
    if (axios.isAxiosError(err)) 
    {
      const axiosError = err as AxiosError;
      console.error(`Error ${context}:`, axiosError.message);
    } 
    else 
    {
      console.error(`Unknown error ${context}:`, err);
    }
  }
}