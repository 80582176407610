import React from 'react';
import { MessageRole, Offer } from '../WindTre.types';

interface MessageButtonsProps 
{
  role: MessageRole;
  isLastMessage: boolean;
  isFirstMessage: boolean;
  currentOffer: Offer | null;
  storeId: string;
  onCercavoAltro?: () => void;
  loading: boolean;
}

export const MessageButtons: React.FC<MessageButtonsProps> = ({
  role,
  isLastMessage,
  isFirstMessage,
  currentOffer,
  storeId,
  onCercavoAltro,
  loading
}) => 
{
  const isProgrammatic = role === MessageRole.Assistant;
  return (
    <div className="flex flex-col items-center">
      {isProgrammatic && currentOffer && isLastMessage && (
        <div className="mt-5 mb-1">
          <button className="hover:scale-105">
            <a
              href={`https://${storeId}.windtre-store.it/${currentOffer.slug}`}
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 bg-gradient-to-r from-green-400 to-green-600 text-white 
              rounded-full shadow-lg transition-colors duration-300 
              hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 
              focus:ring-green-300 focus:ring-opacity-50 text-lg hover:scale-105"
            >
              Vai all'offerta
            </a>
          </button>
        </div>
      )}

      {isProgrammatic && onCercavoAltro && !isFirstMessage && isLastMessage && (
        <div className="mt-3">
          <button
            onClick={onCercavoAltro}
            className="px-6 py-3 bg-white text-orange-500 border border-orange-500 
              rounded-full shadow-sm transition-all duration-300 ease-in-out 
              hover:bg-orange-50 hover:scale-105 focus:outline-none focus:ring-2 
              focus:ring-orange-300 focus:ring-opacity-50 text-lg"
            disabled={loading}
          >
            Cercavo altro
          </button>
        </div>
      )}
    </div>
  );
};