import { Filter } from "../../../../Utils/Filter";
import { IGenAIChatTemplateRequest } from "../Types/GenAIRequest";
import { genAIApiDefinitions } from "../ApiConfig";

const definition = genAIApiDefinitions.aiChatTemplates;

export const genAIChatTemplateController = {
  getAll: async (filter: Filter) => 
  {
    const endpoint = definition.getAll;
    const response = await endpoint.invoke(endpoint.url, { filter });
    return response;
  },

  getTemplate: async (id: string) => 
  {
    const endpoint = definition.getTemplate;
    const response = await endpoint.invoke(
      endpoint.url.replace("{templateId}", id)
    );
    return response;
  },

  count: async () => 
  {
    const endpoint = definition.count;
    const response = await endpoint.invoke(endpoint.url);
    return response;
  },

  createTemplate: async (template: IGenAIChatTemplateRequest) => 
  {
    const endpoint = definition.createTemplate;
    const response = await endpoint.invoke(endpoint.url, template);
    return response;
  },

  editTemplate: async (id: string, template: IGenAIChatTemplateRequest)=> 
  {
    const endpoint = definition.updateTemplate;
    const response = await endpoint.invoke(
      endpoint.url.replace("{templateId}", id),
      template
    );
    return response;
  },

  deleteTemplate: async (id: string) => 
  {
    const endpoint = definition.deleteTemplate;
    const response = await endpoint.invoke(
      endpoint.url.replace("{templateId}", id)
    );
    return response;
  },
};