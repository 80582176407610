import { GenAITemplateStatus } from "../Api/Types/GenAIEnums";
import { GenAIChatMessage, GenAIProviderModel, IGenAITemplate, GenAITokenUsage } from "../Api/Types/GenAIInterface";
import { ChatbotAction } from "./ChatbotTypes";
import React from 'react';

export class ChatbotActions 
{
  private static instance: ChatbotActions; // Istanza singleton
  private dispatch: React.Dispatch<ChatbotAction>;
  
  // Costruttore privato per evitare la creazione diretta di nuove istanze
  private constructor(dispatch: React.Dispatch<ChatbotAction>) 
  {
    this.dispatch = dispatch;
  }
  
  // Metodo statico per ottenere l'unica istanza di ChatbotActions
  public static getInstance(dispatch: React.Dispatch<ChatbotAction>): ChatbotActions 
  {
    if (!ChatbotActions.instance) 
    {
      ChatbotActions.instance = new ChatbotActions(dispatch);
    }
    return ChatbotActions.instance;
  }
  
  // Azioni definite come nel tuo codice
  fetchStart(): void 
  {
    this.dispatch({ type: 'FETCH_START' });
  }
  
  fetchSuccess(): void 
  {
    this.dispatch({ type: 'FETCH_SUCCESS' });
  }
  
  fetchError(error: string): void 
  {
    this.dispatch({ type: 'FETCH_ERROR', payload: error });
  }
  
  setLoading(loading: boolean): void 
  {
    this.dispatch({ type: 'SET_LOADING', payload: loading });
  }
  
  setError(error: string | null): void 
  {
    this.dispatch({ type: 'SET_ERROR', payload: error });
  }
  
  addMessage(message: GenAIChatMessage): void 
  {
    this.dispatch({ type: 'ADD_MESSAGE', payload: message });
  }
  
  resetChat(initialMessage: GenAIChatMessage): void 
  {
    this.dispatch({ type: 'RESET_CHAT', payload: initialMessage });
  }
  
  setChatId(chatId: string): void 
  {
    this.dispatch({ type: 'SET_CHAT_ID', payload: chatId });
  }
  
  setBlockMessage(block: boolean): void 
  {
    this.dispatch({ type: 'SET_BLOCK_MESSAGE', payload: block });
  }
  
  setProviderModel(providerModel: GenAIProviderModel): void 
  {
    this.dispatch({ type: 'SET_PROVIDER_MODEL', payload: providerModel });
  }
  
  setCurrentTemplate(template: IGenAITemplate): void 
  {
    this.dispatch({ type: 'SET_CURRENT_TEMPLATE', payload: template });
  }
  
  setStatus(status: GenAITemplateStatus): void 
  {
    this.dispatch({ type: 'SET_STATUS', payload: status });
  }
  
  setTokenUsage(tokenUsage: GenAITokenUsage): void 
  {
    this.dispatch({ type: 'SET_TOKEN_USAGE', payload: tokenUsage });
  }
   
  setMessages(messages: GenAIChatMessage[]): void 
  {
    this.dispatch({ type: 'SET_MESSAGES', payload: messages });
  }
  
  clearError(): void 
  {
    this.dispatch({ type: 'CLEAR_ERROR' });
  }
}
