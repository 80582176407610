import {FC, MouseEvent, DragEvent} from "react";
import { Folder, FileItem } from "./FileManager.types";
import { Icon } from "../../Components/SvgManager/Icon";
import {motion} from "framer-motion";

interface FolderGridProps
{
  item: Folder | FileItem;
  type: 'folder' | 'file';
  handleLeftClick: (item: Folder | FileItem) => void;
  handleRightClick: (e: MouseEvent, item: Folder | FileItem, type: 'folder' | 'file') => void;
  handleDragStart: (e: DragEvent, item: Folder | FileItem) => void;
  handleDragOver: (e: DragEvent) => void;
  handleDrop: (e: DragEvent, folder: Folder) => void;
  handleClearSelection: () => void;
  navigateToFolder: (folder: Folder) => void;
  selectedItem: Folder | FileItem | null;
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
};

export const FolderGridItem: FC<FolderGridProps> = ({ item, type, ...props}) => 
{

  return (
    <motion.div
      variants={itemVariants}
    >
      <div
        key={item.id}
        className={`p-4 rounded flex flex-col items-center justify-center cursor-pointer ${props.selectedItem?.id === item.id
          ? "bg-indigo-200 dark:bg-indigo-600 border-blue-200 shadow-lg"
          : "bg-transparent dark:bg-transparent hover:bg-gray-200 dark:hover:bg-slate-600"
        }`}
        onClick={() => props.handleLeftClick(item)}
        onDoubleClick={() => 
        {
          if (type === 'folder') 
          {
            props.navigateToFolder(item as Folder);
            props.handleClearSelection();
          }
        }}
        onContextMenu={e => props.handleRightClick(e, item, type)}
        draggable
        onDragStart={(e) => props.handleDragStart(e, item)}
        onDragOver={type === 'folder' ? props.handleDragOver : undefined}
        onDrop={type === 'folder' ? (e) => props.handleDrop(e, item as Folder) : undefined}
      >
        {type === 'folder' ? <Icon name='folder' className="w-10 h-10" /> : <Icon name='document' className="w-10 h-10" />}
        <span className="mt-2 text-center break-words w-full">{item.name}</span>
      </div>
    </motion.div>
  );
      
};