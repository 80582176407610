import { FC, createContext, useState, useEffect, ReactNode, SetStateAction, Dispatch } from 'react';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { useBanner } from '../../Hooks/Alerts';

export interface ErrorContextType 
{
  isBlocked: boolean;
  retrySeconds: number | null;
  setIsBlocked: (blocked: boolean) => void;
  setRetrySeconds: (seconds: number | null) => void;
  bannerOpen: boolean;
  setBannerOpen: Dispatch<SetStateAction<boolean>>
  bannerType: string;
  bannerMessage: string;
  bannerDuration: number;
  showBanner: (message: string, type: 'success' | 'error' | 'warning' | 'info', duration?: number) => void;
}

export const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const ErrorProvider: FC<{ children: ReactNode }> = ({ children }) => 
{
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [retrySeconds, setRetrySeconds] = useState<number | null>(null);
  const { bannerOpen, setBannerOpen, bannerType, bannerMessage, bannerDuration, showBanner } = useBanner();
  
  useEffect(() => 
  {
    if(retrySeconds && isBlocked)
    {
      toast.dismiss();
      toast.loading(`Too many requests, wait ${retrySeconds} seconds and try again`, { id: 'tooManyRequests' });
    }
    else if(!isBlocked)
    {
      toast.dismiss('tooManyRequests');
    }
  }, [isBlocked, retrySeconds]);

  useEffect(() => 
  {
    const handleApiRetryError = (event: CustomEvent) => 
    {
      const axiosError = event.detail as AxiosError;
      if (axiosError.response?.status === 429) 
      {
        const retryAfter = parseInt(axiosError.response.headers['retry-after'] || '60', 10);
        setRetrySeconds(retryAfter);
        setIsBlocked(true);
      }
    };
        
    window.addEventListener('apiRetryError', handleApiRetryError as EventListener);
        
    return () => 
    {
      window.removeEventListener('apiRetryError', handleApiRetryError as EventListener);
    };
  }, [setRetrySeconds, setIsBlocked]);

  useEffect(() => 
  {
    if (retrySeconds && isBlocked) 
    {
      const timer = setInterval(() => 
      {
        if (retrySeconds === 1) 
        {
          setIsBlocked(false);
          setRetrySeconds(null);
        }
        else if (retrySeconds > 1) 
        {
          setRetrySeconds(retrySeconds - 1);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isBlocked, retrySeconds]);

  return (
    <ErrorContext.Provider value={{ isBlocked, retrySeconds, setIsBlocked, setRetrySeconds,
      bannerOpen, setBannerOpen, bannerType, bannerMessage, bannerDuration, showBanner
    }}>
      {children}
    </ErrorContext.Provider>
  );
};
