import React, { useState, useEffect } from 'react';
import { Save } from 'lucide-react';
import { UpdateInstructions } from '../WindTre.types';
import { windtreChatController } from '../../../../Api/Controller/GenAI/Chatbot/ApiWindTreChat';

interface WindBotSettingsProps
{
  onClose: () => void;
  storeId: string;
}
export const WindBotSettings: React.FC<WindBotSettingsProps> = ({ onClose, storeId }) =>
{
  const [instructions, setInstructions] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() =>
  {
    fetchInstructions();
  });

  const fetchInstructions = async () =>
  {
    setIsLoading(true);
    try
    {
      const response = await windtreChatController.getInstructions(storeId);
      setInstructions(response.data);
    }
    catch (err)
    {
      console.error('Error fetching instructions:', err);
    }
    finally
    {
      setIsLoading(false);
    }
  };

  const handleSave = async () =>
  {
    setIsLoading(true);
    try
    {
      const newInstruction: UpdateInstructions = {
        instructions: instructions
      };
      await windtreChatController.updateInstructions(storeId, newInstruction);
      onClose();
    }
    catch (err)
    {
      console.error('Error saving instructions:', err);
    }
    finally
    {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex-grow overflow-y-auto p-6 bg-gradient-to-b from-orange-50">
      <h2 className="text-xl font-bold mb-4 text-orange-600 text-center">Istruzioni Chatbot</h2>
      <label htmlFor="instructions" className="block text-sm font-medium text-gray-700 mb-2">
        Imposta il comportamento del tuo assistente chatbot
      </label>
      <textarea
        id="instructions"
        rows={10}
        className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 mb-4 bg-gradient-to-b from-orange-20 resize-none overflow-hidden"
        value={instructions}
        onChange={(e) => setInstructions(e.target.value)}
        disabled={isLoading}
      />
      <div className="flex justify-end">
        <button
          onClick={handleSave}
          disabled={isLoading}
          className="px-4 py-2 bg-gradient-to-r from-orange-400 to-orange-600 text-white rounded-md shadow-md hover:from-orange-500 hover:to-orange-700 transition-all duration-300 flex items-center"
        >
          {isLoading ? 'Salvataggio...' : (
            <>
              <Save size={20} className="mr-2" />
              Salva
            </>
          )}
        </button>
      </div>
    </div>
  );
};