import React from 'react';
import { TRANSFORMERS } from '@lexical/markdown';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { LinkNode } from '@lexical/link';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { ToolbarPlugin } from './Plugins/ToolbarPlugin';
import { SetIncomingMarkdownPlugin } from './Plugins/SetIncomingMarkdownPlugin';
import { MarkdownActions } from './Plugins/MarkdownActions';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { CodeHighlightPlugin } from './Plugins/CodeHighlightPlugin';
import { EditorTheme } from './Themes/EditorTheme';

const placeholder = 'Enter Text... (Can use Markdown Syntax)';

const editorConfig = {
  namespace: 'AI Rich Text Editor',
  theme: EditorTheme,
  nodes: [
    HorizontalRuleNode,
    HeadingNode,
    QuoteNode,
    ListNode,
    ListItemNode,
    CodeNode,
    CodeHighlightNode,
    LinkNode,
  ],
  onError(error: Error) 
  {
    throw error;
  },
};

interface RichTextEditorProps 
{
  markdown?: string;
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({ markdown }) => 
{
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="max-w-2xl mx-auto bg-white dark:bg-slate-800 rounded-lg shadow-md overflow-hidden">
        <ToolbarPlugin />
        <div className="p-4 relative">
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className="min-h-[150px] outline-none text-base caret-gray-700 dark:caret-gray-200"
                aria-placeholder={placeholder}
                placeholder={
                  <div className="absolute top-0 left-0 text-gray-400 pointer-events-none p-4">
                    {placeholder}
                  </div>
                }
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <CodeHighlightPlugin />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <ListPlugin />
          <MarkdownActions />
          {markdown && <SetIncomingMarkdownPlugin markdown={markdown} />}
        </div>
      </div>
    </LexicalComposer>
  );
};