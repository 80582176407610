// @ts-nocheck
import { Chart, Tooltip } from 'chart.js';

Chart.register(Tooltip);
import { tailwindConfig, hexToRGB } from '../Utils/Utils';
// Define Chart.js default settings
const chartDefaults = Chart.defaults;
chartDefaults.font.family = '"Inter", sans-serif';
chartDefaults.font.weight = 500;

const tooltipDefaults = chartDefaults.plugins.tooltip;
tooltipDefaults.borderWidth = 1;
tooltipDefaults.displayColors = false;
tooltipDefaults.mode = 'nearest';
tooltipDefaults.intersect = false;
tooltipDefaults.position = 'nearest';
tooltipDefaults.caretSize = 0;
tooltipDefaults.caretPadding = 20;
tooltipDefaults.cornerRadius = 4;
tooltipDefaults.padding = 8;

// Register Chart.js plugin to add a bg option for chart area
Chart.register({
  id: 'chartAreaPlugin',
   
  beforeDraw: (chart) => 
  {
    if (chart.config.options?.chartArea && chart.config.options.chartArea.backgroundColor) 
    {
      const ctx = chart.canvas.getContext('2d');
      const { chartArea } = chart;
      if (ctx != null)
      {
        ctx.save();
        ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
         
        ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
        ctx.restore();
      }
    }
  },
});

export const chartColors = {
  textColor: {
    light: tailwindConfig().theme.colors.slate[400],
    dark: tailwindConfig().theme.colors.slate[500],
  },
  gridColor: {
    light: tailwindConfig().theme.colors.slate[100],
    dark: tailwindConfig().theme.colors.slate[700],
  },
  backdropColor: {
    light: tailwindConfig().theme.colors.white,
    dark: tailwindConfig().theme.colors.slate[800],
  },
  tooltipTitleColor: {
    light: tailwindConfig().theme.colors.slate[800],
    dark: tailwindConfig().theme.colors.slate[100],
  },
  tooltipBodyColor: {
    light: tailwindConfig().theme.colors.slate[800],
    dark: tailwindConfig().theme.colors.slate[100],
  },
  tooltipBgColor: {
    light: tailwindConfig().theme.colors.white,
    dark: tailwindConfig().theme.colors.slate[700],
  },
  tooltipBorderColor: {
    light: tailwindConfig().theme.colors.slate[200],
    dark: tailwindConfig().theme.colors.slate[600],
  },
  chartAreaBg: {
    light: tailwindConfig().theme.colors.slate[50],
    dark: `rgba(${hexToRGB(tailwindConfig().theme.colors.slate[900])}, 0.24)`,
  },
};
