import { Folder, FileItem } from "../FileManager.types";
import { generateId } from "../Utils";

export const copyItem = (
  rootFolder: Folder,
  itemId: string,
  targetFolderId: string,
  type: "folder" | "file"
): Folder => 
{
  let itemToCopy: Folder | FileItem | null = null;

  const findItem = (folder: Folder): void => 
  {
    if (folder.id === itemId) 
    {
      itemToCopy = folder;
      return;
    }
    for (const subfolder of folder.subfolders) 
    {
      findItem(subfolder);
      if (itemToCopy) return;
    }
    if (type === "file") 
    {
      const file = folder.files.find((f) => f.id === itemId);
      if (file) 
      {
        itemToCopy = file;
        return;
      }
    }
  };

  findItem(rootFolder);

  if (!itemToCopy) 
  {
    console.error("Item to copy not found");
    return rootFolder;
  }

  const copyFolder = (folder: Folder, newParentId: string): Folder => ({
    ...folder,
    id: generateId(),
    parentId: newParentId,
    subfolders: folder.subfolders.map((sf) => copyFolder(sf, folder.id)),
    files: folder.files.map((f) => ({
      ...f,
      id: generateId(),
      parentId: folder.id,
    })),
  });

  const copyFile = (file: FileItem, newParentId: string): FileItem => ({
    ...file,
    id: generateId(),
    parentId: newParentId,
  });

  const addCopiedItem = (folder: Folder): Folder => 
  {
    if (!itemToCopy) 
    {
      console.error("Item to copy not found");
      return folder;
    }
    // addProgressItem({
    //   type: 'copy',
    //   itemName: folder.name,
    //   fromLocation: findFolderPath(rootFolder, targetFolderId),
    //   toLocation: findFolderPath(rootFolder, targetFolderId),
    //   timestamp: new Date()
    // });
    if (folder.id === targetFolderId) 
    {
      return {
        ...folder,
        subfolders:
          type === "folder"
            ? [
              ...folder.subfolders,
              copyFolder(itemToCopy as Folder, folder.id),
            ]
            : folder.subfolders,
        files:
          type === "file"
            ? [...folder.files, copyFile(itemToCopy as FileItem, folder.id)]
            : folder.files,
      };
    }
    return {
      ...folder,
      subfolders: folder.subfolders.map(addCopiedItem),
    };
  };

  return addCopiedItem(rootFolder);
};
