import { roleListController } from "../Controller/Entities/ApiRoleList";
import { Filter } from "../../Utils/Filter";

export enum Role 
  {
    Admin = 'Admin',
    User = 'User',
  }
export interface RoleResponse 
{
  name: string;
}

export interface Roles 
{
  roles: string[];
}

export class RolesManager 
{
  private roles: string[] = [];
  private static CACHE_KEY = 'cachedRoles';
  private static CACHE_EXPIRATION_KEY = 'cachedRolesExpiration';
  private static CACHE_DURATION = 24 * 60 * 60 * 1000;

  async fetchRoles() 
  {
    const cachedRoles = this.getCachedRoles();
    if (cachedRoles) 
    {
      this.roles = cachedRoles;
      return;
    }

    try 
    {
      const response = await roleListController.getRoles(new Filter());
      console.log(response);
      this.roles = response.data.map(role => this.formatRoleName(role.name));
      this.cacheRoles(this.roles);
    }
    catch (error) 
    {
      console.error('Error fetching roles:', error);
      throw error;
    }
  }

  private formatRoleName(name: string): string 
  {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  getRoles(): string[] 
  {
    return this.roles;
  }

  static getDropdownFields(roles: string[]): { label: string }[] 
  {
    return roles.map(role => ({ label: role }));
  }

  private getCachedRoles(): string[] | null 
  {
    const cachedRoles = localStorage.getItem(RolesManager.CACHE_KEY);
    const expirationTime = localStorage.getItem(RolesManager.CACHE_EXPIRATION_KEY);

    if (cachedRoles && expirationTime) 
    {
      const now = new Date().getTime();
      if (now < parseInt(expirationTime)) 
      {
        return JSON.parse(cachedRoles);
      }
      else 
      {
        this.clearCache();
      }
    }

    return null;
  }

  private cacheRoles(roles: string[]) 
  {
    const expirationTime = new Date().getTime() + RolesManager.CACHE_DURATION;
    localStorage.setItem(RolesManager.CACHE_KEY, JSON.stringify(roles));
    localStorage.setItem(RolesManager.CACHE_EXPIRATION_KEY, expirationTime.toString());
  }

  private clearCache() 
  {
    localStorage.removeItem(RolesManager.CACHE_KEY);
    localStorage.removeItem(RolesManager.CACHE_EXPIRATION_KEY);
  }

  async refreshRoles() 
  {
    this.clearCache();
    await this.fetchRoles();
  }
}

export const rolesManager = new RolesManager();