import React from 'react';

import { ForumLeftContent } from './Partials/ForumLeftContent';
import { ForumPostRightContent } from './Partials/ForumPostRightContent';
import { ForumEntry } from './Partials/ForumEntry';

export const ForumPost: React.FC = () => 
{

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 md:py-0 w-full max-w-9xl mx-auto">

            <div className="xl:flex">

              {/* Left + Middle content */}
              <div className="md:flex flex-1">

                {/* Left content */}
                <ForumLeftContent />

                {/* Middle content */}
                <div className="flex-1 md:ml-8 xl:mx-4 2xl:mx-8">
                  <div className="md:py-8">

                    {/* Forum entry */}
                    <ForumEntry />

                  </div>
                </div>

              </div>

              {/* Right content */}
              <ForumPostRightContent />

            </div>

          </div>
        </main>

      </div>
    </div>
  );
};
