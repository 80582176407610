import React, { useState } from "react";
import { Button } from "../../Components/Actions/Buttons/Buttons";
import { Folder } from "./FileManager.types";
import { Icon } from "../../Components/SvgManager/Icon";
import { motion } from "framer-motion";

interface FolderTreeModalProps 
{
  isOpen: boolean;
  onClose: () => void;
  rootFolder: Folder;
  onSelectFolder: (folderId: string) => void;
  action: "move" | "copy"
}

const FolderTreeItem: React.FC<{
  folder: Folder;
  level: number;
  onSelect: (folder: string) => void;
  selectedFolderId: string | null
}> = ({ folder, level, onSelect, selectedFolderId }) => 
{
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div
        className={`flex items-center p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-700 ${selectedFolderId === folder.id ? 'bg-blue-100 dark:bg-blue-700' : ''
        }`}
        style={{ paddingLeft: `${level * 20}px` }}
        onClick={() => onSelect(folder.id)}
      >
        {folder.subfolders.length > 0 && (
          <span onClick={(e) => 
          {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}>
            <Icon
              name={isOpen ? 'chevronDown' : 'chevronRight'}
              className="w-4 h-4 mr-2 cursor-pointer"
            />
          </span>
        )}
        <Icon name="folder" className="w-5 h-5 mr-2" />
        <span>{folder.name}</span>
      </div>
      {isOpen &&
          folder.subfolders.map((subfolder) => (
            <FolderTreeItem
              key={subfolder.id}
              folder={subfolder}
              level={level + 1}
              onSelect={onSelect}
              selectedFolderId={selectedFolderId}
            />
          ))}
    </div>
  );
};

export const FolderTreeModal: React.FC<FolderTreeModalProps> = ({
  isOpen,
  onClose,
  rootFolder,
  onSelectFolder,
  action
}) => 
{

  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

  if (!isOpen) return null;

  const handleConfirm = () => 
  {
    if (!selectedFolderId) return;
    onSelectFolder(selectedFolderId);
    onClose();
  };

  return (
    <motion.div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      initial={{ opacity: 0, scale: 1.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.3,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01]
      }}>
      <div className="bg-white dark:bg-slate-800 rounded-lg p-6 w-1/3 max-h-[80vh] flex flex-col">
        <h2 className="text-2xl font-bold mb-4">
          {action === 'move' ? 'Move to' : 'Copy to'}
        </h2>
        <div className="flex-grow overflow-auto mb-4">
          <FolderTreeItem
            folder={rootFolder}
            level={0}
            onSelect={setSelectedFolderId}
            selectedFolderId={selectedFolderId}
          />
        </div>
        <div className="flex justify-end space-x-2">
          <div className="m-1.5">
            <Button
              variant="tertiary"
              size="l"
              onClick={onClose}
            >
              Cancel
            </Button>

          </div>
          <div className="m-1.5">
            <Button
              variant="primary"
              size="l"
              onClick={handleConfirm}
              disabled={!selectedFolderId}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};