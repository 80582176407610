import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GenAICardView } from './GenAICardView_';
import { GenAITableView } from './GenAITableView';
import { Button } from '../../../Components/Actions/Buttons/Buttons';
import { IGenAITemplate, IGenAICategory, IGenAIWriterTemplate, IGenAIChatTemplate } from '../Api/Types/GenAIInterface';
// import { useUserInfo } from '../../../Hooks/UseUserInfo';
import { isFavoriteTemplate, parseTemplateMetadata } from '../Api/Types/UtilityFunction';
import { GenAIItem, ViewProps } from '../Utils/GenAITypes';
import { useGenAI } from '../Hooks/UseGenAI_';
import { GenAIWizardPage } from '../Wizard/GenAIWizardPage';
import { GenAIActions } from '../Utils/GenAIActions';
import { GenAIService } from '../Utils/GenAIService';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { wizardValidationSchema } from '../Wizard/Utils/Validation/Schemas/WizardValidationSchema';
import { modal } from '../../../Components/Actions/ProgModal/ProgModal';
import { TabsUl } from '../../../Components/Actions/Tabs/TabsUl';
import { TabsLi } from '../../../Components/Actions/Tabs/TabsLi';
import axios from 'axios';
import toast from 'react-hot-toast';
import { GenAITemplateInputKind } from '../Api/Types/GenAIEnums';
import { useUser } from '../../Auth/Hooks/UseSession';

function hasId(item: GenAIItem): item is IGenAICategory | IGenAITemplate 
{
  return 'id' in item;
}

export const GenAIMainPage: React.FC = () => 
{
  const { state, dispatch } = useGenAI();
  // const { isAdmin } = useUserInfo();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUser();
  const isAdmin = user ? true : false; //N.B: temporaneo e logicamente non proprio corretto, tampone visto rimozione di OICD

  const [showWizard, setShowWizard] = useState(false);
  const [wizardStep, setWizardStep] = useState(0);
  const [wizardType, setWizardType] = useState<'category' | 'chatTemplate' | 'writerTemplate'>('category');
  const [editingItem, setEditingItem] = useState<GenAIItem | null>(null);

  const isCategories = location.pathname.includes('/admin/gen-ai/categories');
  const isWriterPage = location.pathname.includes('/admin/gen-ai/ai-writer') || location.pathname.includes('/gen-ai/ai-writer');
  const isChatPage = location.pathname.includes('/admin/gen-ai/ai-chatbot') || location.pathname.includes('/gen-ai/ai-chatbot');

  const pageTitle = isAdmin
    ? (isCategories ? 'Categories' : (isWriterPage ? 'AI Writer Templates' : 'AI Chatbot Templates'))
    : (isWriterPage ? 'AI Writer' : 'AI Chatbot');

  const actions = useMemo(() => new GenAIActions(dispatch), [dispatch]);
  const service = useMemo(() => new GenAIService(), []);

  const formMethods = useForm({
    resolver: yupResolver(wizardValidationSchema),
    defaultValues:
    {
      data: {
        prompt: '',
        inputs: [{
          name: "",
          kind: GenAITemplateInputKind.Text,
          description: "",
          defaultValue: "",
          possibleValues: [],
        }],
      },
      modalCategoryName: '',
      modalCategoryDescription: '',
      modalCategoryIcon: '',
      modalCategoryColor: '#000000',
      floatingSettings: {
        //by default chatbot is not floating
        isFloatingChatbot: false,
        //default avatar for floating chatbot
        chatbotAvatar: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-fill" viewBox="0 0 16 16">
             <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9 9 0 0 0 8 15"/>
            </svg> `
      }
    }
  });

  //If the user isn't an admin, switch to card view
  useEffect(() => 
  {
    if (!isAdmin) actions.toggleView();
  }, [isAdmin, actions]);

  const fetchData = useCallback(async () => 
  {
    actions.fetchStart();
    try 
    {
      const data = await service.fetchGenAIData(isCategories, isWriterPage, isChatPage);
      actions.fetchSuccess(data);
    }
    catch (error) 
    {
      actions.fetchError(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  }, [service, actions, isCategories, isWriterPage, isChatPage]);

  const fetchUserFavorites = useCallback(async () => 
  {
    try 
    {
      const favorites = await service.fetchUserFavorites();
      actions.setUserFavorites(favorites);
    }
    catch (error) 
    {
      console.error('Failed to fetch user favorites:', error);
    }
  }, [service, actions]);

  useEffect(() => 
  {
    fetchData();
    if (!isCategories) 
    {
      service.fetchCategoriesIfNeeded().then(categories => actions.setCategories(categories));
      fetchUserFavorites();
    }
  }, [location.pathname, fetchData, service, actions, isCategories, fetchUserFavorites]);

  const handleDelete = useCallback(async (id: string) => 
  {
    const itemToDelete = state.items.find(item => hasId(item) && item.id === id);

    if (!itemToDelete) 
    {
      toast.error('Cannot delete item: item not found');
      return;
    }

    if (isCategories) 
    {
      try 
      {
        const { canDelete, associatedNames } = await service.canDeleteCategory(id, state.items);

        if (!canDelete) 
        {
          const message = `This category "${itemToDelete.name}" is associated with the following template${associatedNames.length > 1 ? 's' : ''} and cannot be deleted: ${associatedNames.join(", ")}`;

          // Sostituisci `alert` con `modal.danger`
          await modal.custom({
            title: 'Impossibile Eliminare',
            content: message,
            confirmText: 'OK',
            variant: 'primary',
          });

          return;
        }
      }
      catch (error) 
      {
        console.error('Error checking deletion eligibility:', error);
        actions.fetchError('Failed to check if the item can be deleted');
        return;
      }
    }

    const confirmed = await modal.custom({
      title: 'Conferma Eliminazione',
      content: `Sei sicuro di voler eliminare "${itemToDelete.name}"?`,
      confirmText: 'Elimina',
      cancelText: 'Annulla',
      variant: 'danger',
    });

    if (confirmed) 
    {
      try 
      {
        await service.deleteGenAIItem(id, isCategories, isWriterPage, isChatPage);
        actions.deleteItem(id);
        toast.success('Item deleted successfully');
      }
      catch (error) 
      {
        if (axios.isAxiosError(error)) 
        {
          if (error.response?.data) toast.error("Error: " + error.response?.data);
          else toast.error("Error message: " + error.message);
          return;
        }
        console.error('Failed to delete item:', error);
        actions.fetchError('Failed to delete item');
      }
    }
  }, [isCategories, isWriterPage, isChatPage, service, actions, state.items]);

  const handleAdd = () => 
  {
    formMethods.reset();
    setEditingItem(null);
    setWizardType(isCategories ? 'category' : isWriterPage ? 'writerTemplate' : 'chatTemplate');
    setWizardStep(1);
  };

  const handleEdit = (item: IGenAICategory | Omit<IGenAICategory, "id"> | IGenAIWriterTemplate | IGenAIChatTemplate, number?: number) => 
  {
    setEditingItem(item);
    setWizardType(isCategories ? 'category' : isWriterPage ? 'writerTemplate' : 'chatTemplate');
    if (number) setWizardStep(number);
    else setWizardStep(1);

  };

  const handleWizardSubmit = async (item: GenAIItem) => 
  {
    try 
    {
      if (editingItem && 'id' in editingItem) 
      {
        const updatedItem = await service.updateGenAIItem(
          { ...item, id: editingItem.id },
          isCategories,
          isWriterPage,
          isChatPage
        );
        actions.updateItem(updatedItem);
        await fetchData();
        toast.success("Item updated successfully");
      }
      else 
      {
        const createdItem = await service.createGenAIItem(item, isCategories, isWriterPage, isChatPage);
        actions.createItem(createdItem);
        toast.success("Item created successfully");
      }
      setShowWizard(false);
      setEditingItem(null);
      if (!isCategories) 
      {
        const favorites = await service.fetchUserFavorites();
        actions.setUserFavorites(favorites);
      }
    }
    catch (error) 
    {
      if (axios.isAxiosError(error)) 
      {
        if (error.response?.data) toast.error("Error: " + error.response?.data);
        else toast.error("Error message: " + error.message);
        return;
      }
      actions.fetchError('Failed to save item');
    }
  };

  const handleWizardCancel = () => 
  {
    setWizardStep(0);
    setEditingItem(null);
  };

  const handleCategorySelect = (categoryId: string | null) => 
  {
    actions.setSelectedCategory(categoryId);
    actions.setShowFavorites(false);
  };

  const handleFavoritesToggle = () => 
  {
    actions.setShowFavorites(!state.showFavorites);
    actions.setSelectedCategory(null);
  };

  const handleToggleFavorite = async (templateId: string) => 
  {
    const updatedFavorites = state.userFavorites.map(fav =>
      fav.templateId === templateId ? { ...fav, favorite: !fav.favorite } : fav
    );

    if (!updatedFavorites.some(fav => fav.templateId === templateId)) 
    {
      updatedFavorites.push({ templateId, favorite: true });
    }

    try 
    {
      await service.updateUserFavorites(updatedFavorites);
      actions.setUserFavorites(updatedFavorites);
    }
    catch (error) 
    {
      actions.fetchError('Failed to update favorites');
    }
  };

  const handleChatbotSelect = useCallback((chatbot: IGenAITemplate) => 
  {
    const basePath = isAdmin ? '/admin/gen-ai/chatbot/' : '/gen-ai/chatbot/';
    navigate(`${basePath}ai-chat/${chatbot.id}`, { state: { chatbot } });
  }, [isAdmin, navigate]);

  const handleWriterTemplateSelect = useCallback((template: IGenAITemplate) => 
  {
    const basePath = isAdmin ? '/admin/gen-ai/' : '/gen-ai/';
    navigate(`${basePath}ai-writer/${template.id}`, { state: { template } });
  }, [isAdmin, navigate]);

  if (state.isLoading) 
  {
    return <div className="text-center py-10">Loading...</div>;
  }

  if (state.error) 
  {
    return <div className="text-center py-10 text-red-500">{state.error}</div>;
  }

  const filteredItems = (state.items as IGenAITemplate[]).filter(item => 
  {
    if (!hasId(item)) return false;
    if (state.showFavorites) 
    {
      return isFavoriteTemplate(item.id, state.userFavorites);
    }
    if (state.selectedCategory) 
    {
      return 'categoriesId' in item && item.categoriesId.includes(state.selectedCategory);
    }
    return true;
  });

  const favoriteIds = state.userFavorites.filter(fav => fav.favorite).map(fav => fav.templateId);

  const renderContent = (items: GenAIItem[]) => 
  {
    if (items.length === 0) 
    {
      return <div className="text-center py-10">No items found.</div>;
    }

    const viewProps: ViewProps = {
      items,
      isCategories,
      isWriterPage,
      onDelete: handleDelete,
      onToggleFavorite: handleToggleFavorite,
      onEdit: handleEdit,
      favoriteIds,
      onChatbotSelect: isChatPage ? handleChatbotSelect : undefined,
      onWriterTemplateSelect: isWriterPage ? handleWriterTemplateSelect : undefined,
    };

    return state.isCardView ? (
      <GenAICardView {...viewProps} />
    ) : (
      <GenAITableView {...viewProps} />
    );
  };

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">{pageTitle}</h1>
              <div className='flex'>
                {wizardStep > 0 ? (
                  <Button onClick={handleWizardCancel} variant="secondary" buttonType='button'>
                    Back
                  </Button>
                ) : (
                  <>
                    <Button onClick={handleAdd}
                      variant="primary" buttonType='button'
                      type='add'
                      hideOnMobile='label'
                      title={isCategories ? 'Add Category' : 'Add Template'}>
                      Add {isCategories ? 'Category' : isWriterPage ? 'Writer Template' : ' Chatbot Template'}
                    </Button>
                    <Button onClick={() => actions.toggleView()}
                      variant="secondary"
                      className="ml-2" buttonType='button'
                    >
                      {state.isCardView ? 'Switch to Table View' : 'Switch to Card View'}
                    </Button>
                  </>
                )}
              </div>
            </div>

            {!isCategories && !showWizard && (
              <div className="mb-4">
                <TabsUl showUnderline>
                  <TabsLi
                    label="All"
                    isActive={!state.selectedCategory && !state.showFavorites}
                    onClick={() => 
                    {
                      handleCategorySelect(null);
                    }}
                  />

                  <TabsLi
                    label="Favorites"
                    isActive={state.showFavorites}
                    onClick={() => 
                    {
                      handleFavoritesToggle();
                    }}
                  />

                  {state.categories.map((category) => 
                  {
                    return (
                      <TabsLi
                        key={category.id}
                        label={category.name}
                        isActive={state.selectedCategory === category.id}
                        onClick={() => 
                        {
                          handleCategorySelect(category.id);
                        }}
                      />
                    );
                  })}
                </TabsUl>
              </div>
            )}

            {wizardStep > 0 ? (
              <FormProvider {...formMethods}>
                <GenAIWizardPage
                  type={wizardType}
                  onSubmit={handleWizardSubmit}
                  onCancel={handleWizardCancel}
                  categories={state.categories}
                  initialItem={editingItem}
                  initialStep={wizardStep}
                />
              </FormProvider>
            ) : (
              renderContent(isCategories ? state.items : filteredItems)
            )}
          </div>
        </main>
      </div>
    </div>
  );
};