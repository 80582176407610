import React, { useState, useEffect } from 'react';

export const BeetLoader: React.FC = () => 
{
  const [beetCount, setBeetCount] = useState(1);

  useEffect(() => 
  {
    const interval = setInterval(() => 
    {
      setBeetCount(prevCount => prevCount < 5 ? prevCount + 1 : 1);
    }, 200);

    return () => clearInterval(interval);
  });

  return (
    <div data-testid="beet-loader" className="flex items-center">
      {Array.from({ length: 5 }).map((_, index) => (
        <img
          key={index}
          src="/beet-icon.svg"
          alt="Beet"
          className={`inline-block w-10 h-10 ml-1 transition-opacity duration-200 ${index < beetCount ? 'opacity-100' : 'opacity-0'}`}
        />
      ))}
    </div>
  );
};