import { UserManager, WebStorageStateStore } from "oidc-client-ts";
/* import { OidcConfig } from "../ClientConfig"; */
import axios, { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { requestStorageAccess } from "../Utils/BrowserUtils";
import {
  AntiForgeryToken,
  RetryStrategy,
  BeetRequestConfig,
} from "./BeetClient.types";

let userManager: UserManager;
/* const initializeClient = (config: OidcConfig) => 
{
  userManager = new UserManager({
    authority: config.authority,
    client_id: config.client_id,
    redirect_uri: config.redirect_uri,
    post_logout_redirect_uri: config.post_logout_redirect_uri,
    loadUserInfo: true,
    automaticSilentRenew: true,
    response_type: "code",
    prompt: "consent",
    scope: config.scopes.join(" "),
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  });
}; */
const antiForgeryTokenKey = "antiForgery";
let antiForgeryController: AbortController | null = null;

const getAntiForgeryToken = async (): Promise<AntiForgeryToken> => 
{
  let antiForgeryToken: AntiForgeryToken | null = null;
  const json = sessionStorage.getItem(antiForgeryTokenKey);
  if (json) 
  {
    antiForgeryToken = JSON.parse(json) as AntiForgeryToken;
  }

  if (!antiForgeryToken) 
  {
    if (antiForgeryController) 
    {
      antiForgeryController.abort();
    }

    antiForgeryController = new AbortController();

    let remoteToken: AntiForgeryToken = null!;
    try 
    {
      // const canStoreCookies = await requestStorageAccess();
      const canStoreCookies = false;
      const response = await axios.get(
        `/api/antiforgery?setCookie=${canStoreCookies}`,
        {
          signal: antiForgeryController.signal,
        }
      );

      remoteToken = response.data as AntiForgeryToken;
    }
    catch (error) 
    {
      if (axios.isCancel(error)) 
      {
        console.log("Richiesta antiforgery annullata");
      }
      else if (
        axios.isAxiosError(error) &&
        error.status == HttpStatusCode.NotImplemented
      ) 
      {
        remoteToken = {
          headerName: "X-CSRF-TOKEN",
          alternateHeaderName: "X-CSRF-TOKEN-C",
          cookieToken: "not-implemented",
          requestToken: "not-implemented",
          formFieldName: "not-implemented",
        };
      }
      else 
      {
        throw error;
      }
    }
    finally 
    {
      antiForgeryController = null;
    }

    if (remoteToken) 
    {
      antiForgeryToken = remoteToken;
      antiForgeryToken.alternateHeaderName = antiForgeryToken.headerName + "-C";
      sessionStorage.setItem(
        antiForgeryTokenKey,
        JSON.stringify(antiForgeryToken)
      );
    }

    if (remoteToken) 
    {
      antiForgeryToken = remoteToken;
      antiForgeryToken.alternateHeaderName = antiForgeryToken.headerName + "-C";
      sessionStorage.setItem(
        antiForgeryTokenKey,
        JSON.stringify(antiForgeryToken)
      );
    }
  }

  if (!antiForgeryToken) 
  {
    throw new Error("Impossibile ottenere il token antiforgery");
  }

  return antiForgeryToken;
};

/* const checkAndRefreshToken = async () => 
{
  console.log("userManager", userManager);
  const user = await userManager.getUser();
  const user = { expired: true };
  if (user && user.expired) 
  {
    try 
    {
      return await userManager.signinSilent();
    }
    catch (error) 
    {
      console.error("Failed to refresh user.", error);
      await userManager.removeUser();
      await userManager.clearStaleState();
    }
  }

  return null;
}; */

const createDefaultRetryStrategy = (maxRetries: number): RetryStrategy => 
{
  const shouldRetry = async (error: AxiosError) => 
  {
    try 
    {
      const err = error as AxiosError<{ type: string }>;
      if (
        err?.response &&
        err.response.status === 403 &&
        err.response.data.type === "antiforgery_error"
      ) 
      {
        sessionStorage.removeItem(antiForgeryTokenKey);
        return true;
      }
      if (err?.response && err.response.status === 429) 
      {
        return true;
      }
    }
    catch 
    {
      //
    }
    return false;
  };

  return { maxRetries, shouldRetry };
};

let globalRetryTimer: number | null = null;

const wait = (ms: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

const apiFetch = async <TData>(
  url: string,
  init?: BeetRequestConfig,
  retryStrategy?: RetryStrategy
): Promise<AxiosResponse<TData>> => 
{
  if (globalRetryTimer !== null) 
  {
    throw new Error(
      `Too many requests. Please wait ${globalRetryTimer} seconds before trying again.`
    );
  }

  const requestKey = `${url}-${JSON.stringify(init)}`;

  if (init?.isUnique === undefined) 
  {
    init = init || {};
    init.isUnique = init.method === "GET";
  }

  if (init.isUnique && apiFetch.controllers.has(requestKey)) 
  {
    apiFetch.controllers.get(requestKey)?.abort();
  }

  const controller = new AbortController();
  apiFetch.controllers.set(requestKey, controller);

  // await checkAndRefreshToken();

  const requestInit: BeetRequestConfig = init ? { ...init } : {};

  const method = requestInit.method || "GET";
  if (method !== "GET") 
  {
    const antiForgeryToken = await getAntiForgeryToken();
    if (antiForgeryToken) 
    {
      requestInit.headers ||= {};
      requestInit.headers[antiForgeryToken.headerName] =
        antiForgeryToken.requestToken;

      //CookieToken is only present if the browser doesn't allow storing cookies, in which case use the alternate header (less secure)
      if (antiForgeryToken.cookieToken) 
      {
        requestInit.headers[antiForgeryToken.alternateHeaderName] =
          antiForgeryToken.cookieToken;
      }
    }
  }

  requestInit.signal = controller.signal;

  if (requestInit.sseHandler) 
  {
    requestInit.responseType = "stream";
    requestInit.headers = {
      ...requestInit.headers,
      Accept: "text/event-stream",
    };
    requestInit.adapter = "fetch";
  }

  if (!retryStrategy) 
  {
    retryStrategy = createDefaultRetryStrategy(1);
  }

  try 
  {
    const response = await axios<TData>(url, requestInit);

    if (requestInit.sseHandler) 
    {
      handleSseResponse(response, requestInit);
      return response;
    }

    apiFetch.controllers.delete(requestKey);
    return response;
  }
  catch (error) 
  {
    if (axios.isAxiosError(error)) 
    {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 429) 
      {
        const retryAfter = parseInt(
          axiosError.response.headers["retry-after"] || "60",
          10
        );

        globalRetryTimer = retryAfter;

        const retryEvent = new CustomEvent("apiRetryError", {
          detail: axiosError,
        });
        window.dispatchEvent(retryEvent);

        await wait(retryAfter * 1000);

        globalRetryTimer = null;
        return apiFetch(url, requestInit, retryStrategy);
      }
      else if (
        retryStrategy.maxRetries > 0 &&
        (await retryStrategy.shouldRetry(axiosError))
      ) 
      {
        retryStrategy.maxRetries--;
        return apiFetch(url, requestInit, retryStrategy);
      }
    }
    throw error;
  }
  finally 
  {
    apiFetch.controllers.delete(requestKey);
  }
};

const handleSseResponse = (
  response: AxiosResponse,
  config: BeetRequestConfig
) => 
{
  const reader = response.data.getReader();
  const decoder = new TextDecoder();
  let isStreaming = false;
  let fullMessage = "";

  reader
    .read()
    .then(function processText({
      done,
      value,
    }: ReadableStreamReadResult<Uint8Array>): Promise<void> | void 
    {
      if (done) 
      {
        return;
      }

      const chunk = decoder.decode(value, { stream: true });
      const lines = chunk.split("\n");
      lines.forEach((line) => 
      {
        if (line.startsWith("event: gen-ai:chat:begin")) 
        {
          isStreaming = true;
          fullMessage = "";
        }
        else if (line.startsWith("event: gen-ai:chat:end")) 
        {
          isStreaming = false;
          config.sseHandler?.onMessage?.(fullMessage);
        }
        else if (line.startsWith("data: ") && isStreaming) 
        {
          const content = line.substring(6);
          if (content.trim() !== "") 
          {
            fullMessage += content;
            config.sseHandler?.onMessage?.(content);
          }
        }
        else if (line.startsWith("data: {")) 
        {
          try 
          {
            const jsonData = JSON.parse(line.substring(6));
            config.sseHandler?.onComplete?.(jsonData);
          }
          catch (e) 
          {
            console.error("Error parsing JSON data:", e);
          }
        }
      });
      return reader.read().then(processText);
    })
    .catch((error: unknown) => 
    {
      if (error instanceof Error) 
      {
        config.sseHandler?.onError?.(error);
      }
      else 
      {
        console.error("Unexpected error:", error);
      }
    });
};

apiFetch.controllers = new Map<string, AbortController>();

apiFetch.form = async <TData>(
  url: string,
  body?: URLSearchParams,
  init?: BeetRequestConfig
): Promise<AxiosResponse<TData>> => 
{
  const requestInit: BeetRequestConfig = init ? { ...init } : {};
  requestInit.method = "POST";
  requestInit.headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    ...requestInit.headers,
  };

  if (body) 
  {
    requestInit.data = body;
  }

  return apiFetch<TData>(url, requestInit);
};

apiFetch.json = async <TData>(
  url: string,
  method: string,
  body?: object,
  init?: BeetRequestConfig
): Promise<AxiosResponse<TData>> => 
{
  const requestInit: BeetRequestConfig = init ? { ...init } : {};
  requestInit.method = method;
  requestInit.headers = {
    "Content-Type":
      method === "PATCH" ? "application/json-patch+json" : "application/json",
    ...requestInit.headers,
  };

  if (body) 
  {
    requestInit.data = JSON.stringify(body);
  }

  return apiFetch(url, requestInit);
};

apiFetch.get = async <TData>(
  url: string,
  queryData?: Record<string, object>,
  init?: BeetRequestConfig
): Promise<AxiosResponse<TData>> => 
{
  const requestInit: BeetRequestConfig = init ? { ...init } : {};
  requestInit.method = "GET";
  if (queryData) 
  {
    requestInit.params = queryData;
  }

  return apiFetch(url, requestInit);
};

apiFetch.delete = async <TData>(
  url: string,
  body?: object,
  init?: BeetRequestConfig
): Promise<AxiosResponse<TData>> => 
{
  return apiFetch.json(url, "DELETE", body, init);
};

apiFetch.post = async <TData>(
  url: string,
  body?: object,
  init?: BeetRequestConfig
): Promise<AxiosResponse<TData>> => 
{
  return apiFetch.json(url, "POST", body, init);
};

apiFetch.patch = async <TData>(
  url: string,
  body?: object,
  init?: BeetRequestConfig
): Promise<AxiosResponse<TData>> => 
{
  return apiFetch.json(url, "PATCH", body, init);
};

apiFetch.put = async <TData>(
  url: string,
  body?: object | FormData,
  init?: BeetRequestConfig
): Promise<AxiosResponse<TData>> => 
{
  const requestInit: BeetRequestConfig = init ? { ...init } : {};
  requestInit.method = "PUT";

  if (body instanceof FormData) 
  {
    requestInit.headers = {
      ...requestInit.headers,
      "Content-Type": "multipart/form-data",
    };
    requestInit.data = body;
  }
  else 
  {
    requestInit.headers = {
      ...requestInit.headers,
      "Content-Type": "application/json",
    };
    requestInit.data = JSON.stringify(body);
  }

  return apiFetch(url, requestInit);
};

export { userManager, apiFetch };
