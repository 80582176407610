import React from 'react';
import { Datepicker } from '../../../Components/Datepicker';
import { DropdownFilter } from '../../../Components/DropdownFilter';
import { AnalyticsCard01 } from './Partials/AnalyticsCard01';
import { DashboardCard04 } from './Partials/DashboardCard04';
import { AnalyticsCard10 } from './Partials/AnalyticsCard10';
import { WordCloudComponent } from './Partials/WordCloud';
import { AnalyticsCard05 } from './Partials/AnalyticsCard05';
import { Link } from 'react-router-dom';
import { Button } from '../../../Components/Actions/Buttons/Buttons';

export const AnalyticsDataPage: React.FC = () => 
{
  const words = [
    { text: 'Silvio', value: 63 },
    { text: 'Gae', value: 42 },
    { text: 'Tano', value: 77 },
    { text: 'Dio', value: 87 },
    { text: 'Donatello', value: 100 },
    { text: 'Rick', value: 50 },
    { text: 'Abel', value: 51 },
    { text: 'Andrea', value: 57 },
    { text: 'Braiiin', value: 38 },
    { text: 'Chatbot', value: 52 },
    { text: 'Silvio', value: 37 },
    { text: 'Bellissimo', value: 5 },
    { text: 'Cane', value: 37 },
    { text: 'Porco', value: 25 },
    { text: 'Maiale', value: 22 },
    { text: 'Canaglia', value: 10 },
    { text: 'Gesù', value: 17 },
    { text: 'Maleducato', value: 12 },
    { text: 'Grazioso', value: 2 },
    { text: 'Santissimo', value: 7 },
    { text: 'Doge', value: 1 },
  ];
  return (
    <div className="min-h-screen">
      <div className="relative px-4 sm:px-6 lg:px-12 xl:px-14 py-12 lg:py-10 max-w-6xl mx-auto">
        <div className='absolute top-2 xl:top-11 left-2 sm:left-4 lg:left-10 xl:left-2'>
          <Link to="/">
            <Button
              variant='tertiary'
              type='lArrow'
              hideText
              title='Dashboard'
              className='border-slate-200 hover:border-slate-300 rounded-full dark:border-slate-700 dark:hover:border-slate-600'
            />
          </Link>
        </div>
        {/* Header */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          <div>
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mb-3">
              Analisi dati *Sede Chatbot*
            </h1>
            <p className="text-sm text-slate-800 dark:text-slate-300">
              Qua troverai tutti i dati relativi a *Nome Chatbot* nella sede *Nome Sede*
            </p>
          </div>

          {/* Right: Actions */}
          <div className="flex md:justify-end items-start justify-center md:items-center gap-2">
            <DropdownFilter align="right" fields={[]}/>
            <Datepicker align="right" />
          </div>
        </div>

        {/* Main */}
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12">
            <AnalyticsCard01 />
          </div>

          <div className="col-span-12 lg:col-span-6">          
            <AnalyticsCard05 />
          </div>
          <div className="col-span-12 lg:col-span-6">
            <AnalyticsCard10 />

          </div>
          <div className="col-span-12 lg:col-span-6">
            <DashboardCard04 />
            
          </div>
          <div className="col-span-12 lg:col-span-6">
            <WordCloudComponent words={words} />
          </div>
        </div>

      </div>
    </div>
  );
};