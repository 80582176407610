import React, { useEffect, useState } from 'react';
import { GenAITemplateStatus } from '../../Api/Types/GenAIEnums';
import { GenAIItem } from '../../Utils/GenAITypes';
import { IGenAICategory, IGenAITemplateBase } from '../../Api/Types/GenAIInterface';
import { useFormContext } from 'react-hook-form';
import { TextareaField } from '../../../../ReactHookForm/Components/TextareaField';
import { SelectField } from '../../../../ReactHookForm/Components/SelectField';
import { InputField } from '../../../../ReactHookForm/Components/InputField';
import { Button } from '../../../../Components/Actions/Buttons/Buttons';
import { MainModal } from '../../../../Components/Actions/Modals/MainModal';
import { ModalChoice } from '../../../../Components/Actions/Modals/ModalChoice';
import toast from 'react-hot-toast';
import { ColorInputField } from '../../../../ReactHookForm/Components/ColorInputField';

interface BasicInfoStepProps 
{
  isTemplate: boolean;
  categories: IGenAICategory[];
  onAddCategory: (category: Omit<IGenAICategory, 'id'>) => Promise<IGenAICategory>;
}
export const BasicInfoStep: React.FC<BasicInfoStepProps> = ({
  isTemplate,
  categories,
  onAddCategory
}) => 
{
  const [localCategories, setLocalCategories] = useState<IGenAICategory[]>(categories);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState<boolean>(false);
  const { register, formState: { errors }, trigger, setValue, getValues } = useFormContext();

  const [templateColor, setTemplateColor] = useState<string>(getValues('templateColor') || '#000000'); //TODO: this doesn't update until re-open
  const [newCategoryModalColor, setNewCategoryModalColor] = useState<string>('#000000');
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => 
  {
    const { name, value } = e.target;
    if(name === 'name') 
    {
      const capitalizedValue = 
          e.target instanceof HTMLInputElement && e.target.type === 'text'
            ? e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
            : e.target.value;

      setValue(name, capitalizedValue);
      return;
    }
    setValue(name, value);
  };

  const handleMetadataChange = (field: 'templateColor' | 'templateIcon' | 'categoryColor' | 'categoryIcon', value: string) => 
  {
    setValue(field, value);
    const metadata = isTemplate ? JSON.stringify({ templateColor: getValues('templateColor'), templateIcon: getValues('templateIcon') }) : 
      JSON.stringify({ categoryColor: getValues('categoryColor'), categoryIcon: getValues('categoryIcon') });
    setValue('metadata', metadata);
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => 
  {
    const selectedCategories = Array.from(e.target.selectedOptions, option => option.value);
    setValue('categoriesId', selectedCategories);
  };

  const resetCategoryFields = () => 
  {
    setValue('modalCategoryName', '');
    setValue('modalCategoryDescription', '');
    setValue('modalCategoryIcon', '');
    setValue('modalCategoryColor', '#000000');
  };

  const handleAddNewCategory = async () => 
  {
    setIsCategoryModalOpen(true); 
  };

  const handleConfirmModal = async () =>
  {
    const categoryName = getValues('modalCategoryName');
    const categoryDescription = getValues('modalCategoryDescription');
    const categoryIcon = getValues('modalCategoryIcon');
    const categoryColor = getValues('modalCategoryColor');
    if (categoryName) 
    {
      const newMetadata = JSON.stringify({ categoryColor: categoryColor, categoryIcon: categoryIcon }); 
      try 
      {
        const res = await trigger(['modalCategoryName', 'modalCategoryDescription', 'modalCategoryIcon', 'modalCategoryColor']);
        if (!res)
        {
          toast.error("Failed to add new category. Please try again.");
          return;
        }
        const newCategory = await onAddCategory({ name: categoryName, description: categoryDescription, metadata: newMetadata });
        setLocalCategories(prevCategories => [...prevCategories, newCategory]);
        resetCategoryFields();
        setIsCategoryModalOpen(false);
      }
      catch (error) 
      {
        console.error("Failed to add new category:", error);
      }
    };
  };

  return (
    <div className="space-y-4">
      <InputField
        name="name"
        label={`${isTemplate ? "Template Title" : "Category Title"}`}
        required
        tooltip={`A title for the ${isTemplate ? 'template that will show in templates' : 'category that will show in categories'} list and in search results`}
        register={register}
        errors={errors}
        placeholder="Enter title here.."
        onChange={handleInputChange}
      />

      <TextareaField
        name="description"
        label="Description"
        required
        tooltip={`A short description about what this ${isTemplate ? "template" : "category"} do.`}
        register={register}
        errors={errors}
        placeholder="Enter description here..."
        onChange={handleInputChange}
      />

      <InputField
        name={isTemplate ? "templateIcon" : "categoryIcon"}
        label={isTemplate ? "Template Icon" : "Category Icon"}
        tooltip="Paste the svg code you get from the Tabler Icons or any other icon sets"
        register={register}
        errors={errors}
        placeholder="Paste the svg code you get from the Tabler Icons or any other icon sets"
        onChange={(e) => handleMetadataChange(isTemplate ? 'templateIcon' : 'categoryIcon', e.target.value)}
      />

      <ColorInputField
        name={isTemplate ? "templateColor" : "categoryColor"}
        label={isTemplate ? "Template Color" : "Category Color"}
        tooltip="Pick a color for the icon container shape. Color is in HEX format."
        register={register}
        errors={errors}
        value={templateColor}
        onChange={(e) =>
        {
          handleMetadataChange(isTemplate ? 'templateColor' : 'categoryColor', e.target.value);
          setTemplateColor(e.target.value);
        }}
        className="relative -start-1/2 -top-1/2 h-[200%] w-[200%] cursor-pointer appearance-none rounded-full border-none p-0"
      />

      {isTemplate && (
        <>
          <div className="w-full flex flex-col items-center gap-2">
            <SelectField
              name="categoriesId"
              label="Template Category"
              required
              multiple
              tooltip="Categories of the template. Useful for filtering in the templates list."
              register={register}
              errors={errors}
              options={localCategories.map(category => ({
                value: category.id,
                label: category.name
              }))}
              onChange={handleCategoryChange}
            />
            <Button
              onClick={handleAddNewCategory}
              variant="secondary"
              type="add"
              size="l"
              className="mt-2 "
            >
              Add New Category
            </Button>
          </div>

          <SelectField
            name="status"
            label="Status"
            register={register}
            errors={errors}
            options={Object.values(GenAITemplateStatus).map(status => ({
              value: status,
              label: status
            }))}
            onChange={handleInputChange}
          />
        </>
      )}
      {/* Add Category Modal */}
      {isCategoryModalOpen && (
        <MainModal
          setOpen={setIsCategoryModalOpen}
          title="Add New Category"
          variant="primary"
          onConfirm={handleConfirmModal}
        >

          <InputField
            label="Category Title"
            placeholder='Enter new category title..'
            required
            tooltip={`A title for the category that will show in categories list and in search results`}
            name='modalCategoryName'
            register={register}
            errors={errors}
            onChange={(e) => setValue('modalCategoryName', e.target.value)}
          />

          <InputField
            label="Description"
            placeholder='Enter new category description..'
            name='modalCategoryDescription' 
            tooltip="A short description about what this category do."
            register={register}
            errors={errors}
            onChange={(e) => setValue('modalCategoryDescription', e.target.value)}
          />

          <InputField
            label="Category Icon"
            placeholder='Enter svg code..'
            tooltip="Paste the svg code you get from the Tabler Icons or any other icon sets"
            name='modalCategoryIcon'
            register={register}
            errors={errors}
            onChange={(e) => setValue('modalCategoryIcon', e.target.value)}
          />
          <ColorInputField
            name="modalCategoryColor"
            label="Category Color"
            tooltip="Pick a color for for the icon container shape. Color is in HEX format."
            register={register}
            errors={errors}
            value={newCategoryModalColor}
            onChange={(e) =>
            {
              setValue('modalCategoryColor', e.target.value);
              setNewCategoryModalColor(e.target.value);
            }}
            className="relative -start-1/2 -top-1/2 h-[200%] w-[200%] cursor-pointer appearance-none rounded-full border-none p-0"
          />

          <ModalChoice onConfirm={() => { }} />
        </MainModal>
      )}
    </div>
  );
};