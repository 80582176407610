import { SupabaseClient } from "@supabase/supabase-js";
import { SessionContext } from "../Contexts/SessionContext";
import { useRequiredContext } from "../../../Hooks/UseRequiredContext";

export const useSessionContext = () => 
{
  const context = useRequiredContext(SessionContext);
  return context;
};

export function useSupabaseClient<
  Database = unknown,
  SchemaName extends string & keyof Database = "public" extends keyof Database
    ? "public"
    : string & keyof Database
>() 
{
  const context = useSessionContext();
  return context.supabaseClient as SupabaseClient<Database, SchemaName>;
}

export const useSession = () => 
{
  const context = useSessionContext();
  return context.session;
};

export const useUser = () => 
{
  const context = useSessionContext();
  return context.session?.user ?? null;
};
