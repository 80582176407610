import React, { ElementType, ComponentPropsWithRef, ReactNode } from 'react';
import { Role } from './RoleManager';
import { useRoleRendering } from './Hooks/UseRoleRendering';

export type RoleBasedElementProps<T extends ElementType> = {
  as?: T;
  requiredRoles?: Role | Role[];
  children?: ReactNode;
} & Omit<ComponentPropsWithRef<T>, 'as' | 'requiredRoles'>;

export const RoleBasedElement = <T extends ElementType = 'div'>({
  as,
  requiredRoles,
  children,
  ...props
}: RoleBasedElementProps<T>) => 
{
  const { hasAnyRole, hasRole } = useRoleRendering();
  const Component = as || 'div';

  const userHasRequiredRoles = Array.isArray(requiredRoles)
    ? hasAnyRole(requiredRoles)
    : requiredRoles
      ? hasRole(requiredRoles)
      : true;

  if (!userHasRequiredRoles) 
  {
    return null;
  }

  return <Component {...props}>{children}</Component>;
};
