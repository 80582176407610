// import React, { useEffect, useState, useCallback, useMemo } from 'react';
// import { GenAIProvider } from '../Api/Types/GenAIEnums';
// import { GenAIProviderModel } from '../Api/Types/GenAIInterface';
// import { GenAIService } from '../Utilis/GenAIService';

// export const ProviderSelector: React.FC = () => 
// {
//   const [providerModel, setProviderModel] = useState<GenAIProviderModel>({
//     provider: GenAIProvider.Unspecified,
//     models: []
//   });

//   const service = useMemo(() => new GenAIService(), []);

//   const fetchProviderSettings = useCallback(async () => 
//   {
//     try 
//     {
//       const settings = await service.getProviderSettings();
//       if (settings) 
//       {
//         const models = await service.getProviderModels();
//         const newProviderModel = {
//           provider: settings.provider,
//           models: models[0]?.models || []
//         };
//         setProviderModel(newProviderModel);
//       }
//     }
//     catch (error) 
//     {
//       //
//     }
//   } ,[service]);

//   useEffect(() => 
//   {
//     fetchProviderSettings();
//   }, [fetchProviderSettings]);

//   const handleProviderChange = async (type: 'provider' | 'model', value: string) => 
//   {
//     const updatedProviderModel = type === 'provider'
//       ? { provider: value as GenAIProvider, models: [] }
//       : { ...providerModel, models: [value] };

//     setProviderModel(updatedProviderModel);

//     if (updatedProviderModel.provider !== GenAIProvider.Unspecified && updatedProviderModel.models.length > 0) 
//     {
//       try 
//       {
//         await service.updateProviderSettings(
//           updatedProviderModel.provider,
//           updatedProviderModel.models[0]
//         );
//       }
//       catch (error) 
//       {
//         console.error('Errore durante l\'aggiornamento delle impostazioni del provider', error);
//       }
//     }
//   };

//   return (
//     <div className="grid grid-cols-2 gap-4">
//       <div className="space-y-2">
//         <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
//           Provider
//         </label>
//         <select
//           value={providerModel.provider}
//           onChange={(e) => handleProviderChange('provider', e.target.value)}
//           className="w-full rounded-md border border-gray-300 dark:border-gray-600 p-2"
//         >
//           {Object.values(GenAIProvider).map((provider) => (
//             <option key={provider} value={provider}>
//               {provider}
//             </option>
//           ))}
//         </select>
//       </div>

//       <div className="space-y-2">
//         <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
//           Model
//         </label>
//         <select
//           value={providerModel.models[0] || ''}
//           onChange={(e) => handleProviderChange('model', e.target.value)}
//           className="w-full rounded-md border border-gray-300 dark:border-gray-600 p-2"
//         >
//           {providerModel.models.map((model) => (
//             <option key={model} value={model}>
//               {model}
//             </option>
//           ))}
//         </select>
//       </div>
//     </div>
//   );
// };
import React, { useEffect, useState } from 'react';
import { GenAIProviderName } from '../Api/Types/GenAIEnums';
import { GenAIProviderModel } from '../Api/Types/GenAIInterface';
import { GenAIService } from '../Utils/GenAIService';

export const ProviderSelector: React.FC = () => 
{
  const service = new GenAIService();
  const [providerModel, setProviderModel] = useState<GenAIProviderModel>({
    provider: GenAIProviderName.OpenAI,
    models: ['gpt-4o-mini'],
  });

  const updateProviderSettings = async (provider: string, model: string) => 
  {
    try 
    {
      await service.updateProviderSettings(provider, model);
      console.log(`Provider settings updated to ${provider} and model ${model}`);
    }
    catch (error) 
    {
      console.error('Error updating provider settings', error);
    }
  };

  useEffect(() => 
  {
    updateProviderSettings(providerModel.provider, providerModel.models[0]); // Manda le impostazioni iniziali
  }, []);

  const handleProviderChange = (type: 'provider' | 'model', value: string) => 
  {
    const updatedProviderModel =
      type === 'provider'
        ? { provider: value as GenAIProviderName, models: providerModel.models }
        : { ...providerModel, models: [value] };

    setProviderModel(updatedProviderModel);

    updateProviderSettings(updatedProviderModel.provider, updatedProviderModel.models[0]);
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Provider
        </label>
        <select
          value={providerModel.provider}
          onChange={(e) => handleProviderChange('provider', e.target.value)}
          className="w-full rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 p-2"
        >
          <option value={GenAIProviderName.OpenAI}>OpenAI</option>
        </select>
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          Model
        </label>
        <select
          value={providerModel.models[0]}
          onChange={(e) => handleProviderChange('model', e.target.value)}
          className="w-full rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 p-2"
        >
          <option value="gpt-4o-mini">gpt-4o-mini</option>
        </select>
      </div>
    </div>
  );
};
