import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Search, X } from 'lucide-react';
import { useDebounce } from '../../Hooks/UseDebounce';

export interface SearchableItem 
{
  id: string;
  label: string;
  image?: string;
  svg?: string | React.ReactElement
}

interface SearchableSelectProps 
{
  onSearch: (term: string) => Promise<SearchableItem[]>;
  onSelect: (item: SearchableItem) => void;
  placeholder?: string;
  isModal?: boolean;
  displayAs?: 'list' | 'grid';
  triggerElement?: React.ReactNode;
  debounceTime?: number;
}

/**
 * A searchable select component that allows the user to search for items and select one. You can use it as a dropdown or modal and displays items in a list or grid.
 *
 * @param {function} onSearch - A function that takes a search term and returns a promise that resolves with an array of items that match the search term.
 * @param {function} onSelect - A function that takes an item and is called when the user selects an item.
 * @param {string} [placeholder='Search...'] - The placeholder text to display in the search input.
 * @param {boolean} [isModal=false] - Whether to render the component as a modal or as a dropdown.
 * @param {string} [displayAs='list'] - Whether to display the items in a list or a grid.
 * @param {ReactElement} [triggerElement] - An element to display as the trigger for the dropdown or modal.
 * @param {number} [debounceTime=300] - The amount of time to debounce the search function.
 *
 * @example
 * <SearchableSelect
 *   onSearch={(term) => fetchItems(term)}
 *   onSelect={(item) => console.log(item)}
 * />
 */
export const SearchableSelect: React.FC<SearchableSelectProps> = ({
  onSearch,
  onSelect,
  placeholder = 'Search...',
  isModal = false,
  displayAs = 'list',
  triggerElement,
  debounceTime = 300
}) => 
{
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState<SearchableItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // Search function
  const search = useCallback(async (term: string) => 
  {
    if (!term) return;
    setIsLoading(true);
    try 
    {
      const results = await onSearch(term);
      setItems(results);
    }
    catch (error) 
    {
      console.error('Error fetching results:', error);
      // maybe set an error and display it to the user
    }
    finally 
    {
      setIsLoading(false);
    }
  }, [onSearch]);

  //TODO IMPORTANT: Check that the debounce logic is fully functional,
  //and avoid using lodash.rebounce due to external import problems with vite
  const debouncedSearch = useDebounce(search, debounceTime);

  useEffect(() => 
  {
    debouncedSearch.value(searchTerm);

    return () => debouncedSearch.cancel();
  }, [searchTerm, debouncedSearch]);

  useEffect(() => 
  {
    const handleClickOutside = (event: MouseEvent) => 
    {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) 
      {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => 
    {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (item: SearchableItem) => 
  {
    onSelect(item);
    setIsOpen(false);
    setSearchTerm('');
  };

  const toggleOpen = () => setIsOpen(!isOpen);

  const renderItemImage = (item: SearchableItem) => 
  {
    if (React.isValidElement(item.svg)) 
    {
      return React.cloneElement(item.svg as React.ReactElement, { className: "w-12 h-12 mb-2" });
    }
    else if (typeof item.svg === 'string') 
    {
      return <div dangerouslySetInnerHTML={{ __html: item.svg }} className="w-12 h-12 mb-2" />;
    }
    else if (item.image) 
    {
      return <img src={item.image} alt={item.label} className="w-12 h-12 object-cover mb-2" />;
    }
    return null;
  };

  const renderContent = () => (
    <div className="bg-white dark:bg-slate-600 rounded-lg shadow-lg overflow-hidden">
      <div className="p-4">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={placeholder}
            className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        </div>
      </div>
      <div className={`max-h-60 overflow-y-auto ${displayAs === 'grid' ? 'grid grid-cols-3 gap-4' : ''}`}>
        {isLoading ? (
          <div className="p-4 text-center">Loading...</div>
        ) : (
          items.map((item) => (
            <div
              key={item.id}
              onClick={() => handleSelect(item)}
              className={`cursor-pointer hover:bg-gray-100 ${displayAs === 'list' ? 'p-4 flex items-center gap-4' : 'flex flex-col items-center p-2'
              }`}
            >
              {renderItemImage(item)}

              {/* Show only the label in the center if there are no images or SVG */}
              <span className={`${!item.image && !item.svg ? 'text-center' : ''}`}>
                {item.label}
              </span>
            </div>
          ))
        )}
      </div>
    </div>
  );

  if (isModal) 
  {
    return (
      <>
        {triggerElement && (
          <div onClick={toggleOpen} className="cursor-pointer max-w-max">
            {triggerElement}
          </div>
        )}
        {isOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div ref={containerRef} className="w-full z-60 max-w-md relative">
              {renderContent()}
              <button title='Close'
                onClick={() => setIsOpen(false)}
                className="absolute top-px right-px text-gray-500 hover:text-gray-700"
              >
                <X size={15} />
              </button>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {triggerElement && (
        <div onClick={toggleOpen} className="cursor-pointer max-w-max">
          {triggerElement}
        </div>
      )}
      {isOpen && (
        <div ref={containerRef} className="absolute z-60 max-w-md mt-2">
          {renderContent()}
        </div>
      )}
    </>
  );
};
