import React, { ReactNode } from 'react';

interface ButtonGroupProps 
{
  children: ReactNode;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ children }) => 
{
  return (
    <div className="flex flex-row -space-x-px">
      {children}
    </div>
  );
};

//Nel caso in cui, in futuro, dovremo gestire uno stato condiviso tra più Buttongroup, o avremo una necessità
//concreta di avere un context che centralizzi in ogni parte del progetto la gestione dei Buttons all'interno di ButtonGroup, 
//allora valutare la logica del ButtonContext magari in maniera ibrida, dunque che utilizzi sia uno stato locale
//come attualmente succede e sia con l'utilizzo del context in caso vada gestito lo stato di diversi Buttongroup sparsi 
//nella/e pagina/e che hanno la necessità di reagire all'unisono ad un determinato stato.
