import resolveConfig from 'tailwindcss/resolveConfig';

export const tailwindConfig = (): ReturnType<typeof resolveConfig> => 
{
  const configLocation = '.src/Css/tailwind.config.js';
  return resolveConfig(configLocation as never);
};

export const hexToRGB = (h: string): string => 
{
  let r: string | number = 0;
  let g: string | number = 0;
  let b: string | number = 0;
  if (h.length === 4) 
  {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } 
  else if (h.length === 7) 
  {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value: number | string | null): string => 
{
  if (value === null) return '';
  const num = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(num)) throw new Error('Input cannot be parsed as a number');
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3,
    notation: 'compact',
  }).format(num);
};

export const formatThousands = (value: number | string | null): string => 
{
  if (value === null) return '';
  const num = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(num)) throw new Error('Input cannot be parsed as a number');
  return Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 3,
    notation: 'compact',
  }).format(num);
};

export const is_string = (value: unknown): value is string =>
{
  return typeof value === 'string' || value instanceof String;
};