import { useUIOperations } from './UseUiOperations';
import { useFileSystemOperations } from './UseFileSystemOperations';
import { ContextMenuType } from '../FileManager.types';
import { Rename } from '../MenuContextActions/Rename';
import { Delete } from '../MenuContextActions/Delete';
import { AddFolder } from '../MenuContextActions/AddFolder';
import { AddFile } from '../MenuContextActions/AddFile';
import { useFileSystem } from './UseFileSystem';

export const useContextMenuActions = () => 
{
  const { openModal, closeModal, closeContextMenu } = useUIOperations();
  const { renameItem, deleteItem, addFolder, addFile } = useFileSystemOperations();
  const { selectedFolder } = useFileSystem();

  const handleContextMenuAction = (action: "rename" | "delete" | "addSubfolder" | "addFile", contextMenu: ContextMenuType) => 
  {
    if (!contextMenu) return;

    const handleClose = () => 
    {
      closeModal();
      closeContextMenu();
    };

    const targetFolder = contextMenu.type === 'grid' ? selectedFolder : contextMenu.item;

    switch (action) 
    {
      case "rename":
        if (contextMenu.type !== 'grid') 
        {
          openModal(
            <Rename
              itemName={contextMenu.item.name}
              onConfirm={newName => 
              {
                if (newName) 
                {
                  renameItem(contextMenu.item.id, newName, contextMenu.type);
                }
                handleClose();
              }}
              onCancel={handleClose}
            />
          );
        }
        break;

      case "delete":
        if (contextMenu.type !== 'grid') 
        {
          openModal(
            <Delete
              itemName={contextMenu.item.name}
              onConfirm={() => 
              {
                deleteItem(contextMenu.item.id, contextMenu.type);
                handleClose();
              }}
              onCancel={handleClose}
            />
          );
        }
        break;

      case 'addSubfolder':
        if (contextMenu.type === 'folder' || contextMenu.type === 'grid') 
        {
          openModal(
            <AddFolder
              itemName=""
              onConfirm={(folderName) => 
              {
                if (folderName) 
                {
                  addFolder(folderName, targetFolder.id);
                }
                handleClose();
              }}
              onCancel={handleClose}
            />
          );
        }
        break;

      case 'addFile':
        if (contextMenu.type === 'folder' || contextMenu.type === 'grid') 
        {
          openModal(
            <AddFile
              itemName=""
              onConfirm={(fileName) => 
              {
                if (fileName) 
                {
                  addFile(fileName, targetFolder.id);
                }
                handleClose();
              }}
              onCancel={handleClose}
            />
          );
        }
        break;

      default:
        console.warn(`Unhandled action: ${action}`);
        break;
    }
  };

  return handleContextMenuAction;
};