import React from 'react';

interface ToolbarGroupProps 
{
  children: React.ReactNode;
}

export const ToolbarGroup: React.FC<ToolbarGroupProps> = ({ children }) => (
  <div className="flex items-center space-x-1">
    {children}
  </div>
);