import { apiDefinitions } from "../../ApiConfig";
import { Filter } from "../../../Utils/Filter";
import { ProductCreateDto, ProductDto } from "../../../Utils/Stripe.types";

const definition = apiDefinitions.products;

export const productsController = {

  getAll: async (filter: Filter) => 
  {
    const endpoint = definition.getAll;
    const response = await endpoint.invoke(endpoint.url, {filter});

    return response.data;
  },
  getProduct: async (id: string) => 
  {
    const endpoint = definition.getProduct;
    const response = await endpoint.invoke(id);

    return response;
  },
  createProduct: async (product: ProductCreateDto) => 
  {
    const endpoint = definition.createProduct;
    const response = await endpoint.invoke(endpoint.url, product);

    return response.data;
  },
  updateProduct: async( id: string, product: ProductDto) =>
  {
    const endpoint = definition.updateProduct;
    const response = await endpoint.invoke(endpoint.url.replace("{id}", id), product);
    
    return response;
  },

  deleteProduct: async (id: string ) =>
  {
    const endpoint = definition.deleteProduct;
    const response = await endpoint.invoke(endpoint.url.replace("{id}", id));

    return response;
  },

  getSeries: async () =>
  {
    const endpoint = definition.getSeries;
    const response = await endpoint.invoke(endpoint.url);

    return response;
  }
};