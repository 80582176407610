// BANNER HOOK

import React, { useState, useCallback, useRef, useEffect } from "react";

type BannerType = string;

type BannerReturn = {
  bannerOpen: boolean;
  bannerType: BannerType;
  bannerMessage: string;
  bannerDuration: number;
  setBannerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showBanner: (message: string, type: BannerType, duration?: number) => void;
};

/**
 * Returns a custom hook for managing a banner component. The hook provides state and functions for controlling the banner's visibility, type, message, and duration.
 *
 * @param {number} defaultDuration - The default duration in milliseconds for which the banner will be displayed. Defaults to 5000 milliseconds.
 * @return {Object} An object containing the following properties:
 *   - bannerOpen: A boolean indicating whether the banner is currently open.
 *   - bannerType: A string representing the type of the banner. Can be "info", "success", "warning", or "error". Defaults to "info".
 *   - bannerMessage: A string representing the message to be displayed in the banner.
 *   - bannerDuration: A number representing the duration in milliseconds for which the banner will be displayed.
 *   - setBannerOpen: A function to set the value of bannerOpen.
 *   - showBanner: A function to show the banner with the provided message, type, and duration.
 */
export const useBanner = (defaultDuration: number = 5000): BannerReturn => 
{
  const [bannerOpen, setBannerOpen] = useState<boolean>(false);
  const [bannerType, setBannerType] = useState<BannerType>("info");
  const [bannerMessage, setBannerMessage] = useState<string>("");
  const [bannerDuration, setBannerDuration] = useState<number>(defaultDuration);
  const timeoutRef = useRef<number | null>(null);

  /**
   * Shows the banner with a specified message, type, and duration.
   *
   * @param {string} message - The message to display in the banner.
   * @param {BannerType} type - The type of banner to display. Can be "info", "success", "warning", or "error".
   * @param {number} [duration=bannerDuration] - Optional duration in milliseconds to display the banner. Defaults to the current banner duration.
   */

  useEffect(() => 
  {
    return () => 
    {
      if (timeoutRef.current !== null) 
      {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const showBanner = useCallback(
    (
      message: string,
      type: BannerType = "info",
      duration: number = bannerDuration 
    ) => 
    {
      setBannerMessage(message);
      setBannerType(type);
      setBannerOpen(true);
      setBannerDuration(duration);

      if (timeoutRef.current !== null) 
      {
        clearTimeout(timeoutRef.current);
      }
      
      // Set a new timeout to close the banner
      timeoutRef.current = window.setTimeout(() => 
      {
        setBannerOpen(false);
      }, duration);
    },
    [bannerDuration]
  );

  return {
    bannerOpen,
    bannerType,
    bannerMessage,
    bannerDuration,
    setBannerOpen,
    showBanner
  };
};
