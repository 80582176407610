import { motion } from "framer-motion";
import { FC } from "react";

interface ProgressBarStepsProps
{
  totalSteps: number;
  currentStep: number;
}

export const ProgressBarSteps: FC<ProgressBarStepsProps> = ({totalSteps, currentStep}) => 
{
  return(
    <div className="relative w-full my-3">
      <div className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200 dark:bg-slate-700" aria-hidden="true"></div>
      <ul className="relative flex justify-between w-full items-center">
        {[...Array(totalSteps)].map((_, index) => 
        {
          const step = index + 1;
          return (
            <li key={step}>
              <motion.button
                className={`flex items-center justify-center rounded-full font-semibold w-6 h-6 ${
                  currentStep === step
                    ? "bg-indigo-500 text-white py-1"
                    : "bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
                }`}
                whileHover={{
                  scale: 1.1,
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
                }}
                whileTap={{ scale: 0.9 }}
                animate={{
                  backgroundColor: currentStep === step ? "#6366f1" : "#f3f4f6",
                  color: currentStep === step ? "#ffffff" : "#6b7280",
                }}
                transition={{ duration: 0.3 }}
                type="button"
              >
                {step}
              </motion.button>
            </li>
          );
        })}
      </ul>
    </div>
  );

};