import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IGenAIChatTemplate, IGenAIWriterTemplate, IUserInput, Training } from '../../Api/Types/GenAIInterface';
import { ModalInputDropdown } from '../../../../Components/Actions/Modals/ModalDropdown';
import { Button } from '../../../../Components/Actions/Buttons/Buttons';
import { useFormContext } from 'react-hook-form';
import { TextareaField } from '../../../../ReactHookForm/Components/TextareaField';
import { SelectField } from '../../../../ReactHookForm/Components/SelectField';
import { Tooltip } from '../../../../Components/Tooltip';

export const TemplateDetailsStep: React.FC = () => 
{
  const { register, formState: { errors }, setValue, getValues } = useFormContext();
  const navigate = useNavigate();
  const [trainings, setTrainings] = useState<Training[]>([]);
  const [selectedTraining, setSelectedTraining] = useState<Training | undefined>(undefined);

  const [prompt, setPrompt] = useState<string>(getValues('data.prompt').trim() || '');

  useEffect(() => 
  {
    const storedTrainings = localStorage.getItem('chatbotTrainings');
    if (storedTrainings) 
    {
      setTrainings(JSON.parse(storedTrainings));
    }
  }, []);

  const handleDataChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => 
  {
    const { name, value } = e.target;
    setValue(name, value);
    setPrompt(value);
  };

  const handleAddInputToPrompt = (input: IUserInput) => 
  {
    const inputPlaceholder = `<<${input.name.trim()}>>`;
    const updatedPrompt = getValues('data.prompt') + (getValues('data.prompt') ? ' ' : '') + inputPlaceholder;
    setValue('data.prompt', updatedPrompt);
    setPrompt(updatedPrompt);
  };

  const handleTrainingChange = (e: React.ChangeEvent<HTMLSelectElement>) => 
  {
    const selectedValue = e.target.value;
    const selectedTraining = trainings.find(t => t.id === selectedValue);
    setValue('data.template', selectedTraining, { shouldDirty: true });
    setSelectedTraining(selectedTraining);
  };

  const handleManageTrainings = () => 
  {
    navigate('/admin/gen-ai/chatbot-training');
  };

  const isChatTemplate = !getValues('data.inputs'); //Inputs is undefined in chat templates
  const date = new Date(getValues('data.template.lastModified')).toLocaleDateString();

  return (
    <div className="space-y-4">
      {isChatTemplate && (
        <div className="mb-4">
          <div>

            <SelectField
              name='data.template.id'
              label='Training Source'
              onChange={handleTrainingChange}
              register={register}
              errors={errors}
              options={[
                { value: '', label: 'Nessun training' },
                ...trainings.map(training => ({
                  value: training.id,
                  label: training.name
                }))
              ]}
            />
            {getValues('data.template') && (
              <div className="mt-2 text-sm text-slate-500">
                <p>{getValues('data.template.description')}</p>
                {date !== "Invalid Date" && (
                  <p className="mt-1">
                    Ultimo aggiornamento: {date}
                  </p>                 
                )}
              </div>
            )}
            <Button
              onClick={handleManageTrainings}
              variant="secondary"
              type="add"
              size="l"
              className="mt-2"
            >
              Gestisci Training
            </Button>
          </div>
        </div>
      )}

      {/* CREATED INPUTS */}
      {!isChatTemplate && (
        <div className="w-full flex flex-col items-center">
          <div className="flex justify-center items-center gap-2 text-center">
            <label className="block text-sm font-medium">
              Created Inputs:
            </label>
            <Tooltip position={'top'}>
              <div className="text-xs whitespace-nowrap">Click on each item to get the dynamic data from users input.</div>
            </Tooltip>
          </div>
          <div className="form-input w-full flex flex-wrap gap-2">
            {getValues('data.inputs').map((input: IUserInput, index: number) => (
              <Button
                key={index}
                buttonType="button"
                className="btn-xs bg-indigo-500 hover:bg-indigo-600 text-white"
                onClick={() => handleAddInputToPrompt(input)}
              >
                {input.name}
              </Button>
            ))}
          </div>
        </div>
      )}

      {/* PROMPT */}
      <TextareaField
        name="data.prompt"
        label="Custom Prompt"
        required
        register={register}
        errors={errors}
        placeholder="Enter prompt here..."
        value={prompt}
        onChange={handleDataChange}
      />
      {isChatTemplate && getValues('data.template') && (
        <p className="mt-2 text-sm text-amber-500">
          Nota: Il training selezionato verrà utilizzato come contesto aggiuntivo per questo prompt
        </p>
      )}
    </div>
  );
};