import React, { useEffect, useState } from 'react';
import { accountController } from '../../../../Api/Controller/ApiAccount';
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { ExternalAppsPanelLocale } from '../../../../ReactIntl/LocaleInterfaces';
interface ProviderToggle 
{
  [key: string]: boolean;
}

export function ExternalAppsPanel() 
{
  const [providers, setProviders] = useState<string[]>([]);
  const [toggles, setToggles] = useState<ProviderToggle>({});

  const intl = getIntl<ExternalAppsPanelLocale>('externalAppsPanel');

  useEffect(() => 
  {
    const fetchProviders = async () => 
    {
      try 
      {
        const response = await accountController.fetchExternalLoginProviders();
        const providersList = response.data;
        setProviders(providersList);

        const initialToggles: ProviderToggle = providersList.reduce((acc, provider) => 
        {
          acc[provider] = false;
          return acc;
        }, {} as ProviderToggle);

        setToggles(initialToggles);
      }
      catch (error) 
      {
        console.error('Error fetching providers:', error);
      }
    };

    fetchProviders();
  }, []);

  const handleToggle = (provider: string) => 
  {
    setToggles(prevToggles => ({
      ...prevToggles,
      [provider]: !prevToggles[provider],
    }));
  };

  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">{intl.h2AuthorizedOAuth}</h2>
        {/* Connected Apps list */}
        <section className="pb-6 border-b border-slate-200 dark:border-slate-700">
          <div className="space-y-4">
            {providers.map((provider, index) => (
              <div
                key={index}
                className="flex items-center justify-between bg-white dark:bg-slate-800 shadow-md rounded-sm border border-slate-200 dark:border-slate-700 p-4"
              >
                <div className="flex items-center">
                  <img
                    src={`/OAuthLogos/${provider}.svg`}
                    alt={`${provider} logo`}
                    className="w-16 h-16 mr-4"
                  />
                  <h3 className="text-lg text-slate-800 dark:text-slate-100 font-semibold">{provider}</h3>
                </div>
                <div className="flex items-center">
                  <div className="form-switch">
                    <input
                      type="checkbox"
                      id={`toggle-${index}`}
                      className="sr-only"
                      checked={toggles[provider] || false}
                      onChange={() => handleToggle(provider)}
                    />
                    <label className="bg-slate-400 dark:bg-slate-700" htmlFor={`toggle-${index}`}>
                      <span className="bg-white shadow-sm" aria-hidden="true"></span>
                      <span className="sr-only">{intl.srOnlySwitchLabel}</span>
                    </label>
                  </div>
                  <div className="text-sm text-slate-400 dark:text-slate-500 italic ml-2">
                    {toggles[provider] ? intl.toggleOn : intl.toggleOff}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}