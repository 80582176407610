import React, { createContext } from 'react';
import { TabsState } from './TabsTypes';
import { TabsAction, initialState } from './TabsActions';

// Creiamo il contesto con uno stato vuoto iniziale
export const TabsContext = createContext<{
  state: TabsState;
  dispatch: React.Dispatch<TabsAction>;
}>({
  state: initialState,
  dispatch: () => null,
});
