import React from 'react';
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { MessagesHeaderLocale } from '../../../../ReactIntl/LocaleInterfaces';

interface ChatbotAdminHeaderProps 
{
  msgSidebarOpen: boolean;
  setMsgSidebarOpen: (open: boolean) => void;
  chatbotName: string;
  chatbotIcon: string;
  isFloatingChatbot: boolean | undefined;
}

export const ChatbotAdminHeader: React.FC<ChatbotAdminHeaderProps> = ({
  msgSidebarOpen,
  setMsgSidebarOpen,
  chatbotName,
  chatbotIcon,
  isFloatingChatbot
}) => 
{
  const intl = getIntl<MessagesHeaderLocale>("messagesHeader");

  return (
    <div className="sticky top-0">
      <div className="flex items-center gap-1 bg-slate-50 dark:bg-[#161F32] border-b border-slate-200 dark:border-slate-700 px-4 sm:px-6 md:px-5 h-16">
        <button
          className="md:hidden text-slate-400 hover:text-slate-500 mr-4"
          onClick={() => setMsgSidebarOpen(!msgSidebarOpen)}
          aria-controls="messages-sidebar"
          aria-expanded={msgSidebarOpen}
        >
          <span className="sr-only">{intl.srOnlyCloseSidebar}</span>
          <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
          </svg>
        </button>
        <img src={`${chatbotIcon}`} alt="chatbot icon" className='w-12 h-12 mr-2' />
        <h2 className="text-xl font-bold text-slate-800 dark:text-slate-100">{chatbotName}</h2>
        {/* if floating chatbot icon */}
        {isFloatingChatbot && (
          <svg className="shrink-0 h-6 w-6" viewBox="0 -3 20 24">
            <path
              className='fill-current text-green-500 dark:text-slate-400'
              d="M11 1C5.477 1 1 4.582 1 9c0 1.797.75 3.45 2 4.785V19l4.833-2.416C8.829 16.85 9.892 17 11 17c5.523 0 10-3.582 10-8s-4.477-8-10-8z"
            />
          </svg>
        )}
      </div>
    </div>
  );
};
