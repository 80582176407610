import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";

interface ModalProps 
{
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const SimpleModal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => 
{

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => 
  {
    const handleOutsideClick = (event: MouseEvent) => 
    {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) 
      {
        onClose();
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => 
    {
      if (event.key === 'Escape') 
      {
        onClose();
      }
    };

    if (isOpen) 
    {
      // add small delay to prevent modal from closing immediately
      const timer = setTimeout(() => 
      {
        document.addEventListener("mousedown", handleOutsideClick);
      }, 100);

      document.addEventListener("keydown", handleEscapeKey);

      return () => 
      {
        clearTimeout(timer);
        document.removeEventListener("mousedown", handleOutsideClick);
        document.removeEventListener("keydown", handleEscapeKey);
      };
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <motion.div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      initial={{ opacity: 0, scale: 1.5}}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.3,
        delay: 0.2,
        ease: [0, 0.71, 0.2, 1.01]
      }}>
      <div ref={modalRef} className="bg-white dark:bg-slate-800 p-6 rounded-lg shadow-xl max-w-md w-full">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-200">
            {/* &times; */}
            <svg className="w-4 h-4 fill-current">
              <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
            </svg>
          </button>
        </div>
        {children}
      </div>
    </motion.div>
  );
};