import React from 'react';
import { IGenAICategory, IGenAIChatTemplate, IGenAIWriterTemplate, IGenAITemplate } from '../Api/Types/GenAIInterface';
import { LeafyGreen, Star, StarOff } from 'lucide-react';
import { GenAIItem, ViewProps } from '../Utils/GenAITypes';
import { parseTemplateMetadata, parseCategoryMetadata } from '../Api/Types/UtilityFunction';
import { GenAITemplateStatus } from '../Api/Types/GenAIEnums';
import { RoleBasedElement } from '../../../Api/Roles/RoleBasedElement';
import { Role } from '../../../Api/Roles/RoleManager';

const hasId = (item: GenAIItem): item is IGenAICategory | IGenAIChatTemplate | IGenAIWriterTemplate =>
  'id' in item;

const getItemKey = (item: GenAIItem): string =>
  hasId(item) ? item.id : `temp_${item.name}`;

const Card: React.FC<{
  item: GenAIItem;
  isCategory: boolean;
  isWriter: boolean;
  onDelete: (id: string) => void;
  onEdit: (item: GenAIItem) => void;
  onToggleFavorite: (id: string) => void;
  isFavorite: boolean;
  onChatbotSelect?: (chatbot: IGenAITemplate) => void;
  onWriterTemplateSelect?: (template: IGenAITemplate) => void;
}> = ({ item, isCategory, isWriter, onDelete, onEdit, onToggleFavorite, isFavorite, onChatbotSelect, onWriterTemplateSelect }) => 
{
  const isTemplate = !isCategory && 'data' in item && 'prompt' in item.data;
  const isChatTemplate = isTemplate && !isWriter;
  const actualStatus = (item as IGenAIChatTemplate | IGenAIWriterTemplate).status ?? null;
  const { templateColor, templateIcon } = parseTemplateMetadata(item);
  const { categoryColor, categoryIcon } = parseCategoryMetadata(item);

  // if is chat template, open to chatbot, else open to generator
  const handleOnCardClick = () => 
  {
    if (isChatTemplate) 
    {
      onChatbotSelect && onChatbotSelect(item as IGenAIChatTemplate);
    }
    else if (isWriter) 
    {
      onWriterTemplateSelect && onWriterTemplateSelect(item as IGenAIWriterTemplate);
    }
  };

  const renderStatusIndicator = () => 
  {
    if (!actualStatus) return null;

    const statusStyles = {
      [GenAITemplateStatus.Disabled]: 'bg-red-400',
      [GenAITemplateStatus.Unlisted]: 'bg-gray-400',
      [GenAITemplateStatus.Enabled]: 'bg-green-400',
    };

    const statusColor = statusStyles[actualStatus];

    return (
      <div
        className={`absolute right-0 top-1 rounded-full border border-black w-3 h-3 ${statusColor}`}
        title={actualStatus}
      />
    );
  };

  return (
    <div
      onClick={handleOnCardClick} // if user clicks on card, open to chatbot or generator
      className="col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative cursor-pointer h-full">
      {isTemplate && hasId(item) && (
        <button
          onClick={(e) => 
          {
            e.stopPropagation(); // needed so that the parent's onClick is not called
            onToggleFavorite(item.id);
          }}
          className="absolute top-2 z-10 right-2 text-yellow-500 hover:text-yellow-600 p-2" title={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
        >
          {isFavorite ? <Star size={24} fill="currentColor" /> : <StarOff size={24} />}
        </button>

      )}
      <div className='flex flex-col h-full'>

        <div className="p-4">
          <header className='flex items-center gap-4 mb-3'>
            <div
              className="w-12 h-12 rounded-full flex items-center justify-center relative"
              style={templateColor ? { backgroundColor: templateColor } : categoryColor ? { backgroundColor: categoryColor } : { backgroundColor: '#e6a4df' } }
            >
              {/* default to leafygreen if no templateIcon */}
              {templateIcon ? <div dangerouslySetInnerHTML={{ __html: templateIcon }} /> : categoryIcon ? <div dangerouslySetInnerHTML={{ __html: categoryIcon }} /> : <LeafyGreen />}

              {renderStatusIndicator()}
            </div>
            <h3 className="text-lg font-semibold">{item.name}</h3>
          </header>

          <p className="text-sm text-slate-600 dark:text-slate-400 mb-4">{item.description}</p>
        </div>
        <RoleBasedElement requiredRoles={Role.Admin}>
          <div className='border-t border-slate-200 dark:border-slate-700'>
            <div className='flex divide-x divide-slate-200 dark:divide-slate-700'>
              {hasId(item) && (
                <>
                  <button onClick={(e) => 
                  {
                    e.stopPropagation();
                    onEdit(item);
                  }
                  } className="block flex-1 text-center text-sm text-indigo-500 hover:bg-indigo-500 hover:text-white font-medium px-3 py-4">
                    Edit {isCategory ? 'Category' : 'Template'}
                  </button>
                  <button onClick={(e) => 
                  {
                    e.stopPropagation();
                    onDelete(item.id);
                  }
                  } className="block flex-1 text-center text-sm text-red-500 hover:bg-red-500 hover:text-white font-medium px-3 py-4">
                    Delete
                  </button>
                </>
              )}
            </div>
          </div>
        </RoleBasedElement>
      </div>
    </div>
  );
};

export const GenAICardView: React.FC<ViewProps> = ({
  items,
  isCategories,
  isWriterPage,
  onDelete,
  onEdit,
  onToggleFavorite,
  favoriteIds,
  onChatbotSelect,
  onWriterTemplateSelect
}) => 
{
  return (
    <div className="grid grid-cols-12 gap-4">
      {items.map((item) => (
        <Card
          key={getItemKey(item)}
          item={item}
          isCategory={isCategories}
          isWriter={isWriterPage}
          onDelete={onDelete}
          onEdit={onEdit}
          onToggleFavorite={onToggleFavorite}
          isFavorite={hasId(item) && favoriteIds.includes(item.id)}
          onChatbotSelect={onChatbotSelect}
          onWriterTemplateSelect={onWriterTemplateSelect}
        />
      ))}
    </div>
  );
};