import React, { useRef } from 'react';
import { TabsUlProps } from './Utils/TabsTypes';
import { useTabsContextState, useLocalTabsState, useUnderlinePosition } from './Utils/TabsService';
import { motion } from 'framer-motion';

export const TabsUl: React.FC<TabsUlProps & { useContextState?: boolean }> = ({
  children,
  showUnderline = false,
  useContextState = false,
}) => 
{
  // Scegliere quale hook usare in base alla prop `useContextState`
  const tabsContextState = useTabsContextState();
  const localTabsState = useLocalTabsState();
  const { activeTabIndex, activateTab } = useContextState ? tabsContextState : localTabsState;
  const tabsRef = useRef<HTMLUListElement>(null);

  // Usa la logica underline centralizzata nel servizio
  const underlineStyle = useUnderlinePosition(tabsRef, activeTabIndex, showUnderline);

  // Clona i figli aggiungendo props di `isActive` e `onClick`
  const childrenWithProps = React.Children.map(children, (child, index) => 
  {
    if (React.isValidElement(child)) 
    {
      return React.cloneElement(child, {
        ...child.props,
        isActive: index === activeTabIndex,
        onClick: () => 
        {
          activateTab(index);
          if (child.props.onClick) 
          {
            child.props.onClick(); // Chiama `onClick` se esiste sul figlio
          }
        },
      });
    }
    return child;
  });

  return (
    <div className="relative">
      <ul ref={tabsRef} className="relative text-sm font-medium flex overflow-x-scroll no-scrollbar">
        {childrenWithProps}
      </ul>
      {showUnderline && activeTabIndex !== -1 && (
        <motion.div
          className="absolute bottom-0 h-0.5 bg-indigo-500"
          animate={{
            width: underlineStyle.width,
            x: underlineStyle.x,
          }}
          initial={{
            width: underlineStyle.width,
            x: underlineStyle.x,
          }}
          transition={{ type: 'spring', stiffness: 500, damping: 30 }}
        />
      )}
    </div>
  );
};
