import { svgRegistry } from "./SvgRegistry";
import { cloneElement, FC } from "react";
import { IconName } from "./SvgRegistry.types";

interface IconProps 
{
  name: IconName;
  className?: string;
  fill?: string;
  stroke?: string;
  strokeWidth?: string;
  size?: string;
}

export const Icon: FC<IconProps> = ({ name, className, fill = "currentColor", stroke, strokeWidth, size = "24" }) => 
{
  const SvgIcon = svgRegistry[name];

  if (!SvgIcon) 
  {
    console.warn(`Icon "${name}" not found, check svgRegistry.tsx and eventually add your svg there`);
    return <p className="text-red-500 text-sm">Icon "{name}" not found, check svgRegistry.tsx and eventually add your svg there</p>;
  }

  return cloneElement(SvgIcon, {
    className,
    fill,
    stroke,
    strokeWidth,
    width: size,
    height: size
  });
};