import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import Image from '../../../../Images/user-avatar-80.png';
// import { useUserInfo } from '../../../../Hooks/UseUserInfo';
import { AccountSettingsPanelPhotoLocale } from '../../../../ReactIntl/LocaleInterfaces';
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { MainModal } from '../../../../Components/Actions/Modals/MainModal';
import { ModalContent } from '../../../../Components/Actions/Modals/ModalContent';
import { ModalBreak } from '../../../../Components/Actions/Modals/ModalBreak';
import { Button } from '../../../../Components/Actions/Buttons/Buttons';
import toast from 'react-hot-toast';
import { userController } from '../../../../Api/Controller/ApiUser';
import { useUser } from '../../../../Features/Auth/Hooks/UseSession';

export const PanelPhoto: React.FC = () => 
{
  const [image, setImage] = useState<string | ArrayBuffer | null>(Image);
  const [editorOpen, setEditorOpen] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(1);
  const [filter, _setFilter] = useState<string>('none');
  const editorRef = useRef<AvatarEditor>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useUser();

  // const { email, picture } = useUserInfo();
  // const pictureUrl = picture ? picture : Image
  const pictureUrl = Image;

  const intl = getIntl<AccountSettingsPanelPhotoLocale>('accountSettingsPanelPhoto');

  // Handles file drop event, reads and displays image in an editor
  const handleDrop = (acceptedFiles: File[]) => 
  {
    const reader = new FileReader();
    reader.onload = () => 
    {
      setImage(reader.result);
      setEditorOpen(true);
    };
    reader.readAsDataURL(acceptedFiles[0]);
  };

  // Handles saving the edited image to the server
  const handleSave = async () => 
  {
    setIsLoading(true);

    if (editorRef.current) 
    {
      editorRef.current.getImageScaledToCanvas().toBlob(async (blob) => 
      {
        if (blob) 
        {
          try 
          {
            const response = await userController.profilePicture(blob);
            if (response.data) 
            {
              toast.success(intl.toastSuccess);
            }
            else 
            {
              toast.error(intl.toastFail);
            }
          }
          catch (error) 
          {
            console.error('Error saving profile picture:', error);
            toast.error(intl.toastFail);
          }
          finally 
          {
            setIsLoading(false);
            setEditorOpen(false);
            // await refreshUserInfo();
          }
        }
      });
    }
  };

  return (
    <section className='pb-10 border-b border-gray-300 dark:border-gray-700'>
      <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">{intl.title}</h2>
      <p className="text-s text-slate-500 dark:text-slate-500 italic my-5">{user?.email ?? "Utente"}</p>
      <p className="text-xs text-gray-400 text-wrap mt-2">
        {intl.uploadMsg} <a href="https://gravatar.com" target="_blank" className="text-blue-500 underline">gravatar.com</a>
      </p>

      {/* Picture */}
      <section>
        <div className="flex flex-wrap items-center sm:justify-center gap-5 border rounded-lg border-gray-300 dark:border-gray-700 mt-3 ">
          <div className="">
            <img className="w-20 h-20 rounded-full" src={pictureUrl} width="80" height="80" alt="User upload" />
          </div>
          <div>
            <p className="text-xs mb-2">{intl.uploadMsgPicSection}</p>
            <Dropzone onDrop={handleDrop}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button
                    variant="primary"
                    size="m"
                    type="upload"
                  >
                    {intl.changeButton}
                  </Button>
                </div>
              )}
            </Dropzone>
            <p className="text-xs text-gray-400 text-wrap mt-2">
              {intl.idealImgSize}<br />{intl.maxImgDimensions}
            </p>
          </div>
        </div>
      </section>

      {/* Image editor modal */}
      {editorOpen && (
        <MainModal
          title={intl.modalTitle}
          setOpen={setEditorOpen}
        >
          <ModalBreak />
          <ModalContent>
            <div className="bg-white-800 dark:bg-black-800 rounded-lg flex flex-col items-center">
              <AvatarEditor
                ref={editorRef}
                image={image as string}
                width={250}
                height={250}
                border={20}
                scale={zoom}
                style={{ filter: filter }}
                className="mb-4"
              />
              <ModalBreak />
              <div className="flex flex-wrap gap-6 justify-between w-full">
                <div className="flex items-center">
                  <label className="mr-2 text-black dark:text-white">{intl.zoomLabel}:</label>
                  <input
                    type="range"
                    min="1"
                    max="3"
                    step="0.1"
                    value={zoom}
                    onChange={(e) => setZoom(parseFloat(e.target.value))}
                  />
                </div>
                <div className="flex">
                  <Button
                    variant="primary"
                    size="l"
                    type="upload"
                    onClick={handleSave}
                    loading={isLoading}
                  >
                    {intl.modalButton}
                  </Button>
                </div>
              </div>
            </div>
          </ModalContent>
        </MainModal>
      )}
    </section>
  );
};
