import React, { useEffect, useState, useCallback } from 'react';
import { Button } from '../../Buttons/Buttons';
import { ProgrammaticModalOptions } from './ProgModalTypes';
import { getAnimationStyle, renderIcon, handleConfirm } from './ProgModalService';

export const Modal = ({
  title,
  content,
  confirmText = 'Conferma',
  cancelText = 'Annulla',
  onConfirm,
  resolve,
  variant = 'primary',
  closeModal,
}: ProgrammaticModalOptions & { resolve: (value: boolean) => void; closeModal: () => void }) => 
{
  const [animationState, setAnimationState] = useState<'entering' | 'entered' | 'exiting'>('entering');
  const [isLoading, setIsLoading] = useState(false);

  // Funzione per gestire la conferma
  const confirmHandler = useCallback(() => 
  {
    handleConfirm(onConfirm, closeModal, resolve, setIsLoading, setAnimationState);
  }, [onConfirm, closeModal, resolve]);

  // Funzione per gestire l'annullamento
  const cancelHandler = useCallback(() => 
  {
    if (!isLoading) 
    {
      setAnimationState('exiting');
      setTimeout(() => 
      {
        resolve(false);
        closeModal();
      }, 200);
    }
  }, [closeModal, isLoading, resolve]);

  // Effetto per gestire l'animazione di ingresso
  useEffect(() => 
  {
    const timer = setTimeout(() => 
    {
      setAnimationState('entered');
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  // Effetto per gestire la chiusura tramite tasto Escape
  useEffect(() => 
  {
    const handleKeyDown = (event: KeyboardEvent) => 
    {
      if (event.key === 'Escape' && !isLoading) 
      {
        cancelHandler();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => 
    {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [cancelHandler, isLoading]);

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      role="dialog"
      aria-modal="true"
      onClick={(e) => e.target === e.currentTarget && !isLoading && cancelHandler()}
      style={{
        transition: 'opacity 0.3s ease-out',
        opacity: animationState === 'exiting' ? 0 : 1,
      }}
    >
      <div
        className="bg-white dark:bg-slate-800 p-6 rounded-lg shadow-lg w-full max-w-md flex items-start space-x-4"
        style={getAnimationStyle(animationState)}
      >
        <div>{renderIcon(variant)}</div>
        <div className="flex-1">
          {title && <h2 className="text-lg font-semibold mb-3 mt-1">{title}</h2>}
          <p className="text-base mb-8">{content}</p>
          <div className="flex justify-end space-x-2">
            <Button
              variant="tertiary"
              size="m"
              onClick={cancelHandler}
              disabled={isLoading}
              loading={isLoading}
            >
              {cancelText}
            </Button>
            <Button
              variant={variant === 'caution' ? 'danger' : variant}
              size="m"
              onClick={confirmHandler}
              disabled={isLoading}
              loading={isLoading}
            >
              {confirmText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};