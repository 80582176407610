import React, { useState, FormEvent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AuthImage from '../../Images/auth-image.jpg';
import AuthDecoration from '../../Images/auth-decoration.png';
import { Banner } from '../../Components/Banner';
import { getIntl, getLocale } from '../../ReactIntl/IntlConfig';
import { LanguageSelector } from '../../ReactIntl/LanguageSelector';
import { accountController } from '../../Api/Controller/ApiAccount';
import { ResetPasswordLocale } from '../../ReactIntl/LocaleInterfaces';
import { useError } from '../../Hooks/UseError';

export const ResetPassword: React.FC = () => 
{
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [bannerOpen, setBannerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [locale, setLocale] = useState<string>(getLocale());
  const intl = getIntl<ResetPasswordLocale>('resetPassword');
  const { isBlocked, retrySeconds } = useError();

  useEffect(() => 
  { 
    if(retrySeconds && isBlocked)
    {
      setErrorMessage(`Too many requests, wait ${retrySeconds} seconds and try again`);
      setBannerOpen(true);
    }
  }, [isBlocked, retrySeconds]);

  const handleResetPassword = async (event: FormEvent) => 
  {
    event.preventDefault();
    setIsLoading(true);

    try 
    {
      await accountController.sendPasswordResetLink(email);
      setSuccessMessage(intl.resetLinkSent);
    }
    catch (error) 
    {
      setErrorMessage(intl.invalidEmail);
    }
    finally 
    {
      setBannerOpen(true);
      setIsLoading(false);
    }
  };

  const handleLocaleChange = (newLocale: string) => { setLocale(newLocale); };

  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100vh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src="/beet-icon.svg" alt="Beet Root" className="mx-auto mb-4 my-16 w-16 h-16" />
                </Link>
                {/* Language selector */}
                <div className='flex place-content-end w-40'>
                  <LanguageSelector
                    selectedLanguage={locale}
                    onLanguageChange={handleLocaleChange}
                  />
                </div>
              </div>
            </div>

            <div className="max-w-sm mx-auto w-full px-4 py-8">
              <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">
                {intl.title}
              </h1>
              {/* Error banner */}
              {errorMessage && (
                <Banner className="mb-4" type="error" open={bannerOpen} setOpen={setBannerOpen}>
                  {errorMessage}
                </Banner>
              )}
              {/* Success banner */}
              {successMessage && (
                <Banner className="mb-4" type="success" open={bannerOpen} setOpen={setBannerOpen}>
                  {successMessage}
                </Banner>
              )}
              {/* Form */}
              <form onSubmit={handleResetPassword}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">
                      {intl.emailLabel}{' '}
                      <span className="text-rose-500">*</span>
                    </label>
                    <input
                      id="email"
                      className="form-input w-full"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white whitespace-nowrap"
                    disabled={isLoading}
                  >
                    {isLoading ?
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                      </svg>
                      : intl.sendResetLink}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Image */}
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
          <img className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block" src={AuthDecoration} width="218" height="224" alt="Authentication decoration" />
        </div>
      </div>
    </main>
  );
};
