import { AnimationCustomization, StyleCustomization } from "../../../Features/GenAI/ChatbotUtils/ChatbotTypes";

export const styleCustomization: StyleCustomization = {
  typingDotColor: '#b91c1c',
  loadingRingColor: '#b91c1c',
  thinkingBubbleBackgroundColor: 'bg-red-200',
  chatWindowFooter: 'bg-white',
  headerGradient: 'bg-gradient-to-r from-red-600 to-red-700',
  headerIconHoverColor: 'hover:text-red-200',
  bodyBackgroundColor: 'bg-white',
  aiBubbleColor: 'bg-red-200 text-black',
  humanBubbleColor: 'bg-red-300 text-black',
  footerTextareaFocusColor: 'focus:ring-red-600',
  scrollButtonColor: 'bg-red-600 text-white',
  scrollButtonHoverColor: 'hover:bg-red-900',
  speechToTextMicrophoneColor: 'text-white',
  speechToTextButtonColor: 'bg-red-600 text-white hover:bg-red-900',
  speechToTextButtonListeningColor: 'bg-red-800 text-white',
  speakerColor: 'text-white',
  speakerBackgroundColor: 'bg-red-600',
  speakerPlayingBackgroundColor: 'bg-red-900',
  sendButtonColor: 'bg-red-600 text-white',
  sendButtonHoverColor: 'hover:bg-red-900'
};

export const animationCustomization: { icon: AnimationCustomization, window: AnimationCustomization } = {
  icon: {
    initial: { scale: 0.6, opacity: 0 },
    animate: { scale: 1, opacity: 1, y: -10 },
    transition: { type: 'spring', stiffness: 200, damping: 25 }
  },
  window: {
    initial: { opacity: 0, scale: 0.5, translateY: '50%', translateX: '50%' },
    animate: { opacity: 1, scale: 1, translateY: '0%', translateX: '0%' },
    exit: { opacity: 0, scale: 0.5, translateY: '50%', translateX: '100%' },
    transition: { type: 'spring', stiffness: 300, damping: 30 }
  }
};
