import React, { useCallback, useState, useMemo } from 'react';
import { IGenAIWriterTemplate, IUserInput } from '../Api/Types/GenAIInterface';
import { GenAITemplateInputKind } from '../Api/Types/GenAIEnums';
import { GenAITemplateSendRequest } from '../Api/Types/GenAIRequest';
import { useGenAI } from '../Hooks/UseGenAI_';
import { GenAIActions } from '../Utils/GenAIActions';
import { GenAIService } from '../Utils/GenAIService';
import { ProviderSelector } from '../Provider/ProviderSelector';

interface AIFormGeneratorProps 
{
  templateData: IGenAIWriterTemplate;
  onMarkdownGenerated: (markdown: string) => void;
}

export const AIFormGenerator: React.FC<AIFormGeneratorProps> = ({
  templateData,
  onMarkdownGenerated,
}) => 
{
  const { state, dispatch } = useGenAI();
  const actions = useMemo(() => new GenAIActions(dispatch), [dispatch]);
  const service = useMemo(() => new GenAIService(), []);

  const [formData, setFormData] = useState<GenAITemplateSendRequest>({
    templateId: templateData.id,
    language: 'en',
    toneOfVoice: 'professional',
    input: templateData.data.inputs.map(input => ({ name: input.name, value: '' })),
    maxTokens: 300,
    temperature: 1,
    streamResponse: false,
  });

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => 
    {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: name === 'maxTokens' ? parseInt(value) : name === 'temperature' ? parseFloat(value) : value,
      }));
    },
    []
  );

  const handleTemplateInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => 
    {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        input: prev.input.map((input) =>
          input.name === name ? { ...input, value } : input
        ),
      }));
    },
    []
  );

  const handleSubmit = async (e: React.FormEvent) => 
  {
    e.preventDefault();
    actions.fetchStart();

    try 
    {
      const response = await service.sendWriter(formData);
      if (response?.message) 
      {
        onMarkdownGenerated(response.message);
        actions.setWriterResponse(response);
      }
      actions.fetchSuccess([]);
    }
    catch (error) 
    {
      const errorMessage = error instanceof Error ? error.message : 'Failed to generate content';
      actions.fetchError(errorMessage);
    }
  };

  const renderTemplateInput = useCallback(
    (input: IUserInput) => 
    {
      const formInput = formData.input.find((i) => i.name === input.name) || { name: input.name, value: '' };

      switch (input.kind) 
      {
        case GenAITemplateInputKind.TextArea:
          return (
            <div key={input.name} className="space-y-2">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {input.name}
              </label>
              <textarea
                name={input.name}
                value={formInput.value}
                onChange={handleTemplateInputChange}
                placeholder={input.description}
                className="w-full rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 p-2"
                rows={4}
              />
            </div>
          );

        case GenAITemplateInputKind.Dropdown:
          return (
            <div key={input.name} className="space-y-2">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {input.name}
              </label>
              <select
                name={input.name}
                value={formInput.value}
                onChange={handleTemplateInputChange}
                className="w-full rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 p-2"
              >
                <option value="" disabled>Select an option</option>
                {input.possibleValues?.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          );

        default:
          return (
            <div key={input.name} className="space-y-2">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {input.name}
              </label>
              <input
                type={input.kind === GenAITemplateInputKind.Number ? 'number' : 'text'}
                name={input.name}
                value={formInput.value}
                onChange={handleTemplateInputChange}
                placeholder={input.description}
                className="w-full rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 p-2"
              />
            </div>
          );
      }
    },
    [formData.input, handleTemplateInputChange]
  );

  return (
    <form onSubmit={handleSubmit} className="space-y-6 bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
      {/* Template inputs */}
      {templateData.data.inputs.map(renderTemplateInput)}

      {/* AI Configuration */}
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">AI Configuration</h3>

        <ProviderSelector />

        {/* Generation Settings */}
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Max Tokens
            </label>
            <input
              type="number"
              name="maxTokens"
              value={formData.maxTokens}
              onChange={handleInputChange}
              min={1}
              max={4000}
              className="w-full rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 p-2"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Temperature
            </label>
            <input
              type="number"
              name="temperature"
              value={formData.temperature}
              onChange={handleInputChange}
              min={0}
              max={2}
              step={0.1}
              className="w-full rounded-md border border-gray-300 dark:border-gray-600 dark:bg-gray-700 p-2"
            />
          </div>
        </div>

        {/* Additional Settings */}
        <div className="space-y-2">
          <div className="flex items-center">
            <input
              type="checkbox"
              name="streamResponse"
              checked={formData.streamResponse}
              onChange={(e) => setFormData((prev) => ({ ...prev, streamResponse: e.target.checked }))}
              className="rounded border-gray-300 dark:bg-gray-700"
            />
            <label className="ml-2 text-sm text-gray-700 dark:text-gray-300">
              Stream Response
            </label>
          </div>
        </div>
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        disabled={state.isLoading}
        className="w-full bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded-md disabled:bg-gray-400"
      >
        {state.isLoading ? 'Generating...' : 'Generate Content'}
      </button>
    </form>
  );
};
