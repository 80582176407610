// TabsActions.ts
import { TabsState } from './TabsTypes';

export type TabsAction = { type: 'SET_ACTIVE_TAB'; payload: number };

// Stato iniziale
export const initialState: TabsState = {
  activeTabIndex: 0,
};

// Reducer per gestire le azioni
export function tabsReducer(state: TabsState, action: TabsAction): TabsState 
{
  switch (action.type) 
  {
    case 'SET_ACTIVE_TAB':
      return { ...state, activeTabIndex: action.payload };
    default:
      return state;
  }
}
