import React, { forwardRef } from 'react';
import { ButtonProps } from './Utils/ButtonTypes';
import { buttonConfig, variantStyles, sizeStyles, groupPositionStyles } from './Utils/ButtonService';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      variant = 'primary',
      size = 'l',
      disabled = false,
      icon,
      loading = false,
      children,
      type,
      hideOnMobile,
      groupPosition = 'single',
      selected = false,
      hideText = false,
      className,
      buttonType = 'button',
      onClick,
      stopPropagation = false,
      title,
    },
    ref
  ) => 
  {

    // La funzione `handleClick` è specifica per la gestione degli eventi di click su questo bottone.
    // Viene lasciata qui invece di essere spostata in un file di servizio perché dipende direttamente
    // dalle props (`stopPropagation`, `onClick`) e gestisce la logica del ciclo di vita del bottone.
    // Questo approccio aiuta a mantenere la semplicità e la leggibilità del componente.

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => 
    {
      if (stopPropagation) 
      {
        e.stopPropagation(); // Blocca la propagazione se la prop è attiva
      }
      if (onClick) 
      {
        onClick(e); // Esegue la funzione onClick passata dall'utente
      }
    };

    // La logica di `selectedStyles` dipende strettamente dallo stato e dalle props del bottone (`selected`, `variant`).
    // È meglio lasciarla nel componente principale per mantenere la leggibilità e la coerenza con la logica di rendering,
    // evitando di dover passare troppe dipendenze a una funzione esterna.

    const selectedStyles = selected
      ? variant === 'primary'
        ? 'bg-indigo-700'
        : variant === 'tertiary'
          ? 'bg-slate-50 dark:bg-slate-900 text-indigo-500'
          : ''
      : '';

    // Similmente, le variabili `currentGroupPositionStyles`, `currentSizeStyles`, e `currentVariantStyles`
    // vengono calcolate qui per assicurare che gli stili siano sempre sincronizzati con le props del componente.

    const currentGroupPositionStyles = groupPositionStyles[groupPosition];
    const currentSizeStyles = sizeStyles[size];
    const currentVariantStyles = variantStyles[variant];
    const baseStyles = `btn flex items-center justify-center ${currentGroupPositionStyles} ${currentSizeStyles} ${currentVariantStyles} ${selectedStyles}`;

    // Le classi `iconClasses` e `labelClasses` vengono calcolate dinamicamente in base alle props `hideOnMobile`,
    // `icon`, `hideText`, e altre. La logica è strettamente legata alle props specifiche del bottone,
    // quindi rimane all'interno del componente per mantenere leggibilità e semplicità.

    // Otteniamo l'icona e il testo associati al tipo di bottone
    const { icon: typeIcon, label } =
      type && buttonConfig[type] ? buttonConfig[type] : { icon: null, label: children };

    // Calcoliamo le classi per l'icona
    const iconClasses = [
      hideOnMobile === 'icon' ? 'hidden sm:block' : 'block',
      (icon || typeIcon) && (children || label) ? (hideText ? 'mr-0' : hideOnMobile === 'label' ? 'mr-0 sm:mr-1.5' : 'mr-1.5') : '',
    ].join(' ');

    // Calcoliamo le classi per la label
    const labelClasses = hideText
      ? 'hidden' // Nasconde sempre la label se hideText è true
      : hideOnMobile === 'label'
        ? 'hidden sm:block'
        : 'block';

    const paddingClass = (icon || typeIcon) && (children || label) && !hideText
      ? hideOnMobile === 'label'
        ? 'px-3 sm:px-3'
        : 'px-3'
      : hideText
        ? 'px-2.5'
        : '';

    return (
      <button
        id={id}
        ref={ref}
        type={buttonType}
        onClick={handleClick}
        className={`${baseStyles} ${paddingClass} ${disabled ? 'disabled:bg-slate-100 dark:disabled:bg-slate-800 disabled:text-slate-400 dark:disabled:text-slate-600 disabled:cursor-not-allowed shadow-none' : ''} ${className ? className : ''}`}
        disabled={disabled}
        title={
          title // Priorità al title personalizzato
            ? title
            : (hideText || hideOnMobile === 'label') && type && buttonConfig[type]?.label
              ? buttonConfig[type].label
              : undefined
        }
      >
        <>
          {loading ? (
            <svg className="animate-spin w-4 h-4 fill-current shrink-0 mr-2" viewBox="0 0 16 16">
              <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
            </svg>
          ) : (
            <span className={iconClasses}>{icon || typeIcon}</span>
          )}
          <span className={labelClasses}>{loading ? children || 'Loading' : children || label}</span>
        </>
      </button>
    );
  }
);
