import React, { useState, useEffect, useMemo } from 'react';
import { UserListTableItems } from './UserListTableItems';
import { UserDto } from '../../../../Utils/User.types';
import { UserListTableLocale } from '../../../../ReactIntl/LocaleInterfaces';
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { ListPlaceholder } from '../../../../Components/Placeholder';

interface UserListTableProps 
{
  selectedItems: (items: string[]) => void;
  users: UserDto[];
  totalResults: number;
  currentPage: number;
  itemsPerPage: number;
  sortField: string;
  sortOrder: 'asc' | 'desc';
  isLoading: boolean;
}

export const UserListTable: React.FC<UserListTableProps> = ({
  users,
  totalResults,
  isLoading
}) => 
{
  const [sortedColumn, setSortedColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [list, setList] = useState<UserDto[]>([]);

  const intl = getIntl<UserListTableLocale>("userListTable");

  const mappedUsers = useMemo(() => 
  {
    return users.map((user: UserDto) => ({
      sub: user.sub,
      role: user.role,
      picture: user.picture,
      fullName: `${user.givenName} ${user.familyName}`,
      givenName: user.givenName,
      familyName: user.familyName,
      email: user.email,
      location: `${user.locality}, ${user.region}, ${user.country}`,
      region: user.region,
      streetAddress: user.streetAddress,
      locality: user.locality,
      country: user.country,
      postalCode: user.postalCode,
      createdAt: user.createdAt,
      orders: '0',
      lastOrder: '-',
      spent: '$0.00',
      refunds: '0',
      fav: false
    }));
  }, [users]);

  useEffect(() => 
  {
    setList(mappedUsers);
  }, [mappedUsers]);

  const handleClickHeader = (columnName: string) => 
  {
    const newSortOrder = columnName === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortedColumn(columnName);
    setSortOrder(newSortOrder);

    const event = new CustomEvent('sortColumn', { detail: { columnName, sortOrder: newSortOrder } });
    window.dispatchEvent(event);
  };

  const TableHeader: React.FC<{ children: React.ReactNode, type: 'left' | 'right' | 'center' | 'sr-only', columnName: string, hideOnMobile?: boolean }> = ({ children, type, columnName, hideOnMobile = false }) => 
  {
    const className = type === 'left' ? 'text-left' : type === 'right' ? 'text-right' : type === 'center' ? 'text-center' : 'sr-only';
    const hiddenClass = hideOnMobile ? 'hidden sm:table-cell' : '';
    const isSorted = sortedColumn === columnName;
    const arrowIcon = isSorted ? (sortOrder === 'asc' ? '▲' : '▼') : '';
    const sortedClassName = isSorted ? 'text-red-500' : '';

    return (
      <th
        className={`px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap cursor-pointer ${sortedClassName} ${hiddenClass}`}
        onClick={() => handleClickHeader(columnName)}
      >
        <div className={className}>
          {children} {arrowIcon}
        </div>
      </th>
    );
  };

  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {intl.allUsers} <span className="text-slate-400 dark:text-slate-500 font-medium">{totalResults}</span>
        </h2>
      </header>
      <div>
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300">
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <TableHeader type="sr-only" columnName="">{intl.srOnlyMenu}</TableHeader>
                <TableHeader type="center" columnName="info.givenName">{intl.headerName}</TableHeader>
                <TableHeader type="center" columnName="info.familyName">{intl.headerLastName}</TableHeader>
                <TableHeader type="center" columnName="email">{intl.headerEmail}</TableHeader>
                <TableHeader type="center" columnName="info.country">{intl.headerLocation}</TableHeader>
                <TableHeader type="sr-only" columnName="">{intl.srOnlyMenu}</TableHeader>
                <TableHeader type="sr-only" columnName="">{intl.srOnlyMenu}</TableHeader>
              </tr>
            </thead>
            <tbody className="text-sm divide-slate-200 dark:divide-slate-700">
              {isLoading ? (
                <tr>
                  <td colSpan={7} className="p-0">
                    <ListPlaceholder count={list.length} />
                  </td>
                </tr>
              ) : (
                list.map(customer => (
                  <UserListTableItems
                    key={customer.sub}
                    {...customer}
                    handleClick={() => { }}
                    isChecked={false}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
