import React from 'react';
import { TabsUl } from '../Actions/Tabs/TabsUl';
import { TabsLi } from '../Actions/Tabs/TabsLi';
export function TabsPage() 
{
  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900">

        {/* Site header */}

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-8">
              <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Tabs ✨</h1>
            </div>

            <div className="border-t border-slate-200 dark:border-slate-700">

              {/* Components */}
              <div className="space-y-8 mt-8">
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Default Tab</h2>
                <TabsUl>
                  {["Account", "Notifications", "Integrations", "Plans", "Billing"].map((tab, index) => (
                    <TabsLi
                      key={index}
                      label={tab}
                    />
                  ))}
                </TabsUl>

                {/* With Underline */}
                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">With showUnderline</h2>

                  <TabsUl showUnderline>
                    {["Account", "Notifications", "Integrations", "Plans", "Billing"].map((tab, index) => (
                      <TabsLi
                        key={index}
                        label={tab}
                      />
                    ))}
                  </TabsUl>

                </div>

                {/* With Icons */}
                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">With Icons</h2>

                  <TabsUl>
                    {[
                      {
                        name: "Account",
                        icon: "M12.311 9.527c-1.161-.393-1.85-.825-2.143-1.175A3.991 3.991 0 0012 5V4c0-2.206-1.794-4-4-4S4 1.794 4 4v1c0 1.406.732 2.639 1.832 3.352-.292.35-.981.782-2.142 1.175A3.942 3.942 0 001 13.26V16h14v-2.74c0-1.69-1.081-3.19-2.689-3.733zM6 4c0-1.103.897-2 2-2s2 .897 2 2v1c0 1.103-.897 2-2 2s-2-.897-2-2V4zm7 10H3v-.74c0-.831.534-1.569 1.33-1.838 1.845-.624 3-1.436 3.452-2.422h.436c.452.986 1.607 1.798 3.453 2.422A1.943 1.943 0 0113 13.26V14z",
                      },
                      {
                        name: "Notifications",
                        icon: "M14.3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-8 8c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l8-8zM15 7c.6 0 1 .4 1 1 0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8c.6 0 1 .4 1 1s-.4 1-1 1C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6c0-.6.4-1 1-1z",
                      },
                      {
                        name: "Integrations",
                        icon: "M3.414 2L9 7.586V16H7V8.414l-5-5V6H0V1a1 1 0 011-1h5v2H3.414zM15 0a1 1 0 011 1v5h-2V3.414l-3.172 3.172-1.414-1.414L12.586 2H10V0h5z",
                      },
                      {
                        name: "Plans",
                        icon: "M5 9h11v2H5V9zM0 9h3v2H0V9zm5 4h6v2H5v-2zm-5 0h3v2H0v-2zm5-8h7v2H5V5zM0 5h3v2H0V5zm5-4h11v2H5V1zM0 1h3v2H0V1z",
                      },
                      {
                        name: "Billing",
                        icon: "M15 4c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3h7c.6 0 1 .4 1 1v3h4zM2 3v1h7V2H3c-.6 0-1 .4-1 1zm12 11V6H2v7c0 .6.4 1 1 1h11zm-3-5h2v2h-2V9z",
                      },
                    ].map((tab, index) => (
                      <TabsLi
                        key={index}
                        label={tab.name}
                        icon={tab.icon}
                      />
                    ))}
                  </TabsUl>

                </div>

                {/* With showUnderline and Icons */}
                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">With showUnderline and Icons</h2>
                  <TabsUl showUnderline>
                    {[
                      {
                        name: "Account",
                        icon: "M12.311 9.527c-1.161-.393-1.85-.825-2.143-1.175A3.991 3.991 0 0012 5V4c0-2.206-1.794-4-4-4S4 1.794 4 4v1c0 1.406.732 2.639 1.832 3.352-.292.35-.981.782-2.142 1.175A3.942 3.942 0 001 13.26V16h14v-2.74c0-1.69-1.081-3.19-2.689-3.733zM6 4c0-1.103.897-2 2-2s2 .897 2 2v1c0 1.103-.897 2-2 2s-2-.897-2-2V4zm7 10H3v-.74c0-.831.534-1.569 1.33-1.838 1.845-.624 3-1.436 3.452-2.422h.436c.452.986 1.607 1.798 3.453 2.422A1.943 1.943 0 0113 13.26V14z",
                      },
                      {
                        name: "Notifications",
                        icon: "M14.3.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-8 8c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l8-8zM15 7c.6 0 1 .4 1 1 0 4.4-3.6 8-8 8s-8-3.6-8-8 3.6-8 8-8c.6 0 1 .4 1 1s-.4 1-1 1C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6c0-.6.4-1 1-1z",
                      },
                      {
                        name: "Integrations",
                        icon: "M3.414 2L9 7.586V16H7V8.414l-5-5V6H0V1a1 1 0 011-1h5v2H3.414zM15 0a1 1 0 011 1v5h-2V3.414l-3.172 3.172-1.414-1.414L12.586 2H10V0h5z",
                      },
                      {
                        name: "Plans",
                        icon: "M5 9h11v2H5V9zM0 9h3v2H0V9zm5 4h6v2H5v-2zm-5 0h3v2H0v-2zm5-8h7v2H5V5zM0 5h3v2H0V5zm5-4h11v2H5V1zM0 1h3v2H0V1z",
                      },
                      {
                        name: "Billing",
                        icon: "M15 4c.6 0 1 .4 1 1v10c0 .6-.4 1-1 1H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3h7c.6 0 1 .4 1 1v3h4zM2 3v1h7V2H3c-.6 0-1 .4-1 1zm12 11V6H2v7c0 .6.4 1 1 1h11zm-3-5h2v2h-2V9z",
                      },
                    ].map((tab, index) => (
                      <TabsLi
                        key={index}
                        label={tab.name}
                        icon={tab.icon}
                      />
                    ))}
                  </TabsUl>
                </div>

                {/* With Container */}
                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Variant pill</h2>

                  <TabsUl>
                    {["Account", "Notifications", "Integrations", "Plans", "Billing"].map((tab, index) => (
                      <TabsLi
                        variant='pill'
                        key={index}
                        label={tab}
                      />
                    ))}
                  </TabsUl>

                </div>

              </div>

            </div>

          </div>
        </main>

      </div>

    </div>
  );
}
