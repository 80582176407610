import { Loader } from 'lucide-react';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthorizationConsent } from '../Api/AuthorizationConsent';
import { ExternalLoginCallback } from '../Api/ExternalLoginCallback';
// import { LoginCallback } from '../Api/LoginCallback';
import { ChangePassword } from '../App/Auth/ChangePassword';
import { ResetPassword } from '../App/Auth/ResetPassword';
import { Signin } from '../App/Auth/Signin';
import { Signup } from '../App/Auth/Signup';
// import { useUserInfo } from '../Hooks/UseUserInfo';
import { PageNotFound } from '../Pages/Utility/PageNotFound';
import { LoginForm } from '../Features/Auth/Components/LoginForm';
import { useUser } from '../Features/Auth/Hooks/UseSession';

export const PublicRoutes: React.FC = () => 
{
  // const { email } = useUserInfo();
  const user = useUser();
  if (user?.email) 
  {
    return <Navigate to="/" />;
  }

  return (
    <Routes>
      <Route path="/provaLog" element={<LoginForm />} /> {/* TEST con login donatello  */}
      {/* <Route path="/login-callback" element={<LoginCallback />} /> */}
      <Route path="/authorize" element={<AuthorizationConsent />} />
      <Route path="/login" element={<Signin />} />
      <Route path="/register" element={<Signup />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/loader" element={<Loader />} />
      {/* <Route path="/external-login-callback" element={<ExternalLoginCallback />} /> */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
