import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Phone, MapPin, Mail, Link } from 'lucide-react';
import { TextToSpeech } from '../../BotTools/TextToSpeech';
import  WhatsappIcon  from '../../../../Images/SocialIcon/whatsapp.svg';
import { ChatbotStyles } from '../CommonStyles/ChatbotStyles';
import { GenAIMessageRole } from '../../Api/Types/GenAIEnums';
import { GenAIChatMessage } from '../../Api/Types/GenAIInterface';
 
interface MessageContentProps 
{
  message: GenAIChatMessage;
  isAssistant: boolean;
  loading: boolean;
  onSelection?: (option: string) => void;
  style: typeof ChatbotStyles;
}

export const MessageContent: React.FC<MessageContentProps> = ({
  message,
  isAssistant,
  loading,
  onSelection,
  style,
}) => 
{
  // Unique style button
  const renderButton = (onClick: () => void, text: string, icon: React.ReactNode) => (
    <button
      onClick={onClick}
      className="flex items-center justify-center px-3 py-2 bg-gradient-to-r from-blue-400 to-orange-600 text-white rounded-lg shadow-lg transition-colors duration-300 hover:from-orange-500 hover:to-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-opacity-50 text-sm w-full"
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
      disabled={loading}
    >
      {icon && <span className="mr-2">{icon}</span>}
      <span className="truncate">{text}</span>
    </button>
  );
  
  // Extract contacts from the message, assistants prompt MUST specify to put the value as this: {{ value }}
  const extractContacts = (text: string) => 
  {
    const linkRegex = /(https?:\/\/[^}]+)/g;
    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
    const addressRegex = /(Via [^}]+)/g;
    const phoneRegex = /(\+?\d[\d -]{7,})/g;

    return {
      phone: text.match(phoneRegex)?.[0]?.trim() || null,
      email: text.match(emailRegex)?.[0]?.trim() || null,
      address: text.match(addressRegex)?.[0]?.trim()  || null ,
      link: text.match(linkRegex)?.[0]?.trim() || null,
    };
  };
  
  const renderContent = () => 
  {
    const formattedMessage = message.message;    
    if (formattedMessage === undefined) 
    {
      return null;
    }
    const parts = formattedMessage.split(/\s*({{\s*[^}]+\s*}})\s*/g);

    const renderedMessage = parts.map((part, index, items) => 
    {
       
      if (message.role == GenAIMessageRole.Assistant)
      {
        if (part.startsWith("{{") && part.endsWith("}}"))
        {
          let trimmedText = part.trim().replace(/{{\s*|\s*}}/g, '');

          const contacts = extractContacts(trimmedText);
          if (contacts.phone) 
          {
            if (items[index - 1]?.toLowerCase()?.includes("whatsapp"))
            {
              return renderButton(() => window.open(`https://wa.me/${contacts.phone}`, '_blank'), contacts.phone!, <img src={WhatsappIcon} alt="WhatsApp" className="inline-block w-4 h-4 text-white" />);
            }
            else
            {
              return renderButton(() => window.open(`tel:${contacts.phone}`, '_self'), contacts.phone!, <Phone size={16} className="inline-block" />);
            }
          }
          if (contacts.email) 
          {
            return renderButton(() => window.open(`mailto:${contacts.email}`, '_self'), contacts.email!, <Mail size={16} className="inline-block" />);
          }
          if (contacts.address) 
          {
            return renderButton(() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(contacts.address as string)}`, '_blank'), contacts.address!, <MapPin size={16} className="inline-block" />);
          }
          if (contacts.link)
          {
            return renderButton(() => window.open(contacts.link as string, '_blank'), contacts.link!, <Link size={16} className="inline-block" />);
          }

          return <ReactMarkdown key={index}>{"**" + trimmedText + "**"}</ReactMarkdown>;
        }

        part = part.replace(/^[.,:;!?]\s*\n/, '');
      }

      return <ReactMarkdown key={index}>{part}</ReactMarkdown>;
    });

    if (isAssistant && message.isSelectionPrompt && message.options) 
    {
      return (
        <>
          <ReactMarkdown>{formattedMessage}</ReactMarkdown>
          {message.options.map((option, index) => (
            <button
              key={index}
              onClick={() => onSelection && onSelection(option)}
              className="flex items-center justify-center w-full text-left p-2 my-1 bg-gradient-to-r from-orange-400 to-orange-600 text-white rounded-lg shadow-md transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
              disabled={loading}
            >
              {option}
            </button>
          ))}
        </>
      );
    }

    return <div className="mb-2" key={0}>{renderedMessage}</div>;
  };

  useEffect(() => 
  {
    const links = document.querySelectorAll('a');    
    if (links.length > 0) 
    {
      const firstLink = links[0];
      const previousSibling = firstLink.previousSibling;
  
      if (!(previousSibling instanceof HTMLBRElement)) 
      {
        const br = document.createElement('br');
        firstLink.parentNode?.insertBefore(br, firstLink);
      }
    }
  
    links.forEach(link => 
    {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
      
      link.style.display = 'block'; 
      link.style.textAlign = 'center';
      link.style.margin = '0 auto'; 
      link.style.width = 'fit-content';
    });
    
    const elements = document.querySelectorAll('button, a');

    elements.forEach(element => 
    {
      const nextSibling = element.nextSibling;
      if (nextSibling && nextSibling.nodeType === Node.TEXT_NODE) 
      {
        nextSibling.textContent = nextSibling.textContent?.replace(/^\s*\./, '') ?? null;
      }
    
      const nextElement = element.nextElementSibling;
      if (nextElement && nextElement.tagName === 'P') 
      {
        nextElement.textContent = nextElement.textContent?.replace(/^\s*\./, '') ?? '';
      }
    });
  }, []);
  
  // Speaker Icon
  return (
    <>
      {isAssistant && (
        <div className="absolute top-[-1.2rem] right-[-3rem] mt-2 mr-2 z-10">
          <TextToSpeech
            text={message.message}
            buttonBackgroundColor={style.SpeakerBackgroundColor}
            buttonPlayingBackgroundColor={style.SpeakerPlayingBackgroundColor}
            speakerColor={style.SpeakerColor}
            buttonSize={style.TextToSpeechButtonSize}
          />
        </div>
      )}
      {renderContent()}
    </>
  );
};
