import React, { useState, useRef, useEffect } from 'react';
import { TokenUsageDialogue } from './TokenUsageDialogue';
import { ChatWindowFooter } from './ChatWindowFooter';
import { ChatWindowHeader } from './ChatWindowHeader';
import { ChatWindowBody } from './ChatWindowBody';
import { ChatbotStyles } from '../CommonStyles/ChatbotStyles';

import { ChevronDown } from 'lucide-react';
import { ChatSettingsWindow } from './ChatSettingsWindow';
import { GenAIChatMessage } from '../../Api/Types/GenAIInterface';
interface ChatWindowProps 
{
  onSend: (message: string) => void;
  onClose: () => void;
  onResetChat: () => void;
  messages: GenAIChatMessage[];
  loading: boolean;
  chatId: string;
  chatbotName: string;
  chatbotIcon: string;
  errorMessage: string;
  style: typeof ChatbotStyles;
  onSelection?: (selection: string) => void;
  blockMessage: boolean;
}

export const ChatWindow: React.FC<ChatWindowProps> = ({
  onSend,
  onResetChat,
  onClose,
  onSelection,
  loading,
  chatbotIcon,
  chatbotName,
  messages,
  errorMessage,
  chatId,
  style,
  blockMessage,
}) => 
{
  const [input, setInput] = useState<string>('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [showTokenUsage, setShowTokenUsage] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const MAX_CHARACTERS = 1000;

  useEffect(() => 
  {
    if (messagesEndRef.current && chatContainerRef.current) 
    {
      const messagesEnd = messagesEndRef.current;
      messagesEnd.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => 
  {
    const newInput = e.target.value.slice(0, MAX_CHARACTERS);
    setInput(newInput);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => 
  {
    if (textareaRef.current) 
    {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 120)}px`;
    }
  };

  const handleSend = async () => 
  {
    if (input.trim()) 
    {
      onSend(input);
      setInput('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => 
  {
    if (!blockMessage)
    {
      if (e.key === 'Enter' && !e.shiftKey) 
      {
        e.preventDefault();
        handleSend();
      }
    }
  };

  const handleVoiceInput = (text: string) => 
  {
    setInput(prevInput => prevInput + ' ' + text);
  };

  const toggleSettings = () => 
  {
    setShowSettings(!showSettings);
  };

  const toggleTokenUsage = () => 
  {
    setShowTokenUsage(!showTokenUsage);
  };

  const handleScrollCheck = (needsScrollButton: boolean) => 
  {
    setShowScrollButton(needsScrollButton);
  };

  const scrollToBottom = () => 
  {
    if (chatContainerRef.current) 
    {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={style.ChatWindow}>
      {/* Header */}
      <ChatWindowHeader
        handleResetChat={onResetChat}
        onClose={onClose}
        toggleSettings={toggleSettings}
        toggleTokenUsage={toggleTokenUsage}
        chatId={chatId ?? ''}
        chatbotName={chatbotName}
        style={style}
      />
      {showTokenUsage ? (
        <TokenUsageDialogue
          chatId={chatId ?? ''}
          onClose={toggleTokenUsage}
          chatbotIcon={chatbotIcon ?? ''}
        />
      ) : showSettings ? (
        <ChatSettingsWindow 
          onClose={toggleSettings} 
        />
      ) : (
        <ChatWindowBody
          messages={messages}
          chatbotIcon={chatbotIcon ?? ''}
          chatContainerRef={chatContainerRef}
          loading={loading}
          errorMessage={errorMessage ?? null}
          onSelection={onSelection ?? undefined}
          style={style}
          onSend={onSend}
          onScrollCheck={handleScrollCheck}
        />
      )}
      <ChatWindowFooter
        input={input}
        handleInputChange={handleInputChange}
        handleKeyDown={handleKeyDown}
        handleSend={handleSend}
        handleVoiceInput={handleVoiceInput}
        loading={loading}
        pendingMessage={input} 
        textareaRef={textareaRef} 
        blockMessage={blockMessage}
        style={style}
      />
      {/* Pulsante Scroll, che rimane fisso all'interno del chatbot */}
      {showScrollButton && (
        <button
          onClick={scrollToBottom}
          className={style.ScrollButton}
          aria-label="Scroll to bottom"
        >
          <ChevronDown size={24} />
        </button>
      )}
    </div>
  );
};