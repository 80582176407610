import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { GenAIProvider } from "../Features/GenAI/Utils/GenAIContext";
import { Inbox, Calendar } from "lucide-react";
import { Dashboard } from "../App/Dashboard/Dashboard";
import { Account } from "../App/UserSettings/Account";
import { Apps } from "../App/UserSettings/Apps";
import { Authentication } from "../App/UserSettings/Authentication";
import { Billing } from "../App/UserSettings/Billing";
import { Feedback } from "../App/UserSettings/Feedback";
import { Notifications } from "../App/UserSettings/Notifications";
import { Plans } from "../App/UserSettings/Plans";
import { AccordionPage } from "../Components/Component/AccordionPage";
import { AlertPage } from "../Components/Component/AlertPage";
import { AvatarPage } from "../Components/Component/AvatarPage";
import { BadgePage } from "../Components/Component/BadgePage";
import { BreadcrumbPage } from "../Components/Component/BreadcrumbPage";
import ButtonPage from "../Components/Component/ButtonPage";
import { DropdownPage } from "../Components/Component/DropdownPage";
import { FormPage } from "../Components/Component/FormPage";
import { IconsPage } from "../Components/Component/IconsPage";
import { ModalPage } from "../Components/Component/ModalPage";
import { PaginationPage } from "../Components/Component/PaginationPage";
import { SkeletonPage } from "../Components/Component/SkeletonPlaceHolder";
import { TabsPage } from "../Components/Component/TabsPage";
import { TooltipPage } from "../Components/Component/TooltipPage";
import { AiWriterGenerator } from "../Features/GenAI/TemplateSendRendering/AIWriterGenerator";
import { Analytics } from "../Pages/Analytics/Analytics";
import { Campaigns } from "../Pages/Campaigns/Campaigns";
import { Feed } from "../Pages/Community/Feed";
import { Forum } from "../Pages/Community/Forum";
import { ForumPost } from "../Pages/Community/ForumPost";
import { Meetups } from "../Pages/Community/Meetups";
import { MeetupsPost } from "../Pages/Community/MeetupsPost";
import { Profile } from "../Pages/Community/Profile";
import { UsersTabs } from "../Pages/Community/UsersTabs";
import { UsersTiles } from "../Pages/Community/UsersTiles";
import { Cart } from "../Pages/Ecommerce/Cart";
import { Cart2 } from "../Pages/Ecommerce/Cart2";
import { Cart3 } from "../Pages/Ecommerce/Cart3";
import { Invoices } from "../Pages/Ecommerce/Invoices";
import { Orders } from "../Pages/Ecommerce/Orders";
import { Pay } from "../Pages/Ecommerce/Pay";
import { Product } from "../Pages/Ecommerce/Product";
import { Shop } from "../Pages/Ecommerce/Shop";
import { Shop2 } from "../Pages/Ecommerce/Shop2";
import { FileManagerPage } from "../Pages/FileManagerPage/FileManagerPage";
import { CreditCards } from "../Pages/Finance/CreditCard";
import { Transactions } from "../Pages/Finance/Transaction";
import { TransactionDetails } from "../Pages/Finance/TransactionDetails";
import { Fintech } from "../Pages/Fintech/Fintech";
import { CompanyProfile } from "../Pages/Job/CompanyProfile";
import { JobListing } from "../Pages/Job/JobListing";
import { JobPost } from "../Pages/Job/JobPost";
import { Messages } from "../Pages/Messages/Messages";
import { Onboarding01 } from "../Pages/Onboarding01";
import { Onboarding02 } from "../Pages/Onboarding02";
import { Onboarding03 } from "../Pages/Onboarding03";
import { Onboarding04 } from "../Pages/Onboarding04";
import { TasksKanban } from "../Pages/Tasks/TasksKaban";
import { TasksList } from "../Pages/Tasks/TasksList";
import { Changelog } from "../Pages/Utility/Changelog";
import { EmptyState } from "../Pages/Utility/EmptyState";
import { Faqs } from "../Pages/Utility/Faqs";
import { KnowledgeBase } from "../Pages/Utility/KnowledgeBase";
import { PageNotFound } from "../Pages/Utility/PageNotFound";
import { Roadmap } from "../Pages/Utility/Roadmap";
import { GenAIMainPage } from "../Features/GenAI/Layout/GenAIMainPage";
import { TablesPage } from "../Pages/Moritzino/TablesPage";

export const SharedRoutes: React.FC = () => 
{
  const location = useLocation();

  useEffect(() => 
  {
    const htmlElement = document.querySelector("html");
    if (htmlElement) 
    {
      htmlElement.style.scrollBehavior = "auto";
      window.scroll({ top: 0 });
      htmlElement.style.scrollBehavior = "";
    }
  }, [location.pathname]);

  return (
    <GenAIProvider>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard/analytics" element={<Analytics />} />
        <Route path="/dashboard/fintech" element={<Fintech />} />
        <Route path="/ecommerce/orders" element={<Orders />} />
        <Route path="/ecommerce/invoices" element={<Invoices />} />
        <Route path="/ecommerce/shop" element={<Shop />} />
        <Route path="/ecommerce/shop-2" element={<Shop2 />} />
        <Route path="/ecommerce/product" element={<Product />} />
        <Route path="/ecommerce/cart" element={<Cart />} />
        <Route path="/ecommerce/cart-2" element={<Cart2 />} />
        <Route path="/ecommerce/cart-3" element={<Cart3 />} />
        <Route path="/ecommerce/pay" element={<Pay />} />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/community/users-tabs" element={<UsersTabs />} />
        <Route path="/community/users-tiles" element={<UsersTiles />} />
        <Route path="/community/profile" element={<Profile />} />
        <Route path="/community/feed" element={<Feed />} />
        <Route path="/community/forum" element={<Forum />} />
        <Route path="/community/forum-post" element={<ForumPost />} />
        <Route path="/community/meetups" element={<Meetups />} />
        <Route path="/community/meetups-post" element={<MeetupsPost />} />
        <Route path="/finance/cards" element={<CreditCards />} />
        <Route path="/finance/transactions" element={<Transactions />} />
        <Route path="/finance/transaction-details" element={<TransactionDetails />} />
        <Route path="/job/job-listing" element={<JobListing />} />
        <Route path="/job/job-post" element={<JobPost />} />
        <Route path="/job/company-profile" element={<CompanyProfile />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/tasks/kanban" element={<TasksKanban />} />
        <Route path="/tasks/list" element={<TasksList />} />
        <Route path="/inbox" element={<Inbox />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/settings/account" element={<Account />} />
        <Route path="/settings/notifications" element={<Notifications />} />
        <Route path="/settings/apps" element={<Apps />} />
        <Route path="/settings/authentication" element={<Authentication />} />
        <Route path="/settings/plans" element={<Plans />} />
        <Route path="/settings/billing" element={<Billing />} />
        <Route path="/settings/feedback" element={<Feedback />} />
        <Route path="/utility/changelog" element={<Changelog />} />
        <Route path="/utility/roadmap" element={<Roadmap />} />
        <Route path="/utility/faqs" element={<Faqs />} />
        <Route path="/utility/empty-state" element={<EmptyState />} />
        <Route path="/utility/404" element={<PageNotFound />} />
        <Route path="/utility/knowledge-base" element={<KnowledgeBase />} />
        <Route path="/onboarding-01" element={<Onboarding01 />} />
        <Route path="/onboarding-02" element={<Onboarding02 />} />
        <Route path="/onboarding-03" element={<Onboarding03 />} />
        <Route path="/onboarding-04" element={<Onboarding04 />} />
        <Route path="/component/button" element={<ButtonPage />} />
        <Route path="/component/form" element={<FormPage />} />
        <Route path="/component/dropdown" element={<DropdownPage />} />
        <Route path="/component/alert" element={<AlertPage />} />
        <Route path="/component/modal" element={<ModalPage />} />
        <Route path="/component/pagination" element={<PaginationPage />} />
        <Route path="/component/tabs" element={<TabsPage />} />
        <Route path="/component/breadcrumb" element={<BreadcrumbPage />} />
        <Route path="/component/badge" element={<BadgePage />} />
        <Route path="/component/avatar" element={<AvatarPage />} />
        <Route path="/component/tooltip" element={<TooltipPage />} />
        <Route path="/component/accordion" element={<AccordionPage />} />
        <Route path="/component/icons" element={<IconsPage />} />
        <Route path="/component/placeholder" element={<SkeletonPage />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/file-manager" element={<FileManagerPage />} />

        <Route path="/gen-ai/ai-chatbot" element={<GenAIMainPage />} />
        <Route path="/gen-ai/ai-writer" element={<GenAIMainPage />} />
        <Route path="/gen-ai/ai-writer/:id" element={<AiWriterGenerator />} />

        <Route path="/sala/tables" element={<TablesPage />} />

      </Routes>
    </GenAIProvider>
  );
};
