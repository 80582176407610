import React, { useContext } from 'react';
import { setLanguage, languages, getLanguage, getLanguageData } from './IntlConfig';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import {LanguageContext} from '../Contexts/LanguageContext';

interface LanguageSelectorProps 
{
  selectedLanguage: string;
  onLanguageChange: (newLocale: string) => void;
}

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ selectedLanguage, onLanguageChange }) => 
{
  const languageCtx = useContext(LanguageContext);
  const flagType = "fi fi-";

  const currentLanguageCode = getLanguage(selectedLanguage);

  const currentLanguage = getLanguageData(currentLanguageCode);

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => 
  {
    const language = event.target.value;
    setLanguage(language);
    onLanguageChange(language);
    languageCtx.setLanguage(language);
  };

  return (
    <div className="w-full">
      <div className="relative">
        <select
          value={currentLanguageCode}
          onChange={handleLanguageChange}
          className="w-full appearance-none dropdown dark:bg-slate-600 text-black py-2 pl-10 pr-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-400 dark:border-gray-600 dark:focus:ring-gray-500 dark:text-white"
        >
          {languages.map(lang => (
            <option key={lang.code} value={lang.code} className="flex items-center">
              {lang.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className={`${flagType}${currentLanguage.flag}`}></span>
        </div>
      </div>
    </div>
  );
};