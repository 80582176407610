import React, { useContext } from 'react';
import { Button } from '../../Components/Actions/Buttons/Buttons';
import { Datepicker } from '../../Components/Datepicker';
import { FintechIntro } from './Partials/FintechIntro';
import { FintechCard01 } from './Partials/FintechCard01';
import { FintechCard02 } from './Partials/FintechCard02';
import { FintechCard03 } from './Partials/FintechCard03';
import { FintechCard04 } from './Partials/FintechCard04';
import { FintechCard05 } from './Partials/FintechCard05';
import { FintechCard06 } from './Partials/FintechCard06';
import { FintechCard07 } from './Partials/FintechCard07';
import { FintechCard08 } from './Partials/FintechCard08';
import { FintechCard09 } from './Partials/FintechCard09';
import { FintechCard10 } from './Partials/FintechCard10';
import { FintechCard11 } from './Partials/FintechCard11';
import { FintechCard12 } from './Partials/FintechCard12';
import { FintechCard13 } from './Partials/FintechCard13';
import { FintechCard14 } from './Partials/FintechCard14';
import { getIntl } from '../../ReactIntl/IntlConfig';
import { FintechPageLocale } from '../../ReactIntl/LocaleInterfaces';
import { LanguageContext } from '../../Contexts/LanguageContext';

export const Fintech: React.FC = () => 
{
  const intl = getIntl<FintechPageLocale>('fintechPage');

  const _languageCtx = useContext(LanguageContext);

  return (
    <div className='flex h-[100dvh] overflow-hidden'>

      {/* Content area */}
      <div className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">

              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">{intl.h1Fintech} ✨</h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                {/* Datepicker built with flatpickr */}
                <Datepicker align="right" />

                {/* Add account button */}
                <Button
                  variant="primary"
                  size="m"
                  type="add"
                  hideOnMobile="label"
                >
                  {intl.addAccountBtn}
                </Button>

              </div>

            </div>

            {/* Cards */}
            <div className="grid grid-cols-12 gap-6">

              {/* Page Intro */}
              <FintechIntro />
              {/* Line chart (Portfolio Returns) */}
              <FintechCard01 />
              {/* Credit Card */}
              <FintechCard02 />
              {/* Bar chart (Cash Flow) */}
              <FintechCard03 />
              {/* Horizontal bar chart (Cash Flow by Account) */}
              <FintechCard04 />
              {/* Table (Recent Expenses) */}
              <FintechCard05 />
              {/* Table (Recent Earnings) */}
              <FintechCard06 />
              {/* Line chart (Saving Goals) */}
              <FintechCard07 />
              {/* Line chart (Growth Portfolio) */}
              <FintechCard08 />
              {/* Pie chart (Portfolio Value) */}
              <FintechCard09 />
              {/* Line charts (Stock graphs) */}
              <FintechCard10 />
              <FintechCard11 />
              <FintechCard12 />
              <FintechCard13 />
              {/* Table (Market Trends) */}
              <FintechCard14 />

            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
