import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Transition from '../Utils/Transition';
import UserAvatar from '../Images/user-avatar-32.png';
// import { useUserInfo } from '../Hooks/UseUserInfo';
/* import { accountController } from '../Api/Controller/ApiAccount'; */
import { getIntl } from '../ReactIntl/IntlConfig';
import { NavDropdownProfileLocale } from '../ReactIntl/LocaleInterfaces';
import { RoleNavLink } from '../Api/Roles/RoleNavLink';
import { Role } from '../Api/Roles/RoleManager';
import { useSupabaseClient, useUser } from '../Features/Auth/Hooks/UseSession';

interface DropdownProfileProps 
{
  align: 'left' | 'right';
}

export const DropdownProfile: React.FC<DropdownProfileProps> = ({ align }) => 
{
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const trigger = useRef<HTMLButtonElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);
  const supabaseClient = useSupabaseClient();
  const navigate = useNavigate();
  const user = useUser();

  // const { email, name, given_name, picture } = useUserInfo();
  const nameOrEmail = user?.email ?? "email";
  const pictureUrl = UserAvatar ?? "no image";

  const intl = getIntl<NavDropdownProfileLocale>('navDropdownProfile');

  useEffect(() => 
  {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => 
  {
    const clickHandler = ({ target }: MouseEvent) => 
    {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target as Node) || trigger.current?.contains(target as Node)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [dropdownOpen]);

  useEffect(() => 
  {
    const keyHandler = ({ key }: KeyboardEvent) => 
    {
      if (!dropdownOpen || key !== "Escape") return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [dropdownOpen]);

  const handleSignOut = async () => 
  {
    /* accountController.logout(); */
    const { error } = await supabaseClient.auth.signOut();
    if (error) 
    {
      console.error("Error signing out:", error.message);
    }
    else 
    {
      console.log("Signed out successfully.");
      navigate('/account/login/');
    }
  };

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <img className="w-8 h-8 rounded-full" src={pictureUrl} width="32" height="32" alt="User" />
        {!isMobile && (
          <div className="flex items-center truncate ml-2">
            <span className="truncate text-sm font-medium dark:text-slate-300 group-hover:text-slate-800 dark:group-hover:text-slate-200">
              {nameOrEmail}
            </span>
            <svg className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400" viewBox="0 0 12 12">
              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
            </svg>
          </div>
        )}
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${align === 'right' ? 'right-0' : 'left-0'}`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {!isMobile && (
            <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200 dark:border-slate-700">
              <div className="font-medium text-slate-800 dark:text-slate-100">{user?.id}</div>
              <div className="text-xs text-slate-500 dark:text-slate-400 italic">{user?.email}</div>
            </div>
          )}
          <ul>
            <li>
              <Link
                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center py-1 px-3"
                to="/settings/account"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {intl.settings}
              </Link>
            </li>
            <li>
              <RoleNavLink
                requiredRoles={Role.Admin}
                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center py-1 px-3"
                to="/admin/app-settings/layout"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {intl.appSettings}
              </RoleNavLink>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center py-1 px-3"
                to="/settings/plans"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {intl.plans}
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center py-1 px-3"
                to="/settings/billing"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {intl.billAndInvoice}
              </Link>
            </li>
            <li>
              <button
                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 dark:hover:text-indigo-400 flex items-center py-1 px-3"
                onClick={handleSignOut}
              >
                {intl.signOut}
              </button>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
};
