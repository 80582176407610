import React, { useState } from 'react';
import { MessagesSidebarLocale } from '../../../../ReactIntl/LocaleInterfaces';
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { Button } from '../../../../Components/Actions/Buttons/Buttons';

interface ChatbotSidebarProps 
{
  msgSidebarOpen: boolean;
  setMsgSidebarOpen: (open: boolean) => void;
}

const conversations = [
  { id: 1, name: 'Conversazione 1', lastMessage: 'Ultimo messaggio della conversazione 1' },
  { id: 2, name: 'Conversazione 2', lastMessage: 'Ultimo messaggio della conversazione 2' },
  { id: 3, name: 'Conversazione 3', lastMessage: 'Ultimo messaggio della conversazione 3' },
  { id: 4, name: 'Conversazione 4', lastMessage: 'Ultimo messaggio della conversazione 4' },
];

export const ChatbotSidebar: React.FC<ChatbotSidebarProps> = ({ msgSidebarOpen, setMsgSidebarOpen }) => 
{
  const intl = getIntl<MessagesSidebarLocale>('messagesSidebar');
  const [selectedConversation, setSelectedConversation] = useState<number | null>(null);

  return (
    <div
      id="messages-sidebar"
      className={`fixed z-20 top-0 bottom-0 w-full md:w-auto md:static md:top-auto md:bottom-auto -mr-px md:translate-x-0 transition-transform duration-200 ease-in-out ${msgSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
    >
      <div className="sticky top-16 bg-white dark:bg-slate-900 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 border-r border-slate-200 dark:border-slate-700 md:w-72 xl:w-80 h-[calc(100dvh-64px)]">
        <div className="sticky top-0 z-10">
          <div className="flex items-center bg-white dark:bg-slate-900 border-b border-slate-200 dark:border-slate-700 px-5 h-16">
            <div className="w-full">
              <div className="text-lg font-semibold">{intl.sidebarTitle}</div>
              <span className='flex items-center gap-2 justify-between'>
                {/* Search Bar */}
                <form className="relative w-full">
                  <label htmlFor="msg-search" className="sr-only">
                    {intl.srOnlySearch}
                  </label>
                  <input
                    id="msg-search"
                    className="form-input w-full pl-9 bg-white dark:bg-slate-800"
                    type="search"
                    placeholder={intl.placeholderSearch}
                  />
                  <button className="absolute inset-0 right-auto group" type="submit" aria-label="Search">
                    <svg
                      className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400 ml-3 mr-2"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                      <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                    </svg>
                  </button>
                </form>
                {/* End of Search Bar */}
                {/* Toggle Sidebar Button */}
                <Button variant="secondary" type="rArrow" title='Open Chat' hideText onClick={() => setMsgSidebarOpen(!msgSidebarOpen)} className="md:hidden" />
                {/* End of Toggle Sidebar Button */}
              </span>
            </div>
          </div>
        </div>
        <div className="px-5 py-4">

          <div className="flex flex-col gap-2">
            {conversations.map((conversation) => (
              <div
                key={conversation.id}
                className={`p-3 rounded-lg cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-700 ${selectedConversation === conversation.id ? 'bg-slate-200 dark:bg-slate-600' : ''}`}
                onClick={() => setSelectedConversation(conversation.id)}
              >
                <div className="text-sm font-medium">{conversation.name}</div>
                <div className="text-xs text-slate-500 dark:text-slate-400">{conversation.lastMessage}</div>
              </div>
            ))}
          </div>
          <button
            className="mt-4 w-full p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            onClick={() => console.log('New Chat')}
          >
            Nuova Chat
          </button>
        </div>
      </div>
    </div>
  );
};
