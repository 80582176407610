import React, { useState, useRef, useEffect } from 'react';
import { Paperclip, Send } from 'lucide-react';
import { SpeechToText } from '../../BotTools/SpeechToText';

interface ChatbotAdminFooterProps 
{
  onSend: (input: string) => Promise<void>;
  blockMessage: boolean;
}

export const ChatbotAdminFooter: React.FC<ChatbotAdminFooterProps> = ({
  onSend,
  blockMessage,
}) => 
{
  const [input, setInput] = useState('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => 
  {
    if (textareaRef.current) 
    {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 150)}px`;
    }
  }, [input]);

  const handleSubmit = async (e: React.FormEvent) => 
  {
    e.preventDefault();
    if (!input.trim() || blockMessage) return;
    await onSend(input);
    setInput('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => 
  {
    if (e.key === 'Enter' && !e.shiftKey) 
    {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div className="sticky bottom-0 bg-white dark:bg-slate-900 border-t border-slate-200 dark:border-slate-700">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center py-4">
          <button className="mr-2 text-gray-400 hover:text-gray-600">
            <Paperclip size={20} />
          </button>
          <div className="flex-grow relative">
            <textarea
              ref={textareaRef}
              className="w-full border-none bg-slate-100 dark:bg-slate-800 rounded-lg pl-4 pr-10 py-2 focus:ring-2 focus:ring-blue-500 resize-none"
              placeholder="Aa"
              rows={1}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={blockMessage}
            />

          </div>
          <div className='ml-2 mb-1'>
            <SpeechToText
              onTextChange={(transcript: React.SetStateAction<string>) => setInput(transcript)}
              buttonBackgroundColor="bg-blue-500"
              buttonListeningBackgroundColor="bg-blue-600"
              microphoneColor="text-white"
              buttonSize="p-2"
            />
          </div>
          <button
            onClick={handleSubmit}
            className={`ml-2 mb-1 p-2 rounded-full bg-blue-500 text-white ${input.trim() === '' || blockMessage ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
            disabled={input.trim() === '' || blockMessage}
          >
            <Send size={20} />
          </button>
        </div>
        <div className="text-xs text-gray-500 mb-2">
          Use Shift + Enter for new line
        </div>
      </div>
    </div>
  );
};
