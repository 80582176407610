import { apiDefinitions } from "../../ApiConfig";
import { Filter } from "../../../Utils/Filter";
import { UserDto } from "../../../Utils/User.types";

const definition = apiDefinitions.userList;

export const userListController =
{
  getUsers: async (filter: Filter) => 
  {
    const endpoint = definition.getUsers;
    const response = await endpoint.invoke(endpoint.url, { filter });

    return response;
  },

  getUserById: async (id: string) => 
  {
    const endpoint = definition.getUserById;
    const response = await endpoint.invoke(endpoint.url.replace("{id}", id));

    return response;
  },

  addUser: async (user: UserDto) => 
  {
    const endpoint = definition.addUser;
    const response = await endpoint.invoke(endpoint.url, user);

    return response;
  },

  editUser: async (id: string, user: UserDto) => 
  {
    const endpoint = definition.editUser;
    const response = await endpoint.invoke(
      endpoint.url.replace("{id}", id),
      user
    );

    return response;
  },

  deleteUser: async (id: string) => 
  {
    const endpoint = definition.deleteUser;
    const response = await endpoint.invoke(endpoint.url.replace("{id}", id));

    return response;
  },

  count: async () => 
  {
    const endpoint = definition.count;
    const response = await endpoint.invoke(endpoint.url);

    return response;
  }
};
