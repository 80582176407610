import React from 'react';
import { Button } from './Actions/Buttons/Buttons';

interface PaginationNumericProps 
{
  onPageChange: (newPage: number) => void;
  currentPage: number;
  totalResults: number;
  itemsPerPage: number;
}

export const PaginationNumeric: React.FC<PaginationNumericProps> = ({
  onPageChange,
  currentPage,
  totalResults,
  itemsPerPage
}) => 
{
  const totalPages = Math.ceil(totalResults / itemsPerPage);

  const handlePreviousClick = () => 
  {
    if (currentPage > 0) 
    {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => 
  {
    if (currentPage < totalPages - 1) 
    {
      onPageChange(currentPage + 1);
    }
  };

  const renderPageNumbers = () => 
  {
    return Array.from({ length: totalPages }, (_, index) => 
    {
      const page = index + 1;
      const isActive = page === currentPage + 1;

      return (
        <li key={page}>
          <Button
            variant={isActive ? "primary" : "secondary"}
            size="m"
            onClick={() => onPageChange(page - 1)}
            className="px-4 py-1.5" // Aggiungiamo il padding custom
          >
            {page}
          </Button>
        </li>
      );
    });
  };

  return (
    <div className="flex justify-center mt-4">
      <nav className="flex flex-wrap items-center" role="navigation" aria-label="Navigation">
        <div className="mr-2">
          <Button
            type="lArrow"
            variant="secondary"
            size="m"
            hideText
            onClick={handlePreviousClick}
            disabled={currentPage === 0}
            className={`px-2.5 py-2 ${currentPage === 0 ? 'text-slate-300 dark:text-slate-600' : 'text-indigo-500 dark:text-indigo-300'}`}
          >
            <span className="sr-only">Previous</span>
          </Button>
        </div>

        <ul className="inline-flex text-sm font-medium -space-x-px shadow-sm">
          {renderPageNumbers()}
        </ul>

        <div className="ml-2">

          <Button
            type="rArrow"
            variant="secondary"
            size="m"
            hideText
            onClick={handleNextClick}
            disabled={currentPage === totalPages - 1}
            className={`px-2.5 py-2 hover:bg-indigo-500 dark:hover:bg-indigo-500 ${currentPage >= totalPages - 1 ? 'text-slate-300 dark:text-slate-600' : 'text-indigo-500 dark:text-indigo-300'} hover:text-white`}
          >
            <span className="sr-only">Next</span>
          </Button>
        </div>
      </nav>
    </div>
  );
};
