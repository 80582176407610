import React, { FormEvent, useEffect, useState, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connectController } from "./Controller/ApiConnect";
import Cookies from 'js-cookie';
import { Loader } from "../Pages/Loader";
import { getIntl } from "../ReactIntl/IntlConfig";
import { AuthorizationConsentLocale } from "../ReactIntl/LocaleInterfaces";

export const AuthorizationConsent: React.FC = () => 
{
  const [submitValue, setSubmitValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [checkingCookie, setCheckingCookie] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParameters = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const applicationName = queryParameters.get('applicationName')!;
  const scope = queryParameters.get('scope')!;
  const scopesList = scope.split(' ');

  const intl = getIntl<AuthorizationConsentLocale>('authorizationConsent');

  const setCookie = () => 
  {
    Cookies.set('AcceptContest', 'true');
  };

  function formatScopeItem(scopeItem: string) 
  {
    const withSpaces = scopeItem.replace(/_/g, ' ');
    return withSpaces.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }

  const submitAuthorization = useCallback(async (data: URLSearchParams) => 
  {
    try 
    {
      const response = await connectController.authorize(data);
      if (response.request instanceof XMLHttpRequest) 
      {
        const url = new URL(response.request.responseURL);
        const relativeUrl = url.pathname + url.search + url.hash;
        if (submitValue === 'submit.Accept') 
        {
          setCookie();
          localStorage.removeItem("authorizationDenied");
        }
        else if (submitValue === 'submit.Deny')
        {
          localStorage.setItem("authorizationDenied", "true");
        }
        navigate(relativeUrl);
      }
      else 
      {
        setIsLoading(false);
        console.error('Authorization request failed');
      }
    }
    catch (error) 
    {
      setIsLoading(false);
      console.error('Authorization request failed:', error);
    }
  }, [navigate, submitValue]);
  
  const handleAutoSubmit = useCallback(async () => 
  {
    setIsLoading(true);
    setShowForm(false);
    const data = new URLSearchParams();
    data.append('submit.Accept', 'true');
    for (const [key, value] of queryParameters.entries()) 
    {
      data.append(key, value);
    }
    await submitAuthorization(data);
  }, [ queryParameters, submitAuthorization ]);

  useEffect(() => 
  {
    const authCookie = Cookies.get("AcceptConsent");

    if (authCookie === 'true') 
    {
      handleAutoSubmit();
    }
    else 
    {
      setCheckingCookie(false);
    }
  }, [handleAutoSubmit]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => 
  {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData(event.currentTarget);
    const data = new URLSearchParams();

    if (submitValue) 
    {
      data.append(submitValue, 'true');
    }
    else 
    {
      setIsLoading(false);
      return false;
    }

    formData.forEach((value, key) => 
    {
      data.append(key, value as string);
    });

    await submitAuthorization(data);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 relative">
      {checkingCookie == false && (
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          <img src="/beet-icon.svg" alt="Beet Root" className="mx-auto mb-4 w-16 h-16" />
          <h1 className="text-3xl font-bold mb-4 text-black">{intl.h1Authorization}</h1>
          <p className="lead text-left text-black mb-4">
            {intl.doYouWantToGrant} <strong>{applicationName}</strong> {intl.accessToYourData}
          </p>
          <ul className="list-disc list-inside text-left text-black mb-4">
            {scopesList.map((scopeItem, index) => (
              <li key={index}>{formatScopeItem(scopeItem)}</li>
            ))}
          </ul>
          <form onSubmit={handleSubmit}>
            {Array.from(queryParameters.entries()).map(([key, value]) => (
              <input key={key} type="hidden" name={key} value={value} />
            ))}
            <div className="flex justify-center space-x-4">
              <button
                className="btn btn-lg btn-danger bg-red-500 text-white py-2 px-4 rounded-lg flex items-center justify-center"
                name="submit.Deny"
                type="submit"
                onClick={() => setSubmitValue('submit.Deny')}
                disabled={isLoading}
              >
                {isLoading && submitValue === 'submit.Deny' ? (
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                  </svg>
                ) : (
                  'No'
                )}
              </button>
              <button
                className="btn btn-lg btn-success bg-green-500 text-white py-2 px-4 rounded-lg flex items-center justify-center"
                name="submit.Accept"
                type="submit"
                onClick={() => setSubmitValue('submit.Accept')}
                disabled={isLoading}
              >
                {isLoading && submitValue === 'submit.Accept' ? (
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                  </svg>

                ) : (
                  'Yes'
                )}
              </button>
            </div>
          </form>
        </div>
      )}
      {(isLoading || !showForm) && (
        <div className="absolute inset-0 bg-gray-100 bg-opacity-75">
          <Loader />
        </div>
      )}
    </div>
  );
};