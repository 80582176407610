import { useContext } from "react";
import { UIContext } from "../Contexts/UIContext";

export const useUIState = () => 
{
  const context = useContext(UIContext);
  if (context === undefined) 
  {
    throw new Error('useUIState must be used within a UIProvider');
  }
  return context;
};