import { GenAIMessageRole } from '../Api/Types/GenAIEnums';
import { GenAIChatMessage } from '../Api/Types/GenAIInterface';
import { GenAITemplateChatSendRequest } from '../Api/Types/GenAIRequest';
import { GenAIMessageResponse } from '../Api/Types/GenAIResponse';
import { genAIUserController } from '../Api/Controller/GenAIUser';
import { 
  ChatbotServiceConfig, 
  ChatbotCallbacks 
} from './ChatbotTypes';

export class ChatbotService 
{
  private static instance: ChatbotService;

  private constructor() {}

  static getInstance(): ChatbotService 
  {
    if (!ChatbotService.instance) 
    {
      ChatbotService.instance = new ChatbotService();
    }
    return ChatbotService.instance;
  }

  async sendMessage(
    message: string,
    chatId: string,
    config: ChatbotServiceConfig,
    callbacks: ChatbotCallbacks
  ): Promise<void> 
  {
    const sendRequest: GenAITemplateChatSendRequest = {
      templateId: chatId,
      maxTokens: config.maxTokens,
      temperature: config.temperature,
      streamResponse: config.streamResponse,
      input: message
    };

    try 
    {
      await genAIUserController.sendChat<GenAIMessageResponse>(
        sendRequest,
        (message: string) => callbacks.onMessage?.(message),
        (data: GenAIMessageResponse) => callbacks.onComplete?.(data),
        (error: unknown) => 
        {
          if (callbacks.onError && error instanceof Error) 
          {
            callbacks.onError(error);
          }
          else 
          {
            throw error;
          }
        }
      );
    }
    catch (error) 
    {
      if (callbacks.onError && error instanceof Error) 
      {
        callbacks.onError(error);
      }
      else 
      {
        throw error;
      }
    }
  }

  createMessage(
    role: GenAIMessageRole, 
    message: string,
    isSelectionPrompt: boolean = false,
    options: string[] = []
  ): GenAIChatMessage 
  {
    return {
      role,
      message,
      isSelectionPrompt,
      options
    };
  }
}