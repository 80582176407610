import React, { useEffect } from 'react';
import { Folder, FileItem, DragData } from './FileManager.types';
import { ContextMenuWrapper } from './ContextMenu';
import { SidebarFile } from './SidebarFile';
import { SimpleModal } from './SimpleModal';
import { MultiToolbar } from './Toolbar/MultiToolbar';
import { FolderTreeModal } from './FolderTreeModal';
import { ProgressSidebar } from './ProgressSidebar/ProgressSidebar';
import { useFileSystem } from './Hooks/UseFileSystem';
import { useFileSystemOperations } from './Hooks/UseFileSystemOperations';
import { useUIState } from './Hooks/UseUiState';
import { useUIOperations } from './Hooks/UseUiOperations';
import { useProgress } from './Hooks/UseProgress';
import { FolderGrid } from './FolderGrid';
import toast from 'react-hot-toast';

export const FileManager: React.FC = () => 
{
  const { rootFolder, setRootFolder, selectedFolder, setSelectedFolder, selectedItem, setSelectedItem, breadcrumb, setBreadcrumb } = useFileSystem();
  const { addFile, moveItem, copyItemTo, navigateToFolder, findItem, findFolderPath } = useFileSystemOperations();
  const {
    modalOpen,
    modalContent,
    isFolderTreeModalOpen,
    folderTreeAction
  } = useUIState();
  const {
    closeModal,
    openContextMenu,
    closeFolderTreeModal
  } = useUIOperations();
  const { progressItems, isProgressSidebarOpen, toggleProgressSidebar, addOrShowProgressItem, message, setMessage } = useProgress();

  useEffect(() =>
  {
    if(message) toast.success(message);

  },[ message ]); 

  useEffect(() => 
  {
    const storedData = localStorage.getItem('fileManagerData');
    if (storedData) 
    {
      const parsedData = JSON.parse(storedData);
      setRootFolder(parsedData);
      setSelectedFolder(parsedData);
      setBreadcrumb([parsedData]);
    }
  }, [setRootFolder, setSelectedFolder, setBreadcrumb]);

  useEffect(() => 
  {
    localStorage.setItem('fileManagerData', JSON.stringify(rootFolder));

  }, [rootFolder]);

  const handleRightClick = (e: React.MouseEvent, item: Folder | FileItem | null, type: 'folder' | 'file' | "grid") => 
  {
    e.preventDefault();
    e.stopPropagation();
    console.log('right click', item, type);
    const estimatedWidth = 120;  // estimated width of the menu
    const estimatedHeight = 300; // estimated height of the menu

    const x = (e.clientX + estimatedWidth > window.innerWidth) ? (window.innerWidth - estimatedWidth - 10) : e.clientX;
    const y = (e.clientY + estimatedHeight > window.innerHeight) ? (window.innerHeight - estimatedHeight - 10) : e.clientY;

    openContextMenu({ x, y, item: item || selectedFolder, type });
  };

  const handleDragStart = (e: React.DragEvent, item: Folder | FileItem) => 
  {
    e.stopPropagation();
    const dragData: DragData = {
      id: item.id,
      type: 'subfolders' in item ? 'folder' : 'file',
      parentId: (item as Folder).parentId || 'root'
    };

    e.dataTransfer.setData('text/plain', JSON.stringify(dragData));
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e: React.DragEvent) => 
  {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  // TODO: FIX item moving to itself if dragged over ---- FIXED but still weird solution
  const handleDrop = (e: React.DragEvent, targetFolder: Folder) => 
  {
    e.preventDefault();

    const data = e.dataTransfer.getData('text/plain');
    const { id, type, parentId }: DragData = JSON.parse(data);
    console.log("dataTransfered: ", { id, type, parentId });
    console.log("targetFolder: ", targetFolder);

    // need check
    if (id === parentId) 
    {
      e.dataTransfer.dropEffect = 'none';
    }
    else if (id === targetFolder.id && parentId !== targetFolder.id) 
    {
      e.dataTransfer.dropEffect = 'none';
    }
    else if (id !== targetFolder.id && parentId === targetFolder.id) 
    {
      e.dataTransfer.dropEffect = 'none';
    }
    else if (id !== targetFolder.id && parentId !== targetFolder.id) 
    {
      moveItem(id, parentId, targetFolder.id, type);
      const item = findItem(rootFolder, id, type);
      addOrShowProgressItem({
        type: "move",
        itemName: item && item.name || "",
        itemType: type,
        fromLocation: findFolderPath(rootFolder, id),
        toLocation: findFolderPath(rootFolder, targetFolder.id),
        timestamp: new Date()
      });
    }

  };

  const handleFolderTreeModalSelect = (targetFolderId: string) => 
  {
    if (selectedItem) 
    {
      if (folderTreeAction === 'move') 
      {
        moveItem(selectedItem.id, selectedItem.parentId, targetFolderId, 'subfolders' in selectedItem ? 'folder' : 'file');
      }
      else 
      {
        copyItemTo(selectedItem.id, targetFolderId, 'subfolders' in selectedItem ? 'folder' : 'file');
      }
      setSelectedItem(null);
      closeFolderTreeModal();
    }
  };

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Main content area */}
      <div className="flex flex-col flex-1 overflow-hidden">
        <div className='px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto'>
          {/* Toolbar */}
          <MultiToolbar />
          <div className="flex-1 flex overflow-hidden relative bg-white dark:bg-slate-800">
            {/* SidebarFile */}
            <SidebarFile
              folders={[rootFolder]}
              onSelect={navigateToFolder}
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onContextMenu={handleRightClick}
            />

            {/* File manager content */}
            {/* Main content area */}
            <div className="flex-1 overflow-hidden flex flex-col  min-h-screen">
              {/* Breadcrumb */}
              <div className="p-4">
                {breadcrumb.map((folder, index) => (
                  <React.Fragment key={folder.id}>
                    <span
                      className="text-gray-500 dark:text-gray-400 cursor-pointer hover:underline"
                      onClick={() => navigateToFolder(folder)}
                    >
                      {folder.name}
                    </span>
                    {index < breadcrumb.length - 1 && <span className="mx-2 text-gray-500 dark:text-gray-400">/</span>}
                  </React.Fragment>
                ))}
              </div>

              {/* Folder name */}
              <h2 className="text-2xl font-bold px-4 mb-2">{selectedFolder.name}</h2>

              {/*Folder grid */}
              <FolderGrid 
                selectedItem={selectedItem} 
                setSelectedItem= {setSelectedItem} 
                selectedFolder= {selectedFolder} 
                moveItem= {moveItem}
                findItem= {findItem} 
                findFolderPath= {findFolderPath}
                addOrShowProgressItem= {addOrShowProgressItem} 
                navigateToFolder= {navigateToFolder} 
                rootFolder= {rootFolder}
                addFile={addFile}
              />
            </div>

            {/* Progress Sidebar  */}
            <ProgressSidebar
              isOpen={isProgressSidebarOpen}
              onClose={toggleProgressSidebar}
              progressItems={progressItems}
              setMessage={setMessage}
            />
          </div>
        </div>

      </div>

      {/* Right Click Context Menu  */}
      <ContextMenuWrapper />

      {/* MODALS */}
      <SimpleModal isOpen={modalOpen} onClose={closeModal}>
        {modalContent}
      </SimpleModal>

      <FolderTreeModal
        isOpen={isFolderTreeModalOpen}
        onClose={closeFolderTreeModal}
        rootFolder={rootFolder}
        onSelectFolder={handleFolderTreeModalSelect}
        action={folderTreeAction}
      />
    </div>
  );
};