import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe } from '@stripe/stripe-js';
import { subscriptionController } from '../ApiSubscription';
// import { useUserInfo } from '../../../../Hooks/UseUserInfo';
import { PriceDto, ProductDto, SubResponseDto } from '../../../../Utils/Stripe.types';
import { useAppContext } from '../../../../Hooks/UseAppContext';

interface CheckoutModalProps 
{
  closeModal: () => void;
  priceId: string;
  priceDetails: PriceDto | null;
  productDetails: ProductDto | null;
}

export const CheckoutModal: React.FC<CheckoutModalProps> =
  ({
    closeModal,
    priceId,
    priceDetails,
    productDetails,
  }) => 
  {
    const [stripe, setStripe] = useState<Stripe | null>(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [couponCode, setCouponCode] = useState<string>('');
    // const { sub, email } = useUserInfo();
    const appContext = useAppContext();

    useEffect(() => 
    {
      const loadStripeInstance = async () => 
      {
        const stripePromise = loadStripe(appContext.config?.stripe.public_key || '');
        const stripeInstance = await stripePromise;
        setStripe(stripeInstance);
      };

      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.async = true;
      script.onload = loadStripeInstance;
      document.body.appendChild(script);

      return () => 
      {
        document.body.removeChild(script);
        // Clear Stripe-related items from localStorage and sessionStorage
        Object.keys(localStorage).forEach(key => 
        {
          if (key.includes('stripe') || key.includes('js.stripe.com') || key.includes('m.stripe.network')) 
          {
            localStorage.removeItem(key);
          }
        });
        Object.keys(sessionStorage).forEach(key => 
        {
          if (key.includes('stripe') || key.includes('js.stripe.com') || key.includes('m.stripe.network')) 
          {
            sessionStorage.removeItem(key);
          }
        });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const handleSubmit = async (event: React.FormEvent) => {
    //   event.preventDefault();
    //   if (!stripe) return;

    //   setIsProcessing(true);
    //   setErrorMessage(null);

    //   // if (!sub || !email) {
    //   //   setErrorMessage('User data not available.');
    //   //   setIsProcessing(false);
    //   //   return;
    //   // }

    //   try {
    //     const response = (await subscriptionController.createSub(
    //       sub,
    //       email,
    //       priceId,
    //       couponCode
    //     )) as { data: SubResponseDto };
    //     const { sessionId } = response.data;

    //     if (!sessionId) {
    //       setErrorMessage('Unable to retrieve session ID. Please try again.');
    //       setIsProcessing(false);
    //       return;
    //     }

    //     const { error } = await stripe.redirectToCheckout({ sessionId });

    //     if (error) {
    //       console.error('Redirect error:', error);
    //       setErrorMessage(
    //         error.message || 'An error occurred during payment.'
    //       );
    //     }
    //   }
    //   catch (error) {
    //     console.error('Error:', error);
    //     setErrorMessage('An unexpected error occurred. Please try again.');
    //   }

    //   setIsProcessing(false);
    // };

    return (
      <div className="fixed inset-0 flex items-center justify-end z-50 bg-black bg-opacity-50">
        <div className="bg-white shadow-lg h-full max-w-xs w-full sm:max-w-sm lg:max-w-md relative flex flex-col">
          <button
            onClick={closeModal}
            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
          <div className="flex flex-col h-full p-6">
            <h2 className="text-xl font-semibold mb-4">Checkout</h2>
            <div className="flex-grow flex flex-col">
              {priceDetails && productDetails ? (
                <form
                  // onSubmit={handleSubmit}
                  className="flex flex-col flex-grow space-y-4"
                >
                  <div>
                    <label
                      htmlFor="coupon"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Coupon Code
                    </label>
                    <input
                      type="text"
                      id="coupon"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                      placeholder="Enter your coupon code"
                    />
                  </div>
                  {errorMessage && <p className="text-red-600">{errorMessage}</p>}
                  <button
                    type="submit"
                    disabled={!stripe || isProcessing}
                    className={`w-full py-2 px-4 text-white ${!stripe || isProcessing
                      ? 'bg-gray-400'
                      : 'bg-blue-500 hover:bg-blue-600'
                    } rounded-md mt-auto`}
                  >
                    {isProcessing ? 'Processing...' : 'Pay'}
                  </button>
                </form>
              ) : (
                <p className="text-gray-700">Loading...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };