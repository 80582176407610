import React, { useState, useContext } from "react";
import { Button } from "../../../Components/Actions/Buttons/Buttons";
import { ActionProps } from "./Actions.types";
import { getIntl } from "../../../ReactIntl/IntlConfig";
import { ProgressContextActionsMenu } from "../../../ReactIntl/LocaleInterfaces";
import { LanguageContext } from "../../../Contexts/LanguageContext";

export const Rename: React.FC<ActionProps> = ({ onConfirm, onCancel, itemName }) => 
{
  const [newName, setNewName] = useState(itemName);
  const intl = getIntl<ProgressContextActionsMenu>("progressContextActionsMenu");
  const _languageCtx = useContext(LanguageContext);

  return (
    <div>
      <h2 className="text-xl mb-4">{intl.rename} "{itemName}"</h2>
      <input
        type="text"
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        className="form-input w-full"
      />
      <div className="flex justify-end">
        <div className="m-1.5">
          <Button
            variant="tertiary"
            size="l"
            onClick={onCancel}
          >
            {intl.cancel}
          </Button>
        </div>
        <div className="m-1.5">
          <Button
            variant="primary"
            size="l"
            onClick={() => onConfirm(newName)}
          >
            {intl.rename}
          </Button>
        </div>
      </div>
    </div>
  );
};