import React from 'react';
import { Send } from 'lucide-react';
import { SpeechToText } from '../../BotTools/SpeechToText';
import { ChatbotStyles } from '../CommonStyles/ChatbotStyles';

interface ChatWindowFooterProps 
{
  input: string;
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  handleSend: () => void;
  handleVoiceInput: (text: string) => void;
  loading: boolean;
  pendingMessage: string;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  blockMessage: boolean;
  style: typeof ChatbotStyles;
}

export const ChatWindowFooter: React.FC<ChatWindowFooterProps> = ({
  input,
  handleInputChange,
  handleKeyDown,
  handleSend,
  handleVoiceInput,
  textareaRef,
  blockMessage,
  style
}) => 
{
  return (
    <div className={style.ChatWindowFooter}>
      <div className={style.ChatWindowFooterContainer}>
        <textarea
          ref={textareaRef}
          value={input}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Scrivi un messaggio..."
          className={style.ChatWindowFooterTextarea}
          style={{ minHeight: '60px', maxHeight: '150px' }}
        />
        <div className={style.ChatWindowFooterButtonContainer}>
          <SpeechToText
            onTextChange={handleVoiceInput}          
            buttonSize={style.SpeechToTextButtonSize}
            buttonBackgroundColor={style.SpeechToTextButtonColor}
            buttonListeningBackgroundColor={style.SpeechToTextButtonListeningColor}
            microphoneColor={style.SpeechToTextMicrophoneColor}
          />
          <button
            title="Invia messaggio"
            onClick={handleSend}
            className={style.ChatWindowFooterSendButton}
            disabled={blockMessage}
          >
            <Send size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};
