import React, { useState, useRef, useEffect } from 'react';
import Transition from '../../../Utils/Transition';
import { ModalInputDropdownProps } from './ModalTypes';

// Mappa per le varianti
const dropdownVariantMap = {
  role: {
    name: 'role',
    label: 'Ruolo',
  },
  type: {
    name: 'type',
    label: 'Tipo',
  },
};

export const ModalInputDropdown: React.FC<ModalInputDropdownProps> = ({
  variant,
  name,
  label,
  value,
  onChange,
  options
}) => 
{
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const trigger = useRef<HTMLButtonElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);

  // Se variant è presente, ottieni name e label dalla mappa delle varianti
  const variantDetails = variant ? dropdownVariantMap[variant] : { name: '', label: '' };

  // Usa name e label passati come props se presenti, altrimenti prendi quelli dalla variant.
  const finalName = name ?? variantDetails.name;
  const finalLabel = label ?? variantDetails.label;

  const selectedOption = options.find(option => option.value === value) || options[0];

  // close on click outside
  useEffect(() => 
  {
    if (dropdownOpen) 
    {
      const clickHandler = ({ target }: MouseEvent) => 
      {
        if (!dropdown.current || dropdown.current.contains(target as Node) || trigger.current?.contains(target as Node)) return;
        setDropdownOpen(false);
      };
      const keyHandler = ({ key }: KeyboardEvent) => 
      {
        if (key === 'Escape') 
        {
          setDropdownOpen(false);
        }
      };
      document.addEventListener('click', clickHandler);
      document.addEventListener('keydown', keyHandler);

      return () => 
      {
        document.removeEventListener('click', clickHandler);
        document.removeEventListener('keydown', keyHandler);
      };
    }
  }, [dropdownOpen]);

  // close if the esc key is pressed
  useEffect(() => 
  {
    const keyHandler = ({ key }: KeyboardEvent) => 
    {
      if (!dropdownOpen || key !== 'Escape') return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative mb-4">
      <label className="block text-sm font-medium" htmlFor={finalName}>
        {finalLabel}
      </label>
      <button
        ref={trigger}
        className="mt-1 w-full flex items-center justify-between min-w-44 px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-white dark:bg-slate-800 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
        type='button'
      >
        <span>{selectedOption ? selectedOption.label : 'Seleziona un\'opzione'}</span>
        <svg className="shrink-0 ml-1 fill-current text-slate-400 dark:text-gray-300" width="11" height="7" viewBox="0 0 11 7">
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className="z-10 absolute top-full left-0 w-full bg-white dark:bg-slate-800 border border-gray-300 dark:border-slate-700 rounded shadow-lg overflow-hidden mt-1"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700"
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {options.map(option => (
            <button
              key={option.value}
              tabIndex={0}
              className={`flex items-center justify-between w-full hover:bg-slate-50 dark:hover:bg-slate-700/20 py-2 px-3 cursor-pointer ${option.value === value ? 'text-indigo-500' : ''
              }`}
              onClick={() => 
              {
                onChange(option.value);
                setDropdownOpen(false);
              }}
            >
              <span>{option.label}</span>
              {option.value === value && (
                <svg className="shrink-0 fill-current text-indigo-500" width="12" height="9" viewBox="0 0 12 9">
                  <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                </svg>
              )}
            </button>
          ))}
        </div>
      </Transition>
    </div>
  );
};
