import React, { useRef, useEffect, useState } from 'react';
import { ChartProps } from './Charts';
import { chartColors } from './ChartjsConfig';
import {
  Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { useThemeProvider } from '../Utils/ThemeContext';
import { formatValue, tailwindConfig } from '../Utils/Utils';

// Funzione debounce
const debounce = <T extends unknown[]>(func: (...args: T) => void, delay: number) => 
{
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...args: T) => 
  {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => 
    {
      func(...args);
    }, delay);
  };
};

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

export const RealtimeChart: React.FC<ChartProps> = ({ data, width, height }) => 
{
  const [chart, setChart] = useState<Chart | null>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const chartValue = useRef<HTMLDivElement>(null);
  const chartDeviation = useRef<HTMLDivElement>(null);
  const { currentTheme } = useThemeProvider();
  const darkMode = currentTheme === 'dark';
  const { textColor, gridColor, tooltipTitleColor, tooltipBodyColor, tooltipBgColor, tooltipBorderColor } = chartColors;

  // Funzione di debounce per aggiornare il tema del grafico
  const debouncedUpdateTheme = debounce(() => 
  {
    if (!chart) return;

    const scales = chart.options?.scales;
    if (scales) 
    {
      scales.x!.ticks!.color = darkMode ? textColor.dark : textColor.light;
      scales.y!.ticks!.color = darkMode ? textColor.dark : textColor.light;
      scales.y!.grid!.color = darkMode ? gridColor.dark : gridColor.light;
    }
    const tooltip = chart.options?.plugins?.tooltip;
    if (tooltip) 
    {
      tooltip.bodyColor = darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light;
      tooltip.backgroundColor = darkMode ? tooltipBgColor.dark : tooltipBgColor.light;
      tooltip.borderColor = darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light;
    }

    chart.update('none');
  }, 300); // Delay di 300ms per evitare troppi aggiornamenti

  useEffect(() => 
  {
    const ctx = canvas.current!;
    const newChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            border: {
              display: false,
            },
            suggestedMin: 30,
            suggestedMax: 80,
            ticks: {
              maxTicksLimit: 5,
              callback: (value) => formatValue(value),
              color: darkMode ? textColor.dark : textColor.light,
            },
            grid: {
              color: darkMode ? gridColor.dark : gridColor.light,
            },
          },
          x: {
            type: 'time',
            time: {
              parser: 'hh:mm:ss',
              unit: 'second',
              tooltipFormat: 'MMM DD, H:mm:ss a',
              displayFormats: {
                second: 'H:mm:ss',
              },
            },
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              autoSkipPadding: 48,
              maxRotation: 0,
              color: darkMode ? textColor.dark : textColor.light,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            titleFont: {
              weight: 600,
            },
            callbacks: {
              label: (context) => formatValue(context.parsed.y),
            },
            titleColor: darkMode ? tooltipTitleColor.dark : tooltipTitleColor.light,
            bodyColor: darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light,
            backgroundColor: darkMode ? tooltipBgColor.dark : tooltipBgColor.light,
            borderColor: darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        animation: false,
        maintainAspectRatio: false,
      },
    });
    setChart(newChart);
    return () => newChart.destroy();
  }, [darkMode, data, gridColor.dark, gridColor.light, textColor.dark, textColor.light, tooltipBgColor.dark, tooltipBgColor.light, tooltipBodyColor.dark, tooltipBodyColor.light, tooltipBorderColor.dark, tooltipBorderColor.light, tooltipTitleColor.dark, tooltipTitleColor.light]);

  // Aggiorna i valori nella parte superiore del grafico
  useEffect(() => 
  {
    const currentValue = data.datasets[0].data[data.datasets[0].data.length - 1] as number;
    const previousValue = data.datasets[0].data[data.datasets[0].data.length - 2] as number;

    // Verifica che currentValue e previousValue siano numeri validi
    if (typeof currentValue === 'number' && typeof previousValue === 'number') 
    {
      const diff = ((currentValue - previousValue) / previousValue) * 100;

      // Aggiorna il contenuto del div del valore corrente
      chartValue.current!.innerHTML = String(currentValue);

      // Aggiorna il div della deviazione con il colore appropriato e il valore differenziale
      chartDeviation.current!.style.backgroundColor = diff < 0
        ? tailwindConfig().theme.colors.amber[500]
        : tailwindConfig().theme.colors.emerald[500];

      chartDeviation.current!.innerHTML = `${diff > 0 ? '+' : ''}${diff.toFixed(2)}%`;
    }
  }, [data]);

  // Debounced update del tema del grafico
  useEffect(() => 
  {
    debouncedUpdateTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTheme]);

  return (
    <React.Fragment>
      <div className="px-5 py-3">
        <div className="flex items-start">
          <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2 tabular-nums">$<span ref={chartValue}>57.81</span></div>
          <div ref={chartDeviation} className="text-sm font-semibold text-white px-1.5 rounded-full"></div>
        </div>
      </div>
      <div className="grow">
        <canvas ref={canvas} width={width} height={height}></canvas>
      </div>
    </React.Fragment>
  );
};
