import React, { useEffect, useCallback } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import './Charts/ChartjsConfig';
import './Css/style.css';

import { Debug } from './Pages/Debug';
import { Role } from "./Api/Roles/RoleManager";
import { CommonLayout } from './App/Navigation/Layout';
import { ChatbotRoutes } from './Features/GenAI/ChatbotRoutes';
import { AdminRoutes } from './Routing/AdminRoutes';
import { ProtectedRoute } from './Routing/ProtectedRoute';
import { PublicRoutes } from './Routing/PublicRoutes';
import { SharedRoutes } from './Routing/SharedRoutes';

export const App: React.FC = () => 
{
  const location = useLocation();

  useEffect(() => 
  {
    document.querySelector('html')?.classList.add('scroll-smooth');
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Routes>

      <Route path="/account/*" element={<PublicRoutes />} />
      <Route path="/gen-ai/chatbot/*" element={<ChatbotRoutes />} />
      <Route
        path="*"
        element={
          <ProtectedRoute
            element={(props) => (
              <CommonLayout>
                <SharedRoutes {...props} />
              </CommonLayout>
            )}
          />
        }
      />
      <Route
        path="/admin/*"
        element={
          <ProtectedRoute
            element={(props) => (
              <CommonLayout>
                <AdminRoutes {...props} />
              </CommonLayout>
            )}
            roles={[Role.Admin]}
          />
        }
      />
      <Route path="/debug" element={<Debug />} />
    </Routes>
  );
};

// NUOVO APP.TSX
// export const App: React.FC = () => {
//   const context = useSessionContext();
//   const location = useLocation();

//   if (context.isLoading) {
//     return <div>Loading...</div>;
//   }

//   const ProtectedRouteDona = () => {
//     return context.session
//       ? <Outlet />
//       : <Navigate to="/account/login/" state={{ from: location }} />;
//   };

//   return (

//     <Routes>

//       <Route path="/account/*" element={<PublicRoutes />} />
//       <Route path="/gen-ai/chatbot/*" element={<ChatbotRoutes />} />

//       <Route element={<ProtectedRouteDona />}>
//         <Route path="/" element={<Dashboard />} />
//         <Route path='user-list' element={<UserList />} />
//       </Route>

//       <Route path="/debug" element={<Debug />} />
//       <Route path="*" element={<PageNotFound />} />
//     </Routes>
//   );
// }

// FUNZIONANTE CON SUPABASE
// <Routes>

//   <Route path="/account/login" element={<LoginForm />} />

//   <Route element={<ProtectedRoute />}>
//     <Route path='/' element={<Dashboard />} />
//   </Route>

// </Routes>
