import zxcvbn from 'zxcvbn';
import { getLocale } from '../ReactIntl/IntlConfig';
import { PasswordCriteria } from '../Utils/PasswordUtils';
import { SignupAction, SignupActions } from './SignupActions';

export type SignupState = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  passwordStrength: zxcvbn.ZXCVBNResult | null;
  errorMessage: string;
  bannerOpen: boolean;
  tooltipOpen: boolean;
  successMessage: string;
  isLoading: boolean;
  selectedLocale: string;
  focusedInput: string;
  passwordMatch: boolean;
  passwordCriteria: PasswordCriteria;
};

export const initialSignupState: SignupState = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  passwordStrength: null,
  errorMessage: '',
  bannerOpen: false,
  tooltipOpen: false,
  successMessage: '',
  isLoading: false,
  selectedLocale: getLocale(),
  focusedInput: '',
  passwordMatch: true,
  passwordCriteria: {
    hasSpecialChar: false,
    hasUpperCase: false,
    hasNumber: false,
    isLengthValid: false,
  },
};

export function signupReducer(state: SignupState, action: SignupAction): SignupState 
{
  switch (action.constructor) 
  {
    case SignupActions.SetEmail:
      return { ...state, email: (action as InstanceType<typeof SignupActions.SetEmail>).email };
    case SignupActions.SetFirstName:
      return { ...state, firstName: (action as InstanceType<typeof SignupActions.SetFirstName>).firstName };
    case SignupActions.SetLastName:
      return { ...state, lastName: (action as InstanceType<typeof SignupActions.SetLastName>).lastName };
    case SignupActions.SetPassword:
      return { ...state, password: (action as InstanceType<typeof SignupActions.SetPassword>).password };
    case SignupActions.SetConfirmPassword:
      return { ...state, confirmPassword: (action as InstanceType<typeof SignupActions.SetConfirmPassword>).confirmPassword };
    case SignupActions.SetPasswordStrength:
      return { ...state, passwordStrength: (action as InstanceType<typeof SignupActions.SetPasswordStrength>).passwordStrength };
    case SignupActions.SetErrorMessage:
      return { ...state, errorMessage: (action as InstanceType<typeof SignupActions.SetErrorMessage>).errorMessage };
    case SignupActions.SetBannerOpen:
      return { ...state, bannerOpen: (action as InstanceType<typeof SignupActions.SetBannerOpen>).bannerOpen };
    case SignupActions.SetTooltipOpen:
      return { ...state, tooltipOpen: (action as InstanceType<typeof SignupActions.SetTooltipOpen>).tooltipOpen };
    case SignupActions.SetSuccessMessage:
      return { ...state, successMessage: (action as InstanceType<typeof SignupActions.SetSuccessMessage>).successMessage };
    case SignupActions.SetIsLoading:
      return { ...state, isLoading: (action as InstanceType<typeof SignupActions.SetIsLoading>).isLoading };
    case SignupActions.SetSelectedLocale:
      return { ...state, selectedLocale: (action as InstanceType<typeof SignupActions.SetSelectedLocale>).selectedLocale };
    case SignupActions.SetFocusedInput:
      return { ...state, focusedInput: (action as InstanceType<typeof SignupActions.SetFocusedInput>).focusedInput };
    case SignupActions.SetPasswordMatch:
      return { ...state, passwordMatch: (action as InstanceType<typeof SignupActions.SetPasswordMatch>).passwordMatch };
    case SignupActions.SetPasswordCriteria:
      return { ...state, passwordCriteria: (action as InstanceType<typeof SignupActions.SetPasswordCriteria>).passwordCriteria };
    default:
      return state;
  }
}