import React from 'react';
import { useForm } from 'react-hook-form'; 
import { InputField } from '../../../ReactHookForm/Components/InputField';
import { TextareaField } from '../../../ReactHookForm/Components/TextareaField';
import { Button } from '../../../Components/Actions/Buttons/Buttons';

interface QAInputProps 
{
  onSubmit: (data: { question: string; answer: string }) => void;
}

export const QAInput: React.FC<QAInputProps> = ({ onSubmit }) => 
{
  // Configurazione del form con react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<{ question: string; answer: string }>();

  const onSubmitForm = handleSubmit((data) => 
  {
    onSubmit(data);
    reset();
  });

  // Monitoraggio dei valori per abilitare/disabilitare il pulsante di submit
  const questionValue = watch('question');
  const answerValue = watch('answer');

  return (
    <div className="shadow-md border border-gray-100 dark:border-slate-700 dark:bg-slate-800 p-4 rounded-lg">
      <div className="flex items-center mb-4">
        <span className="w-6 h-6 rounded-full bg-indigo-500 text-white flex items-center justify-center text-sm mr-2">
          1
        </span>
        <span className="text-sm font-medium">Add Q/A</span>
      </div>
      <form onSubmit={onSubmitForm} className="space-y-4">
        <div>
          <InputField
            name="question"
            label="Question"
            placeholder="Type your question here"
            type="text"
            register={register}
            errors={errors}
            value={questionValue}
            onChange={register('question').onChange}
            className="w-full p-2 border rounded-md"
          />
        </div>
        <div>
          <TextareaField
            name="answer"
            label="Answer"
            placeholder="Type your answer here"
            rows={4}
            register={register}
            errors={errors}
            value={answerValue}
            onChange={register('answer').onChange}
            className="w-full p-2 border rounded-md min-h-[100px]"
          />
        </div>
        <Button
          variant="primary"
          buttonType="submit"
          className="w-full dark:disabled:border-slate-500"
          disabled={!questionValue || !answerValue}
        >
          Add Q&A
        </Button>
      </form>
    </div>
  );
};
