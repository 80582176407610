export const SigninActions = {
  SetEmail: class 
  {
    constructor(public readonly email: string) {}
  },
  SetPassword: class 
  {
    constructor(public readonly password: string) {}
  },
  SetRememberMe: class 
  {
    constructor(public readonly rememberMe: boolean) {}
  },
  SetErrorMessage: class 
  {
    constructor(public readonly errorMessage: string) {}
  },
  SetBannerOpen: class 
  {
    constructor(public readonly bannerOpen: boolean) {}
  },
  SetIsLoading: class 
  {
    constructor(public readonly isLoading: boolean) {}
  },
  SetResentButton: class 
  {
    constructor(public readonly resentButton: boolean) {}
  },
  SetToastOpen: class 
  {
    constructor(public readonly toastOpen: boolean) {}
  },
  SetToastType: class 
  {
    constructor(public readonly toastType: 'warning' | 'error' | 'success' | 'info') {}
  },
  SetToastMessage: class 
  {
    constructor(public readonly toastMessage: string) {}
  }
};

export type SigninAction = InstanceType<typeof SigninActions[keyof typeof SigninActions]>;