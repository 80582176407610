import React, { forwardRef, useCallback, useMemo, useEffect, useState, useRef } from "react";
import WordCloud from "react-d3-cloud-modern";

type Word = { text: string; value: number };

type Props = {
  words: Word[];
};

const MAX_WORDS = 100;
const MAX_ALLOWED_FONT_SIZE = 80; // Limite massimo per la dimensione del font

export const WordCloudComponent = forwardRef<HTMLDivElement, Props>(({ words }, ref) => 
{
  const [containerSize, setContainerSize] = useState({ width: 500, height: 400 });
  const containerRef = useRef<HTMLDivElement>(null);

  // Calcolo della dimensione del container per rendere la WordCloud responsiva
  useEffect(() => 
  {
    const handleResize = () =>
    {
      if (containerRef.current)
      {
        const { offsetWidth, offsetHeight } = containerRef.current;
        setContainerSize({
          width: offsetWidth,
          height: offsetHeight,
        });
      }
    };

    // Chiamata iniziale e listener per il ridimensionamento
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sortedWords = useMemo(() => words.sort((a, b) => b.value - a.value).slice(0, MAX_WORDS), [words]);

  const [minOccurrences, maxOccurrences] = useMemo(() => 
  {
    const min = Math.min(...sortedWords.map((w) => w.value));
    const max = Math.max(...sortedWords.map((w) => w.value));
    return [min, max];
  }, [sortedWords]);

  // Adatta la dimensione del font in base alla dimensione del container, con un limite massimo
  const calculateFontSize = useCallback(
    (wordOccurrences: number) => 
    {
      const normalizedValue = (wordOccurrences - minOccurrences) / (maxOccurrences - minOccurrences);
      // Calcola il font massimo e minimo in base alla larghezza del container, con un limite massimo
      let dynamicMaxFontSize;

      if (containerSize.width > 600) 
      {
        dynamicMaxFontSize = containerSize.width / 3; // Aumenta la dimensione per contenitori grandi
      } 
      else if (containerSize.width > 400) 
      {
        dynamicMaxFontSize = containerSize.width / 4; // Dimensione intermedia per contenitori medi
      } 
      else 
      {
        dynamicMaxFontSize = containerSize.width / 5; // Dimensione più piccola per contenitori più piccoli
      }

      // Imposta un limite massimo alla dimensione del font
      if (dynamicMaxFontSize > MAX_ALLOWED_FONT_SIZE) 
      {
        dynamicMaxFontSize = MAX_ALLOWED_FONT_SIZE;
      }

      const dynamicMinFontSize = containerSize.width / 40;
      const fontSize = dynamicMinFontSize + normalizedValue * (dynamicMaxFontSize - dynamicMinFontSize);

      return Math.round(fontSize);
    },
    [maxOccurrences, minOccurrences, containerSize]
  );

  const calculateFontWeight = useCallback(
    (wordOccurrences: number) => 
    {
      const normalizedValue = (wordOccurrences - minOccurrences) / (maxOccurrences - minOccurrences);
      const fontWeight = 400 + normalizedValue * (700 - 400);
      return Math.round(fontWeight);
    },
    [maxOccurrences, minOccurrences]
  );

  // Calcola un padding dinamico in base alla dimensione del container
  const calculatePadding = useCallback(() => 
  {
    // Rimuoviamo completamente il padding per avere parole più vicine possibile
    return 0;
  }, []);

  // Funzione per determinare la rotazione delle parole
  const calculateRotation = useCallback((word: Word) => 
  {
    // Le parole con valore inferiore a 40 sono in verticale, altrimenti in orizzontale
    return word.value < 40 ? 90 : 0;
  }, []);

  return (
    <div
      ref={ref}
      className="flex flex-col col-span-full sm:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700"
    >
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">Intenti e Parole Chiave</h2>
      </header>
      <div ref={containerRef} className="relative w-full h-[400px] sm:h-[400px]">
        <WordCloud
          width={containerSize.width}
          height={containerSize.height}
          font={"Poppins"}
          fontWeight={(word) => calculateFontWeight(word.value)}
          data={sortedWords}
          rotate={(word) => calculateRotation(word)} // Parole con valore sotto 40 sono in verticale, altre in orizzontale
          padding={calculatePadding()} // Padding ridotto a 0 per massima vicinanza delle parole
          fontSize={(word) => calculateFontSize(word.value)}
          random={() => 0.5} // Fissato per ridurre la casualità
        />
      </div>
    </div>
  );
});

WordCloudComponent.displayName = "WordCloud";
