import React from 'react';
import { getIntl } from '../../ReactIntl/IntlConfig';
import { DeleteButtonLocale } from '../../ReactIntl/LocaleInterfaces';

interface DeleteButtonProps 
{
  selectedItems: string[];
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ selectedItems }) => 
{
  const intl = getIntl<DeleteButtonLocale>("deleteButton");

  return (
    <div className={`${selectedItems.length < 1 ? 'hidden' : ''}`}>
      <div className="flex items-center">
        <div className="hidden xl:block text-sm italic mr-2 whitespace-nowrap">
          <span>{selectedItems.length}</span> {intl.itemsSelected}
        </div>
        <button className="btn bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-rose-500">
          {intl.deleteBtn}
        </button>
      </div>
    </div>
  );
};
