import React from 'react';
import { ModalTextareaProps } from './ModalTypes';

const textAreaVariantMap = {
  description: {
    name: 'description',
    label: 'Descrizione',
  },
  message: {
    name: 'message',
    label: 'Messaggio',
  },
  notes: {
    name: 'notes',
    label: 'Note',
  },
};

export const ModalTextarea: React.FC<ModalTextareaProps> = ({ variant, name, label, value, onChange }) => 
{
  const variantDetails = variant ? textAreaVariantMap[variant] : { name: '', label: '' };

  // Usa name e label passati come props se presenti, altrimenti prendi quelli dalla variant.
  const finalName = name ?? variantDetails.name;
  const finalLabel = label ?? variantDetails.label;

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium" htmlFor={finalName}>
        {finalLabel}
      </label>
      <textarea
        id={finalName}
        name={finalName}
        value={value}
        onChange={onChange}
        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-slate-800 text-gray-900 dark:text-gray-100"
        rows={4}
      />
    </div>
  );
};
