import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IGenAIWriterTemplate } from '../Api/Types/GenAIInterface';
import { useGenAI } from '../Hooks/UseGenAI_';
import { GenAIActions } from '../Utils/GenAIActions';
import { GenAIService } from '../Utils/GenAIService';
import { RichTextEditor } from '../../../Components/Component/RichTextEditor/RichTextEditor';
import { AIFormGenerator } from './AIFormGenerator';

export const AiWriterGenerator: React.FC = () => 
{
  const { id } = useParams<{ id: string }>(); 
  const navigate = useNavigate();
  const { state, dispatch } = useGenAI();
  const [templateData, setTemplateData] = useState<IGenAIWriterTemplate | undefined>();
  const [generatedMarkdown, setGeneratedMarkdown] = useState<string>('');

  const actions = useMemo(() => new GenAIActions(dispatch), [dispatch]);
  const service = useMemo(() => new GenAIService(), []);

  const fetchTemplateData = useCallback(async () => 
  {
    if (id) 
    { 
      actions.fetchStart();
      const response = await service.getWriterTemplate(id);
      setTemplateData(response.data);
      actions.fetchSuccess([response.data]);
    
    }
  }, [id, actions, service]); 

  useEffect(() => 
  {
    fetchTemplateData();
  }, [fetchTemplateData]);

  const handleMarkdownGenerated = useCallback((markdown: string) => 
  {
    setGeneratedMarkdown(markdown);
  }, []);

  const handleGoBack = useCallback(() => 
  {
    navigate(-1);
  }, [navigate]);

  if (state.isLoading) 
  {
    return <div className="text-center py-10">Loading...</div>;
  }

  if (state.error) 
  {
    return <div className="text-center py-10 text-red-500">{state.error}</div>;
  }

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {templateData?.name}
                </h1>
                <p className="text-sm text-slate-600 dark:text-slate-400">
                  {templateData?.description}
                </p>
              </div>
              <div>
                <button
                  onClick={handleGoBack}
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  Back
                </button>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row gap-8">
              <div className="w-full lg:w-2/5">
                {templateData && (
                  <AIFormGenerator
                    templateData={templateData}
                    onMarkdownGenerated={handleMarkdownGenerated}
                  />
                )}
              </div>
              <div className="w-full lg:w-3/5">
                <div className="bg-white dark:bg-gray-800 shadow-lg rounded-sm mb-8 p-6">
                  <RichTextEditor markdown={generatedMarkdown} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};