const SeparatorLV1 = '\x11';
const SeparatorLV2 = '\x12';
const SeparatorLV3 = '\x13';

//MAX PAGE SIZE 100
// I%11{index}%11{page_size}
export class PageIndex
{
  public static readonly TYPE_ID = 'I';
  public static readonly DEFAULT_PAGE_SIZE = 10;

  constructor(public index: number = 0, public size: number = PageIndex.DEFAULT_PAGE_SIZE) { }

  toString(): string
  {
    return `${PageIndex.TYPE_ID}${SeparatorLV1}${this.index}${SeparatorLV1}${this.size}`;
  }

  static parse(s: string): PageIndex
  {
    if (s[0] !== PageIndex.TYPE_ID) throw new Error("Invalid PageIndex string");
    const [, index, size] = s.split(SeparatorLV1);
    return new PageIndex(Number(index), Number(size)); 
  }
}

// d%11{name}%12a%11{email}
//{I%110%1130}%13{%12info.givenName}%13 

export class Sorting
{
  constructor(public operations: Array<{ property: string; order: 'asc' | 'desc' }> = []) { }

  toString(): string 
  {
    return this.operations.map(op =>
      `${op.order === 'desc' ? 'd' : 'a'}${SeparatorLV1}${op.property}`
    ).join(SeparatorLV2);
  }

  static parse(s: string): Sorting
  {
    const operations = s.split(SeparatorLV2).map(op =>
    {
      const [order, property] = op.split(SeparatorLV1);
      return { property, order: order === 'd' ? 'desc' : 'asc' as 'asc' | 'desc' };
    });

    return new Sorting(operations);
  }
}

//export class Sorting 
//{
//  constructor(public operations: Array<{ property: string }> = []) { }

//  toString(): string 
//  {
//    return this.operations.map(op =>
//      `${op.property}`
//    ).join(SeparatorLV2);
//  }

//  static parse(s: string): Sorting 
//  {
//    const operations = s.split(SeparatorLV2).map(op => 
//    {
//      const [property] = op.split(SeparatorLV1);
//      return { property };
//    });

//    return new Sorting(operations);
//  }
//}

// {property_1}%11{value_1}%12{property_2}%11{value_2}
export class Search
{
  constructor(public operations: Array<{ property: string; term: string }> = []) { }

  toString(): string
  {
    return this.operations.map(op =>
      `${op.property}${SeparatorLV1}%${op.term}%`
    ).join(SeparatorLV2);
  }

  static parse(s: string): Search 
  {
    const operations = s.split(SeparatorLV2).map(op => 
    {
      const [property, term] = op.split(SeparatorLV1);
      return { property, term };
    });
    return new Search(operations);
  }
}

//{page}%13{sorting}%13{search}
//%13%1e
export class Filter
{
  constructor(
    public page?: PageIndex,
    public sorting?: Sorting,
    public search?: Search
  ) { }

  toString(): string
  {
    return [
      this.page?.toString() || '',
      this.sorting?.toString() || '',
      this.search?.toString() || ''
    ].join(SeparatorLV3);
  }

  static parse(s: string): Filter
  {
    const [pageStr, sortingStr, searchStr] = s.split(SeparatorLV3);
    return new Filter(
      pageStr ? PageIndex.parse(pageStr) : undefined,
      sortingStr ? Sorting.parse(sortingStr) : undefined,
      searchStr ? Search.parse(searchStr) : undefined
    );
  }
}

export const userFilter = (
  currentPage: number,
  itemsPerPage: number,
  sortingProperty: string,
  order: 'asc' | 'desc',
  searchProperty: string,
  searchTerm: string
): Filter =>
{
  const pageIndex = new PageIndex(currentPage, itemsPerPage);
  const sorting = new Sorting([{ property: sortingProperty, order: order }]);
  const search = searchTerm.trim() !== "" ? new Search([{ property: searchProperty, term: searchTerm }]) : new Search([]);

  return new Filter(pageIndex, sorting, search);
};
