import { useEffect, useState, useCallback, useRef } from "react";

interface DebouncedValue<T> 
{
  value: T;
  cancel: () => void;
}

/**
 * A custom React hook that debounces a value by a specified delay.
 *
 * @param {T} value - The value to debounce.
 * @param {number} delay - The delay in milliseconds before the value is updated.
 * @returns {DebouncedValue<T>} The debounced value and a cancel function.
 */
export const useDebounce = <T>(value: T, delay: number): DebouncedValue<T> => 
{
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const cancel = useCallback(() => 
  {
    if (timeoutRef.current) 
    {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  useEffect(() => 
  {
    // Clear the timeout if there's a new value or delay change
    cancel();

    timeoutRef.current = setTimeout(() => 
    {
      setDebouncedValue(value);
    }, delay);

    // Cleanup on unmount or value/delay change
    return cancel;
  }, [value, delay, cancel]);

  return { value: debouncedValue, cancel };
};
