import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { BaseChatbot } from '../ChatbotLayout/BaseChatbot';

import { IGenAIChatTemplate } from '../Api/Types/GenAIInterface';
import { animationCustomization, styleCustomization } from '../../../Modules/WindTreTest/WindTreAssets/WindChatboStyles';
import { useChatbot } from '../Hooks/UseChatbot';
import { ChatbotActions } from '../ChatbotUtils/ChatbotActions';
import { useAIChatbotLogic } from './AIChatLogic';

export const AiChatPage: React.FC = () => 
{
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const chatbot = location.state?.chatbot as IGenAIChatTemplate | undefined;
  const isFloatingChatbot = chatbot?.metadata ? JSON.parse(chatbot.metadata).floatingSettings.isFloatingChatbot : false;
  
  const { dispatch } = useChatbot();
  const actions =  ChatbotActions.getInstance(dispatch);
  
  useEffect(() => 
  {
    if (id) 
    {
      actions.setChatId(id);
    }
    if (chatbot) 
    {
      actions.setCurrentTemplate(chatbot);
    }
  }, [id, chatbot, actions]);
  
  const useChatbotLogic = useAIChatbotLogic(chatbot?.id);
  
  if (!chatbot) 
  {
    return <div>Error: Chat template not found</div>;
  }
  
  return (
    <BaseChatbot
      chatbotIcon={chatbot.metadata || "/path/to/default-ai-chat-icon.png"}
      chatbotName={chatbot.name}
      isFloatingChatbot={isFloatingChatbot}
      useChatbotLogic={() => useChatbotLogic}
      styleCustomization={styleCustomization}
      animationCustomization={animationCustomization}
    />
  );
};