import React from 'react';
import { FullScreenChatbotMain } from './FullScreenChatbotMain';
import { WidgetChatbotMain } from './WidgetChatbotMain';
import { useLocation } from 'react-router-dom';
import { ChatbotLogic, WidgetChatbotProps } from '../ChatbotUtils/ChatbotTypes';

interface BaseChatbotProps extends Omit<WidgetChatbotProps, 'chatbotLogic'> 
{
  useChatbotLogic: () => ChatbotLogic;
  isFloatingChatbot?: boolean;
}

export const BaseChatbot: React.FC<BaseChatbotProps> = ({
  chatbotIcon,
  chatbotName,
  useChatbotLogic,
  styleCustomization,
  animationCustomization,
  isFloatingChatbot
}) => 
{
  const location = useLocation();
  
  const isAdminRoute = location.pathname.startsWith('/gen-ai/admin/chatbot');
  
  const chatbotLogic = useChatbotLogic();
  
  console.log('Is Admin Route:', isAdminRoute);

  if (isAdminRoute) 
  {
    return (
      <FullScreenChatbotMain
        chatbotIcon={chatbotIcon}
        chatbotName={chatbotName}
        chatbotLogic={chatbotLogic}
        isFloatingChatbot={isFloatingChatbot}
      />
    );
  }
  else 
  {
    return (
      <WidgetChatbotMain
        chatbotIcon={chatbotIcon}
        chatbotName={chatbotName}
        chatbotLogic={chatbotLogic}
        isFloatingChatbot={isFloatingChatbot}
        styleCustomization={styleCustomization}
        animationCustomization={animationCustomization}
      />
    );
  }
};
