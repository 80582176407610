import React, { useState, useEffect, useRef } from 'react';
import { LucideProps } from 'lucide-react';
import {
  AlertCircle,
  Archive,
  ArrowRight,
  Award,
  Bell,
  Book,
  BookOpen,
  Box,
  Brain,
  Building2,
  Calendar,
  Camera,
  Car,
  Check,
  ChevronLeft,
  ChevronRight,
  CircleDollarSign,
  Clipboard,
  Heart,
  Home
} from 'lucide-react';
import { createRoot } from 'react-dom/client';

interface IconPickerProps 
{
  isOpen: boolean;
  onClose: () => void;
  onSelect: (svg: string) => void;
}

type IconInfo = {
  name: string;
  component: React.FC<LucideProps>;
};

const ICONS_PER_PAGE = 20;

export const IconPicker: React.FC<IconPickerProps> = ({ isOpen, onClose, onSelect }) => 
{
  const [currentPage, setCurrentPage] = useState(0);
  const [animationState, setAnimationState] = useState<'entering' | 'entered' | 'exiting'>('entering');
  const pickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => 
  {
    if (isOpen) 
    {
      setAnimationState('entering');
      const timer = setTimeout(() => 
      {
        setAnimationState('entered');
      }, 10); // Piccolo ritardo per l'animazione di entrata
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const icons: IconInfo[] = [
    { name: 'Alert Circle', component: AlertCircle },
    { name: 'Archive', component: Archive },
    { name: 'Arrow Right', component: ArrowRight },
    { name: 'Award', component: Award },
    { name: 'Bell', component: Bell },
    { name: 'Book', component: Book },
    { name: 'Book Open', component: BookOpen },
    { name: 'Box', component: Box },
    { name: 'Brain', component: Brain },
    { name: 'Building', component: Building2 },
    { name: 'Calendar', component: Calendar },
    { name: 'Camera', component: Camera },
    { name: 'Car', component: Car },
    { name: 'Check', component: Check },
    { name: 'Dollar', component: CircleDollarSign },
    { name: 'Clipboard', component: Clipboard },
    { name: 'Heart', component: Heart },
    { name: 'Home', component: Home },
    { name: 'Alert', component: AlertCircle },
    { name: 'Archive Box', component: Archive }
  ];

  const totalPages = Math.ceil(icons.length / ICONS_PER_PAGE);
  const startIndex = currentPage * ICONS_PER_PAGE;
  const visibleIcons = icons.slice(startIndex, startIndex + ICONS_PER_PAGE);

  const handleSelectIcon = async (IconComponent: React.FC<LucideProps>) => 
  {
    const container = document.createElement('div');
    const root = createRoot(container);

    await new Promise<void>(resolve => 
    {
      root.render(
        <IconComponent
          color="rgb(147, 51, 234)"
          size={24}
          strokeWidth={2}
        />
      );
      resolve();
    });

    const svgElement = container.querySelector('svg');
    if (svgElement) 
    {
      svgElement.setAttribute('class', '');
      svgElement.setAttribute('fill', 'none');
      svgElement.setAttribute('stroke', 'currentColor');
      svgElement.setAttribute('stroke-width', '2');
      svgElement.setAttribute('viewBox', '0 0 24 24');
      const svgString = svgElement.outerHTML;
      root.unmount();
      onSelect(svgString);
      handleClose();
    }
  };

  const handleClose = () => 
  {
    setAnimationState('exiting');
    setTimeout(() => 
    {
      onClose();
    }, 200); // Durata dell'animazione di uscita
  };

  const getAnimationStyle = () => 
  {
    switch (animationState) 
    {
      case 'entering':
        return {
          opacity: 0,
          transform: 'translateY(35px)',
          transition: 'opacity 0.2s ease-out, transform 0.1s ease-out',
        };
      case 'entered':
        return {
          opacity: 1,
          transform: 'translateY(0)',
          transition: 'opacity 0.1s ease-out, transform 0.1s ease-out',
        };
      case 'exiting':
        return {
          opacity: 0,
          transform: 'translateY(30px)',
          transition: 'opacity 0.2s ease-in, transform 0.2s ease-in',
        };
      default:
        return {};
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center" onClick={handleClose}>
      <div
        ref={pickerRef}
        className="bg-white dark:bg-slate-800 rounded-2xl shadow-2xl w-full max-w-2xl max-h-[600px] overflow-hidden"
        style={getAnimationStyle()}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="px-6 py-4 border-b border-gray-100">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold">Choose an Icon</h2>
            <button
              onClick={handleClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300 transition-colors p-1 rounded-full"
            >
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M18 6L6 18M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>

        {/* Icons Grid */}
        <div className="p-6 grid grid-cols-5 gap-4 max-h-[400px] overflow-y-auto">
          {visibleIcons.map((icon, index) => (
            <button
              key={`${icon.name}-${index}`}
              onClick={() => handleSelectIcon(icon.component)}
              className="aspect-square p-4 rounded-xl hover:bg-gray-50 dark:hover:bg-slate-900 flex flex-col items-center justify-center gap-2 group transition-all relative overflow-hidden"
            >
              <div className="relative z-10">
                <icon.component
                  size={24}
                  className="group-hover:text-indigo-500 transition-colors"
                />
              </div>
              <span className="text-xs group-hover:text-indigo-500 transition-colors relative z-10 truncate w-full text-center">
                {icon.name}
              </span>
              <div className="absolute inset-0 bg-gray-50/0 dark:bg-slate-50/0 group-hover:bg-gray-50 dark:group-hover:bg-slate-900 transition-colors" />
            </button>
          ))}
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="px-6 py-4 border-t border-gray-100 flex items-center justify-between">
            <button
              onClick={() => setCurrentPage(prev => Math.max(0, prev - 1))}
              disabled={currentPage === 0}
              className="p-2 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent transition-colors"
            >
              <ChevronLeft className="w-5 h-5 text-gray-600" />
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage + 1} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage(prev => Math.min(totalPages - 1, prev + 1))}
              disabled={currentPage === totalPages - 1}
              className="p-2 rounded-lg hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent transition-colors"
            >
              <ChevronRight className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
