import React, { createContext, useState } from 'react';
import { ProgressContextActions } from '../../../ReactIntl/LocaleInterfaces';
import { getIntl } from '../../../ReactIntl/IntlConfig';

export interface ProgressItem 
{
  type: 'add' | 'move' | 'delete' | 'rename' | 'copy';
  itemName: string;
  itemType: 'file' | 'folder' | 'grid';
  fromLocation?: string;
  toLocation?: string;
  timestamp: Date;
}

export interface ProgressContextType 
{
  progressItems: ProgressItem[];
  isProgressSidebarOpen: boolean;
  addProgressItem: (item: ProgressItem) => void;
  toggleProgressSidebar: () => void;
  addOrShowProgressItem: (item: ProgressItem) => void;
  message: string | null;
  setMessage: (message: string | null) => void;
}

export const ProgressContext = createContext<ProgressContextType | undefined>(undefined);
const intl = getIntl<ProgressContextActions>('progressContextActions');

export const ProgressProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => 
{
  const [progressItems, setProgressItems] = useState<ProgressItem[]>([]);
  const [isProgressSidebarOpen, setIsProgressSidebarOpen] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const addProgressItem = (item: ProgressItem) => 
  {
    setProgressItems(prevItems => [...prevItems, item]);
  };

  const toggleProgressSidebar = () => 
  {
    setIsProgressSidebarOpen(prev => !prev);
    const toggleButton = document.querySelector(".toggle-progress-btn");
    if (toggleButton) toggleButton.classList.toggle("rotate-180", !isProgressSidebarOpen);
  };

  const addOrShowProgressItem = (item: ProgressItem) => 
  {
    addProgressItem(item);
    if (!isProgressSidebarOpen) 
    {
      switch (item.type) 
      {
        case 'add':
          setMessage(`${intl.added} ${item.itemType} "${item.itemName}" ${intl.to} ${item.toLocation || 'Root'}`); 
          break;
        case 'move':
          setMessage(`${intl.moved} ${item.itemType} "${item.itemName}" ${intl.from} ${item.fromLocation} ${intl.to} ${item.toLocation}`);
          break;
        case 'delete':
          setMessage(`${intl.deleted} ${item.itemType} "${item.itemName}" ${intl.from} ${item.fromLocation || 'Root'}`);
          break;
        case 'rename':
          setMessage(`${intl.renamed} ${item.itemType} "${item.fromLocation}" ${intl.to} "${item.itemName}"`);
          break;
        case 'copy':
          setMessage(`${intl.copied} ${item.itemType} "${item.itemName}" ${intl.to} ${item.toLocation}`);
          break;
      }
    }
  };

  return (
    <ProgressContext.Provider value={{
      progressItems,
      isProgressSidebarOpen,
      addProgressItem,
      toggleProgressSidebar,
      addOrShowProgressItem,
      message,
      setMessage
    }}>
      {children}
    </ProgressContext.Provider>
  );
};
