import React, { useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TRANSFORMERS
} from '@lexical/markdown';
import { $createCodeNode, $isCodeNode } from '@lexical/code';
import { $createTextNode, $getRoot } from 'lexical';

export const MarkdownActions: React.FC = () => 
{
  const [editor] = useLexicalComposerContext();
  const [isMarkdownMode, setIsMarkdownMode] = useState(false);

  const toggleMarkdown = () => 
  {
    editor.update(() => 
    {
      const root = $getRoot();
      const firstChild = root.getFirstChild();

      if ($isCodeNode(firstChild) && firstChild.getLanguage() === 'markdown') 
      {
        $convertFromMarkdownString(firstChild.getTextContent(), TRANSFORMERS);
        setIsMarkdownMode(false);
      }
      else 
      {
        const markdown = $convertToMarkdownString(TRANSFORMERS);
        root.clear().append($createCodeNode('markdown').append($createTextNode(markdown)));
        setIsMarkdownMode(true);
      }
    });
  };

  return (
    <div className="flex items-center space-x-2 mt-3 p-2 border-t border-gray-200 dark:border-gray-700">
      <span className="font-medium text-sm">Markdown Mode:</span>
      <button
        onClick={toggleMarkdown}
        className={`
          px-3 py-1 rounded-md text-sm font-medium
          ${isMarkdownMode
      ? 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-100'
      : 'text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700'
    }
        `}
      >
        {isMarkdownMode ? 'Enabled' : 'Disabled'}
      </button>
    </div>
  );
};