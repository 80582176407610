import React, { useState } from 'react';
import { Button } from '../Actions/Buttons/Buttons';
import { ButtonGroup } from '../Actions/Buttons/ButtonGroup';

const ButtonPage: React.FC = () => 
{
  // Stato per sapere quale bottone è selezionato nei gruppi primario e secondario
  const [selectedPrimary, setSelectedPrimary] = useState<string | null>('Weekly');
  const [selectedSecondary, setSelectedSecondary] = useState<string | null>('Weekly');

  // Funzioni per aggiornare lo stato quando un bottone viene cliccato
  const handlePrimaryClick = (buttonType: string) => 
  {
    setSelectedPrimary(buttonType); // Aggiorna il bottone selezionato nel gruppo primario
  };

  const handleSecondaryClick = (buttonType: string) => 
  {
    setSelectedSecondary(buttonType); // Aggiorna il bottone selezionato nel gruppo secondario
  };

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900">
        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="mb-8">
              <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Button ✨</h1>
            </div>

            <div className="border-t border-slate-200 dark:border-slate-700">
              <div className="space-y-8 mt-8">

                {/* Appearances */}
                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Appearances</h2>
                  <div className="flex flex-wrap items-center -m-1.5">
                    <div className="m-1.5">
                      <Button variant="primary" onClick={() => console.log('Primary button clicked')}>Primary</Button>
                    </div>
                    <div className="m-1.5">
                      <Button variant="secondary" onClick={() => console.log('Secondary button clicked')}>Secondary</Button>
                    </div>
                    <div className="m-1.5">
                      <Button variant="tertiary" onClick={() => console.log('Tertiary button clicked')}>Tertiary</Button>
                    </div>
                    <div className="m-1.5">
                      <Button variant="danger" onClick={() => console.log('Danger button clicked')}>Danger</Button>
                    </div>
                    <div className="m-1.5">
                      <Button variant="textDanger" onClick={() => console.log('Danger button clicked')}>Danger</Button>
                    </div>
                    <div className="m-1.5">
                      <Button variant="success" onClick={() => console.log('Success button clicked')}>Success</Button>
                    </div>
                    <div className="m-1.5">
                      <Button variant="textSuccess" onClick={() => console.log('Success button clicked')}>Success</Button>
                    </div>
                  </div>
                </div>

                {/* States */}
                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">States</h2>
                  <div className="flex flex-wrap items-center -m-1.5">
                    <div className="m-1.5">
                      <Button variant="tertiary" disabled onClick={() => console.log('Disabled button clicked')}>
                        Disabled
                      </Button>
                    </div>
                    <div className="m-1.5">
                      <Button variant="tertiary" loading disabled onClick={() => console.log('Loading button clicked')}>
                        Loading
                      </Button>
                    </div>
                  </div>
                </div>

                {/* Sizes */}
                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Sizes</h2>
                  <div className="flex flex-wrap items-center -m-1.5">
                    <div className="m-1.5">
                      <Button size="s" variant="primary" onClick={() => console.log('XS button clicked')}>Button</Button>
                    </div>
                    <div className="m-1.5">
                      <Button size="m" variant="primary" onClick={() => console.log('SM button clicked')}>Button</Button>
                    </div>
                    <div className="m-1.5">
                      <Button size="l" variant="primary" onClick={() => console.log('MD button clicked')}>Button</Button>
                    </div>
                    <div className="m-1.5">
                      <Button size="xl" variant="primary" onClick={() => console.log('LG button clicked')}>Button</Button>
                    </div>
                  </div>
                </div>

                {/* Buttons with an Icon */}
                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Buttons with an Icon</h2>
                  <div className="flex flex-wrap items-center -m-1.5">
                    <div className="m-1.5">
                      <Button type="add" hideOnMobile="label"
                        onClick={() => console.log('Add Event clicked')}>
                        Add Event
                      </Button>
                    </div>
                    <div className="m-1.5">
                      <Button type="edit" hideOnMobile="label"
                        variant="tertiary"
                        onClick={() => console.log('Edit Content clicked')}>
                        Edit Content
                      </Button>
                    </div>
                    <div className="m-1.5">
                      <Button type="delete" hideOnMobile="icon"
                        variant="textDanger"
                        onClick={() => console.log('Delete clicked')}>
                        Delete
                      </Button>
                    </div>
                    <div className="m-1.5">
                      <Button type="download" hideOnMobile="icon"
                        variant="primary"
                        onClick={() => console.log('Download clicked')}>
                        Download
                      </Button>
                    </div>
                    <div className="m-1.5">
                      <Button type="move" hideOnMobile="label"
                        variant="secondary"
                        onClick={() => console.log('Move To clicked')}>
                        Move To
                      </Button>
                    </div>
                    <div className="m-1.5">
                      <Button type="copy" hideOnMobile="icon"
                        variant="secondary"
                        onClick={() => console.log('Copy To clicked')}>
                        Copy To
                      </Button>
                    </div>
                  </div>
                </div>

                {/* Icon Buttons */}
                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Icon Buttons</h2>
                  <div className="flex flex-wrap items-center -m-1.5">

                    <div className="m-1.5">
                      {/* Button con icona "edit" */}
                      <Button
                        variant="tertiary"
                        type="edit" // Utilizza la configurazione predefinita per l'icona e la label
                        onClick={() => console.log('Edit button clicked')}
                        hideText
                      />
                    </div>

                    <div className="m-1.5">
                      {/* Button con icona "delete" */}
                      <Button
                        variant="textDanger"
                        type="delete" // Utilizza la configurazione predefinita per l'icona e la label
                        onClick={() => console.log('Delete button clicked')}
                        hideText
                      />
                    </div>

                    <div className="m-1.5">
                      {/* Button con icona "check" */}
                      <Button
                        variant="tertiary"
                        type="check"
                        hideText
                        onClick={() => console.log('Check button clicked')}
                      />
                    </div>

                  </div>
                </div>

                {/* Button Groups */}
                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Button Groups</h2>
                  <div className="flex flex-wrap items-center -m-1.5">
                    <div className="m-1.5">
                      {/* Gruppo di bottoni primari */}
                      <ButtonGroup>
                        <Button
                          variant="primary"
                          groupPosition="first"
                          selected={selectedPrimary === 'Weekly'}
                          onClick={() => handlePrimaryClick('Weekly')}
                        >
                          Weekly
                        </Button>
                        <Button
                          variant="primary"
                          groupPosition="middle"
                          selected={selectedPrimary === 'Monthly'}
                          onClick={() => handlePrimaryClick('Monthly')}
                        >
                          Monthly
                        </Button>
                        <Button
                          variant="primary"
                          groupPosition="last"
                          selected={selectedPrimary === 'Yearly'}
                          onClick={() => handlePrimaryClick('Yearly')}
                        >
                          Yearly
                        </Button>
                      </ButtonGroup>
                    </div>

                    <div className="m-1.5">
                      {/* Gruppo di bottoni secondari */}
                      <ButtonGroup>
                        <Button
                          variant="tertiary"
                          groupPosition="first"
                          selected={selectedSecondary === 'Weekly'}
                          onClick={() => handleSecondaryClick('Weekly')}
                        >
                          Weekly
                        </Button>
                        <Button
                          variant="tertiary"
                          groupPosition="middle"
                          selected={selectedSecondary === 'Monthly'}
                          onClick={() => handleSecondaryClick('Monthly')}
                        >
                          Monthly
                        </Button>
                        <Button
                          variant="tertiary"
                          groupPosition="last"
                          selected={selectedSecondary === 'Yearly'}
                          onClick={() => handleSecondaryClick('Yearly')}
                        >
                          Yearly
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ButtonPage;