import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from './Utils/ThemeContext';
// import { UserInfoProvider } from './Contexts/UserInfo';
import { App } from './App';
import { IntlProvider } from 'react-intl';
import { AppContextProvider } from './Contexts/AppContext';
import { getLanguage } from './ReactIntl/IntlConfig';
import { LanguageContextProvider } from './Contexts/LanguageContext';
import { ErrorProvider } from './Contexts/ErrorContext/ErrorContext';
import { Toaster } from 'react-hot-toast';
import beetIcon from '/beet-icon.svg';
import { RoleRenderingProvider } from './Api/Roles/RoleBaseRendering';
import { RoutesProvider } from './Routing/RoutesContext';
import { ButtonProvider } from './Components/Actions/Buttons/Utils/ButtonProvider';
//import { TabsProvider } from './Components/Actions/Tabs/Utils/TabsProvider';
import { getProjectInfo } from './Utils/GetProjectInfo';
import { projectInfo } from './Config/Env';
import { fetchClientConfig } from './Config/ClientConfig';
import { createClient } from '@supabase/supabase-js';
import { composeProviders, buildProvider } from './Utils/ReactUtlis';
import { SessionContextProvider } from './Features/Auth/Contexts/SessionContext';

// const rootElement = document.getElementById('root');
// if (rootElement) 
// {
//   // details about the project
//   console.log(getProjectInfo());

//   //Taken and modified from https://gist.github.com/phatnguyenuit/68122170e317d13e7148c7563be021b6
//   //This simply allows to compose multiple providers without nesting them as html-like tags
//   type CT = React.ComponentType<React.PropsWithChildren>;
//   type CTWithProps<T> = React.ComponentType<React.PropsWithChildren<T>>;
//   type AnyProvider = Provider<unknown>;
//   type Provider<TProps> = { Component: CTWithProps<TProps>; props?: Omit<TProps, "children">; };
//   const buildProvider = <T,>(Component: CTWithProps<T>, props?: Omit<T, "children">): AnyProvider => ({ Component, props }) as AnyProvider;
//   const composeProviders = <T extends Array<AnyProvider>>(providers: T): CT => ({ children }) => providers.reduceRight<React.JSX.Element>
//   ((prevJSX, { Component: CurrentProvider, props = {} }) => <CurrentProvider {...props}>{prevJSX}</CurrentProvider>, <>{children}</>);
//   //

// const locale = getLanguage();
// const GlobalContextProvider = composeProviders
// ([
//   buildProvider(Router),
//   buildProvider(AppContextProvider),
//   buildProvider(UserInfoProvider),
//   buildProvider(ThemeProvider),
//   buildProvider(RoutesProvider),
//   buildProvider(RoleRenderingProvider),
//   buildProvider(IntlProvider, { locale: locale }),
//   buildProvider(LanguageContextProvider),
//   buildProvider(ErrorProvider),
//   buildProvider(ButtonProvider),
//   //buildProvider(TabsProvider),   //in caso di più Tabs che necessitano di essere sinconizzate tra di loro, scommentare
// ]);

//   const root = ReactDOM.createRoot(rootElement);
//   root.render(
//     // <React.StrictMode>
//     <>
//       <GlobalContextProvider>
//         <Toaster toastOptions={
//           {
//             className: 'dark:bg-slate-900 dark:text-white lg:ml-64',
//             success:
//               {
//                 duration: 3000,
//               },
//             error:
//               {
//                 duration: 3000
//               },
//             loading:
//               {
//                 icon: <img src={beetIcon} className='w-5 animate-bounce' alt="Beet Icon" />,
//               }
//           }
//         } />
//         <App />
//       </GlobalContextProvider>
//     </>
//   //  </React.StrictMode>
//   );
// }

async function renderRoot(rootElement: HTMLElement | null) 
{
  if (!rootElement) return;

  console.log(projectInfo);

  const config = await fetchClientConfig();
  console.log("fetchClientConfig", config);
  const supabase = config.supabase;
  const supabaseClient = createClient(supabase.url, supabase.public_key);
  console.log("supabaseClient ", supabaseClient);

  // const { data: { user } } = await supabaseClient.auth.getUser();
  // const { data: { session } } = await supabaseClient.auth.getSession();
  // console.log("MAIN.tsx: session taken from localStorage", session);

  const locale = getLanguage();
  const GlobalContextProvider = composeProviders
  ([
    // buildProvider(SessionContextProvider, { supabaseClient, initialSession: session }),
    buildProvider(SessionContextProvider, { supabaseClient }),
    buildProvider(Router),
    buildProvider(AppContextProvider, { config }),
    // buildProvider(AppContextProvider),
    // buildProvider(UserInfoProvider),
    buildProvider(RoleRenderingProvider),
    buildProvider(ThemeProvider),
    buildProvider(RoutesProvider),
    buildProvider(IntlProvider, { locale: locale }),
    buildProvider(LanguageContextProvider),
    buildProvider(ErrorProvider),
    // buildProvider(ButtonProvider),
    //buildProvider(TabsProvider),   //in caso di più Tabs che necessitano di essere sinconizzate tra di loro, scommentare
  ]);

  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <GlobalContextProvider>
        {/* <BrowserRouter> */}
        <Toaster toastOptions={
          {
            className: 'dark:bg-slate-900 dark:text-white lg:ml-64',
            success:
            {
              duration: 3000,
            },
            error:
            {
              duration: 3000
            },
            loading:
            {
              icon: <img src={beetIcon} className='w-5 animate-bounce' alt="Beet Icon" />,
            }
          }
        } />
        <App />
        {/* </BrowserRouter> */}
      </GlobalContextProvider>
    </React.StrictMode>
  );
}

renderRoot(document.getElementById('root'));
