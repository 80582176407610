import zxcvbn from 'zxcvbn';
import { PasswordCriteria } from '../Utils/PasswordUtils';

export const SignupActions = {
  SetEmail: class { constructor(public readonly email: string) {} },
  SetFirstName: class { constructor(public readonly firstName: string) {} },
  SetLastName: class { constructor(public readonly lastName: string) {} },
  SetPassword: class { constructor(public readonly password: string) {} },
  SetConfirmPassword: class { constructor(public readonly confirmPassword: string) {} },
  SetPasswordStrength: class { constructor(public readonly passwordStrength: zxcvbn.ZXCVBNResult | null) {} },
  SetErrorMessage: class { constructor(public readonly errorMessage: string) {} },
  SetBannerOpen: class { constructor(public readonly bannerOpen: boolean) {} },
  SetTooltipOpen: class { constructor(public readonly tooltipOpen: boolean) {} },
  SetSuccessMessage: class { constructor(public readonly successMessage: string) {} },
  SetIsLoading: class { constructor(public readonly isLoading: boolean) {} },
  SetSelectedLocale: class { constructor(public readonly selectedLocale: string) {} },
  SetFocusedInput: class { constructor(public readonly focusedInput: string) {} },
  SetPasswordMatch: class { constructor(public readonly passwordMatch: boolean) {} },
  SetPasswordCriteria: class { constructor(public readonly passwordCriteria: PasswordCriteria) {} },
};

export type SignupAction = InstanceType<typeof SignupActions[keyof typeof SignupActions]>;