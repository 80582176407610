import React from 'react';
import { Settings, RefreshCw, X, BarChart } from 'lucide-react';

import { ChatbotStyles } from '../CommonStyles/ChatbotStyles';
import { RoleBasedElement } from '../../../../Api/Roles/RoleBasedElement';
import { Role } from '../../../../Api/Roles/RoleManager';

interface ChatWindowHeaderProps 
{
  toggleSettings: () => void;
  toggleTokenUsage: () => void;
  handleResetChat: () => void;
  onClose: () => void;
  chatId: string;
  chatbotName: string;
  style: typeof ChatbotStyles;
}

export const ChatWindowHeader: React.FC<ChatWindowHeaderProps> = ({
  chatId,
  chatbotName,
  toggleSettings,
  toggleTokenUsage,
  handleResetChat,
  onClose,
  style  
}) => 
{
  return (
    <div className={style.ChatWindowHeader}>
      <div className="flex space-x-2">
        <RoleBasedElement as="button" requiredRoles={Role.Admin} title="Impostazioni" onClick={toggleSettings} className={style.ChatWindowHeaderIcon}>
          <Settings size={24} />
        </RoleBasedElement>
      </div>
      <h3  className={style.ChatWindowHeaderTitle}>{chatbotName}</h3>
      <div className="flex space-x-2">
        {chatId && (
          <button 
            title="Visualizza utilizzo token" 
            onClick={toggleTokenUsage}
            className={style.ChatWindowHeaderIcon}
          >
            <BarChart size={24} />
          </button>
        )}
        <button title="Resetta chat" onClick={handleResetChat} className={style.ChatWindowHeaderIcon}>
          <RefreshCw size={24} />
        </button>
        <button title="Chiudi chat" onClick={onClose} className={style.ChatWindowHeaderIcon}>
          <X size={24} />
        </button>
      </div>
    </div>
  );
};