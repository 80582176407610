import React, { useState, ReactNode } from 'react';
import { useRoleRendering } from '../../Api/Roles/Hooks/UseRoleRendering';

interface SidebarLinkGroupProps 
{
  children: (handleClick: () => void, open: boolean) => ReactNode;
  activecondition: boolean;
  requiredRoles?: string[];
}

export const SidebarLinkGroup: React.FC<SidebarLinkGroupProps> = ({ children, activecondition, requiredRoles }) => 
{
  const [open, setOpen] = useState(activecondition);
  const { hasAnyRole } = useRoleRendering(); 

  const handleClick = () => 
  {
    setOpen(!open);
  };

  if (requiredRoles && !hasAnyRole(requiredRoles)) 
  {
    return null;
  }

  return (
    <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${activecondition && 'bg-slate-900'}`}>
      {children(handleClick, open)}
    </li>
  );
};
