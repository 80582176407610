import { GenAITokenUsage } from "../Api/Types/GenAIInterface";

export interface WindTreChat
{
  text: string;
  role: MessageRole;
  isSelectionPrompt?: boolean;
  options?: string[];
  isTemporary?: boolean;
}

export interface Category
{
  slug: string;
  name: string;
  offers: Offer[];
}

export interface Offer
{
  slug: string;
  name: string;
  description: string;
}

export interface UpdateInstructions
{
  instructions: string
}

export enum MessageRole 
  {
    System = 'System',
    Human = 'Human',
    Assistant = 'Assistant'
  }

export interface ChatMessage
{
  role: MessageRole;
  message?: string;
  usage?: GenAITokenUsage;
  botId?: string;
  text?: string;
  isSelectionPrompt?: boolean;
  options?: string[];
}

export interface ChatbotInfo 
{
  chatId?: string | null;
  isBusiness?: boolean | null;
  name?: string;
  image?: string;
  description?: string;
}

export interface ChatResponseWithoutSSE 
{
  info: ChatbotInfo,
  newMessages: ChatMessage[]
}