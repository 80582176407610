import React from 'react';
import { Tooltip } from '../../Components/Tooltip';
import { BaseFieldProps } from '../Utils/FormFields.types';
import { FieldValues, get } from 'react-hook-form';

export interface SelectFieldProps<T extends FieldValues> extends BaseFieldProps<T> 
{
  options: Array<{ value: string; label: string }>;
  multiple?: boolean;
  placeholder?: string;
  value?: string | string[];
  defaultValue?: string | string[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void ;
  className?: string;
}

export const SelectField = <T extends FieldValues>({
  name,
  label,
  required,
  tooltip,
  register,
  errors,
  options,
  multiple,
  placeholder,
  value,
  defaultValue,
  className,
  onChange,
}: SelectFieldProps<T>) => 
{
  const error = get(errors, name);
  const errorMessage = error?.message as string;

  return (
    <div className="w-full">
      <div className="flex justify-center items-center gap-2 text-center">
        <label className="block text-sm font-medium" htmlFor={name}>
          {label}
          {required && <span className="text-rose-500">*</span>}
        </label>
        {tooltip && (
          <Tooltip position="top">
            <div className="text-xs whitespace-nowrap">{tooltip}</div>
          </Tooltip>
        )}
      </div>
      <select
        {...register(name)}
        id={name}
        multiple={multiple}
        className={`form-select w-full dark:bg-slate-800 ${error ? 'border-rose-500' : ''} ${multiple ? 'bg-none' : ''} ${className}`}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && (
        <div className="text-xs mt-1 text-rose-500">{errorMessage}</div>
      )}
      {multiple && (
        <small className="block mt-1 text-center">
          Hold cmd(on mac) or ctrl(on pc) to select multiple items.
        </small>
      )}
    </div>
  );
};