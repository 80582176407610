import React from 'react';
import { BeetLoader } from '../Components/BeetLoader';

export const Loader: React.FC = () => 
{

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <BeetLoader />
    </div>
  );
};
