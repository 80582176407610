import React, { useState, useEffect, useRef } from 'react';
import { GenAIChatMessage } from '../Api/Types/GenAIInterface';
import { ChatbotLogic } from '../ChatbotUtils/ChatbotTypes';
import { ChatbotAdminBody } from './FullScreenChat/ChatboFullBody';
import { ChatbotAdminFooter } from './FullScreenChat/ChatbotFullFooter';
import { ChatbotAdminHeader } from './FullScreenChat/ChatbotFullHeader';
import { ChatbotSidebar } from './FullScreenChat/ChatbotSidebar';

export interface FullScreenChatbotProps 
{
  chatbotIcon: string;
  chatbotName: string;
  chatbotLogic: ChatbotLogic;
  isFloatingChatbot?: boolean;
}

export const FullScreenChatbotMain: React.FC<FullScreenChatbotProps> = ({
  chatbotIcon,
  chatbotName,
  chatbotLogic,
  isFloatingChatbot
}) => 
{
  const contentArea = useRef<HTMLDivElement>(null);
  const [msgSidebarOpen, setMsgSidebarOpen] = useState<boolean>(true);
  const [messages, setMessages] = useState<GenAIChatMessage[]>(chatbotLogic.messages);

  useEffect(() => 
  {
    if (contentArea.current) 
    {
      contentArea.current.scrollTop = msgSidebarOpen ? 0 : 99999999;
    }
  }, [msgSidebarOpen, messages]);

  useEffect(() => 
  {
    setMessages(chatbotLogic.messages);
  }, [chatbotLogic.messages]);

  const handleSend = async (input: string) => 
  {
    await chatbotLogic.handleSend(input);
  };

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden" ref={contentArea}>
        <main className="grow">
          <div className="relative flex h-full">
            <ChatbotSidebar 
              msgSidebarOpen={msgSidebarOpen} 
              setMsgSidebarOpen={setMsgSidebarOpen}
            />
            <div
              className={`grow flex flex-col md:translate-x-0 transition-transform duration-300 ease-in-out ${
                msgSidebarOpen ? 'translate-x-1/3' : 'translate-x-0'
              }`}
            >
              <ChatbotAdminHeader 
                msgSidebarOpen={msgSidebarOpen} 
                setMsgSidebarOpen={setMsgSidebarOpen}
                chatbotName={chatbotName}
                chatbotIcon={chatbotIcon}
                isFloatingChatbot={isFloatingChatbot}
              />
              <ChatbotAdminBody 
                messages={messages}
                loading={chatbotLogic.loading}
                errorMessage={chatbotLogic.errorMessage}
              />
              <ChatbotAdminFooter 
                onSend={handleSend}
                blockMessage={chatbotLogic.blockMessage}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};