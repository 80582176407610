import React, { useState } from 'react';
import { Button } from '../../../../Components/Actions/Buttons/Buttons';
import { IUserInput } from '../../Api/Types/GenAIInterface';
import { GenAITemplateInputKind } from '../../Api/Types/GenAIEnums';
import { createNewAiWriterInput } from '../../Api/Types/UtilityFunction';
import { useFormContext } from 'react-hook-form';
import { InputField } from '../../../../ReactHookForm/Components/InputField';
import { SelectField } from '../../../../ReactHookForm/Components/SelectField';
import toast from 'react-hot-toast';

export const WriterInputsStep: React.FC= () => 
{
  const [newOption, setNewOption] = useState<string>('');
  const [inputs, setInputs] = useState<IUserInput[]>([]);
  const { register, formState: { errors }, setValue, getValues } = useFormContext();

  const handleAddInput = () => 
  {
    setValue('data.inputs', [...getValues('data.inputs'), createNewAiWriterInput()]);
    setInputs([...getValues('data.inputs'), createNewAiWriterInput()]);
  };

  const handleInputFieldChange = (index: number, field: keyof IUserInput, value: string) => 
  {
    const newInputs = getValues('data.inputs').map((input : IUserInput, i : number) =>
      i === index ? { ...input, [field]: value } : input
    );
    setValue('data.inputs', newInputs);
    setInputs([...inputs, newInputs]);
  };

  const handleAddOption = (index: number) => 
  {
    if (newOption.trim() !== '') 
    {
      const newInputs = getValues('data.inputs').map((input : IUserInput, i : number) =>
        i === index
          ? {
            ...input,
            possibleValues: [...(input.possibleValues || []), newOption.trim()],
          }
          : input
      );
      setValue('data.inputs', newInputs);
      setNewOption('');
    }
  };

  const handleRemoveOption = (inputIndex: number, optionIndex: number) => 
  {
    const newInputs = getValues('data.inputs').map((input : IUserInput, i : number) =>
      i === inputIndex
        ? {
          ...input,
          possibleValues: input.possibleValues?.filter((_, index) => index !== optionIndex),
        }
        : input
    );
    setValue('data.inputs', newInputs);
    setInputs([...inputs, newInputs]);
  };

  const handleRemoveInput = (index: number) => 
  {
    if(getValues('data.inputs').length === 1)
    {
      toast.error("At least one input is required");
      return;
    }
    const newInputs = getValues('data.inputs').filter((_: IUserInput, i: number) => i !== index);
    setValue('data.inputs', newInputs);
    setInputs([newInputs]);
  };

  // Convert enum values to options for SelectField
  const inputTypeOptions= Object.values(GenAITemplateInputKind).map(kind => ({
    value: kind,
    label: kind
  }));

  inputTypeOptions.shift(); // it removes Unknown option

  return (
    <div className="space-y-6 ">
      {getValues('data.inputs').map((input: IUserInput, index: number) => (
        <div key={index} className="p-5 border border-gray-300 rounded-lg bg-white shadow-sm dark:bg-slate-800 relative">
          <Button onClick={() => handleRemoveInput(index)} variant="danger" className="absolute top-0 right-0" buttonType='button' type='cancel' hideText={true}/>
          <div className="grid grid-cols-1 gap-4">

            <SelectField
              name={`data.inputs.${index}.kind`}
              label="Select Input Type"
              required
              tooltip="Input fields for short texts and Textarea fields are good for long text."
              register={register}
              errors={errors}
              options={inputTypeOptions}
              onChange={(e) => handleInputFieldChange(index, 'kind', e.target.value as GenAITemplateInputKind)}
            />

            <InputField
              name={`data.inputs.${index}.name`}
              label="Input name"
              required
              tooltip="Unique input name that you can use in your prompts later."
              register={register}
              errors={errors}
              onChange={(e) => handleInputFieldChange(index, `name`, e.target.value)}
              placeholder="Enter name here.."
            />

            <InputField
              name={`data.inputs.${index}.description`}
              label="Input Description"
              tooltip="A description for the input."
              register={register}
              errors={errors}
              onChange={(e) => handleInputFieldChange(index, 'description', e.target.value)}
              placeholder="Enter description here.."
            />

            {input.kind === GenAITemplateInputKind.Dropdown && (
              <div className="text-center flex flex-col gap-3">
                <div className="flex flex-col gap-2">
                  <InputField
                    name="new-option"
                    register={register}
                    errors={errors}
                    value={newOption}
                    onChange={(e) => setNewOption(e.target.value)}
                    placeholder="New option here.."
                    tooltip='Add a new option for this dropdown.'
                    label='New Option'
                    /* onKeyDown={(e) => e.key === 'Enter' && handleAddOption(index)} */
                  />
                  <Button onClick={() => handleAddOption(index)} variant="secondary" className="ml-2" buttonType='button'>
                    Add Option
                  </Button>
                </div>
                {input.possibleValues?.length !== 0 && <h4 className="text-sm font-medium">Options</h4>}
                <ul className="space-y-2 dark:bg-slate-800">
                  {input.possibleValues?.map((option, optionIndex) => (
                    <li 
                      key={optionIndex} 
                      className="flex items-center justify-between p-2 bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                    >
                      <span className="text-gray-800 dark:text-gray-100">{option}</span>
                      <Button 
                        onClick={() => handleRemoveOption(index, optionIndex)} 
                        variant="danger" 
                        size="s" 
                        buttonType="button"
                        type='delete'
                        hideText
                      />
                    </li>

                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      ))}
      <Button onClick={handleAddInput} variant="primary" className="w-full" buttonType='button'>
        Add New Input
      </Button>
    </div>
  );
};