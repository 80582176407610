import { apiDefinitions } from "../ApiConfig";

const definition = apiDefinitions.connect;

export const connectController = {
  authorize: async (data: URLSearchParams) => 
  {
    const endpoint = definition.authorize;
    const response = await endpoint.invoke(endpoint.url, data);

    return response;
  },
};
