import { useCallback, useState } from 'react';
import { ChatbotLogic } from '../ChatbotUtils/ChatbotTypes';
import { GenAIMessageRole } from '../Api/Types/GenAIEnums';
import { ChatbotService } from '../ChatbotUtils/ChabotService';
import { ChatbotActions } from '../ChatbotUtils/ChatbotActions';
import { useChatbot } from '../Hooks/UseChatbot';

const INITIAL_MESSAGE = "Ciao, di cosa hai bisogno?";

export const useAIChatbotLogic = (chatId: string | undefined): ChatbotLogic => 
{
  const { state, dispatch } = useChatbot();
  const actions = ChatbotActions.getInstance(dispatch);
  const service = ChatbotService.getInstance();
  const [loading, setLoading] = useState(false);

  const handleSend = useCallback(async (message: string) => 
  {
    if (!chatId) return;
    actions.clearError();
    setLoading(true);

    try 
    {
      const userMessage = service.createMessage(
        GenAIMessageRole.Human,
        message
      );
      actions.addMessage(userMessage);

      await service.sendMessage(
        message,
        chatId,
        {
          maxTokens: 100,
          temperature: 0,
          streamResponse: false
        },
        {
          onMessage: () => {},
          onComplete: (response) => 
          {
            const assistantMessage = service.createMessage(
              GenAIMessageRole.Assistant,
              response.message
            );
            actions.addMessage(assistantMessage);
            setLoading(false);
          },
          onError: () => 
          {
            actions.setError('Failed to send message');
            setLoading(false);
          }
        }
      );
    }
    catch (error) 
    {
      actions.setError('Failed to send message');
      setLoading(false);
    }
  }, [chatId, actions, service]);

  const handleResetChat = useCallback(() => 
  {
    const initialMessage = service.createMessage(
      GenAIMessageRole.Assistant, 
      INITIAL_MESSAGE
    );
    actions.resetChat(initialMessage);
  }, [actions, service]);

  return {
    handleSend,
    errorMessage: state.error,
    messages: state.messages,
    chatId: state.chatId,
    loading,
    handleResetChat,
    blockMessage: state.blockMessage
  };
};
