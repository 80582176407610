import React, { useContext } from "react";

export const useRequiredContext = <T>(
  context: React.Context<T>,
  errorMessage?: string
): T => 
{
  const value = useContext(context);
  if (value === undefined) 
  {
    throw new Error(
      errorMessage ??
        `${context.displayName || "Context"} is missing its required provider`
    );
  }

  return value;
};
