import React, { useState } from 'react';
import { Folder, FileItem } from './FileManager.types';
import { Icon } from '../../Components/SvgManager/Icon';
import { motion } from 'framer-motion';

interface FolderComponentProps 
{
  folder: Folder;
  level: number;
  onDragStart: (e: React.DragEvent, item: Folder | FileItem) => void;
  onDragOver: (e: React.DragEvent) => void;
  onDrop: (e: React.DragEvent, folder: Folder) => void;
  onContextMenu: (e: React.MouseEvent, item: Folder | FileItem, type: 'folder' | 'file') => void;
  onSelect: (folder: Folder) => void;
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1,
    },
  },
};

export const FolderComponent: React.FC<FolderComponentProps> = ({
  folder,
  level,
  onDragStart,
  onDragOver,
  onDrop,
  onContextMenu,
  onSelect
}) => 
{
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = (e: React.MouseEvent) => 
  {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <motion.div className={`ml-${level * 4}`}
      variants={itemVariants}>
      <div>
        <div>
          <div
            draggable
            onDragStart={(e) => onDragStart(e, folder)}
            onDragOver={(e) => 
            {
              e.preventDefault();
              onDragOver(e);
            }}
            onDrop={(e) => 
            {
              e.preventDefault();
              onDrop(e, folder);
            }}
            onContextMenu={(e) => onContextMenu(e, folder, 'folder')}
            onClick={() => onSelect(folder)}
            className="flex items-center p-2 hover:bg-gray-100 dark:hover:bg-slate-600 cursor-pointer"
          >
            {/* chevron to open/close folders in sidebar */}
            <span onClick={handleToggle} className="mr-2">
              <Icon
                name={isExpanded ? 'chevronDown' : 'chevronRight'}
                className="w-4 h-4 cursor-pointer"
              />
            </span>

            <Icon name='folder' className="w-5 h-5 mr-2" />
            <span>{folder.name}</span>
          </div>
        </div>
        <div
        >
          {isExpanded && (
            <div>
              {folder.subfolders.map(subfolder => (
                <FolderComponent
                  key={subfolder.id}
                  folder={subfolder}
                  level={level + 1}
                  onDragStart={onDragStart}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                  onContextMenu={onContextMenu}
                  onSelect={onSelect}
                />
              ))}
              {folder.files.map(file => (
                <div
                  key={file.id}
                  draggable
                  onDragStart={(e) => onDragStart(e, file)}
                  className={`ml-${(level + 1) * 4} p-2 flex items-center hover:bg-gray-100 dark:hover:bg-slate-600 cursor-pointer`}
                  onContextMenu={(e) => onContextMenu(e, file, 'file')}
                >
                  <Icon name='document' className="w-5 h-5 mr-2" />
                  <span>{file.name}</span>
                </div>
              ))}
            </div>    
          )}
        </div>
      </div>
    </motion.div>
  );
};