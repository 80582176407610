import React from 'react';

import { DropdownFull } from '../DropdownFull';
import { DropdownFilter } from '../DropdownFilter';
import { DropdownProfile } from '../DropdownProfile';
import { DropdownSwitch } from '../DropdownSwitch';
import { DropdownNotifications } from '../DropdownNotifications';
import { DropdownHelp } from '../DropdownHelp';
import { DropdownEditMenu } from '../DropdownEditMenu';
import { Datepicker } from '../Datepicker';

import Flatpickr from 'react-flatpickr';
import { SearchableItem, SearchableSelect } from '../SearchableSelect/SearchableSelect';
import axios from 'axios';

export const DropdownPage: React.FC = () => 
{

  // esempio non proprio corretto perchè da solo un risultato
  // const handleSearch = async (term: string) => 
  // {
  //   try 
  //   {
  //     const { data: pokemon } = await axios.get(`https://pokeapi.co/api/v2/pokemon/${term}`);
  //     console.log(pokemon);

  //     const pokeData: SearchableItem = {
  //       id: pokemon.id.toString(),
  //       label: pokemon.name,
  //       image: pokemon.sprites.front_default
  //     };

  //     return [pokeData];
  //   }
  //   catch (error) 
  //   {
  //     console.log(error);
  //     return [];
  //   }
  // };

  // solo per test e non lasciare any
  interface Movie 
  {
    imdbID: string;
    Title: string;
    Year: string;
    Type: string;
    Poster: string;
  }

  // QUESTO E' L'ESEMPIO MIGLIORE:
  // mettete la vostra chiave in apiKey= ,creandovela qui: https://www.omdbapi.com/ per poter testare
  const handleSearch = async (term: string) => 
  {
    try 
    {
      const { data: movies } = await axios.get(`https://www.omdbapi.com/?apikey=METTI_TUA_CHIAVE&s=${term}`);
      console.log(movies);
      return movies.Search.map((movie: Movie) => ({
        id: movie.imdbID,
        label: movie.Title,
        image: movie.Poster,
        extraData: { // qui ci aggiungo altra roba che può servire quando vado a selezionare un elemento, poi lo gestisco in handleSelect come unknown
          type: movie.Type,
          year: movie.Year
        }
      }));

    }
    catch (error) 
    {
      console.log(error);
      return [];
    }
  };

  const handleSelect = (selectedItem: { id: string; label: string; image?: string; extraData?: unknown }) => 
  {
    console.log(selectedItem);
    // qua salvate in stato o dove vi pare, di base potrebbe servirvi anche solo l'id per poi selezionarvi l'elemento tramite fetch o roba simile, in base a come è fatto il backend o come volte fare voi
  };

  // per esempio con svg come JSX
  const handleSearchSvg = async (term: string) => 
  {
    try 
    {
      const { data: svg } = await axios.get(`https://api.iconify.design/fluent-emoji-flat/${term}.svg`);
      console.log("svg??", svg);
      const svgData: SearchableItem = {
        id: "1",
        label: "prova svg",
        svg: svg // svg in formato stringa
      };
      return [svgData]; // sarebbe meglio una api che da una lista di svg, in uqesto caso ho trovato questo per il momento
    }
    catch (error) 
    {
      console.log(error);
      return [];
    }
  };

  const handleSelectSvg = (selectedItem: { id: string; label: string; image?: string }) => 
  {
    console.log(selectedItem);
  };

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900">

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-8">
              <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Dropdown ✨</h1>
            </div>

            <div className="border-t border-slate-200 dark:border-slate-700">

              {/* Components */}
              <div className="space-y-8 mt-8 mb-80">
                {/* Classic Dropdown */}

                {/* Full-width Dropdown */}
                <div className="w-80">
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Full-width Dropdown</h2>
                  <DropdownFull />
                </div>

                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Gae searchable item</h2>
                  <div className='mb-3'>
                    {/* Gae searchable select modal - grid */}
                    <SearchableSelect
                      onSearch={handleSearch}
                      onSelect={handleSelect}
                      isModal={true}
                      displayAs="grid"
                      placeholder="Cerca film..."
                      triggerElement={<button className='btn bg-yellow-300'>Apri per cercare Films con modale e grid</button>}
                      debounceTime={1000} // can sat debouce manually if you need
                    />
                  </div>
                  <div className='mb-5'>
                    {/* Gae searchable select dropdown - list */}
                    <SearchableSelect
                      onSearch={handleSearch}
                      onSelect={handleSelect}
                      isModal={false}
                      displayAs="list"
                      placeholder="Cerca film..."
                      triggerElement={<button className='btn bg-green-300'>Apri per cercare Films con dropdown e list</button>}
                      debounceTime={1000} // can sat debouce manually if you need
                    />
                  </div>
                  <div>
                    {/* Gae searchable select dropdown example with svg */}
                    <SearchableSelect
                      onSearch={handleSearchSvg}
                      onSelect={handleSelectSvg}
                      isModal={false}
                      displayAs="list"
                      placeholder="Cerca svg..."
                      triggerElement={<button className='btn bg-blue-300'>Apri per cercare SVG</button>}
                      debounceTime={1000}
                    />
                  </div>

                  {/* Filter */}
                  <div>
                    <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Filter</h2>
                    <DropdownFilter align="left" fields={[]} />
                  </div>

                  {/* Profile */}
                  <div>
                    <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Profile</h2>
                    <DropdownProfile align="right" />
                  </div>

                  {/* Switch Account */}
                  <div>
                    <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Switch Account</h2>
                    <DropdownSwitch align="right" />
                  </div>

                  {/* Notification */}
                  <div>
                    <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Notification</h2>
                    <DropdownNotifications align="right" />
                  </div>

                  {/* Help Center */}
                  <div>
                    <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Help Center</h2>
                    <DropdownHelp align="right" />
                  </div>

                  {/* Quick Selection */}
                  <div>
                    <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Quick Selection</h2>
                    <DropdownEditMenu className="relative inline-flex">
                      <li>
                        <a className="font-medium text-sm text-slate-600 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-200 flex py-1 px-3" href="#0">Option 1</a>
                      </li>
                      <li>
                        <a className="font-medium text-sm text-slate-600 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-200 flex py-1 px-3" href="#0">Option 2</a>
                      </li>
                      <li>
                        <a className="font-medium text-sm text-rose-500 hover:text-rose-600 flex py-1 px-3" href="#0">Remove</a>
                      </li>
                    </DropdownEditMenu>
                  </div>

                  {/* Datepicker (built with flatpickr) */}
                  <div>
                    <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Datepicker (built with <a className="underline hover:no-underline" href="https://github.com/flatpickr/flatpickr" target="_blank" rel="noreferrer">flatpickr</a>)</h2>
                    <Datepicker />
                  </div>
                </div>

                <div>
                  <Flatpickr />
                </div>

              </div>
            </div>
          </div>
        </main>

      </div>

    </div>
  );
};
