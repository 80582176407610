import React from 'react';

import { getIntl } from '../../ReactIntl/IntlConfig';
import { Shop2Locale } from '../../ReactIntl/LocaleInterfaces';
import { ShopCards07 } from './Partials/ShopCards07';
import { ShopSidebar } from './Partials/ShopSidebar';

export const Shop2: React.FC = () => 
{

  const intl = getIntl<Shop2Locale>("shop2");

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-5">

              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">{intl.h1} ✨</h1>

            </div>

            {/* Page content */}
            <div className="flex flex-col space-y-10 sm:flex-row sm:space-x-6 sm:space-y-0 md:flex-col md:space-x-0 md:space-y-10 xl:flex-row xl:space-x-6 xl:space-y-0 mt-9">
        
              <ShopSidebar />

              {/* Content */}
              <div>

                {/* Filters */}
                <div className="mb-5">
                  <ul className="flex flex-wrap -m-1">
                    <li className="m-1">
                      <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-indigo-500 text-white duration-150 ease-in-out">{intl.filtersViewAllBtn}</button>
                    </li>
                    <li className="m-1">
                      <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">{intl.filtersFeaturedBtn}</button>
                    </li>
                    <li className="m-1">
                      <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">{intl.filtersNewestBtn}</button>
                    </li>
                    <li className="m-1">
                      <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">{intl.filtersPriceLowHighBtn}</button>
                    </li>
                    <li className="m-1">
                      <button className="inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out">{intl.filtersPriceHighLowBtn}</button>
                    </li>
                  </ul>
                </div>

                <div className="text-sm text-slate-500 dark:text-slate-400 italic mb-4">67.975 {intl.totalItems}</div>

                {/* Cards 1 (Video Courses) */}
                <div>
                  <div className="grid grid-cols-12 gap-6">
                    <ShopCards07 />
                  </div>
                </div>

                {/* Pagination */}
                {/*}
           <div className="mt-8">
              <PaginationClassic />
            </div> 
            */}

              </div>

            </div>

          </div>
        </main>

      </div>

    </div>
  );
};
