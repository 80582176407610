import React, { ChangeEvent, ChangeEventHandler } from 'react';

import { AccountPanelChildProps } from '../AccountPanel';
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { AccountSettingsPanelAddressLocale } from '../../../../ReactIntl/LocaleInterfaces';

export const PanelAddress: React.FC<AccountPanelChildProps> = ({ userData, setUserData, onFieldChange }) => 
{
  const intl = getIntl<AccountSettingsPanelAddressLocale>('accountSettingsPanelAddress');

  /**
   * Returns a function that updates the specified key of the userData address object with the given field name and value.
   *
   * @param {string} field - The name of the address field to update.
   * @return {ChangeEventHandler<HTMLInputElement>} A function that updates the specified key of the userData address object with the given field name and value.
   */
  const handleAddressChangeFor = (field: string): ChangeEventHandler<HTMLInputElement> => (e: ChangeEvent<HTMLInputElement>) => 
  {
    setUserData(prevState => ({ ...prevState, address: { ...prevState.address, [field]: e.target.value } }));
    if (onFieldChange) onFieldChange();
  };

  return (
    <section>
      {/* <h2 className="text-xl text-slate-800 dark:text-slate-100 font-bold mb-5">{intl.title}</h2> */}
      <section>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1 dark:text-slate-300" htmlFor="country">{intl.countryLabel}</label>
            <input
              id="country"
              className="form-input w-full"
              type="text"
              value={userData.address?.country || ""}
              onChange={handleAddressChangeFor('country')}
              required
            />
          </div>
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1 dark:text-slate-300" htmlFor="region">{intl.provStateLabel}</label>
            <input
              id="region"
              className="form-input w-full"
              type="text"
              value={userData.address?.region || ""}
              onChange={handleAddressChangeFor('region')}
              required
            />
          </div>
        </div>
      </section>
      <section>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1 dark:text-slate-300" htmlFor="city">{intl.cityLabel}</label>
            <input
              id="city"
              className="form-input w-full"
              type="text"
              value={userData.address?.locality || ""}
              onChange={handleAddressChangeFor('locality')}
              required
            />
          </div>
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1 dark:text-slate-300" htmlFor="address">{intl.addressLabel}</label>
            <input
              id="address"
              className="form-input w-full"
              type="text"
              value={userData.address?.street_address || ""}
              onChange={handleAddressChangeFor('street_address')}
              required
            />
          </div>
        </div>
      </section>
      <section>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1 dark:text-slate-300" htmlFor="postalCode">{intl.postalCodeLabel}</label>
            <input
              id="postalCode"
              className="form-input w-full"
              type="text"
              autoComplete="postal-code"
              value={userData.address?.postal_code || ""}
              onChange={handleAddressChangeFor('postal_code')}
              required
            />
          </div>
        </div>
      </section>
    </section>
  );
};
