import React, { createContext, useState } from 'react';
import { ContextMenuType } from '../FileManager.types';

export interface UIContextType 
{
  contextMenuState: ContextMenuType | null;
  setContextMenuState: React.Dispatch<React.SetStateAction<ContextMenuType | null>>;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalContent: React.ReactNode | null;
  setModalContent: React.Dispatch<React.SetStateAction<React.ReactNode | null>>;
  isDragging: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  isExternalDrag: boolean;
  setIsExternalDrag: React.Dispatch<React.SetStateAction<boolean>>;
  isFolderTreeModalOpen: boolean;
  setIsFolderTreeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  folderTreeAction: 'move' | 'copy';
  setFolderTreeAction: React.Dispatch<React.SetStateAction<'move' | 'copy'>>;
}

export const UIContext = createContext<UIContextType | undefined>(undefined);

export const UIProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => 
{
  const [contextMenuState, setContextMenuState] = useState<ContextMenuType | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isExternalDrag, setIsExternalDrag] = useState(false);
  const [isFolderTreeModalOpen, setIsFolderTreeModalOpen] = useState(false);
  const [folderTreeAction, setFolderTreeAction] = useState<'move' | 'copy'>('copy');

  return (
    <UIContext.Provider value={{
      contextMenuState, setContextMenuState,
      modalOpen, setModalOpen,
      modalContent, setModalContent,
      isDragging, setIsDragging,
      isExternalDrag, setIsExternalDrag,
      isFolderTreeModalOpen, setIsFolderTreeModalOpen,
      folderTreeAction, setFolderTreeAction,
    }}>
      {children}
    </UIContext.Provider>
  );
};