import { GenAIItem } from "../../Utils/GenAITypes";
import {
  GenAITemplateInputKind,
  GenAITemplateKind,
  GenAITemplateStatus,
} from "./GenAIEnums";
import {
  IGenAIChatTemplate,
  IGenAIWriterTemplate,
  IUserGenAITemplate,
  IUserInput,
} from "./GenAIInterface";
import { GenAICategoryResponse, GenAITemplateResponse } from "./GenAIResponse";

export const createNewAiWriterTemplate = (): IGenAIWriterTemplate => ({
  id: "",
  kind: GenAITemplateKind.AIWriter,
  status: GenAITemplateStatus.Enabled,
  name: "",
  description: "",
  categoriesId: [],
  data: {
    prompt: "",
    inputs: [{
      name: "",
      kind: GenAITemplateInputKind.Text,
      description: "",
      defaultValue: "",
      possibleValues: [],
    }],
  },
  metadata: "",
});

export const createNewAiWriterInput = (): IUserInput => ({
  name: "",
  kind: GenAITemplateInputKind.Text,
  description: "",
  defaultValue: "",
  possibleValues: [],
});

export const parseTemplateMetadata = (
  template: GenAITemplateResponse | GenAIItem
): { templateColor: string; templateIcon: string } => 
{
  if (!template.metadata) return { templateColor: "", templateIcon: "" };
  try 
  {
    const metadata = JSON.parse(template.metadata);
    return {
      templateColor: metadata.templateColor || "",
      templateIcon: metadata.templateIcon || "",
    };
  }
  catch (error) 
  {
    console.error("Error parsing template metadata:", error);
    return { templateColor: "", templateIcon: "" };
  }
};

export const parseCategoryMetadata = (
  category: GenAICategoryResponse | GenAIItem
): { categoryColor: string; categoryIcon: string } => 
{
  if (!category.metadata) return { categoryColor: "", categoryIcon: "" };
  try 
  {
    const metadata = JSON.parse(category.metadata);
    return {
      categoryColor: metadata.categoryColor || "",
      categoryIcon: metadata.categoryIcon || "",
    };
  }
  catch (error) 
  {
    console.error("Error parsing category metadata:", error);
    return { categoryColor: "", categoryIcon: "" };
  }
};

export const isFavoriteTemplate = (
  templateId: string,
  favorites: IUserGenAITemplate[]
): boolean => 
{
  return favorites.some((fav) => fav.templateId === templateId && fav.favorite);
};

export const createNewAiChatTemplate = (): Omit<IGenAIChatTemplate, "id"> => ({
  kind: GenAITemplateKind.AIChat,
  status: GenAITemplateStatus.Enabled,
  name: "",
  description: "",
  categoriesId: [],
  data: {
    prompt: "",
  },
  metadata: "",
});
