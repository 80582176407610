import React, { useState } from 'react';
import { InputField } from '../../../ReactHookForm/Components/InputField';
import { useForm } from 'react-hook-form';
import { Button } from '../../../Components/Actions/Buttons/Buttons';

interface WebsiteInputProps 
{
  onSubmit: (data: { url: string }) => void;
}

export const WebsiteInput: React.FC<WebsiteInputProps> = ({ onSubmit }) => 
{
  const [selectedAll, setSelectedAll] = useState(false);

  // Configura il form con react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset, // Aggiunto per resettare il form
  } = useForm<{ url: string }>();

  const onSubmitForm = handleSubmit((data) => 
  {
    onSubmit(data);
    reset(); // Resetta il form dopo il submit
  });

  return (
    <div className="space-y-4">
      <div className="shadow-md border border-gray-100 dark:border-slate-700 dark:bg-slate-800 p-4 rounded-lg">
        <div className="flex items-center mb-4">
          <span className="w-6 h-6 rounded-full bg-indigo-500 text-white flex items-center justify-center text-sm mr-3">
            1
          </span>
          <span className="text-sm font-medium">Add URL</span>
        </div>
        <form onSubmit={onSubmitForm} className="flex items-start">
          <div className="flex-1">
            <div className="flex items-center">
              <span className="text-sm mr-4">Website</span>
              <span className="text-sm">Single URL</span>
            </div>
            <InputField
              name="url"
              label=""
              placeholder="https://example.com"
              type="text"
              register={register}
              errors={errors}
              className="mt-3"
            />
          </div>
          <Button
            buttonType="submit"
            variant="primary"
            type="check"
            hideText
            size="l"
            className="mt-9 ml-2 py-2.5"
          />
        </form>
      </div>
      {/*
        Poiché il watch è stato rimosso, se vuoi comunque visualizzare un'ulteriore sezione 
        basata sul valore dell'input, dovrai gestirlo in modo diverso.
      */}
      {selectedAll && (
        <div className=" dark:bg-slate-800 p-4 rounded-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span className="w-6 h-6 rounded-full bg-indigo-500 text-white flex items-center justify-center text-sm mr-2">
                2
              </span>
              <span className="text-sm font-medium">Select Pages</span>
            </div>
            <button
              className="text-indigo-500 hover:text-indigo-600 text-sm font-medium"
              onClick={() => setSelectedAll(!selectedAll)}
            >
              {selectedAll ? 'Deselect All' : 'Select All'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
