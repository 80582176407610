import React, { useState } from 'react';
import { useSupabaseClient } from '../Hooks/UseSession';
import { useNavigate, useLocation } from 'react-router-dom';

export const LoginForm: React.FC = () => 
{
  const supabaseClient = useSupabaseClient();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as { from?: Location })?.from?.pathname || '/';

  const handleLogin = async (loginMethod: () => Promise<{ error: Error | null }>) => 
  {
    try 
    {
      const { error } = await loginMethod();
      if (error) 
      {
        console.error('Error logging in:', error.message);
      }
      else 
      {
        const session = await supabaseClient.auth.getSession();
        console.log('Login successful:', session.data.session);
        navigate(from, { replace: true });
      }
    }
    catch (err) 
    {
      console.error('Unexpected error:', err);
    }
  };

  const handleEmailLogin = () => handleLogin(() => supabaseClient.auth.signInWithPassword({ email, password }));
  const handleOAuthLogin = () => handleLogin(() => supabaseClient.auth.signInWithOAuth({ provider: 'github' }));

  return (
    <div>
      <div>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type='submit' onClick={handleEmailLogin}>Sign In with Email</button>
      </div>
      <div>
        <button type='submit' onClick={handleOAuthLogin}>Sign In with GitHub</button>
      </div>
    </div>
  );
};