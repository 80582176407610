export function formatDate(timestamp: number): string 
{
  const milliseconds = (timestamp - 62135596800) * 1000;
  
  const date = new Date(milliseconds);
  
  if (isNaN(date.getTime())) 
  {
    throw new Error('Timestamp non valido');
  }
  
  const options: Intl.DateTimeFormatOptions = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: 'UTC'
  };
  
  return date.toLocaleString('it-IT', options);
}
export function capitalizeWords(text: string): string 
{
  return text
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}
export function formatRoles(roles: string[]): string 
{
  return roles.map(capitalizeWords).join(", ");
}