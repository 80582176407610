
import { createApiDefinition } from "../../../Api/ApiConfig";
import { apiFetch } from "../../../Api/BeetClient";
import { WindChatResponse } from "../MoritzinoAssets/MoritzinoInterface";

//TODO: substitute with real api from Moritzino

export const moritzinoApiDefinitions = {
  windTreChat: createApiDefinition("gen-ai/chatbots/windTre", {
    send: {
      url: "{storeId}/send",
      invoke: apiFetch.post<WindChatResponse>,
    },
    getInstructions: {
      url: "{storeId}/instructions",
      invoke: apiFetch.get<string>,
    },
    updateInstructions: {
      url: "{storeId}/instructions",
      invoke: apiFetch.put<string>,
    },
    getCategories: {
      url: "{storeId}/categories",
      invoke: apiFetch.get<string>,
    },
    getTokenUsage: { url: "{storeId}/usage", invoke: apiFetch.get<string> },
    getStoreIds: { url: "", invoke: apiFetch.get<string[]> },
  }),
};