import React from 'react';
import ChatBotIcon from './RiminiAssets/ChatbotIcon.png';

import { animationCustomization, styleCustomization } from './RiminiAssets/RiminiChatbotStyles';
import { useRiminiChatbotLogic } from './RiminiChatbotLogic';
import { BaseChatbot } from '../../Features/GenAI/ChatbotLayout/BaseChatbot';

export const RiminiChatbot: React.FC = () => 
{
  
  const chatbotName = 'Riminy';

  return (
    <BaseChatbot
      chatbotIcon={ChatBotIcon}
      chatbotName={chatbotName}
      useChatbotLogic={useRiminiChatbotLogic}
      styleCustomization={styleCustomization}
      animationCustomization={animationCustomization}
    />
  );
};