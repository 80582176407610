import React, { useState, useCallback } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { DropdownNotifications } from '../../Components/DropdownNotifications';
import { DropdownProfile } from '../../Components/DropdownProfile';
import { LanguageSelectorButton } from '../../Components/LanguageSelectorButton';
import { ModalSearch } from '../../Components/ModalSearch';
import { ThemeToggle } from '../../Components/ThemeToggle';
import { getLocale, setLocale } from '../../ReactIntl/IntlConfig';

interface HeaderProps 
{
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
interface BreadcrumbItem 
{
  label: string;
  path: string;
}

const DynamicBreadcrumb: React.FC = () => 
{
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const formatBreadcrumbLabel = (name: string) => 
  {
    return name
      .split('-')
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ');
  };

  const breadcrumbs: BreadcrumbItem[] = [
    { label: 'Dashboard', path: '/' },
    ...pathnames.map((name, index) => ({
      label: formatBreadcrumbLabel(name),
      path: `/${pathnames.slice(0, index + 1).join('/')}`,
    })),
  ];

  const filteredBreadcrumbs = breadcrumbs.filter(
    (breadcrumb, index) =>
      index === 0 || breadcrumb.label !== 'Dashboard'
  );
  
  return (
    <nav className="text-sm font-medium hidden sm:block" aria-label="Breadcrumb">
      <ol className="inline-flex">
        {filteredBreadcrumbs.map((breadcrumb, index) => (
          <li key={breadcrumb.path} className="flex items-center">
            {index > 0 && (
              <svg className="h-4 w-4 fill-current text-slate-400 dark:text-slate-600 mx-2" viewBox="0 0 16 16">
                <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
              </svg>
            )}
            <Link
              to={breadcrumb.path}
              className={`${
                index === filteredBreadcrumbs.length - 1
                  ? 'text-slate-800 dark:text-slate-200'
                  : 'text-slate-500 dark:text-slate-400 hover:text-indigo-500 dark:hover:text-indigo-500'
              }`}
            >
              {breadcrumb.label}
            </Link>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export const Header: React.FC<HeaderProps> = ({ sidebarOpen, setSidebarOpen }) => 
{
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [selectedLocale, setSelectedLocale] = React.useState<string>(getLocale());
  const handleLocaleChange = useCallback((newLocale: string) => 
  {
    setSelectedLocale(newLocale);
    setLocale(newLocale);
  }, []);

  return (
    <header className="sticky top-0 bg-white dark:bg-[#182235] border-b border-slate-200 dark:border-slate-700 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex items-center">
            {/* Hamburger button */}
            <button
              className="text-slate-500 hover:text-slate-600 lg:hidden mr-4"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={(e) => 
              {
                e.stopPropagation();
                setSidebarOpen(!sidebarOpen);
              }}
            >
              <span className="sr-only">Open sidebar</span>
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
            {/* Breadcrumb */}
            <DynamicBreadcrumb />
          </div>
          {/* Header: Right side */}
          <div className="flex items-center space-x-3">
            <div>
              <button
                className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full ml-3 ${searchModalOpen && 'bg-slate-200'}`}
                onClick={(e) => 
                {
                  e.stopPropagation();
                  setSearchModalOpen(true);
                }}
                aria-controls="search-modal"
              >
                <span className="sr-only">Search</span>
                <svg className="w-4 h-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path className="fill-current text-slate-500 dark:text-slate-400" d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                  <path className="fill-current text-slate-400 dark:text-slate-500" d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                </svg>
              </button>
              <ModalSearch id="search-modal" searchId="search" modalOpen={searchModalOpen} setModalOpen={setSearchModalOpen} />
            </div>
            <DropdownNotifications align="right" />
            <ThemeToggle />
            <LanguageSelectorButton selectedLanguage={selectedLocale} onLanguageChange={handleLocaleChange} />
            <hr className="w-px h-6 bg-slate-200 dark:bg-slate-700 border-none" />
            <DropdownProfile align="right" />
          </div>
        </div>
      </div>
    </header>
  );
};