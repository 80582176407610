import React, { useState, useCallback } from 'react';
import { Mic } from 'lucide-react';
import { SpeechRecognition, SpeechRecognitionEvent, SpeechRecognitionError } from './SpeechRecognition';

interface VoiceToTextProps 
{
  onTextChange: (text: string) => void;
  buttonBackgroundColor?: string; 
  buttonListeningBackgroundColor?: string;
  microphoneColor?: string;
  buttonSize?: string;
}

export const SpeechToText: React.FC<VoiceToTextProps> = ({
  onTextChange,
  buttonBackgroundColor = 'bg-blue-500',
  buttonListeningBackgroundColor = 'bg-red-500',
  microphoneColor = 'text-white',
  buttonSize = 'p-2',
}) => 
{
  const [isListening, setIsListening] = useState(false);

  const startListening = useCallback(() => 
  {
    if ('webkitSpeechRecognition' in window) 
    {
      const webkitSpeechRecognition = window.webkitSpeechRecognition as SpeechRecognition;
      const recognition = new webkitSpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = false;
      recognition.lang = 'it-IT';

      recognition.onstart = () => 
      {
        setIsListening(true);
      };

      recognition.onresult = (event: SpeechRecognitionEvent) => 
      {
        const transcript = event.results[0][0].transcript;
        onTextChange(transcript);
      };

      recognition.onerror = (event: SpeechRecognitionError) => 
      {
        console.error('Error recognizing voice:', event.error);
        setIsListening(false);
      };

      recognition.onend = () => 
      {
        setIsListening(false);
      };

      recognition.start();
    }
    else 
    {
      console.error('Il riconoscimento vocale non è supportato in questo browser.');
    }
  }, [onTextChange]);

  return (
    <button
      onClick={startListening}
      disabled={isListening}
      className={`${buttonSize} rounded-full ${
        isListening ? buttonListeningBackgroundColor : buttonBackgroundColor
      } focus:outline-none`}
    >
      <Mic size={20} className={microphoneColor} />
    </button>
  );
};