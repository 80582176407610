import { EditorThemeClasses } from "lexical";

export const EditorTheme: EditorThemeClasses = {
  code: "bg-gray-100 dark:bg-gray-600 font-mono block p-2 pl-14 text-[13px] my-2 tab-[2] overflow-x-auto relative before:content-[attr(data-gutter)] before:absolute before:bg-gray-200 before:dark:bg-gray-500 before:left-0 before:top-0 before:border-r before:border-gray-300 before:dark:border-gray-200 before:p-2 before:whitespace-pre-wrap before:text-right before:min-w-[25px] after:content-[attr(data-highlight-language)] after:absolute after:top-0 after:right-[3px] after:p-[3px] after:uppercase after:text-[rgba(0,0,0,0.5)] after:dark:text-[rgba(255,255,255,0.5)]",
  heading: {
    h1: "text-3xl font-bold",
    h2: "text-2xl font-semibold",
    h3: "text-xl font-medium",
    h4: "text-lg font-medium",
    h5: "text-base font-medium",
  },
  image: "max-w-full h-auto",
  link: "text-blue-600 hover:underline",
  list: {
    listitem: "mb-1",
    nested: {
      listitem: "ml-4",
    },
    ol: "list-decimal pl-4",
    ul: "list-disc pl-4",
  },
  ltr: "text-left",
  paragraph: "mb-4",
  placeholder: "text-gray-400",
  quote: "border-l-4 border-gray-200 pl-4 italic",
  rtl: "text-right",
  text: {
    bold: "font-bold",
    code: "bg-gray-100 rounded px-1 py-0.5 font-mono text-sm",
    italic: "italic",
    strikethrough: "line-through",
    underline: "underline",
    underlineStrikethrough: "underline line-through",
  },
  codeHighlight: {
    atrule: "text-blue-600 dark:text-blue-400",
    attr: "text-green-600 dark:text-green-400",
    boolean: "text-purple-600 dark:text-purple-400",
    builtin: "text-pink-600 dark:text-pink-400",
    cdata: "text-gray-500 dark:text-gray-400",
    char: "text-pink-600 dark:text-pink-400",
    class: "text-yellow-600 dark:text-yellow-400",
    "class-name": "text-yellow-600 dark:text-yellow-400",
    comment: "text-gray-500 dark:text-gray-400",
    constant: "text-purple-600 dark:text-purple-400",
    deleted: "text-red-600 dark:text-red-400",
    doctype: "text-gray-500 dark:text-gray-400",
    entity: "text-teal-600 dark:text-teal-400",
    function: "text-indigo-600 dark:text-indigo-400",
    important: "text-red-600 dark:text-red-400",
    inserted: "text-green-600 dark:text-green-400",
    keyword: "text-blue-600 dark:text-blue-400",
    namespace: "text-orange-600 dark:text-orange-400",
    number: "text-purple-600 dark:text-purple-400",
    operator: "text-teal-600 dark:text-teal-400",
    prolog: "text-gray-500 dark:text-gray-400",
    property: "text-purple-600 dark:text-purple-400",
    punctuation: "text-gray-900 dark:text-gray-300",
    regex: "text-green-600 dark:text-green-400",
    selector: "text-pink-600 dark:text-pink-400",
    string: "text-green-600 dark:text-green-400",
    symbol: "text-purple-600 dark:text-purple-400",
    tag: "text-blue-600 dark:text-blue-400",
    url: "text-teal-600 dark:text-teal-400",
    variable: "text-orange-600 dark:text-orange-400",
  },
};
