import { RoleRenderingContext } from "../RoleBaseRendering";
import { useContext } from "react";

export const useRoleRendering = () => 
{
  const context = useContext(RoleRenderingContext);
  if (context === undefined) 
  {
    throw new Error('useRoleRendering must be used within a RoleRenderingProvider');
  }
  return context;
};