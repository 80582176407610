import React from 'react';
import { IGenAICategory, IGenAIChatTemplate, IGenAIWriterTemplate } from '../Api/Types/GenAIInterface';
import { GenAICategoryRow } from './GenAICategoryRow_';
import { GenAIItem, ViewProps } from '../Utils/GenAITypes';
import { GenAITemplateRow } from './GenAITemplateRow_';
import { Role } from '../../../Api/Roles/RoleManager';
import { RoleBasedElement } from '../../../Api/Roles/RoleBasedElement';

const hasId = (item: GenAIItem): item is IGenAICategory | IGenAIChatTemplate | IGenAIWriterTemplate =>
  'id' in item;

const getItemKey = (item: GenAIItem): string =>
  hasId(item) ? item.id : `temp_${item.name}`;

const TableHeader: React.FC<{ columnName: string }> = ({ columnName }) => (
  <th className="px-2 py-3 text-center">{columnName}</th>
);

export const GenAITableView: React.FC<ViewProps> = ({
  items,
  isCategories,
  isWriterPage,
  onDelete,
  onEdit,
  onToggleFavorite,
  favoriteIds,
  onChatbotSelect,
  onWriterTemplateSelect
}) => 
{
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative w-full overflow-x-auto">
      <table className="table-auto w-full dark:text-slate-300">
        <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
          <tr>
            {!isCategories && <TableHeader columnName="Favorite" />}
            <TableHeader columnName="Name" />
            <TableHeader columnName="Description" />
            {!isCategories && (
              <>
                <TableHeader columnName="Status" />
                <TableHeader columnName="Prompt" />
                {/* {isWriterPage && <TableHeader columnName="Inputs" />} */}
              </>
            )}
            <RoleBasedElement requiredRoles={Role.Admin} as={React.Fragment} >
              <TableHeader columnName="Actions" />
            </RoleBasedElement>
            {!isCategories && (
              <TableHeader columnName={isWriterPage ? "Generator" : "Chatbot"} />
            )}
          </tr>
        </thead>
        <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
          {items.map((item) => (
            isCategories ? (
              <GenAICategoryRow
                key={getItemKey(item)}
                category={item as IGenAICategory}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            ) : (
              <GenAITemplateRow
                key={getItemKey(item)}
                template={item as IGenAIChatTemplate | IGenAIWriterTemplate}
                isWriter={isWriterPage}
                onDelete={onDelete}
                onEdit={onEdit}
                onToggleFavorite={onToggleFavorite}
                isFavorite={hasId(item) && favoriteIds.includes(item.id)}
                onChatbotSelect={onChatbotSelect}
                onWriterTemplateSelect={onWriterTemplateSelect}
              />
            )
          ))}
        </tbody>
      </table>
    </div>
  );
};