import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { MessageRole, WindTreChat } from '../WindTre.types';
import { Phone, MapPin, Mail, Link } from 'lucide-react';

import  WhatsappIcon  from '../../../../Images/SocialIcon/whatsapp.svg';
import { TextToSpeech } from '../../BotTools/TextToSpeech';

interface MessageContentProps 
{
  message: WindTreChat;
  isAi: boolean;
  loading: boolean;
  onSelectOption?: (option: string) => void;
}

export const MessageContent: React.FC<MessageContentProps> = ({
  message,
  isAi,
  loading,
  onSelectOption,
}) => 
{
  // Unique style button
  const renderButton = (onClick: () => void, text: string, icon: React.ReactNode) => (
    <button
      onClick={onClick}
      className="flex items-center justify-center px-3 py-2 bg-gradient-to-r from-orange-400 to-orange-600 text-white rounded-lg shadow-lg transition-colors duration-300 hover:from-orange-500 hover:to-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-300 focus:ring-opacity-50 text-sm w-full"
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
      disabled={loading}
    >
      {icon && <span className="mr-2">{icon}</span>}
      <span className="truncate">{text}</span>
    </button>
  );
  
  // Extract contacts from the message, assistants prompt MUST specify to put the value as this: {{ value }}
  const extractContacts = (text: string) => 
  {
    if (text.startsWith("address:"))
    {
      return { address: text.substring("address:".length).trim() };
    }
    if (text.startsWith("email:"))
    {
      return { email: text.substring("email:".length).trim() };
    }
    if (text.startsWith("phone:"))
    {
      return { phone: text.substring("phone:".length).trim() };
    }
    if (text.startsWith("whatsapp:"))
    {
      return { whatsapp: text.substring("whatsapp:".length).trim() };
    }
    if (text.startsWith("offer:"))
    {
      return { url: text.substring("offer:".length).trim() };
    }
    if (text.startsWith("contact:"))
    {
      return { url: text.substring("contact:".length).trim() };
    }

    return {};
  };
  
  const renderContent = () => 
  {
    const formattedMessage = message.text;    
    const parts = formattedMessage.split(/\s*({{\s*[^}]+\s*}})\s*/g);

    const renderedMessage = parts.map((part, index, _items) => 
    {
      if (message.role == MessageRole.Assistant)
      {
        if (part.startsWith("{{") && part.endsWith("}}"))
        {
          let trimmedText = part.trim().replace(/{{\s*|\s*}}/g, '');

          const contacts = extractContacts(trimmedText);
          if (contacts.whatsapp)
          {
            return renderButton(() => window.open(`https://wa.me/${contacts.whatsapp}`, '_blank'), contacts.whatsapp!, <img src={WhatsappIcon} alt="WhatsApp" className="inline-block w-4 h-4 text-white" />);
          }
          if (contacts.phone) 
          {
            return renderButton(() => window.open(`tel:${contacts.phone}`, '_self'), contacts.phone!, <Phone size={16} className="inline-block" />);
          }
          if (contacts.email) 
          {
            return renderButton(() => window.open(`mailto:${contacts.email}`, '_self'), contacts.email!, <Mail size={16} className="inline-block" />);
          }
          if (contacts.address) 
          {
            return renderButton(() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(contacts.address as string)}`, '_blank'), contacts.address!, <MapPin size={16} className="inline-block" />);
          }
          if (contacts.url)
          {
            return renderButton(() => window.open(contacts.url as string, '_blank'), contacts.url!, <Link size={16} className="inline-block" />);
          }

          return <ReactMarkdown key={index}>{"**" + trimmedText + "**"}</ReactMarkdown>;
        }

        part = part.replace(/^[.,:;!?]\s*\n/, '');
      }

      return <ReactMarkdown key={index}>{part}</ReactMarkdown>;
    });

    if (isAi && message.isSelectionPrompt && message.options) 
    {
      return (
        <>
          <ReactMarkdown>{formattedMessage}</ReactMarkdown>
          {message.options.map((option, index) => (
            <button
              key={index}
              onClick={() => onSelectOption && onSelectOption(option)}
              className="flex items-center justify-center w-full text-left p-2 my-1 bg-gradient-to-r from-orange-400 to-orange-600 text-white rounded-lg shadow-md transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
              disabled={loading}
            >
              {option}
            </button>
          ))}
        </>
      );
    }

    return <div className="mb-2" key={0}>{renderedMessage}</div>;
  };

  useEffect(() => 
  {
    const links = document.querySelectorAll('a');    
    if (links.length > 0) 
    {
      const firstLink = links[0];
      const previousSibling = firstLink.previousSibling;
  
      if (!(previousSibling instanceof HTMLBRElement)) 
      {
        const br = document.createElement('br');
        firstLink.parentNode?.insertBefore(br, firstLink);
      }
    }
  
    links.forEach(link => 
    {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
      
      link.style.display = 'block'; 
      link.style.textAlign = 'center';
      link.style.margin = '0 auto'; 
      link.style.width = 'fit-content';
    });
    
    const elements = document.querySelectorAll('button, a');

    elements.forEach(element => 
    {
      const nextSibling = element.nextSibling;
      if (nextSibling && nextSibling.nodeType === Node.TEXT_NODE) 
      {
        nextSibling.textContent = nextSibling.textContent?.replace(/^\s*\./, '') ?? null;
      }
    
      const nextElement = element.nextElementSibling;
      if (nextElement && nextElement.tagName === 'P') 
      {
        nextElement.textContent = nextElement.textContent?.replace(/^\s*\./, '') ?? '';
      }
    });
  }, []);
  
  // Speaker Icon
  return (
    <>
      {isAi && (
        <div className="absolute top-[-1.2rem] right-[-3rem] mt-2 mr-2 z-10">
          <TextToSpeech
            text={message.text}
            buttonBackgroundColor="bg-gradient-to-r from-orange-400 to-orange-600"
            buttonPlayingBackgroundColor="bg-red-500"
            speakerColor="text-white"
            buttonSize="p-1"
          />
        </div>
      )}
      {renderContent()}
    </>
  );
};
