import React from 'react';
import { Link } from 'react-router-dom';
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { AnalyticsCards } from '../../../../ReactIntl/LocaleInterfaces';
import { RiStarFill } from "@remixicon/react";
import { Button } from '../../../../Components/Actions/Buttons/Buttons';

export const AnalyticsCard05: React.FC = () => 
{
  const intl = getIntl<AnalyticsCards>('analyticsCards');

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">{intl.h2TopChannels}</h2>
      </header>
      <div className="grow p-3">
        <div className="flex flex-col h-full">
          {/* Card content */}
          <div className="grow">
            <div className='flex ml-1.5'>
              <RiStarFill className="w-5 h-5 text-indigo-500 mt-0.5"/>
              <RiStarFill className="w-5 h-5 text-indigo-500 mt-0.5" />
              <RiStarFill className="w-5 h-5 text-indigo-500 mt-0.5" />
              <RiStarFill className="w-5 h-5 text-indigo-500 mt-0.5" />
              <RiStarFill className="w-5 h-5 dark:text-none text-gray-300 mr-4 mt-0.5" />
              <p className='font-semibold'>3,9 su 5</p>
            </div>

            <p className="text-sm ml-2 text-slate-800 dark:text-slate-300 mt-3 mb-4">2019 recensioni per la sede *Città*</p>

            <ul className="space-y-1 text-sm text-slate-800 dark:text-slate-100 mt-3 mb-4">
              {/* Item */}
              <li className="relative px-2 py-1">
                <div className="absolute inset-0 bg-indigo-100 dark:bg-indigo-500/30" aria-hidden="true" style={{ width: '53%' }}></div>
                <div className="relative flex justify-between space-x-2">
                  <div>
                    <p>5 Stelle
                    </p>
                  </div>
                  <div className="font-medium">53%</div>
                </div>
              </li>
              {/* Item */}
              <li className="relative px-2 py-1">
                <div className="absolute inset-0 bg-indigo-100 dark:bg-indigo-500/30" aria-hidden="true" style={{ width: '19%' }}></div>
                <div className="relative flex justify-between space-x-2">
                  <div>
                    <p>4 Stelle
                    </p>
                  </div>
                  <div className="font-medium">19%</div>
                </div>
              </li>
              {/* Item */}
              <li className="relative px-2 py-1">
                <div className="absolute inset-0 bg-indigo-100 dark:bg-indigo-500/30" aria-hidden="true" style={{ width: '8%' }}></div>
                <div className="relative flex justify-between space-x-2">
                  <div>
                    <p>3 Stelle
                    </p>
                  </div>
                  <div className="font-medium">8%</div>
                </div>
              </li>
              {/* Item */}
              <li className="relative px-2 py-1">
                <div className="absolute inset-0 bg-indigo-100 dark:bg-indigo-500/30" aria-hidden="true" style={{ width: '5%' }}></div>
                <div className="relative flex justify-between space-x-2">
                  <div>
                    <p>2 Stelle
                    </p>
                  </div>
                  <div className="font-medium">5%</div>
                </div>
              </li>
              {/* Item */}
              <li className="relative px-2 py-1">
                <div className="absolute inset-0 bg-indigo-100 dark:bg-indigo-500/30" aria-hidden="true" style={{ width: '15%' }}></div>
                <div className="relative flex justify-between space-x-2">
                  <div>
                    <p>1 Stella
                    </p>
                  </div>
                  <div className="font-medium">15%</div>
                </div>
              </li>
            </ul>
          </div>
          {/* Card footer */}
          <div className="flex justify-center pt-4 pb-1 border-t border-slate-100 dark:border-slate-700">
            <Link to="#0">
              <Button
                type='rArrow'
              >{intl.channelsReportLink}</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
