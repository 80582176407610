import { createIntl, createIntlCache } from "react-intl";
import moment from "moment-timezone";
import enMessages from "./Locales/Translation/en.json";
import itMessages from "./Locales/Translation/it.json";
import zhMessages from "./Locales/Translation/zh.json";
import esMessages from "./Locales/Translation/es.json";
import frMessages from "./Locales/Translation/fr.json";

import { is_string } from "../Utils/Utils";

const cache = createIntlCache();
const localeKey = "appLocale";
const timezoneKey = "appTimezone";
const supportedLanguages = ["en", "it", "zh"];

export const languages = [
  { code: "en", label: "English", flag: "gb" },
  { code: "it", label: "Italiano", flag: "it" },
  { code: "zh", label: "中文", flag: "cn" },
  { code: "es", label: "Español", flag: "es" },
  { code: "fr", label: "Français", flag: "fr" },
];

export const getIntlMessages = (locale: string) => 
{
  const language = getLanguage(locale);
  switch (language) 
  {
    case "it":
      return itMessages;
    case "zh":
      return zhMessages;
    case "es":
      return esMessages;
    case "fr":
      return frMessages;
    case "en":
    default:
      return enMessages;
  }
};

type Messages = { [key: string]: Record<string, string> };

export const getIntl = <T extends Record<string, string>>(
  component: string
) => 
{
  const locale = getLocale();
  const messages = getIntlMessages(locale) as Messages;
  const intl = createIntl({ locale, messages: messages[component] }, cache);
  const result = {
    ...(messages[component] as T),
    formatMessage: intl.formatMessage,
  };

  return result as T;
};

export const getTimezone = (defaultTimezone?: string): string => 
{
  const timezone =
    localStorage.getItem(timezoneKey) ?? defaultTimezone ?? "UTC";
  return timezone;
};

export const getLocale = (defaultLocale?: string): string => 
{
  let browserLocale;
  if (typeof navigator !== "undefined") 
  {
    browserLocale =
      navigator.language || (navigator.languages && navigator.languages[0]);
  }
  let storedLocale;
  if (typeof localStorage !== "undefined") 
  {
    storedLocale = localStorage.getItem(localeKey);
  }
  const locale = storedLocale ?? defaultLocale ?? browserLocale ?? "";
  return locale;
};

export const getLocaleString = (language: string, region: string): string => 
{
  let locale = language;
  if (language.includes("-")) 
  {
    locale = language.split("-")[0];
  }
  if (region) 
  {
    locale += `-${region}`;
  }

  return locale;
};

export const getLocaleRegion = (locale?: string): string => 
{
  locale ??= getLocale();
  return locale.includes("-") ? locale.split("-")[1] : "";
};

/**
 * Extracts language from provided locale or directly from localStorage. Supports format en-US, en, or empty string.
 */
export const getLanguage = (locale?: string): string => 
{
  locale = (locale ?? getLocale()).trim();
  return locale.includes("-")
    ? locale.split("-")[0]
    : locale.length == 2
      ? locale
      : "en";
};

export const getLanguageData = (language?: string) => 
{
  for (const lang of languages) 
  {
    if (lang.code == language) 
    {
      return lang;
    }
  }

  return languages[0];
};

export const getSupportedLanguage = (locale?: string): string => 
{
  const language = getLanguage(locale);
  return supportedLanguages.includes(language) ? language : "en";
};

export const setLocale = (
  locale: string | { language: string; region: string }
) => 
{
  locale = is_string(locale)
    ? locale
    : getLocaleString(locale.language, locale.region);
  localStorage.setItem(localeKey, locale);
};

export const setTimezone = (timezone: string) => 
{
  localStorage.setItem(timezoneKey, timezone);
};

export const setLanguage = (language: string, defaultLocale?: string) => 
{
  const region = getLocaleRegion(defaultLocale);
  const locale = region ? `${language}-${region}` : language;
  setLocale(locale);
};

export const getLocalTimeDate = (
  utcDate: Date,
  defaultTimezone?: string,
  defaultLocale?: string
): Date => 
{
  const timezone = getTimezone(defaultTimezone);
  const locale = getLocale(defaultLocale);
  moment.locale(locale);

  const localMoment = moment(utcDate).tz(timezone);
  return localMoment.toDate();
};

export const formatNumber = (
  number: number,
  defaultLocale?: string
): string => 
{
  const locale = getLocale(defaultLocale);
  return new Intl.NumberFormat(locale).format(number);
};
