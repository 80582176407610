import React, { useState, useEffect } from 'react';
import { Button } from '../../../Components/Actions/Buttons/Buttons';
import { IGenAICategory, IGenAIChatTemplate, IGenAIWriterTemplate } from '../Api/Types/GenAIInterface';
import { createNewAiChatTemplate, createNewAiWriterTemplate } from '../Api/Types/UtilityFunction';
import { GenAIItem } from '../Utils/GenAITypes';
import { BasicInfoStep } from './WizardSteps/GenAIBasicInfoStep';
import { TemplateDetailsStep } from './WizardSteps/GenAITemplateDetailsStep';
import { WriterInputsStep } from './WizardSteps/GenAIWriterInputsStep';
import { genAICategoryController } from '../Api/Controller/GenAICategory';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { ProgressBarSteps } from './Components/ProgressBarSteps';
import { StepsTitleBar } from './Components/StepsTitleBar';
import { FloatingChatbotStep } from './WizardSteps/GenAIFloatingChatbotStep';

type WizardType = 'category' | 'chatTemplate' | 'writerTemplate';

interface GenAIWizardPageProps 
{
  type: WizardType;
  onSubmit: (item: GenAIItem) => Promise<void>;
  onCancel: () => void;
  categories: IGenAICategory[];
  initialItem?: GenAIItem | null;
  initialStep?: number;
}

export const GenAIWizardPage: React.FC<GenAIWizardPageProps> = ({
  type,
  onSubmit,
  onCancel,
  categories: initialCategories,
  initialItem,
  initialStep,
}) => 
{ 
  const [step, setStep] = useState(initialStep || 1);
  const [categories, setCategories] = useState<IGenAICategory[]>(initialCategories);
  const { trigger, formState: { errors }, setValue, getValues} = useFormContext();

  useEffect(() => 
  {
    const data = initialItem ||
      (type === 'category' ? { name: '', description: '' } : 
        type === 'chatTemplate' ? createNewAiChatTemplate() : 
          createNewAiWriterTemplate());
    const metadata = data.metadata ? JSON.parse(data.metadata) : {};
    Object.entries(data).forEach(([key, value]) => setValue(key as keyof GenAIItem, value)); //set all values in form
    Object.entries(metadata).forEach(([key, value]) => setValue(key as keyof GenAIItem, value)); //set all metadata parsed values in form
  }, [initialItem, type, setValue]);
  console.log(initialItem, "initialItem");

  //If initialItem is null we are creating a new item
  const isCreating = !initialItem;

  const handleAddCategory = async (category: Omit<IGenAICategory, 'id'>): Promise<IGenAICategory> => 
  {
    try 
    {
      const response = await genAICategoryController.createCategory(category);
      const newCategory = response.data;
      setCategories(prevCategories => [...prevCategories, newCategory]);
      return newCategory;
    }
    catch (error) 
    {
      console.error("Failed to create category:", error);
      throw error;
    }
  };

  const renderStep = () => 
  {
    switch (step) 
    {
      case 1:
        return (
          <BasicInfoStep
            isTemplate={type !== 'category'}
            categories={categories}
            onAddCategory={handleAddCategory}
          />
        );
      case 2:
        if (type === 'writerTemplate') 
        {
          return (
            <WriterInputsStep />
          );
        }
        else if (type !== 'category') 
        {
          return (
            <TemplateDetailsStep />
          );
        }
        return null;
      case 3:
        if (type === 'writerTemplate') 
        {
          return (
            <TemplateDetailsStep />
          );
        }
        else if(type === 'chatTemplate')
        {
          return(
            <FloatingChatbotStep />
          );
        }
        return null;
      default:
        return null;
    }
  };

  const handleSubmit = async () => 
  {
    switch (type)
    {
      case 'writerTemplate':
        console.log(getValues() as IGenAIWriterTemplate);
        await onSubmit(getValues() as IGenAIWriterTemplate);
        break;
      case 'chatTemplate':
        console.log(getValues() as IGenAIChatTemplate);
        console.log(getValues("metadata"), "metadata SUBMITTATA");
        await onSubmit(getValues() as IGenAIChatTemplate);
        break;
      case 'category':
        await onSubmit({
          name: getValues('name'),
          description: getValues('description'),
          metadata: JSON.stringify({ categoryColor: getValues('categoryColor'), categoryIcon: getValues('categoryIcon') })
        });
        break;
    }
    //TODO: feedback se si crea un template con lo stesso nome invece di error 409
  };

  const handleStepValidation = async () => 
  {
    let res = false;
    switch(step)
    {
      case 1:
        if(type === 'writerTemplate' || type === 'chatTemplate')
        {
          res = await trigger(['name', 'description', 'categoriesId', 'templateIcon', 'templateColor']);
        }
        else if(type === 'category')
        {
          res = await trigger(['name', 'description']);
        }
        break;
      case 2:
        if(type === 'writerTemplate')
        {
          res = await trigger(['data.inputs']);
        }
        else if(type === 'chatTemplate')
        {
          //TODO: insert names of chatSettings second step like training for auth
          res = await trigger(['data.prompt']);
        }
        break;
      case 3:
        if(type === 'writerTemplate')
        {
          res = await trigger(['data.prompt']);  
        }
        else if(type === 'chatTemplate')
        {
          res = await trigger(['floatingSettings']);
        }
        break;
    }
    return res;
  };

  const handleNext = async () => 
  {
    try
    {
      const isValid = await handleStepValidation();
      if (!isValid)
      {
        throw new Error(`Error validation at step ${step}`);
      }
      if (step >= getMaxSteps()) 
      {
        await handleSubmit();
        onCancel();
        return;
      }
        
      setStep(step + 1);
    
    }
    catch(error)
    {
      if (errors && Array.isArray(errors.inputs))
      {
        console.log(errors);
        errors.inputs.forEach((error) => 
        {
          if (error.possibleValues?.message) 
          {
            toast.error(error.possibleValues.message, { id: error.possibleValues.message }); // id for unique toast if multiple dropdown has no options
          }
        });
      }
    }
  };

  const handleBack = () => 
  {
    if (step > 1) 
    {
      setStep(step - 1);
    }
    else 
    {
      onCancel();
    }
  };

  const getMaxSteps = () => 
  {
    switch (type) 
    {
      case 'category':
        return 1;
      case 'chatTemplate':
        return 3;
      case 'writerTemplate':
        return 3;
    }
  };

  const getTitles = () => 
  {
    switch (type) 
    {
      case 'category':
        return ['Category'];
      case 'chatTemplate':
        return ['Template', 'Training', 'Floating Settings'];
      case 'writerTemplate':
        return ['Template', 'Inputs', 'Prompt'];
    }
  };

  return (
    <form className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 p-6">
      {/* Progress bar steps numbers*/}
      {type !== 'category' && <ProgressBarSteps totalSteps={getMaxSteps()} currentStep={step} />}

      <div className="mb-8 flex flex-col items-center">
        <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
          {isCreating ? 'Create' : 'Edit'} {type === 'category' ? 'Category' : type === 'chatTemplate' ? 'Chat Template' : 'Writer Template'} ✨
        </h1>
      </div>

      {/* Steps titles */}
      <StepsTitleBar titles={getTitles()} currentStep={step} />

      {/* Form content */}
      {renderStep()}
      
      <div className="flex items-center justify-between mt-6">
        <Button onClick={handleBack} variant="secondary" buttonType='button'>
          {step === 1 ? 'Cancel' : 'Back'}
        </Button>
        <Button onClick={handleNext} variant="primary" buttonType='button'>
          {step === getMaxSteps() ? 'Submit' : 'Next'}
        </Button>
      </div>
    </form>
  );
};