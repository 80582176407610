import React, { useState } from 'react';
import { Tooltip } from '../../Components/Tooltip';
import { FieldValues, get, UseFormWatch } from 'react-hook-form';
import { BaseFieldProps } from '../Utils/FormFields.types';
import { CheckIcon, XIcon, EyeIcon, EyeOffIcon } from 'lucide-react';

export interface InputFieldProps<T extends FieldValues> extends BaseFieldProps<T> 
{
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  watch?: UseFormWatch<T>;
}

export const PasswordInputField = <T extends FieldValues>({
  name,
  label,
  required,
  tooltip,
  register,
  errors,
  placeholder,
  value,
  className,
  onChange,
  watch,
}: InputFieldProps<T>) => 
{
  const error = get(errors, name);
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = (e: React.MouseEvent) => 
  {
    e.preventDefault();
    setShowPassword((prev) => !prev);
  };

  // Password required checks
  const validationChecks = [
    { label: 'At least 8 characters', isValid: value!.length >= 8 },
    { label: 'Contains an uppercase letter', isValid: /[A-Z]/.test(value || '') },
    { label: 'Contains a lowercase letter', isValid: /[a-z]/.test(value || '') },
    { label: 'Contains a number', isValid: /[0-9]/.test(value || '') },
    { label: 'Contains a special character', isValid: /[@$!%*?&]/.test(value || '') },
  ];

  return (
    <div className="w-full">
      <div className="flex justify-center items-center gap-2 text-center mb-1">
        <label className="block text-sm font-medium" htmlFor={name}>
          {label}
          {required && <span className="text-rose-500">*</span>}
        </label>
        {tooltip && (
          <Tooltip position="top">
            <div className="text-xs whitespace-nowrap">{tooltip}</div>
          </Tooltip>
        )}
      </div>

      <div className="relative">
        <input
          {...register(name)}
          id={name}
          type={showPassword ? 'text' : 'password'} // Show or hide password
          placeholder={placeholder}
          className={`form-input w-full ${error ? 'border-rose-500' : ''} ${className}`}
          onChange={onChange}
          value={value}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />

        <button
          type="button"
          onClick={handleShowPassword}
          onMouseDown={(e) => e.preventDefault()} // Prevent losing focus on button
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600"
        >
          {showPassword ? <EyeOffIcon /> : <EyeIcon />}
        </button>
      </div>
          
      {/* Password feedbacks */}
      {isFocused && (
        <ul className="mt-2 text-xs">
          {validationChecks.map((check, index) => (
            <li key={index} className={`${check.isValid ? 'text-green-500' : 'text-rose-500'} flex items-center`}>
              {check.isValid ? <CheckIcon /> : <XIcon />}{check.label}
            </li>
          ))}
        </ul>
      )}

    </div>
  );
};
