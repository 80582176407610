import React from 'react';
import { Volume2 } from 'lucide-react';

interface TextToSpeechProps 
{
  text: string | undefined;
  buttonBackgroundColor?: string;
  buttonPlayingBackgroundColor?: string;
  speakerColor?: string;
  buttonSize?: string;
}

export const TextToSpeech: React.FC<TextToSpeechProps> = ({
  text,
  buttonBackgroundColor = 'bg-green-500',
  buttonPlayingBackgroundColor = 'bg-yellow-500',
  speakerColor = 'text-white',
  buttonSize = 'p-2',
}) => 
{
  const [isPlaying, setIsPlaying] = React.useState(false);

  const handleTextToSpeech = () => 
  {
    if ('speechSynthesis' in window) 
    {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.lang = 'it-IT';

      utterance.onstart = () => 
      {
        setIsPlaying(true);
      };

      utterance.onend = () => 
      {
        setIsPlaying(false);
      };

      window.speechSynthesis.speak(utterance);
    }
    else 
    {
      console.error('La sintesi vocale non � supportata in questo browser.');
    }
  };

  return (
    <button
      onClick={handleTextToSpeech}
      disabled={isPlaying}
      className={`${buttonSize} rounded-full ${
        isPlaying ? buttonPlayingBackgroundColor : buttonBackgroundColor
      } focus:outline-none`}
    >
      <Volume2 size={15} className={speakerColor} />
    </button>
  );
};
