import { apiDefinitions } from "../../ApiConfig";
import { Filter } from "../../../Utils/Filter";
import { PriceDto, PriceUpdateDto } from "../../../Utils/Stripe.types";

const definition = apiDefinitions.price;

export const priceController = {
  getAll: async (filter: Filter) => 
  {
    const endpoint = definition.getAll;
    const response = await endpoint.invoke(endpoint.url, {filter});

    return response.data;
  },

  getPrice: async (id: PriceDto) => 
  {
    const endpoint = definition.getPrice;
    const response = await endpoint.invoke(endpoint.url, {id});

    return response.data;
  },

  updatePrice: async (priceId: string, price: PriceUpdateDto) => 
  {
    const endpoint = definition.updatePrice;
    const response = await endpoint.invoke(endpoint.url.replace('{id}', priceId),  price );

    return response;
  }
};