import React, { useContext } from "react";
import { Button } from "../../../Components/Actions/Buttons/Buttons";
import { ActionProps } from "./Actions.types";
import { getIntl } from "../../../ReactIntl/IntlConfig";
import { ProgressContextActionsMenu } from "../../../ReactIntl/LocaleInterfaces";
import { LanguageContext } from "../../../Contexts/LanguageContext";

export const Delete: React.FC<ActionProps> = ({ onConfirm, onCancel, itemName }) => 
{
  const intl = getIntl<ProgressContextActionsMenu>("progressContextActionsMenu");
  const _languageCtx = useContext(LanguageContext);
  return (
    <div>
      <h2 className="text-xl mb-4">Delete {itemName}</h2>
      <p className="mb-4">{intl.areYouSureDelete} {itemName}?</p>
      <div className="flex justify-end">
        <div className="m-1.5">
          <Button
            variant="tertiary"
            size="l"
            onClick={onCancel}
          >
            {intl.cancel}
          </Button>
        </div>
        <div className="m-1.5">
          <Button
            variant="danger"
            size="l"
            onClick={() => onConfirm()}
          >
            {intl.delete}
          </Button>
        </div>
      </div>
    </div>

  );
};