import React from 'react';
import { FolderComponent } from './FolderComponent';
import { Folder, FileItem } from './FileManager.types';
import { useProgress } from './Hooks/UseProgress';
import {motion} from "framer-motion";

interface SidebarProps 
{
  folders: Folder[];
  onSelect: (folder: Folder) => void;
  onDragStart: (e: React.DragEvent, item: Folder | FileItem) => void;
  onDragOver: (e: React.DragEvent) => void;
  onDrop: (e: React.DragEvent, folder: Folder) => void;
  onContextMenu: (e: React.MouseEvent, item: Folder | FileItem, type: 'folder' | 'file') => void;
}

const containerVariants = {
  hidden: {
    opacity: 0,
    x: 300,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.2,
      staggerChildren: 0.2,
    },
  },
};

export const SidebarFile: React.FC<SidebarProps> = ({
  folders,
  onSelect,
  onDragStart,
  onDragOver,
  onDrop,
  onContextMenu
}) => 
{
  const _progressCtx = useProgress();

  return (
    <motion.div className="w-1/4 p-4 border-r border-gray-300 dark:border-slate-500 max-h-full overflow-y-auto"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {folders.map(folder => (
        <FolderComponent
          key={folder.id}
          folder={folder}
          level={0}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onContextMenu={onContextMenu}
          onSelect={onSelect}
        />
      ))}
    </motion.div>
  );
};