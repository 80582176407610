import React, { useContext } from 'react';
import { Button } from '../../Components/Actions/Buttons/Buttons';
import { DropdownFilter } from '../../Components/DropdownFilter';
import { Datepicker } from '../../Components/Datepicker';

// import { useUserInfo } from '../../Hooks/UseUserInfo';
import { WelcomeBanner } from './Partials/WelcomeBanner';
import { DashboardAvatars } from './Partials/DashboardAvatars';
import { DashboardCard01 } from './Partials/DashboardCard01';
import { DashboardCard02 } from './Partials/DashboardCard02';
import { DashboardCard03 } from './Partials/DashboardCard03';
import { DashboardCard04 } from '../../Features/GenAI/Analitycs/Partials/DashboardCard04';
import { DashboardCard06 } from './Partials/DashboardCard06';
import { DashboardCard05 } from './Partials/DashboardCard05';
import { DashboardCard07 } from './Partials/DashboardCard07';
import { DashboardCard08 } from './Partials/DashboardCard08';
import { DashboardCard09 } from './Partials/DashboardCard09';
import { DashboardCard10 } from './Partials/DashboardCard10';
import { DashboardCard11 } from './Partials/DashboardCard11';
import { DashboardUserSearch } from './Partials/DashboardUserSearch';
import { DashboardUserPlan } from './Partials/DashboardUserPlan';
import { getIntl } from '../../ReactIntl/IntlConfig';
import { DashboardPageLocale } from '../../ReactIntl/LocaleInterfaces';
import { LanguageContext } from '../../Contexts/LanguageContext';
import { useUser } from '../../Features/Auth/Hooks/UseSession';

export const Dashboard: React.FC = () => 
{

  const _languageCtx = useContext(LanguageContext);
  // const { isAdmin } = useUserInfo();
  const user = useUser();
  const isAdmin = user ? true : false;

  const intl = getIntl<DashboardPageLocale>("dashboardPage");

  return (
    <div className="flex h-[100dvh] overflow-hidden p-0">

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <WelcomeBanner />

            {/* Dashboard actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">

              {isAdmin && (
                <>
                  {/* Left: Avatars */}
                  <DashboardAvatars />

                  {/* Right: Actions */}
                  <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    {/* Filter button */}
                    <DropdownFilter align="right" fields={[]} />

                    {/* Datepicker built with flatpickr */}
                    <Datepicker align="right" />

                    {/* Add view button */}
                    <Button
                      variant="primary"
                      size="m"
                      type="add"
                      hideOnMobile="label"
                    >
                      {intl.addView}
                    </Button>

                  </div>
                </>
              )}

            </div>

            {isAdmin ? (

              <div className="grid grid-cols-12 gap-6">
                {/* Line chart (Acme Plus) */}
                <DashboardCard01 />
                {/* Line chart (Acme Advanced) */}
                <DashboardCard02 />
                {/* Line chart (Acme Professional) */}
                <DashboardCard03 />
                {/* Bar chart (Direct vs Indirect) */}
                <DashboardCard04 />
                {/* Line chart (Real Time Value) */}
                <DashboardCard05 />
                {/* Doughnut chart (Top Countries) */}
                <DashboardCard06 />
                {/* Table (Top Channels) */}
                <DashboardCard07 />
                {/* Line chart (Sales Over Time) */}
                <DashboardCard08 />
                {/* Stacked bar chart (Sales VS Refunds) */}
                <DashboardCard09 />
                {/* Card (Recent Activity) */}
                <DashboardCard10 />
                {/* Card (Income/Expenses) */}
                <DashboardCard11 />
              </div>
            ) : (
              <div className="">

                {/* Search bar */}
                <DashboardUserSearch />
                <DashboardUserPlan />
              </div>
            )}

          </div>
        </main>
      </div>
    </div>
  );
};
