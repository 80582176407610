import { Layout } from 'lucide-react';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ExternalApps } from '../App/Admin/ClientSettings/ExternalApps';
import { UserList } from '../App/Admin/UserList';
import { ChatbotRoutes } from '../Features/GenAI/ChatbotRoutes';
import { ChatbotProvider } from '../Features/GenAI/ChatbotUtils/ChatbotContext';
import { GenAIMainPage } from '../Features/GenAI/Layout/GenAIMainPage';
import { AiWriterGenerator } from '../Features/GenAI/TemplateSendRendering/AIWriterGenerator';
import { GenAIProvider } from '../Features/GenAI/Utils/GenAIContext';
import { PageNotFound } from '../Pages/Utility/PageNotFound';
import { ChatbotTrainingPage } from '../Features/GenAI/Training/TrainingChatbotPage';
import { AnalyticsChatbotPage } from '../Features/GenAI/Analitycs/AnalyticsChatbotPage';
import { AnalyticsDataPage } from '../Features/GenAI/Analitycs/AnalyticsDataPage';

export const AdminRoutes: React.FC = () => 
{

  return (
    <GenAIProvider>
      <ChatbotProvider>
        <Routes>

          <Route path="/user-list" element={<UserList />} />
          <Route path="/app-settings/layout" element={<Layout />} />
          <Route path="/app-settings/external-login" element={<ExternalApps />} />
          <Route path="*" element={<PageNotFound />} />

          {/* GenAI */}
          <Route path="/gen-ai/chatbot/*" element={<ChatbotRoutes />} />
          <Route path="/gen-ai/categories" element={<GenAIMainPage />} />
          <Route path="/gen-ai/ai-writer" element={<GenAIMainPage />} />
          <Route path="/gen-ai/ai-chatbot" element={<GenAIMainPage />} />
          <Route path="/gen-ai/chatbot-training" element={<ChatbotTrainingPage />} />
          <Route path="/gen-ai/chatbot-analytics" element={<AnalyticsChatbotPage />} />
          <Route path="/gen-ai/chatbot-analytics/data" element={<AnalyticsDataPage />} />
          <Route path="/gen-ai/ai-writer/:id" element={<AiWriterGenerator />} />
        </Routes>

      </ChatbotProvider>
    </GenAIProvider>
  );
};