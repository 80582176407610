import React, { useEffect, useState, useContext } from 'react';
import { accountController } from '../../../Api/Controller/ApiAccount';
import { getIntl } from '../../../ReactIntl/IntlConfig';
import { AccountSettingsAppsPanelLocale } from '../../../ReactIntl/LocaleInterfaces';
import {LanguageContext} from '../../../Contexts/LanguageContext';

export function AppsPanel() 
{
  const [providers, setProviders] = useState<string[]>([]);
  const _languageCtx = useContext(LanguageContext);

  const intl = getIntl<AccountSettingsAppsPanelLocale>('accountSettingsAppsPanel');

  useEffect(() => 
  {
    const fetchProviders = async () => 
    {
      try 
      {
        const providers = await accountController.fetchExternalLoginProviders();
        setProviders(providers.data);
      }
      catch (error) 
      {
        console.error('Error fetching providers:', error);
      }
    };

    fetchProviders();
  }, []);

  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">{intl.title}</h2>
        {/* Connected Apps cards */}
        <section className="pb-6 border-b border-slate-200 dark:border-slate-700">
          <div className="grid grid-cols-12 gap-6">
            {providers.map((provider, index) => (
              <div key={index} className="col-span-full xl:col-span-6 2xl:col-span-4 bg-white dark:bg-slate-800 shadow-md rounded-sm border border-slate-200 dark:border-slate-700">
                {/* Card content */}
                <div className="flex h-full p-5">
                  {/* SVG Logo */}
                  <img src={`/OAuthLogos/${provider}.svg`} alt={`${provider} logo`} className="w-16 h-16 mr-4" />

                  <div className="flex flex-col justify-center">
                    <header className="mb-2">
                      <h3 className="text-lg text-slate-800 dark:text-slate-100 font-semibold">{provider}</h3>
                    </header>
                    {/* Card footer */}
                    <footer>
                      <div className="flex justify-between items-center">
                        {/* Left side */}
                        <div className="flex space-x-3">
                          <div className="flex items-center text-slate-400 dark:text-slate-500">
                          </div>
                          <div className="flex items-center text-amber-500">
                          </div>
                        </div>
                        {/* Right side */}
                        <button className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm flex items-center">
                          <svg className="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2" viewBox="0 0 12 12">
                            <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                          </svg>
                          <span>Connected</span>
                        </button>
                      </div>
                    </footer>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>

    </div>
  );
}
