import { apiDefinitions } from "../../ApiConfig";
import { Filter } from "../../../Utils/Filter";
import { RoleDto } from "../../Roles/Role.types";

const definition = apiDefinitions.roleList;

export const roleListController =
{
  getRoles: async (filter: Filter) => 
  {
    const endpoint = definition.getRoles;
    const response = await endpoint.invoke(endpoint.url, { filter });

    return response;
  },

  getRoleById: async (id: string) => 
  {
    const endpoint = definition.getRoleById;
    const response = await endpoint.invoke(endpoint.url.replace("{id}", id));

    return response;
  },

  editRole: async (id: string, role: RoleDto) => 
  {
    const endpoint = definition.editRole;
    const response = await endpoint.invoke(
      endpoint.url.replace("{id}", id),
      role
    );

    return response;
  },

  count: async () => 
  {
    const endpoint = definition.count;
    const response = await endpoint.invoke(endpoint.url);

    return response;
  }
};
