import { Filter } from "../../../../Utils/Filter";
import { genAIApiDefinitions } from "../ApiConfig";
import { IUserGenAITemplateConfig } from "../Types/GenAIInterface";
import { GenAITemplateChatSendRequest, GenAITemplateSendRequest } from "../Types/GenAIRequest";

const definition = genAIApiDefinitions.genAIUser;

export const genAIUserController = {
  getModels: async (filter: Filter) => 
  {
    const endpoint = definition.getModels;
    const response = await endpoint.invoke(endpoint.url, { filter });
    return response;
  },

  getProviderSettings: async (filter: Filter) => 
  {
    const endpoint = definition.getProviderSettings;
    const response = await endpoint.invoke(endpoint.url, { filter });
    return response;
  },

  updateProviderSettings: async (provider: string, model: string) => 
  {
    const endpoint = definition.updateProviderSettings;
    const response = await endpoint.invoke(endpoint.url, { provider, model });
    return response;
  },

  addContext: async (context: File) => 
  {
    const endpoint = definition.addContext;
    const response = await endpoint.invoke(endpoint.url, { context });
    return response;
  },

  sendWriter: async <T>(
    sendRequest: GenAITemplateSendRequest,
    onMessageCallback: (message: string) => void,
    onCompleteCallback: (data: T) => void,
    onErrorCallback?: (error: unknown) => void
  ) => 
  {
    const endpoint = definition.sendWriter;

    if (sendRequest.streamResponse) 
    {
      await endpoint.invoke(endpoint.url, sendRequest, {
        sseHandler: {
          onMessage: onMessageCallback,
          onComplete: (data: unknown) => onCompleteCallback(data as T),
          onError: onErrorCallback,
        },
      });
    }
    else 
    {
      const response = await endpoint.invoke(endpoint.url, sendRequest);
      onCompleteCallback(response.data as T);
      return response;
    }
  },
  sendChat: async <T>(
    sendRequest: GenAITemplateChatSendRequest,
    onMessageCallback: (message: string) => void,
    onCompleteCallback: (data: T) => void,
    onErrorCallback?: (error: unknown) => void
  ) => 
  {
    const endpoint = definition.sendChat;
  
    try 
    {
      if (sendRequest.streamResponse) 
      {
        await endpoint.invoke(endpoint.url, sendRequest, {
          sseHandler: {
            onMessage: onMessageCallback,
            onComplete: (data: unknown) => onCompleteCallback(data as T),
            onError: onErrorCallback,
          },
        });
      }
      else 
      {
        const response = await endpoint.invoke(endpoint.url, sendRequest);
        onCompleteCallback(response.data as T);
        return response;
      }
    }
    catch (error) 
    {
      onErrorCallback?.(error);
      throw error;
    }
  },

  updateTemplateFavorite: async (templatesConfig: IUserGenAITemplateConfig) => 
  {
    const endpoint = definition.updateTemplateFavorite;
    const response = await endpoint.invoke(endpoint.url, templatesConfig);
    return response;
  },

  getTemplatesConfig: async () => 
  {
    const endpoint = definition.getTemplatesConfig;
    const response = await endpoint.invoke(endpoint.url);
    return response;
  },
};