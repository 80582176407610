import React from 'react';
import { BaseChatbot } from '../../Features/GenAI/ChatbotLayout/BaseChatbot';
import ChatBotIcon from './BrissagoAssets/ChatbotIcon.png';
import { useBrissagoChatbotLogic } from './BrissagoChatbotLogic';
import { styleCustomization, animationCustomization } from './BrissagoAssets/BrissagoChatbotStyles';

const chatbotName = 'Giorgino';

export const BrissagoChatbot: React.FC = () => (
  <BaseChatbot
    chatbotIcon={ChatBotIcon}
    chatbotName={chatbotName}
    useChatbotLogic={useBrissagoChatbotLogic}
    styleCustomization={styleCustomization}
    animationCustomization={animationCustomization}
  />
);