import React from 'react';
import { Button } from '../Buttons/Buttons';
import { useModalContext } from './UseModal';
import { ModalChoiceProps } from './ModalTypes';

export const ModalChoice: React.FC<ModalChoiceProps> = ({ onConfirm }) => 
{
  const { close, confirm, isConfirming, variant } = useModalContext();

  const handleConfirm = async () => 
  {
    if (onConfirm) 
    {
      await onConfirm(); // Esegui la funzione onConfirm personalizzata, se presente
    }
    await confirm(); // Esegui la logica di conferma centrale (chiusura modale e altro)
  };

  // Determina il testo del pulsante di conferma in base alla variant
  const confirmButtonText = variant === 'danger'
    ? 'Elimina'
    : variant === 'success'
      ? 'Salva'
      : 'Conferma';

  return (
    <div className="flex justify-end space-x-2">
      <Button
        variant="tertiary"
        size="m"
        onClick={close}
        disabled={isConfirming}
        loading={isConfirming}
      >
        Annulla
      </Button>
      <Button
        variant={variant || 'primary'}
        size="m"
        onClick={handleConfirm}
        disabled={isConfirming}
        loading={isConfirming}
      >
        {confirmButtonText}
      </Button>
    </div>
  );
};
