import React, { useCallback, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../Components/Actions/Buttons/Buttons';
import toast from 'react-hot-toast';
import { modal } from '../../Components/Actions/ProgModal/ProgModal';

export const TablesPage: React.FC = () => 
{
  const piatti = useMemo(() => ({
    antipasti: [
      'Tagliere salumi e formaggi', 'Bruschette miste', 'Carpaccio di manzo',
      'Insalata di mare', 'Verdure in pinzimonio', 'Crostini toscani',
      'Frittura di calamari', 'Caprese', 'Prosciutto e melone', 'Insalata russa'
    ],
    primi: [
      'Spaghetti al pomodoro', 'Penne con crema di zucchine', 'Risotto ai funghi',
      'Gnocchi al ragù', 'Ravioli burro e salvia', 'Fettuccine al pesto',
      'Lasagna classica', 'Tagliolini al tartufo', 'Tortellini in brodo', 'Orecchiette alle cime di rapa'
    ],
    secondi: [
      'Guancia di maiale', 'Fiorentina', 'Pollo alla cacciatora',
      'Branzino al forno', 'Tagliata di manzo', 'Frittata di patate',
      'Agnello scottadito', 'Filetto al pepe verde', 'Stufato di manzo', 'Salmone grigliato'
    ],
    contorni: [
      'Verdure grigliate', 'Patate al forno', 'Insalata mista',
      'Carote al burro', 'Spinaci saltati', 'Fagiolini all’agro',
      'Zucchine trifolate', 'Purè di patate', 'Peperonata', 'Cavolfiore gratinato'
    ],
    dolci: [
      'Tiramisù della casa', 'Cheesecake ai frutti di bosco', 'Torta della nonna',
      'Panna cotta al caramello', 'Gelato misto', 'Crema catalana',
      'Profiteroles', 'Torta al cioccolato', 'Millefoglie', 'Crostata di frutta'
    ],
    bevande: [
      'Acqua naturale', 'Acqua frizzante', 'Coca Cola', 'Vino rosso', 'Vino bianco',
      'Birra chiara', 'Birra scura', 'Spritz', 'Caffè', 'Tè freddo'
    ]
  }), []);

  // Stato per tracciare i piatti ordinati di ogni tavolo
  const [orderedDishes, setOrderedDishes] = useState<{ [key: string]: { antipasti: string[], primi: string[], secondi: string[], contorni: string[], dolci: string[], bevande: string[] } }>({});

  // Stato per tracciare lo stato di loading di ogni tavolo
  const [loadingTables, setLoadingTables] = useState<{ [key: string]: boolean }>({});
  // Stato per tracciare se stiamo pulendo tutti i tavoli
  const [isClearingAllTables, setIsClearingAllTables] = useState<boolean>(false);
  // Stato per tenere traccia del numero di servizi e dell'orario di inizio per ogni tavolo
  const [services, setServices] = useState<{ [key: string]: { count: number; startTime: string } }>(
    {
      'Tavolo 1': { count: 0, startTime: '00:00' },
      'Tavolo 2': { count: 0, startTime: '00:00' },
      'Tavolo 3': { count: 0, startTime: '00:00' },
      'Tavolo 4': { count: 0, startTime: '00:00' },
      'Tavolo 5': { count: 0, startTime: '00:00' },
      'Tavolo 6': { count: 0, startTime: '00:00' },
      'Tavolo 7': { count: 0, startTime: '00:00' },
      'Tavolo 8': { count: 0, startTime: '00:00' },
      'Tavolo 9': { count: 0, startTime: '00:00' },
    }
  );

  // Funzione per ottenere l'orario corrente in formato HH:MM
  const getCurrentTime = () => 
  {
    const now = new Date();
    return `${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}`;
  };

  // Funzione per generare piatti casuali per ogni categoria con numero random di elementi
  const generaPiattiCasuali = useMemo(() => () => 
  {
    const getRandomItems = (items: string[]) => 
    {
      const count = Math.floor(Math.random() * (items.length + 1)); // Numero random da 0 a items.length
      const shuffled = [...items].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, count);
    };
    return {
      antipasti: getRandomItems(piatti.antipasti),
      primi: getRandomItems(piatti.primi),
      secondi: getRandomItems(piatti.secondi),
      contorni: getRandomItems(piatti.contorni),
      dolci: getRandomItems(piatti.dolci),
      bevande: getRandomItems(piatti.bevande),
    };
  }, [piatti]);

  // Funzione per gestire il toast di "Pulisci tavolo" e aggiornare lo stato di loading e i servizi
  const handleClearTable = useCallback(async (tableName: string) => 
  {
    // Mostra la modale di conferma
    const confirmed = await modal.custom({
      title: 'Conferma Pulizia Tavolo',
      content: `Sei sicuro di voler pulire il ${tableName}?`,
      confirmText: 'Pulisci',
      cancelText: 'Annulla',
      variant: 'caution',
    });

    // Solo se l'utente conferma, esegui l'azione
    if (confirmed) 
    {
      setLoadingTables(prevState => ({ ...prevState, [tableName]: true }));

      toast.loading(`Pulizia del ${tableName} in corso...`, { id: `clearTable_${tableName}` });

      setTimeout(() => 
      {
        // Simulazione di successo
        toast.success(`${tableName} è stato pulito con successo`, { id: `clearTable_${tableName}` });

        // Aggiorniamo il numero di servizi, l'orario di inizio e i piatti per il tavolo specifico
        setServices(prevState => ({
          ...prevState,
          [tableName]: {
            count: prevState[tableName].count + 1,
            startTime: getCurrentTime(),
          },
        }));

        // Generiamo piatti casuali per il tavolo
        setOrderedDishes(prevState => ({
          ...prevState,
          [tableName]: generaPiattiCasuali(),
        }));

        // Dopo il successo, rimuoviamo lo stato di loading per quel tavolo
        setLoadingTables(prevState => ({ ...prevState, [tableName]: false }));
      }, 1000);
    }
  }, [generaPiattiCasuali]);

  // Funzione per gestire il reset di tutti i tavoli
  const handleClearAllTables = useCallback(async () => 
  {
    // Mostra la modale di conferma
    const confirmed = await modal.custom({
      title: 'Conferma Pulizia',
      content: 'Sei sicuro di voler pulire tutti i tavoli?',
      confirmText: 'Pulisci',
      cancelText: 'Annulla',
      variant: 'danger',
    });

    // Solo se l'utente conferma, esegui l'azione
    if (confirmed) 
    {
      setIsClearingAllTables(true);
      toast.loading('Pulizia di tutti i tavoli in corso...', { id: "clearAllTables" });

      setTimeout(() => 
      {
        // Simulazione di successo
        toast.success('Tutti i tavoli sono stati puliti con successo', { id: "clearAllTables" });

        // Resettiamo lo stato dei servizi e dell'orario di inizio dei tavoli
        setServices(prevState => 
        {
          const resetServices: { [key: string]: { count: number; startTime: string } } = {};
          Object.keys(prevState).forEach(tableName => 
          {
            resetServices[tableName] = { count: 0, startTime: '00:00' };
          });
          return resetServices;
        });

        // Ripristiniamo lo stato dei piatti ordinati per ogni tavolo
        setOrderedDishes(() => ({}));

        // Rimuoviamo lo stato di clearing all tables
        setIsClearingAllTables(false);
      }, 1000);
    }
  }, []);

  return (
    <div className="min-h-screen">
      <div className="relative px-4 sm:px-6 lg:px-12 xl:px-14 py-12 lg:py-10 max-w-6xl mx-auto">
        <div className='absolute top-2 xl:top-11 left-2 sm:left-4 lg:left-10 xl:left-2'>
          <Link to="/">
            <Button
              variant='tertiary'
              type='lArrow'
              hideText
              title='Dashboard'
              className='border-slate-200 hover:border-slate-300 rounded-full dark:border-slate-700 dark:hover:border-slate-600'
            />
          </Link>
        </div>
        {/* Header */}
        <div className='flex justify-between'>
          <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mb-3">
            Moritzino: Gestione Tavoli
          </h1>
          <div>
            <Button
              type='rArrow'
              variant='danger'
              onClick={handleClearAllTables}
              disabled={isClearingAllTables}
              loading={isClearingAllTables}
            >
              Pulisci tutti i Tavoli
            </Button>
          </div>
        </div>
        <p className="text-sm text-slate-800 dark:text-slate-300">
          Qui puoi leggere le conversazioni e gli ordini dei clienti nei chatbot di tutti i tavoli.
        </p>
        <p className="text-sm text-slate-800 dark:text-slate-300">
          Ricordati di premere "Pulisci Tavolo" all'arrivo dei clienti nel tavolo specifico.
        </p>

        {/* Main */}
        <div className="grid gap-4 mt-5 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-center">
          {Object.keys(services).map((tableName, index) => (
            <div
              key={index}
              className="w-full p-6 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
            >
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {tableName}
              </h5>
              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Servizio N. {services[tableName].count}</p>
              <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Orario inizio: {services[tableName].startTime}</p>

              {/* Sezione per piatti con altezza fissa e overflow scorrevole */}
              <div className="max-h-48 overflow-y-auto mb-3">
                {orderedDishes[tableName] && Object.values(orderedDishes[tableName]).some(dishes => dishes.length > 0) ? (
                  <>
                    {Object.entries(orderedDishes[tableName]).map(([category, dishes]) => (
                      dishes.length > 0 && (
                        <div key={category}>
                          <h6 className="mt-2 mb-1 text-xl font-semibold text-gray-900 dark:text-white">
                            {category.charAt(0).toUpperCase() + category.slice(1)}:
                          </h6>
                          {dishes.map((piatto, idx) => (
                            <p key={idx} className="mb-1 font-normal text-gray-700 dark:text-gray-400">
                              {piatto}
                            </p>
                          ))}
                        </div>
                      )
                    ))}
                  </>
                ) : (
                  <div className="h-48 flex items-center justify-center">
                    <p className="font-normal text-gray-700 dark:text-gray-400">
                      Qua verranno visualizzati i piatti ordinati
                    </p>
                  </div>
                )}
              </div>

              <div className="flex justify-evenly mt-5">
                <Button
                  type="rArrow"
                  variant="textDanger"
                  onClick={() => handleClearTable(tableName)}
                  disabled={isClearingAllTables || (loadingTables[tableName] ?? false)}
                  loading={isClearingAllTables || (loadingTables[tableName] ?? false)}
                >
                  Pulisci Tavolo
                </Button>
                <Button type="rArrow">
                  Leggi Chat
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
