import { LineChart09 } from '../../../Charts/LineChart09';
import React from 'react';
// Import utilities
import { tailwindConfig } from '../../../Utils/Utils';
import { getIntl } from '../../../ReactIntl/IntlConfig';
import { FintechCards } from '../../../ReactIntl/LocaleInterfaces';

interface MiniChartData 
{
  data: number[];
  growth: boolean;
}

interface ChartData 
{
  labels: string[];
  datasets: {
    data: number[];
    borderColor: string;
    borderWidth: number;
    tension: number;
    pointRadius: number;
    pointHoverRadius: number;
    pointBackgroundColor: string;
    pointHoverBackgroundColor: string;
    pointBorderWidth: number;
    pointHoverBorderWidth: number;
    clip: number;
  }[];
}

export function FintechCard14(): React.JSX.Element 
{
  const intl = getIntl<FintechCards>('fintechCards');

  const miniCharts: MiniChartData[] = [
    // Twitter
    {
      data: [
        540, 466, 540, 466, 385, 432, 334,
        334, 289, 289, 200, 289, 222, 289,
        289, 403, 554, 304, 289, 270, 134,
        270, 829, 644, 688, 664,
      ],
      growth: true,
    },
    // Facebook
    {
      data: [
        245, 288, 332, 404, 404, 314, 314,
        314, 314, 314, 234, 314, 234, 234,
        314, 314, 314, 388, 314, 202, 202,
        202, 202, 514, 720, 642,
      ],
      growth: true,
    },
    // Google
    {
      data: [
        732, 610, 610, 504, 504, 504, 349,
        349, 504, 342, 504, 610, 391, 192,
        154, 273, 191, 191, 126, 263, 349,
        252, 323, 322, 270, 232,
      ],
      growth: false,
    },
    // Apple
    {
      data: [
        222, 222, 226, 271, 365, 365, 238,
        324, 288, 206, 324, 324, 500, 409,
        409, 273, 232, 273, 500, 570, 767,
        808, 685, 767, 685, 685,
      ],
      growth: true,
    },
    // Coinbase
    {
      data: [
        632, 510, 610, 404, 504, 404, 449,
        349, 404, 542, 404, 410, 491, 392,
        254, 273, 291, 191, 226, 363, 449,
        252, 223, 222, 170, 132,
      ],
      growth: false,
    },
  ];

  const chartData: ChartData[] = [];

  const buildChartData = (chart: MiniChartData): ChartData => 
  {
    const obj: ChartData = {
      labels: [
        '12-01-2020',
        '01-01-2021',
        '02-01-2021',
        '03-01-2021',
        '04-01-2021',
        '05-01-2021',
        '06-01-2021',
        '07-01-2021',
        '08-01-2021',
        '09-01-2021',
        '10-01-2021',
        '11-01-2021',
        '12-01-2021',
        '01-01-2022',
        '02-01-2022',
        '03-01-2022',
        '04-01-2022',
        '05-01-2022',
        '06-01-2022',
        '07-01-2022',
        '08-01-2022',
        '09-01-2022',
        '10-01-2022',
        '11-01-2022',
        '12-01-2022',
        '01-01-2023',
      ],
      datasets: [
        // Line
        {
          data: chart.data,
          borderColor: chart.growth ? tailwindConfig().theme.colors.emerald[500] : tailwindConfig().theme.colors.rose[500],
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: chart.growth ? tailwindConfig().theme.colors.emerald[500] : tailwindConfig().theme.colors.rose[500],
          pointHoverBackgroundColor: chart.growth ? tailwindConfig().theme.colors.emerald[500] : tailwindConfig().theme.colors.rose[500],
          pointBorderWidth: 0,
          pointHoverBorderWidth: 0,
          clip: 20,
        },
      ],
    };
    return obj;
  };

  miniCharts.forEach((miniChart) => 
  {
    chartData.push(buildChartData(miniChart));
  });

  return (
    <div className="col-span-full bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">{intl.h2MarketTrends}</h2>
      </header>
      <div className="p-3">
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300">
            {/* Table header */}
            <thead className="text-xs uppercase text-slate-400 dark:text-slate-500 bg-slate-50 dark:bg-slate-700 dark:bg-opacity-50 rounded-sm">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">{intl.thMarket}</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">{intl.thMktCap}</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">{intl.thChart}</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">{intl.thPrice}</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">{intl.thChg} (24h)</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm font-medium divide-y divide-slate-100 dark:divide-slate-700">
              {/* Rows */}
              {chartData.map((data, index) => (
                <tr key={index}>
                  <td className="p-2 whitespace-nowrap md:w-1/2">
                    <div className="flex items-center">
                      {/* Market icon */}
                      <svg className="shrink-0 mr-2 sm:mr-3" width="36" height="36" viewBox="0 0 36 36">
                        {/* SVG content */}
                      </svg>
                      <div>
                        <div className="text-slate-800 dark:text-slate-100 uppercase">Market Name</div>
                        <div className="text-xs text-slate-500">Market Description</div>
                      </div>
                    </div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    <div className="font-normal text-left">Market Cap Value</div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    {/* Chart built with Chart.js 3 */}
                    <div className="w-[96px]">
                      {/* Change the height attribute to adjust the chart height */}
                      <LineChart09 data={data} width={96} height={32} />
                    </div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    <div className="text-left">Price</div>
                  </td>
                  <td className="p-2 whitespace-nowrap">
                    <div className="text-left">Change Value</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}