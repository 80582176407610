import { useFileSystem } from './UseFileSystem';
import { AddFolder } from '../MenuContextActions/AddFolder';
import { Delete } from '../MenuContextActions/Delete';
import { Rename } from '../MenuContextActions/Rename';
import { useFileSystemOperations } from './UseFileSystemOperations';
import { useUIOperations } from './UseUiOperations';

export const useToolbarAction = () => 
{
  const { selectedItem, selectedFolder } = useFileSystem();
  const {
    addFolder,
    renameItem,
    deleteItem
  } = useFileSystemOperations();
  const { openModal, closeModal, openFolderTreeModal } = useUIOperations();

  const handleToolbarAction = (action: "addFolder" | "rename" | "delete" | "moveTo" | "copyTo" | "download") => 
  {
    switch (action) 
    {
      case 'addFolder':
        openModal(
          <AddFolder
            onConfirm={(name) => 
            {
              addFolder(name!, selectedFolder.id);
              closeModal();
            }}
            onCancel={closeModal}
            itemName={''}
          />
        );
        break;

      case 'moveTo':
      case 'copyTo':
        if (selectedItem) 
        {
          openFolderTreeModal(action === 'moveTo' ? 'move' : 'copy');
        }
        break;

      case 'rename':
        if (selectedItem) 
        {
          openModal(
            <Rename
              itemName={selectedItem.name}
              onConfirm={(newName) => 
              {
                renameItem(selectedItem.id, newName!, 'subfolders' in selectedItem ? 'folder' : 'file');
                closeModal();
              }}
              onCancel={closeModal}
            />
          );
        }
        break;

      case 'delete':
        if (selectedItem) 
        {
          openModal(
            <Delete
              itemName={selectedItem.name}
              onConfirm={() => 
              {
                deleteItem(selectedItem.id, 'subfolders' in selectedItem ? 'folder' : 'file');
                closeModal();
              }}
              onCancel={closeModal}
            />
          );
        }
        break;

      case 'download':
        if (selectedItem) 
        {
          console.log('downloading', selectedItem);
        }
        break;

      default:
        console.warn(`Unhandled action: ${action}`);
        break;
    }
  };

  return handleToolbarAction;
};