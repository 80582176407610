import React, { useState, useEffect } from 'react';
// import { useUserInfo } from '../Hooks/UseUserInfo';
import { MainModal } from '../Components/Actions/Modals/MainModal';
import { ModalContent } from '../Components/Actions/Modals/ModalContent';
import { ModalBreak } from '../Components/Actions/Modals/ModalBreak';
import { ModalChoice } from '../Components/Actions/Modals/ModalChoice';
import { ModalInputText } from '../Components/Actions/Modals/ModalInputText';
import { ModalTextarea } from '../Components/Actions/Modals/ModalTextArea';
import { ModalInputDropdown } from '../Components/Actions/Modals/ModalDropdown';
import { modal } from '../Components/Actions/ProgModal/ProgModal';
import { debugController } from '../Api/Controller/ApiDebug';

interface Spider 
{
  id: number;
  x: number;
  y: number;
  scale: number;
  speed: number;
  color: string;
  opacity: number;
}

export const Debug: React.FC = () => 
{
  // const { refreshUserInfo } = useUserInfo();
  const [status, setStatus] = useState<string | null>(null);
  const [spiders, setSpiders] = useState<Spider[]>([]);
  const [initialCount] = useState<number>(1000);

  // Stati per gestire le modali
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalSendOpen, setIsModalSendOpen] = useState(false);
  const [isModalSuccessFlowOpen, setIsModalSuccessFlowOpen] = useState(false);
  const [isModalErrorFlowOpen, setIsModalErrorFlowOpen] = useState(false);

  // Stati per gli input della modale di modifica
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [numberValue, setNumberValue] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [dropdownValue, setDropdownValue] = useState('Admin');

  const generateRandomColor = () => 
  {
    return `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`;
  };

  useEffect(() => 
  {
    const createSpiders = (count: number) => 
    {
      const newSpiders: Spider[] = Array.from({ length: count }, (_, i) => ({
        id: i,
        x: Math.random() * window.innerWidth,
        y: window.innerHeight + Math.random() * window.innerHeight,
        scale: Math.random() * 0.5 + 0.5,
        speed: Math.random() * 5 + 3,
        color: generateRandomColor(),
        opacity: 1,
      }));

      setSpiders(newSpiders);
    };

    createSpiders(initialCount);

    const startTime = Date.now();
    const duration = 4000;
    const fadeOutDuration = 4000;

    const animationFrame = requestAnimationFrame(function animate() 
    {
      const elapsedTime = Date.now() - startTime;

      if (elapsedTime < duration) 
      {
        setSpiders(prevSpiders =>
          prevSpiders.map(spider => ({
            ...spider,
            y: spider.y - spider.speed,
            ...(spider.y < -50 && {
              y: window.innerHeight,
              x: Math.random() * window.innerWidth,
            }),
            opacity: elapsedTime > duration - fadeOutDuration
              ? 1 - (elapsedTime - (duration - fadeOutDuration)) / fadeOutDuration
              : 1,
          }))
        );
        requestAnimationFrame(animate);
      }
      else 
      {
        setSpiders([]);
      }
    });

    return () => cancelAnimationFrame(animationFrame);
  }, [initialCount]);

  const handleCreateUsers = async () => 
  {
    try 
    {
      await debugController.createRandomUsers(10);
      setStatus('success');
    }
    catch (error) 
    {
      setStatus('failed');
    }
  };

  async function pingServer(times: number) 
  {
    for (let i = 0; i < times; i++) 
    {
      await debugController.ping().catch(_error => { });
    }
  }

  const simulateDataFlowSuccess = async () => 
  {
    setIsModalSuccessFlowOpen(true);
  };

  const simulateDataFlowError = async () => 
  {
    setIsModalErrorFlowOpen(true);
  };

  const simulateDataFlow = async () => 
  {
    console.log('Inizio del flusso di dati.');

    // Simula un'attesa per un'operazione precedente
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Chiedi conferma all'utente con la nuova funzione modal
    await modal(async () => 
    {
      console.log("L'utente ha confermato. Proseguo con il flusso di dati.");

      // Simula un'operazione successiva (come un flusso di dati lungo)
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log('Flusso di dati completato.');
    });
  };

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <div className="flex-grow px-6 py-6 w-full max-w-7xl mx-auto bg-white rounded-lg shadow-md">
          {status && (
            <div className={`mb-6 p-4 rounded-md ${status === 'success' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
              {status === 'success' ? 'Operation successful!' : 'Operation failed!'}
            </div>
          )}
          <h1 className="text-4xl font-bold mb-6 text-gray-900 text-center">Debug</h1>
          <div className="space-y-4 text-center space-x-3">
            <button
              className="bg-teal-500 hover:bg-teal-600 text-white font-semibold py-2 px-4 rounded-md shadow-sm"
            // onClick={refreshUserInfo}
            >
              Refresh User Info (not working anymore with supabase)
            </button>
            <button
              className="bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-2 px-4 rounded-md shadow-sm"
              onClick={handleCreateUsers}
            >
              Create 10 Random Users
            </button>
            <button className="btn bg-green-100" onClick={async () => await pingServer(1)}>Ping Server</button>
            <button className="btn bg-green-100" onClick={async () => await pingServer(5)}>Ping Server (x5)</button>

            {/* Pulsanti per aprire le modali */}
            <button
              className="btn bg-red-500 text-white"
              onClick={() => setIsModalDeleteOpen(true)}
            >
              Apri Modale Cancella
            </button>
            <button
              className="btn bg-blue-500 text-white"
              onClick={() => setIsModalEditOpen(true)}
            >
              Apri Modale Modifica
            </button>
            <button
              className="btn bg-yellow-500 text-white"
              onClick={() => setIsModalSendOpen(true)}
            >
              Apri Modale Invia
            </button>
            <button
              className="btn bg-green-500 text-white"
              onClick={simulateDataFlow}
            >
              Test Flusso di Dati con Modale Programmatica
            </button>
            <button
              className="btn bg-green-500 text-white"
              onClick={simulateDataFlowSuccess}
            >
              Test Flusso di Dati con Successo
            </button>
            <button
              className="btn bg-red-500 text-white"
              onClick={simulateDataFlowError}
            >
              Test Flusso di Dati con Errore
            </button>
          </div>
        </div>
      </div>

      {/* Render modale Cancella */}
      {isModalDeleteOpen && (
        <MainModal setOpen={setIsModalDeleteOpen} variant="danger" title="Elimina elemento">
          <ModalContent>Are you sure you want to delete this item?</ModalContent>
          <ModalChoice
            onConfirm={() => 
            {
              console.log("Elemento cancellato");
            }}
          />
        </MainModal>
      )}

      {/* Modale Modifica */}
      {isModalEditOpen && (
        <MainModal setOpen={setIsModalEditOpen} title='Modifica elementi'>
          <ModalBreak />

          <ModalContent>Prova title edit modal</ModalContent>
          {/* Input di tipo testo generico */}
          <ModalInputText
            variant="name"
            value={nameValue}
            onChange={(event) => setNameValue(event.target.value)}
          />

          <ModalInputText
            variant="email"
            value={emailValue}
            onChange={(event) => setEmailValue(event.target.value)}
          />
          <ModalInputText
            variant="quantity"
            name="customQuantity"
            label="Quantità Personalizzata"
            type="number"
            value={numberValue}
            onChange={(event) => setNumberValue(event.target.value)}
          />

          {/* Textarea */}
          <ModalTextarea
            variant="description"
            value={textareaValue}
            onChange={(event) => setTextareaValue(event.target.value)}
          />

          {/* Dropdown */}
          <ModalInputDropdown
            variant='role'
            value={dropdownValue}
            onChange={(newValue) => setDropdownValue(newValue)}
            options={[
              { value: 'Admin', label: 'Admin' },
              { value: 'User', label: 'User' },
              { value: 'Gaetanal', label: 'Gaetanal' },
            ]}
          />
          <ModalBreak />
          <ModalChoice
            onConfirm={() => 
            {
              console.log("Elemento modificato con i dati:", {
                name: nameValue,
                email: emailValue,
                quantity: numberValue,
                description: textareaValue,
                role: dropdownValue,
              });
            }}
          />

        </MainModal>
      )}

      {/* Modale invia */}
      {isModalSendOpen && (
        <MainModal setOpen={setIsModalSendOpen} variant="success" title='Invia elemento'>
          <ModalContent>Sei sicuro di voler inviare questo elemento?</ModalContent>
          <ModalChoice
            onConfirm={() => 
            {
              console.log("Elemento inviato");
            }}
          />
        </MainModal>
      )}

      {/* Modale Flusso Successo */}
      {isModalSuccessFlowOpen && (
        <MainModal setOpen={setIsModalSuccessFlowOpen} variant="success" title="Test Flusso di Dati positivo" onConfirm={async () => 
        {
          console.log('Flusso di dati con successo iniziato.');
          await new Promise(resolve => setTimeout(resolve, 2000));
          console.log('Flusso di dati con successo completato.');
        }}>
          <ModalContent>Vuoi procedere con un flusso di dati che andrà a buon fine?</ModalContent>
          <ModalChoice
            onConfirm={() => 
            {
              console.log("Conferma del flusso di successo.");
            }}
          />

        </MainModal>
      )}

      {/* Modale Flusso Errore */}
      {isModalErrorFlowOpen && (
        <MainModal setOpen={setIsModalErrorFlowOpen} variant="danger" title='Test Flusso di Dati negativo' onConfirm={async () => 
        {
          console.log('Flusso di dati con errore iniziato.');
          await new Promise((_, reject) => setTimeout(() => reject(new Error("Errore nel flusso di dati")), 2000));
          console.log("Questa riga non verrà mai eseguita a causa dell'errore.");
        }}>
          <ModalContent>Vuoi procedere con un flusso di dati che terminerà con un errore?</ModalContent>
          <ModalChoice
            onConfirm={() => 
            {
              console.log("Conferma del flusso di errore.");
            }}
          />

        </MainModal>
      )}

      <svg className="absolute inset-0 w-full h-full pointer-events-none">
        {spiders.map(spider => (
          <g key={spider.id} transform={`translate(${spider.x}, ${spider.y}) scale(${spider.scale})`} opacity={spider.opacity}>
            <path
              d="M0,0 L-5,-5 M0,0 L5,-5 M0,0 L-8,0 M0,0 L8,0 M0,0 L-5,5 M0,0 L5,5"
              stroke={spider.color}
              strokeWidth="1"
            />
            <circle cx="0" cy="0" r="3" fill={spider.color} />
          </g>
        ))}
      </svg>
    </div>
  );
};
