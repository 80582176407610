import { useState, useEffect, useCallback } from 'react';
import { ChatbotLogic } from '../../Features/GenAI/ChatbotUtils/ChatbotTypes';
import { GenAIChatMessage } from '../../Features/GenAI/Api/Types/GenAIInterface';
import { GenAIMessageRole } from '../../Features/GenAI/Api/Types/GenAIEnums';
import { apiFetch } from '../../Api/BeetClient';
import toast from 'react-hot-toast';
import { Message } from 'postcss';
import { setMaxListeners } from 'events';

const LOCAL_STORAGE_KEY = 'riminyChatState';
const CHAT_API_URL = 'https://chatbot.braiiin.com/chat/rimini';

// per testare rimini
const CHAT_ID = "thread_pikGvUQfPpKBnMzyRfByG8cv";
const ASSISTANT_ID = "asst_9SH6g0cczaxY9pSS2MijYsvu";
const RUN_ID = "run_YiYfEB6pHy8kTx7wthYgP9T4";
const initialMessage = "Ciao, sono Riminy, il tuo assistente virtuale di Rimini! Sono stato progettato come demo per mostrarti come funzionerebbe un chatbot turistico. Come posso aiutarti oggi?";

interface GenAIAssistantRequest 
{
  prompt: string,
  info: {
    chatId: string,
    assistantId: string,
    runId: string,
    inputId: string,
    isReady: boolean
  }
}

interface GenAIAssistantResponse 
{
  chatId: string,
  assistantId: string,
  runId: string,
  inputId: string,
  isReady: boolean
}

interface GenAIAssistantMessage 
{
  role: string,
  message: string,
  usage: {
    model: string,
    inputTokens: number,
    outputTokens: number,
    totalTokens: number
  }
}

interface GenAIAssistantMessagesResponse extends Omit<GenAIAssistantRequest, "prompt"> 
{
  newMessages: Array<GenAIAssistantMessage>
}

export const useRiminiChatbotLogic = (): ChatbotLogic => 
{
  const [messages, setMessages] = useState<GenAIChatMessage[]>([
    { message: initialMessage, role: GenAIMessageRole.Assistant }
  ]);
  const [chatId, setChatId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleLocalStorageUpdate = (messages: GenAIChatMessage[], chatId: string) => 
  {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
      messages,
      chatId,
    }));
  };

  useEffect(() => 
  {
    const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    console.log("localStorage saved chat", savedState);
    if (savedState) 
    {
      try 
      {
        const parsedState = JSON.parse(savedState);
        console.log(parsedState, "PARSED");
        setMessages(parsedState.messages || []);
        setChatId(parsedState.chatId || '');
      }
      catch (error) 
      {
        toast.error("Error obtaining chat");
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      }
    }
  }, []);

  useEffect(() => 
  {
    if(messages.length > 1)
    {
      handleLocalStorageUpdate(messages, chatId);
    }
  }, [messages, chatId]);

  const sendMessage = useCallback(async (message: string) => 
  {
    setLoading(true);
    const resBody: GenAIAssistantRequest = {
      prompt: message,
      info: {
        chatId: chatId,
        assistantId: ASSISTANT_ID,
        runId: RUN_ID,
        inputId: "",
        isReady: false
      }
    };

    try 
    {
      const { data } = await apiFetch.post<GenAIAssistantResponse>("https://dev.braiiin.com/api/gen-ai/assistant/send", resBody);
      if (data) 
      {
        setChatId(data.chatId);

        const requestString = `https://dev.braiiin.com/api/gen-ai/assistant/check?chatId=${data.chatId}&assistantId=${data.assistantId}&runId=${data.runId}&isReady=${data.isReady}`;

        const END_TIMEOUT = 30000;
        let delay = 0;
        let actuallyReady = false;

        // manda una fetch per vedere se la risposta e' pronta e incrementa il delay del doppio ogni volta 
        while (!actuallyReady && delay <= END_TIMEOUT) 
        {
          const { data: { isReady } } = await apiFetch.get<GenAIAssistantResponse>(requestString);
          if (isReady) 
          {
            actuallyReady = true;
          }
          else 
          {
            await new Promise(resolve => setTimeout(resolve, delay));
            if (delay === 0) 
            {
              delay = 300;
            }
            else 
            {
              delay *= 2; // aumenta il delay del doppio
            }
          }
        }

        if (!actuallyReady) 
        {
          throw new Error("Timeout");
        }
        // se la risposta e' pronta, aggiungo il messaggio
        const requestMessagesString = `https://dev.braiiin.com/api/gen-ai/assistant/messages?chatId=${data.chatId}&assistantId=${data.assistantId}&runId=${data.runId}&isReady=${data.isReady}&inputId=${data.inputId}`;

        const { data: answer } = await apiFetch.get<GenAIAssistantMessagesResponse>(requestMessagesString);
        // console.log(answer);

        // ATTENZIONE: Con il nuovo metodo Dona di inputId, probabilmente adaptedMessages può essere semplificato notevolmente perchè arriva
        // sempre e solo un messaggio
        const adaptedMessages = answer.newMessages.map((message) => 
        {
          // ATTENZIONE: Tano usa Human per l'utente invece di User come Dona
          // if (message.role == "User") message.role = GenAIMessageRole.Human; \\ non dovrebbe servire più perchè passando anche inputId ottengo solo l'ultimo messaggio del chatbot
          return { message: message.message, role: message.role as GenAIMessageRole };
        });

        // .filter((message) => message.role == GenAIMessageRole.Assistant);
        console.log("adapted messages for frontend chatbot:", adaptedMessages);

        // rimetto i messaggi precedenti salvati nel local storage e aggiungo quello nuovo singolo dell'assistant(adaptedMessages)
        setMessages(prevMessages => [
          ...prevMessages,
          ...adaptedMessages
        ]);
      }
    }
    catch (error) 
    {
      setErrorMessage("Error sending message: " + error);
    }
    finally 
    {
      setLoading(false);
    }
  }, [chatId]);

  const handleSend = useCallback(async (input: string) => 
  {
    if (input !== "debugging message") 
    {
      setMessages(prevMessages => [
        ...prevMessages,
        { message: input, role: GenAIMessageRole.Human }
      ]);
      setErrorMessage(null);
      await sendMessage(input);

    }
    else 
    {
      const assistantLocalStorage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || "[]");
      const messages = assistantLocalStorage.messages;
      const lastMessage = messages[messages.length - 1];
      setErrorMessage(null);
      await sendMessage(lastMessage.message);
    }
  }, [sendMessage]);

  const handleResetChat = useCallback(() => 
  {
    setMessages([{ message: initialMessage, role: GenAIMessageRole.Assistant }]);
    // setChatId(CHAT_ID); // Per test setta a id fisso
    setChatId("");
    setErrorMessage(null);
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }, []);

  const blockMessage = loading;

  return {
    handleSend,
    errorMessage,
    messages,
    chatId,
    loading,
    handleResetChat,
    blockMessage,
  };
};
