import { FileSystemProvider } from "../../Features/FileManager/Contexts/FileSystemContext";
import { ProgressProvider } from "../../Features/FileManager/Contexts/ProgressContext";
import { UIProvider } from "../../Features/FileManager/Contexts/UIContext";
import { FileManager } from "../../Features/FileManager/FileManager";

export const FileManagerPage = () => 
{
  return (
    <FileSystemProvider>
      <UIProvider>
        <ProgressProvider>
          <FileManager />
        </ProgressProvider>
      </UIProvider>
    </FileSystemProvider>
  );
};