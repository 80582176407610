// @ts-nocheck
import React, { useRef, useEffect, useState } from 'react';
import { useThemeProvider } from '../Utils/ThemeContext';
import { ChartProps } from './Charts';
import { chartColors } from './ChartjsConfig';
import {
  Chart, LineController, LineElement, Filler, PointElement, LinearScale, CategoryScale, Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';

// Import utilities
import { formatValue } from '../Utils/Utils';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, CategoryScale, Tooltip);

export const LineChart07: React.FC<ChartProps> = ({ data, width, height }) => 
{
  const [chart, setChart] = useState<Chart | null>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const { currentTheme } = useThemeProvider();
  const darkMode = currentTheme === 'dark';
  const { textColor, gridColor, tooltipBodyColor, tooltipBgColor, tooltipBorderColor } = chartColors;

  useEffect(() => 
  {
    const ctx = canvas.current!;

    const newChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        layout: {
          padding: {
            top: 12,
            bottom: 16,
            left: 20,
            right: 20,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            border: {
              display: false,
            },
            ticks: {
              maxTicksLimit: 7,
              callback: (value) => formatValue(value),
              color: darkMode ? textColor.dark : textColor.light,
            },
            grid: {
              color: darkMode ? gridColor.dark : gridColor.light,
            },
          },
          x: {
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            ticks: {
              autoSkipPadding: 48,
              maxRotation: 0,
              color: darkMode ? textColor.dark : textColor.light,
              align: 'end',
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: () => false, // Disable tooltip title
              label: (context) => formatValue(context.parsed.y),
            },
            bodyColor: darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light,
            backgroundColor: darkMode ? tooltipBgColor.dark : tooltipBgColor.light,
            borderColor: darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light,
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        maintainAspectRatio: false,
      },
    });
    setChart(newChart);
    return () => newChart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => 
  {
    if (!chart) return;
    const scales = chart.options?.scales;
    if (scales) 
    {
      scales.x!.ticks!.color = darkMode ? textColor.dark : textColor.light;
      scales.y!.ticks!.color = darkMode ? textColor.dark : textColor.light;
      scales.y!.grid!.color = darkMode ? gridColor.dark : gridColor.light;
    }
    const tooltip = chart.options?.plugins?.tooltip;
    if (tooltip) 
    {
      tooltip.bodyColor = darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light;
      tooltip.backgroundColor = darkMode ? tooltipBgColor.dark : tooltipBgColor.light;
      tooltip.borderColor = darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light;
    }

    chart.update('none');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTheme]);

  return (
    <canvas ref={canvas} width={width} height={height}></canvas>
  );
};