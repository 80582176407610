import { AnimationCustomization, StyleCustomization } from "../../../Features/GenAI/ChatbotUtils/ChatbotTypes";

export const styleCustomization: StyleCustomization = {
  typingDotColor: '#f97316',
  loadingRingColor: '#f97316',
  thinkingBubbleBackgroundColor: 'bg-orange-100',
  headerGradient: 'bg-gradient-to-r from-orange-400 to-orange-600',
  chatWindowFooter: 'bg-white',
  headerIconHoverColor: 'hover:text-orange-200',
  bodyBackgroundColor: 'bg-white',
  aiBubbleColor: 'bg-orange-100 text-black',
  humanBubbleColor: 'bg-orange-200 text-black',
  footerTextareaFocusColor: 'focus:ring-orange-500',
  scrollButtonColor: 'bg-gradient-to-r from-orange-400 to-orange-600 text-white',
  scrollButtonHoverColor: 'hover:from-orange-500 hover:to-orange-700',
  speechToTextMicrophoneColor: 'text-white',
  speechToTextButtonColor: 'bg-gradient-to-r from-orange-400 to-orange-600 hover:from-orange-500 hover:to-orange-700 text-white',
  speechToTextButtonListeningColor: 'bg-gradient-to-r from-orange-500 to-orange-700 text-white',
  speakerColor: 'text-white',
  speakerBackgroundColor: 'bg-gradient-to-r from-orange-400 to-orange-600 hover:from-orange-500 hover:to-orange-700',
  speakerPlayingBackgroundColor: 'bg-gradient-to-r from-orange-500 to-orange-700',
  sendButtonColor: 'bg-gradient-to-r from-orange-400 to-orange-600 text-white',
  sendButtonHoverColor: 'hover:from-orange-500 hover:to-orange-700',

};

export const animationCustomization: { icon: AnimationCustomization, window: AnimationCustomization } = {
  icon: {
    initial: { scale: 0.6, opacity: 0 },
    animate: { scale: 1, opacity: 1, y: -10 },
    transition: { type: 'spring', stiffness: 200, damping: 25 }
  },
  window: {
    initial: { opacity: 0, scale: 0.5, translateY: '50%', translateX: '50%' },
    animate: { opacity: 1, scale: 1, translateY: '0%', translateX: '0%' },
    exit: { opacity: 0, scale: 0.5, translateY: '50%', translateX: '100%' },
    transition: { type: 'spring', stiffness: 300, damping: 30 }
  }
};