import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import '../../../Css/semiCircle.css';
import { formatNumber, getIntl } from "../../../ReactIntl/IntlConfig";
import { DashboardUserPlanLocale } from "../../../ReactIntl/LocaleInterfaces";

export const DashboardUserPlan = () => 
{
  const [tokens, setTokens] = useState<number>(2400);   //TODO take it from server
  const [maxTokens, _setMaxTokens] = useState<number>(10000);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const intl = getIntl<DashboardUserPlanLocale>('dashboardUserPlan');

  useEffect(() => 
  {
    if (progressBarRef.current) 
    {
      const $bar = progressBarRef.current.querySelector(".bar") as HTMLElement;
      const $val = progressBarRef.current.querySelector("span") as HTMLElement;

      const animateProgressBar = (targetTokens: number, duration: number = 3000) => 
      {
        const startTokens = tokens;
        const startTime = performance.now();

        const animate = (currentTime: number) => 
        {
          const elapsedTime = currentTime - startTime;
          if (elapsedTime >= duration) 
          {
            setTokens(targetTokens);
            return;
          }

          const progress = elapsedTime / duration;
          const currentTokens = startTokens + (targetTokens - startTokens) * progress;

          $bar.style.transform = `rotate(${45 + (currentTokens / maxTokens) * 180}deg)`;
          $val.textContent = `${formatNumber(currentTokens)} / ${formatNumber(maxTokens)}`;

          requestAnimationFrame(animate);
        };

        requestAnimationFrame(animate);
      };

      animateProgressBar(tokens);
    }
  }, [tokens, maxTokens]);

  return (
    <div className="mt-10 relative border border-gray-300 p-6 flex flex-col justify-center items-center">
      <h2 className="mb-4 font-bold">{intl.title}</h2>
      {/* TODO: conditional rendering of plan */}
      <p className="text-center text-xs mb-6">
        {intl.descriptionStart}
        <span className="font-bold"> {formatNumber(tokens)} </span> {intl.descriptionMid}
        <span className="font-bold"> {formatNumber(tokens)} </span> {intl.descriptionEnd}
      </p>

      <div ref={progressBarRef} className="progress">
        <div className="barOverflow">
          <div className="bar"></div>
        </div>
        <span>{formatNumber(tokens)} / {formatNumber(maxTokens)}</span>
      </div>

      <Link to="settings/plans" className="btn hover:bg-slate-600 hover:text-white mt-2">
        {intl.link}
        <span>
          <svg className="size-3 ml-2 group-hover:bg-slate-800 group-hover:text-white rounded-full" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path d="M12 5l0 14"></path>
            <path d="M5 12l14 0"></path>
          </svg>
        </span>
      </Link>
    </div>
  );
};
