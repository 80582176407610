import React from 'react';

import { Tooltip } from '../Tooltip';

export const TooltipPage: React.FC = () => 
{

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900">
        {/* Site header */}

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="mb-8">
              <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Tooltip ✨</h1>
            </div>

            <div className="border-t border-slate-200 dark:border-slate-700">
              {/* Components */}
              <div className="space-y-8 mt-8">
                {/* Tooltip Types */}
                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Tooltip Types</h2>
                  <div className="flex flex-wrap items-center -m-4">
                    {[
                      { label: "Label White", content: "Just a tip", bg: "light", size: "sm" },
                      { label: "Label Dark", content: "Just a tip", bg: "dark", size: "sm" },
                      { label: "Basic White", content: "Excepteur sint occaecat cupidata non proident, sunt in.", bg: "light", size: "md" },
                      { label: "Basic Dark", content: "Excepteur sint occaecat cupidata non proident, sunt in.", bg: "dark", size: "md" },
                      { label: "Large White", content: "Excepteur sint occaecat cupidata non proident, sunt in.", bg: "light", size: "lg" },
                      { label: "Large Dark", content: "Excepteur sint occaecat cupidata non proident, sunt in.", bg: "dark", size: "lg" },
                      {
                        label: "Rich White", content: (
                          <div className="text-xs">
                            <div className="font-medium text-slate-800 mb-0.5">Let's Talk Paragraph</div>
                            <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                          </div>
                        ), bg: "light", size: "lg"
                      },
                      {
                        label: "Rich Dark", content: (
                          <div className="text-xs">
                            <div className="font-medium text-slate-200 mb-0.5">Let's Talk Paragraph</div>
                            <div className="text-slate-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                          </div>
                        ), bg: "dark", size: "lg"
                      },
                    ].map((tooltip, index) => (
                      <div className="m-4" key={index}>
                        <div className="flex items-center space-x-2">
                          {/* Start */}
                          <Tooltip bg={tooltip.bg as 'light' | 'dark'}>
                            {tooltip.content}
                          </Tooltip>
                          {/* End */}
                          <div className="text-sm font-medium text-slate-500 dark:text-slate-400">{tooltip.label}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Tooltip Position */}
                <div>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-6">Tooltip Position</h2>
                  <div className="flex flex-wrap items-center -m-4">
                    {[
                      { position: "top", label: "Top" },
                      { position: "bottom", label: "Bottom" },
                      { position: "left", label: "Left" },
                      { position: "right", label: "Right" },
                    ].map((tooltip, index) => (
                      <div className="m-4" key={index}>
                        <div className="flex items-center space-x-2">
                          {/* Start */}
                          <Tooltip position={tooltip.position as 'top' | 'bottom' | 'left' | 'right'}>
                            <div className="text-xs whitespace-nowrap">Just a tip</div>
                          </Tooltip>
                          {/* End */}
                          <div className="text-sm font-medium text-slate-500 dark:text-slate-400">{tooltip.label}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
