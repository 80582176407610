import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";

export const SetIncomingMarkdownPlugin = ({ markdown }: { markdown: string }) => 
{
  const [editor] = useLexicalComposerContext();

  useEffect(() => 
  {
    if (markdown) 
    {
      editor.update(() => 
      {
        $convertFromMarkdownString(markdown, TRANSFORMERS);
      });
    }
  }, [editor, markdown]);

  return null;
};