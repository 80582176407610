import { apiDefinitions } from "../ApiConfig";
import { PhoneNumber } from "../../Utils/PhoneNumber";

const definition = apiDefinitions.user;

export const userController = 
{
  profileInfo: async (info: { [key: string]: unknown }) => 
  {
    const patchOperations = Object.entries(info).reduce((acc, [key, value]) => 
    {
      const path = `/${key}`;
      if (value !== undefined) 
      {
        acc.push({ op: "replace", path, value });
      }
      return acc;
    }, [] as { op: string; path: string; value: unknown }[]);

    const endpoint = definition.profileInfo;
    const response = await endpoint.invoke(endpoint.url, patchOperations);

    return response;
  },

  profilePicture: async (blob: Blob) =>
  {
    const formData = new FormData();
    formData.append("file", blob, "profile-picture.jpg");

    const endpoint = definition.profilePicture;
    const response = await endpoint.invoke(endpoint.url, formData);
    return response;
  },

  phoneNumber: async (phoneNumber: PhoneNumber) => 
  {
    const endpoint = definition.phoneNumber;
    const response = await endpoint.invoke(endpoint.url, 
      {
        phoneNumber: phoneNumber.toString(),
      });

    return response;
  },
};
