import { useState, useEffect, useCallback } from 'react';
import { ChatbotLogic } from '../../Features/GenAI/ChatbotUtils/ChatbotTypes';
import { GenAIChatMessage } from '../../Features/GenAI/Api/Types/GenAIInterface';
import { GenAIMessageRole } from '../../Features/GenAI/Api/Types/GenAIEnums';

const LOCAL_STORAGE_KEY = 'giorginoChatState';
const CHAT_API_URL = 'https://chatbot.braiiin.com/chat/brissago';
const initialMessage = "Ciao sono Giorgino, come posso aiutarti oggi?";

export const useBrissagoChatbotLogic = (): ChatbotLogic => 
{
  const [messages, setMessages] = useState<GenAIChatMessage[]>([
    { message: initialMessage, role: GenAIMessageRole.Assistant }
  ]);
  const [chatId, setChatId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => 
  {
    const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedState) 
    {
      const parsedState = JSON.parse(savedState);
      setMessages(parsedState.messages || []);
      setChatId(parsedState.chatId || '');
    }
  }, []);

  useEffect(() => 
  {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
      messages,
      chatId,
    }));
  }, [messages, chatId]);

  const sendMessage = useCallback(async (message: string) => 
  {
    setLoading(true);

    const requestBody = {
      message: message,
      chatId: chatId || undefined
    };

    try 
    {
      const response = await fetch(CHAT_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) 
      {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data && data.response) 
      {
        if (data.response.chatId) 
        {
          setChatId(data.response.chatId);
        }

        if (data.response.message) 
        {
          setMessages(prevMessages => [
            ...prevMessages,
            { 
              message: data.response.message, 
              role: data.response.role as GenAIMessageRole 
            }
          ]);
        }
      }
    }
    catch (error) 
    {
      setErrorMessage("Error sending message: " + error);
    }
    finally 
    {
      setLoading(false);
    }
  }, [chatId]);

  const handleSend = useCallback(async (input: string) => 
  {
    setMessages(prevMessages => [
      ...prevMessages,
      { message: input, role: GenAIMessageRole.Human }
    ]);
    await sendMessage(input);
  }, [sendMessage]);

  const handleResetChat = useCallback(() => 
  {
    setMessages([{ message: initialMessage, role: GenAIMessageRole.Assistant }]);
    setChatId('');
    setErrorMessage(null);
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }, []);

  const blockMessage = loading;

  return {
    handleSend,
    errorMessage,
    messages,
    chatId,
    loading,
    handleResetChat,
    blockMessage,
  };
};