import { useUIState } from "./UseUiState";
import { ContextMenuType } from "../FileManager.types";
import React from "react";

export const useUIOperations = () => 
{
  const {
    setContextMenuState,
    setModalOpen,
    setModalContent,
    setIsDragging,
    setIsExternalDrag,
    setIsFolderTreeModalOpen,
    setFolderTreeAction,
  } = useUIState();

  const openModal = (content: React.ReactNode) => 
  {
    setModalContent(content);
    setModalOpen(true);
  };

  const closeModal = () => 
  {
    setModalOpen(false);
    setModalContent(null);
  };

  const openContextMenu = (menu: ContextMenuType) => 
  {
    setContextMenuState(menu);
  };

  const closeContextMenu = () => 
  {
    setContextMenuState(null);
  };

  const startDragging = (isExternal: boolean = false) => 
  {
    setIsDragging(true);
    setIsExternalDrag(isExternal);
  };

  const stopDragging = () => 
  {
    setIsDragging(false);
    setIsExternalDrag(false);
  };

  const openFolderTreeModal = (action: "move" | "copy") => 
  {
    setFolderTreeAction(action);
    setIsFolderTreeModalOpen(true);
  };

  const closeFolderTreeModal = () => 
  {
    setIsFolderTreeModalOpen(false);
  };

  return {
    openModal,
    closeModal,
    openContextMenu,
    closeContextMenu,
    startDragging,
    stopDragging,
    openFolderTreeModal,
    closeFolderTreeModal,
  };
};
