import React from 'react';
import { InfoIcon } from '../../Modals/InfoIcon';

// Funzione per gestire la conferma
export const handleConfirm = async (
  onConfirm: (() => Promise<void>) | undefined,
  closeModal: () => void,
  resolve: (value: boolean) => void,
  setIsLoading: (value: boolean) => void,
  setAnimationState: (value: 'entering' | 'entered' | 'exiting') => void
) => 
{
  setIsLoading(true);
  try 
  {
    if (onConfirm) 
    {
      await onConfirm();
    }
    setAnimationState('exiting');
    setTimeout(() => 
    {
      resolve(true);
      closeModal();
    }, 200);
  } 
  catch (error) 
  {
    console.error('Errore durante la conferma:', error);
    resolve(false);
  } 
  finally 
  {
    setIsLoading(false);
  }
};

// Funzione per ottenere gli stili di animazione
export const getAnimationStyle = (animationState: 'entering' | 'entered' | 'exiting') => 
{
  switch (animationState) 
  {
    case 'entering':
      return {
        opacity: 0,
        transform: 'translateY(20px)',
        transition: 'opacity 0.3s ease-out, transform 0.1s ease-out',
      };
    case 'entered':
      return {
        opacity: 1,
        transform: 'translateY(0)',
        transition: 'opacity 0.3s ease-out, transform 0.1s ease-out',
      };
    case 'exiting':
      return {
        opacity: 0,
        transform: 'translateY(20px)',
        transition: 'opacity 0.2s ease-in, transform 0.2s ease-in',
      };
    default:
      return {};
  }
};

// Funzione per renderizzare l'icona basata sulla variante
export const renderIcon = (variant: 'primary' | 'success' | 'danger' | 'caution') => 
{
  switch (variant) 
  {
    case 'success':
      return <InfoIcon type="success" />;
    case 'danger':
      return <InfoIcon type="danger" />;
    case 'caution':
      return <InfoIcon type="caution" />;
    default:
      return <InfoIcon type="indigo" />;
  }
};