import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useRoleRendering } from './Hooks/UseRoleRendering';
import { Role } from './RoleManager';

interface RoleBasedNavLinkProps extends NavLinkProps 
{
  requiredRoles?: Role | Role[];
}

export const RoleNavLink: React.FC<RoleBasedNavLinkProps> = ({ requiredRoles, ...props }) => 
{
  const { hasAnyRole, hasRole } = useRoleRendering();

  const userHasRequiredRoles = Array.isArray(requiredRoles)
    ? hasAnyRole(requiredRoles)
    : requiredRoles
      ? hasRole(requiredRoles)
      : true;

  if (!userHasRequiredRoles) 
  {
    return null;
  }

  return <NavLink {...props} />;
};