import React, { useState, useRef, useEffect, ReactNode } from 'react';
import Transition from '../Utils/Transition';

interface DropdownEditMenuProps extends React.HTMLAttributes<HTMLDivElement> 
{
  children: ReactNode;
  align?: 'left' | 'right';
}

export function DropdownEditMenu({ children, align = 'left', ...rest }: DropdownEditMenuProps) 
{
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef<HTMLButtonElement>(null);
  const dropdown = useRef<HTMLUListElement>(null);

  // close on click outside
  useEffect(() => 
  {
    const clickHandler = ({ target }: MouseEvent) => 
    {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target as Node) || trigger.current?.contains(target as Node)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [dropdownOpen]);

  // close if the esc key is pressed
  useEffect(() => 
  {
    const keyHandler = ({ key }: KeyboardEvent) => 
    {
      if (!dropdownOpen || key !== "Escape") return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [dropdownOpen]);

  return (
    <div {...rest}>
      <button
        ref={trigger}
        className={`rounded-full ${dropdownOpen
          ? 'bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400'
          : 'text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400'
        }`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">Menu</span>
        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
          <circle cx="16" cy="16" r="2" />
          <circle cx="10" cy="16" r="2" />
          <circle cx="22" cy="16" r="2" />
        </svg>
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`origin-top-right z-10 absolute top-full min-w-36 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${align === 'right' ? 'right-0' : 'left-0'
        }`}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <ul ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)}>
          {children}
        </ul>
      </Transition>
    </div>
  );
}
