import { FC, useState, useEffect, useRef, useContext } from 'react';
import { setLanguage, languages, getLanguage, getLanguageData } from '../ReactIntl/IntlConfig';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import {LanguageContext} from '../Contexts/LanguageContext';
import Transition from '../Utils/Transition';

interface LanguageSelectorProps 
{
  selectedLanguage: string;
  onLanguageChange: (newLocale: string) => void;
}

export const LanguageSelectorButton: FC<LanguageSelectorProps> = ({ selectedLanguage, onLanguageChange }) => 
{
  const languageCtx = useContext(LanguageContext);
  const flagType = "fi fi-";
  const [dropdownOpen, setDropdownOpen] = useState(false);
  
  const currentLanguageCode = getLanguage(selectedLanguage);
  const currentLanguage = getLanguageData(currentLanguageCode);

  const trigger = useRef<HTMLButtonElement>(null);
  const dropdown = useRef<HTMLUListElement>(null);

  useEffect(() => 
  {
    const clickHandler = ({ target }: MouseEvent) => 
    {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target as Node) || trigger.current?.contains(target as Node)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  }, [dropdownOpen]);

  useEffect(() => 
  {
    const keyHandler = ({ key }: KeyboardEvent) => 
    {
      if (!dropdownOpen || key !== "Escape") return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [dropdownOpen]);

  const handleLanguageChange = (language: string) => 
  {
    setLanguage(language);
    onLanguageChange(language);
    languageCtx.setLanguage(language);
    setDropdownOpen(false);
  };

  return (
    <div className="relative">
      <button
        ref={trigger}
        className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full false`}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className={`${flagType}${currentLanguage.flag}`}></span>
      </button>

      <Transition
        show={dropdownOpen}
        tag="div"
        className="origin-top-right z-10 absolute w-full bg-white dark:bg-slate-800 border border-gray-300 dark:border-gray-600 py-1.5 rounded shadow-lg mt-1"
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <ul ref={dropdown} className="py-1">
          {languages.map(lang => (
            <li
              key={lang.code}
              className="flex justify-center py-2 hover:bg-gray-100 dark:hover:bg-slate-700 cursor-pointer"
              onClick={() => handleLanguageChange(lang.code)}
            >
              <span className={`${flagType}${lang.flag}`}></span>
            </li>
          ))}
        </ul>
      </Transition>
    </div>
  );
};