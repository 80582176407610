import { createApiDefinition } from "../../../Api/ApiConfig";
import { apiFetch } from "../../../Api/BeetClient";
import { GenAIModelInfo, GenAIProviderModel, IUserGenAITemplate } from "./Types/GenAIInterface";
import { GenAICategoryResponse, GenAIMessageResponse, IGenAIChatTemplateResponse, IGenAIWriterTemplateResponse } from "./Types/GenAIResponse";

export const genAIApiDefinitions = {
  aiWriterCategories: createApiDefinition("gen-ai/categories", {
    getAll: { url: "", invoke: apiFetch.get<GenAICategoryResponse[]> },
    getCategory: {
      url: "{categoryId}",
      invoke: apiFetch.get<GenAICategoryResponse>,
    },
    count: { url: "count", invoke: apiFetch.get<number> },
    createCategory: { url: "", invoke: apiFetch.post<GenAICategoryResponse> },
    updateCategory: {
      url: "{categoryId}",
      invoke: apiFetch.put<GenAICategoryResponse>,
    },
    deleteCategory: { url: "{categoryId}", invoke: apiFetch.delete<string> },
  }),

  aiWriterTemplates: createApiDefinition("gen-ai/templates/writer", {
    getAll: { url: "", invoke: apiFetch.get<IGenAIWriterTemplateResponse[]> },
    getTemplate: {
      url: "{templateId}",
      invoke: apiFetch.get<IGenAIWriterTemplateResponse>,
    },
    count: { url: "count", invoke: apiFetch.get<number> },
    createTemplate: { url: "", invoke: apiFetch.post<IGenAIWriterTemplateResponse> },
    updateTemplate: {
      url: "{templateId}",
      invoke: apiFetch.put<IGenAIWriterTemplateResponse>,
    },
    deleteTemplate: { url: "{templateId}", invoke: apiFetch.delete<string> },
  }),

  genAIUser: createApiDefinition("user/gen-ai", {
    getModels: { url: "models", invoke: apiFetch.get<GenAIProviderModel[]> },
    // configure: { url: "configure", invoke: apiFetch.put<string> },
    getProviderSettings: {
      url: "settings",
      invoke: apiFetch.get<GenAIModelInfo>,
    },
    updateProviderSettings: {
      url: "settings",
      invoke: apiFetch.put<{ provider: string; model: string }>,
    },
    addContext: { url: "chat/add-context", invoke: apiFetch.put<string> },
    sendWriter: {
      url: "writer/send",
      invoke: apiFetch.put<GenAIMessageResponse>,
    },
    sendChat: {
      url: "chat/send",
      invoke: apiFetch.put<GenAIMessageResponse>,
    },
    updateTemplateFavorite: {
      url: "templates/config",
      invoke: apiFetch.put<IUserGenAITemplate>,
    },
    getTemplatesConfig: {
      url: "templates/config",
      invoke: apiFetch.get<IUserGenAITemplate[]>,
    },
  }),
  
  aiChatTemplates: createApiDefinition("gen-ai/templates/chat", {
    getAll: { url: "", invoke: apiFetch.get<IGenAIChatTemplateResponse[]> },
    getTemplate: {
      url: "{templateId}",
      invoke: apiFetch.get<IGenAIChatTemplateResponse>,
    },
    count: { url: "count", invoke: apiFetch.get<number> },
    createTemplate: { url: "", invoke: apiFetch.post<IGenAIChatTemplateResponse> },
    updateTemplate: {
      url: "{templateId}",
      invoke: apiFetch.put<IGenAIChatTemplateResponse>,
    },
    deleteTemplate: { url: "{templateId}", invoke: apiFetch.delete<string> },
  }),
};
