import React, { useEffect, useReducer, useCallback, useState, useContext } from 'react';
import { MainModal } from '../../Components/Actions/Modals/MainModal';
import { ModalContent } from '../../Components/Actions/Modals/ModalContent';
import { ModalInputText } from '../../Components/Actions/Modals/ModalInputText';
import { Button } from '../../Components/Actions/Buttons/Buttons';
import { UserDto } from '../../Utils/User.types';
import { UserListPageLocale } from '../../ReactIntl/LocaleInterfaces';
import { getIntl } from '../../ReactIntl/IntlConfig';
import { userListController } from '../../Api/Controller/Entities/ApiUserList';
import { userFilter } from '../../Utils/Filter';
import { DropdownFilter } from '../../Components/DropdownFilter';
import { PaginationNumeric } from '../../Components/PaginationNumeric';
import { UserListTable } from './Partial/UserList/UserListTable';
import { rolesManager } from '../../Api/Roles/RoleManager';
import { RoleSelector } from '../../Api/Roles/RoleSelector';
import { UserListActions } from '../../Reducer/UserListAction';
import { AxiosError } from 'axios';
import { useError } from '../../Hooks/UseError';
import { LanguageContext } from '../../Contexts/LanguageContext';
import { initialUserListState, userListReducer } from '../../Reducer/UserListReducer';
import toast from 'react-hot-toast';

export const UserList: React.FC = () => 
{
  const [state, dispatch] = useReducer(userListReducer, initialUserListState);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const intl = getIntl<UserListPageLocale>("userListPage");
  const _languageCtx = useContext(LanguageContext);

  const { isBlocked } = useError();

  useEffect(() => 
  {
    const loadRoles = async () => 
    {
      await rolesManager.fetchRoles();
      const roles = rolesManager.getRoles();

      const fields = roles.map(role => ({ label: role, value: role }));

      dispatch(new UserListActions.SetState({ dropdownFields: fields }));
    };

    loadRoles();
  }, []);

  const fetchUsers = useCallback(async () => 
  {
    if (isBlocked) return;
    dispatch(new UserListActions.SetState({ isLoading: true }));
    try 
    {
      const field = state.searchMode === 'givenName'
        ? 'info.givenName'
        : state.searchMode === 'familyName'
          ? 'info.familyName'
          : 'email';

      const filter = userFilter(state.currentPage, state.itemsPerPage, state.sortField, state.sortOrder, field, state.searchQuery);
      const users = await userListController.getUsers(filter);

      dispatch(new UserListActions.SetState({ usersList: users.data }));
    }
    catch (error) 
    {
      console.log("error fetchUsers: ", error);
      console.error(error);
    }
    finally 
    {
      dispatch(new UserListActions.SetState({ isLoading: false }));
    }
  }, [state.currentPage, state.itemsPerPage, state.sortField, state.sortOrder, state.searchMode, state.searchQuery, isBlocked]);

  const getTotalUsers = useCallback(async () => 
  {
    if (isBlocked) return;
    try 
    {
      const usersCount = await userListController.count();
      dispatch(new UserListActions.SetState({ totalUsers: usersCount.data }));
    }
    catch (error) 
    {
      console.log("error getTotalUsers: ", error);
    }
  }, [isBlocked]);

  useEffect(() => 
  {
    const loadData = async () => 
    {
      await fetchUsers();
      await getTotalUsers();
    };
    loadData();

    const handleUserAction = async () => 
    {
      await loadData();
    };

    window.addEventListener('userAction', handleUserAction as EventListener);
    return () => 
    {
      window.removeEventListener('userAction', handleUserAction as EventListener);
    };
  }, [fetchUsers, getTotalUsers]);

  useEffect(() => 
  {
    const handleSortColumn = (event: CustomEvent<{ columnName: string; sortOrder: 'asc' | 'desc' }>) => 
    {
      const { columnName, sortOrder } = event.detail;
      dispatch(new UserListActions.SetState({ sortField: columnName, sortOrder }));
    };

    window.addEventListener('sortColumn', handleSortColumn as EventListener);
    return () => 
    {
      window.removeEventListener('sortColumn', handleSortColumn as EventListener);
    };
  }, []);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => 
  {
    const { name, value } = e.target;
    dispatch(new UserListActions.SetState({
      formData: { ...state.formData, [name]: value },
    }));
  }, [state.formData]);

  const handlePostConfirm = useCallback(async (e: React.FormEvent) => 
  {
    e.preventDefault();
    if (state.isSubmitting) return;

    dispatch(new UserListActions.SetSubmitting(true));
    dispatch(new UserListActions.SetError(null));

    const newUser: UserDto = {
      sub: '',
      email: state.formData.email,
      role: state.newUserRoles,
      givenName: state.formData.givenName || undefined,
      familyName: state.formData.familyName || undefined,
      streetAddress: state.formData.streetAddress || undefined,
      locality: state.formData.locality || undefined,
      region: state.formData.region || undefined,
      postalCode: state.formData.postalCode || undefined,
      country: state.formData.country || undefined,
      locale: state.formData.locale,
      zoneInfo: state.formData.timeZone,
    };

    toast.loading('Adding user...', { id: "addUser" });

    try 
    {
      await userListController.addUser(newUser);
      dispatch(new UserListActions.SetState({ basicModalOpen: false }));
      fetchUsers();
      getTotalUsers();
      toast.success('User added successfully');
      dispatch(new UserListActions.ResetForm());
    }
    catch (error) 
    {
      if (error instanceof AxiosError && error.response) 
      {
        toast.error(error.response.data.message);
      }
    }
    finally 
    {
      dispatch(new UserListActions.SetSubmitting(false));
      toast.dismiss('addUser');
    }
  }, [state.formData, state.newUserRoles, state.isSubmitting, fetchUsers, getTotalUsers]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => 
  {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const handleSubmitSearchQuery = (e: React.FormEvent) => 
  {
    e.preventDefault();
    dispatch(new UserListActions.SetState({ searchQuery: searchQuery, currentPage: 0 }));
  };

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => 
  {
    const itemsPerPage = parseInt(e.target.value, 10);

    dispatch(new UserListActions.SetState({ itemsPerPage, currentPage: 0 }));
  };

  const handlePageChange = (page: number) => 
  {
    dispatch(new UserListActions.SetState({ currentPage: page }));
  };

  const handleSelectedItems = (selected: string[]) => 
  {
    dispatch(new UserListActions.SetState({ selectedItems: selected }));
  };

  const handleModeChange = (mode: 'givenName' | 'familyName' | 'email') => 
  {
    dispatch(new UserListActions.SetState({ searchMode: mode }));
  };

  return (
    <div className="flex h-[100vh] overflow-hidden">

      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-8">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {intl.title} ✨
                </h1>
              </div>
              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Search bar */}
                <div className="relative">
                  <select
                    id="searchMode"
                    value={state.searchMode}
                    onChange={(e) => handleModeChange(e.target.value as 'givenName' | 'familyName' | 'email')}
                    className="form-select ml-4"
                  >
                    <option value="email">{intl.email}</option>
                    <option value="givenName">{intl.firstName}</option>
                    <option value="familyName">{intl.lastName}</option>
                  </select>
                </div>
                <form onSubmit={handleSubmitSearchQuery} className="relative">
                  <input
                    id="form-search"
                    className="form-input w-full pl-9"
                    type="search"
                    placeholder={intl.searchPlaceholder}
                    onChange={handleSearchChange}
                  />
                  <button
                    className="absolute inset-0 right-auto group"
                    type="submit"
                    aria-label="Search"
                  >
                    <svg
                      className="w-4 h-4 shrink-0 fill-current text-slate-400 dark:text-slate-500 group-hover:text-slate-500 dark:group-hover:text-slate-400 ml-3 mr-2"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                      <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                    </svg>
                  </button>
                </form>
                {/* Add user button */}
                <Button
                  type="add"
                  variant="primary"
                  size="m"
                  stopPropagation={true}
                  onClick={() => 
                  {
                    dispatch(new UserListActions.SetState({ basicModalOpen: true }));
                  }}
                  hideOnMobile="label"
                  className="ml-2"
                >
                  {intl.addUser}
                </Button>
              </div>
            </div>

            {/* Add user modal */}
            {state.basicModalOpen && (
              <MainModal
                title={intl.addUser}
                setOpen={(open) => dispatch(new UserListActions.SetState({ basicModalOpen: open }))}
                variant="primary"
              >
                {/* Sposta il form qui all'esterno di ModalChoice */}
                <form onSubmit={handlePostConfirm}>
                  {state.error && <div className="text-red-500 mb-4">{state.error}</div>}

                  <ModalContent>
                    <div className="flex w-full gap-6">

                      {/* Campo Nome */}
                      <ModalInputText
                        variant="name"
                        name="givenName"
                        label={intl.firstName}
                        value={state.formData.givenName}
                        onChange={handleChange}
                      />
                      <ModalInputText
                        variant="surname"
                        name="familyName"
                        label={intl.lastName}
                        value={state.formData.familyName}
                        onChange={handleChange}
                      />
                    </div>
                    {/* Campo Email */}
                    <ModalInputText
                      variant="email"
                      name="email"
                      label={intl.email}
                      value={state.formData.email}
                      onChange={handleChange}
                      validate={true}
                    />

                    {/* Role Selector */}
                    <div className="flex flex-wrap w-full gap-6">
                      <RoleSelector
                        roles={state.newUserRoles}
                        onRolesChange={(roles) => dispatch(new UserListActions.SetState({ newUserRoles: roles }))}
                      />
                    </div>
                  </ModalContent>

                  {/* Aggiungi i pulsanti di conferma e annulla */}
                  <div className="flex flex-wrap justify-end space-x-2 mt-4">
                    <Button
                      variant="tertiary"
                      size="m"
                      onClick={() => dispatch(new UserListActions.SetState({ basicModalOpen: false }))}
                    >
                      {intl.modalCloseBtn}
                    </Button>
                    <Button
                      variant="primary"
                      size="m"
                      buttonType="submit"
                      disabled={state.isSubmitting}
                    >
                      {state.isSubmitting ? intl.submitting : intl.addUser}
                    </Button>
                  </div>
                </form>
              </MainModal>
            )}

            {/* Filters and pagination */}
            <div className='flex gap-1 justify-between'>
              <div>
                <DropdownFilter align="left" fields={state.dropdownFields} />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="itemsPerPage"
                  className="mr-2 hidden md:inline"
                >
                  {intl.resultPerPage}:
                </label>
                <select
                  id="itemsPerPage"
                  value={state.itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  className="form-select"
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>

            {/* User list table */}
            <UserListTable
              selectedItems={handleSelectedItems}
              itemsPerPage={state.itemsPerPage}
              totalResults={state.totalUsers}
              users={state.usersList}
              currentPage={state.currentPage}
              sortField={state.sortField}
              sortOrder={state.sortOrder}
              isLoading={state.isLoading}
            />

            {/* Pagination */}
            <PaginationNumeric
              totalResults={state.totalUsers}
              itemsPerPage={state.itemsPerPage}
              currentPage={state.currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </main>

      </div>
    </div>
  );
};
