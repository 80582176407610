import React, { useEffect, useRef } from 'react';
import { RotateCcw } from 'lucide-react';
import { MessageBubble } from './MessageBubble';
import { ChatbotStyles } from '../CommonStyles/ChatbotStyles';
import { GenAIChatMessage } from '../../Api/Types/GenAIInterface';

interface ChatWindowBodyProps 
{
  messages: GenAIChatMessage[];
  loading: boolean;
  chatbotIcon: string;
  errorMessage: string | null;
  chatContainerRef: React.RefObject<HTMLDivElement>;
  onSelection?: (selection: string) => void;
  style: typeof ChatbotStyles;
  onSend: (message: string) => void;
  onScrollCheck: (isScrolledToBottom: boolean) => void;
}

export const ChatWindowBody: React.FC<ChatWindowBodyProps> = ({
  messages,
  loading,
  chatbotIcon,
  errorMessage,
  chatContainerRef,
  onSelection,
  style,
  onSend,
  onScrollCheck
}) => 
{

  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => 
  {
    const checkScrollPosition = () => 
    {
      if (chatContainerRef.current) 
      {
        const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
        onScrollCheck(scrollHeight - scrollTop - clientHeight > 20);
      }
    };

    const container = chatContainerRef.current;
    if (container) 
    {
      container.addEventListener('scroll', checkScrollPosition);
      checkScrollPosition();
    }

    return () => 
    {
      if (container) 
      {
        container.removeEventListener('scroll', checkScrollPosition);
      }
    };
  }, [chatContainerRef, onScrollCheck]);

  useEffect(() => 
  {
    if (lastMessageRef.current) 
    {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [messages]);

  return (
    <div ref={chatContainerRef} className={style.ChatWindowBody}>

      <style>
        {`
        a {
           display: flex;
           align-items: center;
           justify-content: center;
           padding: 0.5rem 3rem; 
           background: linear-gradient(to right, #d97706, #f97316); 
           color: #ffffff; 
           border-radius: 0.5rem;
           box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); 
           transition: background-color 0.3s; 
           font-size: 0.875rem; 
           width: 100%; 
        }
        a:hover {
          background: #ea580c;
        }
        a:focus {
          outline: none; 
          box-shadow: 0 0 0 4px rgba(251, 146, 60, 0.5);
        }

      `}
      </style>

      {messages.map((msg, index) => (
        <div key={index} ref={index === messages.length - 1 ? lastMessageRef : null}>
          <MessageBubble
            message={msg}
            chatbotIcon={chatbotIcon}
            loading={loading}
            onSelection={onSelection}
            style={style}
          />
        </div>
      ))}
      {loading && (
        <div className="flex justify-start mb-4">
          <img
            src={chatbotIcon}
            alt="Riminy"
            className="w-8 h-8 rounded-full flex-shrink-0 mr-2 shadow-md"
          />
          <div className={style.ThinkingBubbleDONTCHANGECOLOR + '' + style.ThinkingBubbleBackgroundColor}>
            <div className="text-gray-700">
              Sto pensando
              <span className="typing ml-1 inline-block">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </div>
            <div className={style.ThinkingBubbleArrowDONTCHANGECOLOR + '' + style.ThinkingBubbleBackgroundColor}></div>
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="flex items-center justify-between bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg relative mb-4 shadow-md">
          <span>C'è stato un errore inaspettato, riprova ad inviare il messaggio</span>
          <button
            onClick={() => 
            {
              onSend("debugging message"); // per rimini chatbot mando manage_error e da lì gestisco dicendo che se messaggio è diverso da manage_error allora non aggiungo messaggio all'utente (RiminiChatbotLogic.tsx)
            }}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded-full shadow-sm"
          >
            <RotateCcw size={16} />
          </button>
        </div>
      )}

    </div>
  );
};
