import { AnimationCustomization, StyleCustomization } from "../../../Features/GenAI/ChatbotUtils/ChatbotTypes";

export const styleCustomization: StyleCustomization = {
  chatWindowFooter: 'bg-moritzinoPrimary',
  chatWindowFooterContainer: 'bg-green-700',
  typingDotColor: '#f97316',
  loadingRingColor: '#f97316',
  thinkingBubbleBackgroundColor: 'bg-green-700',
  headerGradient: 'bg-black',
  headerIconHoverColor: 'hover:text-slate-400',
  bodyBackgroundColor: 'bg-moritzinoPrimary',
  aiBubbleColor: 'bg-green-800 text-white',
  humanBubbleColor: 'bg-green-700 text-white',
  footerTextareaFocusColor: 'text-white placeholder:text-white focus:ring-green-900',
  scrollButtonColor: 'bg-green-900 text-white',
  scrollButtonHoverColor: 'hover:bg-green-800',
  speechToTextMicrophoneColor: 'text-white',
  speechToTextButtonColor: 'bg-green-900 hover:bg-green-800 text-white',
  speechToTextButtonListeningColor: 'bg-green-600 text-white',
  speakerColor: 'text-white',
  speakerBackgroundColor: 'bg-green-900',
  speakerPlayingBackgroundColor: 'bg-green-900',
  sendButtonColor: 'bg-green-900 text-white',
  sendButtonHoverColor: 'hover:bg-green-800',
};

export const animationCustomization: { icon: AnimationCustomization, window: AnimationCustomization } = {
  icon: {
    initial: { scale: 0.6, opacity: 0 },
    animate: { scale: 1, opacity: 1, y: -10 },
    transition: { type: 'spring', stiffness: 200, damping: 25 }
  },
  window: {
    initial: { opacity: 0, scale: 0.5, translateY: '50%', translateX: '50%' },
    animate: { opacity: 1, scale: 1, translateY: '0%', translateX: '0%' },
    exit: { opacity: 0, scale: 0.5, translateY: '50%', translateX: '100%' },
    transition: { type: 'spring', stiffness: 300, damping: 30 }
  }
};