// Separiamo la gestione dello stato globale (con contesto) e locale in due hook distinti.
// Hook per gestire lo stato con il contesto
import { RefObject, useEffect, useState, useContext } from 'react';
import { TabsContext } from './TabsContext';

export function useTabsContextState() 
{
  const context = useContext(TabsContext);
  if (!context || !context.state || !context.dispatch) 
  {
    throw new Error('useTabsContextState must be used within a TabsProvider');
  }

  return {
    activeTabIndex: context.state.activeTabIndex,
    activateTab: (index: number) => context.dispatch({ type: 'SET_ACTIVE_TAB', payload: index }),
  };
}

// Hook per gestire lo stato locale
export function useLocalTabsState() 
{
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return {
    activeTabIndex,
    activateTab: setActiveTabIndex,
  };
}

// Funzione per calcolare la posizione dell'underline in base all'elemento attivo
export function calculateUnderlinePosition(
  tabsRef: RefObject<HTMLUListElement>,
  activeIndex: number
) 
{
  if (tabsRef.current) 
  {
    const activeTab = tabsRef.current.children[activeIndex] as HTMLLIElement;
    if (activeTab) 
    {
      return {
        width: activeTab.offsetWidth,
        x: activeTab.offsetLeft,
      };
    }
  }
  return { width: 0, x: 0 };
}

// Funzione per gestire la logica underline
export function useUnderlinePosition(
  tabsRef: RefObject<HTMLUListElement>,
  activeIndex: number,
  showUnderline: boolean
) 
{
  const [underlineStyle, setUnderlineStyle] = useState({ width: 0, x: 0 });

  useEffect(() => 
  {
    if (showUnderline) 
    {
      const newUnderlineStyle = calculateUnderlinePosition(tabsRef, activeIndex);
      setUnderlineStyle(newUnderlineStyle);
    }
  }, [activeIndex, showUnderline, tabsRef]);

  return underlineStyle;
}

// Stile dei Tab in base all'attività
export function getTabStyle(isActive: boolean) 
{
  return `
    ${isActive ? 'text-indigo-500' : 'text-slate-500 dark:text-slate-400 hover:text-slate-600 dark:hover:text-slate-300'}
  `;
}