
import { GenAIChatSendRequest, GenAIChatUpdateInstructions } from "../../../Features/GenAI/Api/Types/GenAIRequest";
import { ChatbotInfo } from "../../../Features/GenAI/Windtre/WindTre.types";
import { moritzinoApiDefinitions } from "./MoritzinoApiConfig";

//TODO: substitute with real api from Moritzino

const definition = moritzinoApiDefinitions.windTreChat;

export const moritzinoController = {
  
  send: async (
    storeId: string,
    request: GenAIChatSendRequest,
    onMessageCallback?: (message: string) => void,
    onCompleteCallback?: (data: unknown) => void,
    onErrorCallback?: (error: unknown) => void
  ) =>
  {
    const endpoint = definition.send;

    if (request.streamResponse)
    {
      await endpoint.invoke(endpoint.url.replace("{storeId}", storeId), request, {
        sseHandler: {
          onMessage: onMessageCallback,
          onComplete: onCompleteCallback,
          onError: onErrorCallback
        }
      });
    }
    else
    {
      const response = await endpoint.invoke(endpoint.url.replace("{storeId}", storeId), request);
      return response;
    }
  },
  getInstructions: async (storeId: string) => 
  {
    const endpoint = definition.getInstructions;
    const response = await endpoint.invoke(endpoint.url.replace("{storeId}", storeId));

    return response;
  },
  updateInstructions: async (storeId: string, instructions: GenAIChatUpdateInstructions) => 
  {
    const endpoint = definition.updateInstructions;
    const response = await endpoint.invoke(endpoint.url.replace("{storeId}", storeId), {instructions});

    return response;
  },
  getCategories: async (storeId: string, context: ChatbotInfo) => 
  {
    const endpoint = definition.getCategories;
    const response = await endpoint.invoke(endpoint.url.replace("{storeId}", storeId), context as Record<string, object>);
    return response;
  },
  getTokenUsage: async (storeId: string, chatId: string) => 
  {
    const endpoint = definition.getTokenUsage;
    const response = await endpoint.invoke(endpoint.url.replace("{storeId}", storeId), {chatId} as unknown as Record<string, object> );
    return response;
  },
  getStoreIds: async (storeId: string) => 
  {
    const endpoint = definition.getStoreIds;
    const response = await endpoint.invoke(endpoint.url, storeId as unknown as Record<string, object>);
    return response;
  }
};
