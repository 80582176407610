import React, { createContext, useEffect, useState, ReactNode } from 'react';
import { rolesManager } from './RoleManager';
import { useUser } from '../../Features/Auth/Hooks/UseSession';

export interface RoleRenderingType 
{
  roles: string[];
  hasRole: (role: string) => boolean;
  hasAnyRole: (roles: string[]) => boolean;
}

export const RoleRenderingContext = createContext<RoleRenderingType | undefined>(undefined);

export const RoleRenderingProvider: React.FC<{ children: ReactNode }> = ({ children }) => 
{
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => 
  {
    // const fetchRoles = async () => 
    // {
    //   try 
    //   {
    //     const oidcStorageKey = Object.keys(localStorage).find(key => key.startsWith('oidc.user'));
    //     if (!oidcStorageKey) return;
    //     await rolesManager.fetchRoles();
    //     setRoles(rolesManager.getRoles());
    //   }
    //   catch 
    //   {
    //     setRoles([]);
    //   }
    // };
    const fetchRoles = () => 
    {
      setRoles(["Admin"]); // Così l'utente sarà sempre admin
    };

    fetchRoles();
  }, []);

  const hasRole = (role: string) => roles.includes(role);
  const hasAnyRole = (rolesArray: string[]) => rolesArray.some(role => roles.includes(role));

  return (
    <RoleRenderingContext.Provider value={{ roles, hasRole, hasAnyRole }}>
      {children}
    </RoleRenderingContext.Provider>
  );
};

/* export const useRoleRendering = () => 
{
  const context = useContext(RoleRenderingContext);
  if (context === undefined) 
  {
    throw new Error('useRoleRendering must be used within a RoleRenderingProvider');
  }
  return context;
}; */
