import React, { useState } from 'react';
import { ModalInputTextProps } from './ModalTypes';

const variantMap = {
  name: {
    name: 'name',
    label: 'Nome',
    type: 'text' as const,
  },
  surname: {
    name: 'surname',
    label: 'Cognome',
    type: 'text' as const,
  },
  email: {
    name: 'email',
    label: 'Email',
    type: 'email' as const,
  },
  quantity: {
    name: 'quantity',
    label: 'Quantità',
    type: 'number' as const,
  },
};

export const ModalInputText: React.FC<ModalInputTextProps> = ({
  variant,
  name,
  label,
  type,
  value,
  onChange,
  validate = true,
}) => 
{
  const variantDetails = variant ? variantMap[variant] : { name: '', label: '', type: 'text' };

  // Usa name, label e type passati come props se presenti, altrimenti prendi quelli dalla variant.
  const finalName = name ?? variantDetails.name;
  const finalLabel = label ?? variantDetails.label;
  const finalType = type ?? variantDetails.type;

  const [error, setError] = useState<string | null>(null);

  const handleBlur = () => 
  {
    if (validate) 
    {
      if (finalType === 'email' && value) 
      {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) 
        {
          setError('Indirizzo email non valido');
        } 
        else 
        {
          setError(null);
        }
      }
    }
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium" htmlFor={finalName}>
        {finalLabel}
      </label>
      <input
        type={finalType}
        id={finalName}
        name={finalName}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        className={`mt-1 block w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'
        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-white dark:bg-slate-800 text-gray-900 dark:text-gray-100`}
      />
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};
