import React, { ChangeEvent, ChangeEventHandler } from 'react';
import { AccountPanelChildProps } from '../AccountPanel';
import flatpickr from 'flatpickr';
import { AccountSettingsPanelProfileInfoLocale } from '../../../../ReactIntl/LocaleInterfaces';
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { Datepicker } from '../../../../Components/Datepicker';

// TODO: remove it when genders will be fetched from BE
const mockOptions = ["Male", "Female", "Other"];

const DATE_PICKER_OPTIONS: flatpickr.Options.Options = {
  mode: "single",
  monthSelectorType: "dropdown",
  altFormat: "F j, Y",
};

export const PanelName: React.FC<AccountPanelChildProps> = ({ userData, setUserData, onFieldChange }) => 
{
  const intl = getIntl<AccountSettingsPanelProfileInfoLocale>('accountSettingsPanelProfileInfo');

  /**
   * Updates the specified key in the userData object with the value from the event target.
   *
   * @param {string} field - The key to update in the userData object.
   * @return {ChangeEventHandler<HTMLInputElement | HTMLSelectElement>} A function that updates the specified key in the userData object.
   */
  const handleChangeFor = (field: string): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> => (e: ChangeEvent<HTMLInputElement>) => 
  {
    setUserData(prevState => ({ ...prevState, [field]: e.target.value }));
    if (onFieldChange) onFieldChange();
  };

  const handleDateChange = (selectedDates: Date[]) => 
  {
    const timeUTC = selectedDates[0].toUTCString();
    setUserData(prevState => ({ ...prevState, birthdate: timeUTC }));
    if (onFieldChange) onFieldChange();
  };

  return (
    <section className='pb-10 border-b border-gray-300 dark:border-gray-700'>
      {/* <h2 className="text-xl text-slate-800 dark:text-slate-100 font-bold mb-5">{intl.title}</h2> */}

      <section>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1 dark:text-slate-300" htmlFor="name">{intl.firstNameLabel}</label>
            <input
              id="name"
              className="form-input w-full"
              type="text"
              value={userData.given_name}
              onChange={handleChangeFor('given_name')}
              required
            />
          </div>

          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1 dark:text-slate-300" htmlFor="surname">{intl.lastNameLabel}</label>
            <input
              id="surname"
              className="form-input w-full"
              type="text"
              onChange={handleChangeFor('family_name')}
              value={userData.family_name}
            />
          </div>
        </div>
      </section>
      <section>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1 dark:text-slate-300">{intl.genderLabel}</label>
            <div className="flex items-center space-x-2">
              <div className="relative flex-1">
                <select
                  className="w-full dropdown dark:bg-slate-900/30 text-black py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-gray-400 dark:border-gray-600 dark:focus:ring-gray-500 dark:text-slate-300"
                  onChange={handleChangeFor('gender')}
                  value={userData.gender}
                  data-testid="gender-select"
                >
                  {/* TODO: Fetch genders from server */}
                  {mockOptions.map((gender, index) => (
                    <option key={index} value={gender} className='dark:bg-slate-900/30'>
                      {gender}
                    </option>
                  ))}
                </select>
              </div>
            </div>

          </div>

          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1 dark:text-slate-300" htmlFor="surname">{intl.birthdayLabel}</label>
            <Datepicker options={{ ...DATE_PICKER_OPTIONS, defaultDate: userData.birthdate ? userData.birthdate : "today", onChange: handleDateChange }} />
          </div>
        </div>

      </section >
    </section>
  );
};
