
export const ChatbotStyles = 
{
  // Main Styles
  ChatWindow: 
  "fixed inset-0 w-full rounded-lg shadow-2xl overflow-hidden flex flex-col min-h-[450px] max-h-[600px] " + 
  "sm:inset-auto sm:bottom-0 sm:right-0 sm:w-96 sm:min-h-[600px]",

  // Header Styles
  ChatWindowHeader: 
    "p-4 flex justify-between items-center",

  ChatWindowHeaderIcon: 
    "hover:scale-110 hover:shadow-lg transition-all duration-300 text-white",

  ChatWindowHeaderTitle: 
    "text-xl font-bold text-white",

  ChatWindowBody: 
    "flex-grow overflow-y-auto p-4 scrollbar-thin scrollbar-thumb scrollbar-track-transparent relative",
   
  ScrollButton: 
"absolute bottom-32 right-7 transform -translate-x-1/2 rounded-full p-2 shadow-lg transition-all duration-300 animate-bounce",

  // Footer Styles
  ChatWindowFooter: 
    "border-t p-4",

  ChatWindowFooterContainer: 
    "relative bg-gray-100 rounded-2xl overflow-hidden shadow-inner",

  ChatWindowFooterTextarea: 
    "w-full p-4 text-black pr-24 bg-transparent border-none focus:outline-none resize-none overflow-hidden focus:ring-1",

  ChatWindowFooterButtonContainer: 
    "absolute top-2 right-2 flex items-center space-x-2",

  ChatWindowFooterSendButton: 
    "p-2 rounded-full transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed shadow-md",

  // Message Bubble Style
  AIBubble:
  " rounded-tr-xl rounded-tl-xl rounded-br-xl ml-2",
  HumanBubble:
  " rounded-tl-xl rounded-tr-xl rounded-bl-xl ml-2",

  // Thinking Bubble Style
  ThinkingBubbleDONTCHANGECOLOR:
  "max-w-[70%] p-3 rounded-tr-xl rounded-tl-xl rounded-br-xl break-words relative shadow-md",
  ThinkingBubbleArrowDONTCHANGECOLOR:
  "absolute w-3 h-3  transform rotate-45 left-[-6px] top-3",
  ThinkingBubbleBackgroundColor:
  "",
  // Speech to Text Button Styles
  SpeechToTextButtonSize:
    "p-2 ",
  SpeechToTextButtonColor:
    "",   
  SpeechToTextMicrophoneColor:
    "",
  SpeechToTextButtonListeningColor:
    "",

  // Text to Speech Button Styles
  TextToSpeechButtonSize:
    "p-1",
  SpeakerBackgroundColor:
    "",
  SpeakerPlayingBackgroundColor:
    "",
  SpeakerColor:
    "",
};
