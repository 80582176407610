export interface StripeConfig
{
  public_key: string;
}

export interface SupabaseConfig
{
  url: string;
  public_key: string;
}

export interface ClientConfig
{
  stripe: StripeConfig;
  supabase: SupabaseConfig;
}

export const fetchClientConfig = async () => 
{
  const etag = Math.trunc(new Date().valueOf() / (1000 * 60 * 30));
  const response = await fetch(`/client-config.json?etag=${etag}`/*, { cache: 'no-store' }*/);

  if (!response.ok) 
  {
    throw new Error(`Failed to fetch client-config.json: ${response.statusText}`);
  }

  return await response.json() as ClientConfig;
};