import React, { useEffect } from 'react';
import { PolarChart } from '../../../../Charts/PolarChart';
// Import utilities
import { getIntl } from '../../../../ReactIntl/IntlConfig';
import { AnalyticsCards } from '../../../../ReactIntl/LocaleInterfaces';
import { hexToRGB, tailwindConfig } from '../../../../Utils/Utils';
interface ChartData
{
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
    hoverBackgroundColor: string[];
    borderWidth: number;
  }[];
}

export const AnalyticsCard10: React.FC = () => 
{
  const intl = getIntl<AnalyticsCards>('analyticsCards');
  const [newChartData, setNewChartData] = React.useState<ChartData>({
    labels: [],
    datasets: []
  });
  
  useEffect(() => 
  {
    const chartData = {
      labels: [`${intl.positive}`, `${intl.negative}`, `${intl.neutral}`], //TODO: use keys instead of variables
      datasets: [
        {
          label: 'Sentiment Analysis',
          data: [500, 326, 242],
          backgroundColor: [
            `rgba(${hexToRGB(tailwindConfig().theme.colors.emerald[400])}, 0.8)`,
            `rgba(${hexToRGB(tailwindConfig().theme.colors.rose[400])}, 0.8)`,
            `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[400])}, 0.8)`,
          ],
          hoverBackgroundColor: [
            `rgba(${hexToRGB(tailwindConfig().theme.colors.emerald[600])}, 0.8)`,
            `rgba(${hexToRGB(tailwindConfig().theme.colors.rose[500])}, 0.8)`,
            `rgba(${hexToRGB(tailwindConfig().theme.colors.gray[600])}, 0.8)`,
          ],
          borderWidth: 0,
        },
      ],
    };
    setNewChartData(chartData);
    console.log(chartData);
    //TODO: fix multiple http request 
  }, [intl.negative, intl.neutral, intl.positive]);

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b border-slate-100 dark:border-slate-700">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">{intl.h2SessionsByGender}</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <PolarChart data={newChartData} width={389} height={280} />
    </div>
  );
};
