import React, { useState, FormEvent, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthImage from '../../Images/auth-image.jpg';
import AuthDecoration from '../../Images/auth-decoration.png';
import { Banner } from '../../Components/Banner';
import { Tooltip } from '../../Components/Tooltip';
import zxcvbn from 'zxcvbn';
import { getIntl, setLocale, getLocale } from '../../ReactIntl/IntlConfig';
import { LanguageSelector } from '../../ReactIntl/LanguageSelector';
import { accountController } from '../../Api/Controller/ApiAccount';
import { ChangePasswordLocale, PasswordRequirementsLocale } from '../../ReactIntl/LocaleInterfaces';
import { useError } from '../../Hooks/UseError';
import { PasswordCriteria, getPasswordCriteriaDisplay, doPasswordsMatch, checkPasswordCriteria, getPasswordCriteriaMessages, getPasswordStrengthBarColor } from '../../Utils/PasswordUtils';

export const ChangePassword: React.FC = () => 
{
  const intl = getIntl<ChangePasswordLocale>("changePassword");
  const intlPass = getIntl<PasswordRequirementsLocale>("password");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState<zxcvbn.ZXCVBNResult | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [bannerOpen, setBannerOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resetCode, setResetCode] = useState<string | null>(null);
  const [decodedEmail, setDecodedEmail] = useState<string | null>(null);
  const [selectedLocale, setSelectedLocale] = useState<string>(getLocale());
  const navigate = useNavigate();
  const [focusedInput, setFocusedInput] = useState<string>("");
  const { isBlocked, retrySeconds } = useError();

  const [passwordCriteria, setPasswordCriteria] = useState<PasswordCriteria>
  ({
    hasSpecialChar: false,
    hasUpperCase: false,
    hasNumber: false,
    isLengthValid: false,
  });

  const [passwordMatch, setPasswordMatch] = useState<boolean>(true);
  const { containerClasses, criteriaElements } = getPasswordCriteriaDisplay(passwordCriteria, intlPass, focusedInput);

  useEffect(() => 
  {
    setPasswordStrength(zxcvbn(password));
  }, [password]);

  useEffect(() => 
  { 
    if(retrySeconds && isBlocked)
    {
      setErrorMessage(`Too many requests, wait ${retrySeconds} seconds and try again`);
      setBannerOpen(true);
    }
  }, [isBlocked, retrySeconds]);

  const extractCodesFromUrl = () => 
  {
    const searchParams = new URLSearchParams(window.location.search);
    const resetCode = searchParams.get("code");
    const encodedEmail = searchParams.get("email");
    let decodedEmail = null;

    if (encodedEmail) 
    {
      decodedEmail = atob(encodedEmail);
    }

    return { resetCode, decodedEmail };
  };

  useEffect(() => 
  {
    const { resetCode, decodedEmail } = extractCodesFromUrl();
    setResetCode(resetCode);
    setDecodedEmail(decodedEmail);
  }, []);

  const handleChangePassword = async (event: FormEvent) => 
  {
    event.preventDefault();
    setIsLoading(true);

    if (!doPasswordsMatch(password, confirmPassword)) 
    {
      setErrorMessage(intl.passwordMismatch);
      setBannerOpen(true);
      setIsLoading(false);
      return;
    }

    try 
    {
      await accountController.changePassword(
        decodedEmail ?? " ",
        password,
        resetCode ?? " "
      );

      setSuccessMessage(intl.passwordChanged);
      setBannerOpen(true);
      setIsLoading(false);
      setTimeout(() => navigate("/account/login"), 2000);
    }
    catch (error) 
    {
      console.log(error); //TODO: error 401 at every request
      setErrorMessage(`${intl.changeFailed}`);
      setBannerOpen(true);
      setIsLoading(false);
    }
  };

  const handleLocaleChange = (newLocale: string) => 
  {
    setSelectedLocale(newLocale);
    setLocale(newLocale);
  };

  useEffect(() => 
  {
    setSelectedLocale(getLocale());
  }, []);

  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100vh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img
                    src="/beet-icon.svg"
                    alt="Beet Root"
                    className="mx-auto mb-4 my-16 w-16 h-16"
                  />
                </Link>
                {/* Language selector */}
                <div className="flex place-content-end w-40">
                  <LanguageSelector
                    selectedLanguage={selectedLocale}
                    onLanguageChange={handleLocaleChange}
                  />
                </div>
              </div>
            </div>

            <div className="max-w-sm mx-auto w-full px-4 py-8">
              <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">
                {intl.title}
              </h1>
              {/* Error banner */}
              {errorMessage && (
                <Banner
                  className="mb-4"
                  type="error"
                  open={bannerOpen}
                  setOpen={setBannerOpen}
                >
                  {errorMessage}
                </Banner>
              )}
              {/* Success banner */}
              {successMessage && (
                <Banner
                  className="mb-4"
                  type="success"
                  open={bannerOpen}
                  setOpen={setBannerOpen}
                >
                  {successMessage}
                </Banner>
              )}
              {/* Form */}
              <form onSubmit={handleChangePassword}>
                <div className="space-y-4">
                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      {intl.newPasswordLabel}{" "}
                      <span className="text-rose-500">*</span>
                    </label>
                    <div className="flex items-center">
                      <input
                        id="password"
                        className="form-input w-full"
                        type="password"
                        value={password}
                        onFocus={() => 
                        {
                          setTooltipOpen(true);
                          setFocusedInput("password");
                        }}
                        onBlur={() => 
                        {
                          setTooltipOpen(false);
                          setFocusedInput("");
                        }}
                        onChange={(e) => 
                        {
                          setPassword(e.target.value);
                          setPasswordCriteria(checkPasswordCriteria(e.target.value));
                          setPasswordMatch(e.target.value === confirmPassword);
                        }}
                        autoComplete="on"
                        required
                      />
                      {tooltipOpen && (
                        <Tooltip
                          className="ml-2"
                          position="right"
                          size="sm"
                          bg="dark"
                        >
                          <div className="text-xs">
                            <ul>
                              {Object.entries(getPasswordCriteriaMessages(passwordCriteria, intlPass)).map(([key, value]) => (
                                <li key={key}>{value}</li>
                              ))}
                            </ul>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <div className={containerClasses}>
                      {criteriaElements.map((element, index) => (
                        <p key={index} className={element.classes}>
                          {element.message}
                        </p>
                      ))}
                    </div>
                    {passwordStrength && (
                      <div className="mt-2">
                        <div className="flex">
                          {Array.from({ length: 5 }, (_, i) => (
                            <div
                              key={i}
                              className={`h-2 flex-1 mx-1 rounded ${
                                i <= passwordStrength.score
                                  ? getPasswordStrengthBarColor(
                                    passwordStrength.score
                                  )
                                  : "bg-gray-300"
                              }`}
                            ></div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="confirmPassword"
                    >
                      {intl.confirmPasswordLabel}{" "}
                      <span className="text-rose-500">*</span>
                    </label>
                    <input
                      id="confirmPassword"
                      className="form-input w-full"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => 
                      {
                        setConfirmPassword(e.target.value);
                        setPasswordMatch(e.target.value === password);
                      }}
                      autoComplete="on"
                      required
                    />
                    {!passwordMatch && (
                      <p className="text-xs text-red-500">{intlPass.match}</p>
                    )}
                  </div>
                </div>

                <div className="flex justify-center items-center mt-6">
                  <button
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v8H4z"
                        ></path>
                      </svg>
                    ) : (
                      intl.changePasswordButton
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Image */}
        <div className="hidden md:flex md:w-1/2 bg-slate-900 dark:bg-slate-900">
          <img
            src={AuthImage}
            alt="Authenticity"
            className="inset-0 h-full w-full object-cover object-center"
          />
        </div>
        {/* Decoration */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
          <img
            className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div>
      </div>
    </main>
  );
};
