import { Link } from 'react-router-dom';
import NotFoundImage from '../../Images/404-illustration.svg';
import NotFoundImageDark from '../../Images/404-illustration-dark.svg';
import { getIntl } from '../../ReactIntl/IntlConfig';
import { ErrorPage404Locale } from '../../ReactIntl/LocaleInterfaces';
import { Button } from '../../Components/Actions/Buttons/Buttons';

export function PageNotFound() 
{
  const intl = getIntl<ErrorPage404Locale>('errorPage404');

  return (
    <div className="flex h-[100dvh] overflow-hidden">
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900">
        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <div className="max-w-2xl m-auto mt-16">
              <div className="text-center px-4">
                <div className="inline-flex mb-8">
                  <img className="dark:hidden" src={NotFoundImage} width="176" height="176" alt="404 illustration" />
                  <img className="hidden dark:block" src={NotFoundImageDark} width="176" height="176" alt="404 illustration dark" />
                </div>
                <div className="mb-6">{intl.description}</div>

                {/* Link with Button centralizzato */}
                <Link to="/" className="inline-block">
                  <Button
                    variant="primary"
                    size="l"
                  >
                    {intl.backToDashboardBtn}
                  </Button>
                </Link>

              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
