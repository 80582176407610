import React from 'react';

interface ToolbarButtonProps 
{
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
  isActive?: boolean;
  disabled?: boolean;
}

export const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  onClick,
  icon,
  label,
  isActive = false,
  disabled = false
}) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={`
      p-2 rounded-md transition-colors
      ${isActive ? 'bg-gray-200 dark:bg-gray-700' : 'hover:bg-gray-100 dark:hover:bg-gray-700'}
      ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
    `}
    aria-label={label}
    title={label}
  >
    {icon}
  </button>
);