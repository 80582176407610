import React, { useState, useEffect, useRef } from 'react';
import { Send, X, RefreshCw, RotateCcw, Settings, BarChart } from 'lucide-react';
import '../../ChatbotLayout/CommonStyles/ThreeDotsAnimation.css';
import { MessageBubble } from './MessageBubble';
import { Category, ChatMessage, ChatResponseWithoutSSE, MessageRole, Offer, WindTreChat } from '../WindTre.types';
import { WindBotSettings } from './WindBotSettings';
import { TokenUsageDialogue } from './TokenUsageDialogue';
import { ChatbotStyles } from '../../ChatbotLayout/CommonStyles/ChatbotStyles';
import { GenAIChatSendRequest } from '../../Api/Types/GenAIRequest';
import { SpeechToText } from '../../BotTools/SpeechToText';
import { WindTreSendRequest } from '../../../../Modules/WindTreTest/WindTreAssets/WindTreInterface';
import { RoleBasedElement } from '../../../../Api/Roles/RoleBasedElement';
import { Role } from '../../../../Api/Roles/RoleManager';
import { windtreChatController } from '../../../../Api/Controller/GenAI/Chatbot/ApiWindTreChat';
interface ChatWindowProps
{
  onClose: () => void;
  lastMessageContent: string;
  chatbotIcon: string;
  storeId: string;
  setStyle: (style: typeof ChatbotStyles) => void;
}

const initialMessage = "Ciao sono Windy! Come posso aiutarti?";
const LOCAL_STORAGE_KEY = 'chatState';

export const ChatWindow: React.FC<ChatWindowProps> = ({ onClose, lastMessageContent, chatbotIcon, storeId}) =>
{
  const [messages, setMessages] = useState<WindTreChat[]>([
    { text: initialMessage, role: MessageRole.Assistant }
  ]);
  const [input, setInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isBusiness, setIsBusiness] = useState<boolean | null>(null);
  const [showTypeButtons, setShowTypeButtons] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [currentOffer, setCurrentOffer] = useState<Offer | null>(null);
  const [chatId, setChatId] = useState<string>('');
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [isFirstInteraction, setIsFirstInteraction] = useState<boolean>(true);
  const [pendingMessage, setPendingMessage] = useState<string>('');
  const [isTextInput, setIsTextInput] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [showTokenUsage, setShowTokenUsage] = useState<boolean>(false);

  const MAX_CHARACTERS = 1000;

  useEffect(() =>
  {
    if (!isInitialized)
    {
      const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (savedState)
      {
        const parsedState = JSON.parse(savedState);
        setMessages(parsedState.messages || []);
        setIsBusiness(parsedState.isBusiness);
        setShowTypeButtons(parsedState.showTypeButtons);
        setCategories(parsedState.categories || []);
        setCurrentCategory(parsedState.currentCategory || null);
        setCurrentOffer(parsedState.currentOffer || null);
        setChatId(parsedState.chatId || '');
      }
      else
      {
        setMessages([{ text: initialMessage, role: MessageRole.Assistant }]);
      }
      setIsInitialized(true);
    }
  }, [isInitialized]);

  useEffect(() =>
  {
    if (isInitialized)
    {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
        messages,
        isBusiness,
        showTypeButtons,
        categories,
        currentCategory,
        currentOffer,
        chatId,
      }));
    }
  }, [messages, isBusiness, showTypeButtons, categories, currentCategory, currentOffer, chatId, isInitialized]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
  {
    const newInput = e.target.value.slice(0, MAX_CHARACTERS);
    setInput(newInput);
    adjustTextareaHeight();
  };

  useEffect(() =>
  {
    if (lastMessageContent)
    {
      setMessages(prevMessages => [
        ...prevMessages,
        { text: lastMessageContent, role: MessageRole.System }
      ]);
    }
    adjustTextareaHeight();
  }, [lastMessageContent, input]);

  useEffect(() =>
  {
    if (messagesEndRef.current && chatContainerRef.current)
    {
      const messagesEnd = messagesEndRef.current;
      messagesEnd.previousElementSibling?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const adjustTextareaHeight = () =>
  {
    if (textareaRef.current)
    {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 120)}px`;
    }
  };

  const handleSend = async () =>
  {
    if (input.trim())
    {
      if (isBusiness !== null)
      {
        setMessages(prevMessages => [
          ...prevMessages,
          { text: input, role: MessageRole.Human }
        ]);
        await sendMessage(input);
        setInput('');
        return;
      }

      setCurrentOffer(null);

      if (isFirstInteraction)
      {
        setPendingMessage(input);
        setInput('');
        setIsFirstInteraction(false);
        setShowTypeButtons(false);
        setIsTextInput(true);

        setMessages(prevMessages => [
          ...prevMessages,
          { text: input, role: MessageRole.Human },
          {
            text: "Sei un cliente business o privato?",
            role: MessageRole.Assistant,
            isSelectionPrompt: true,
            options: ['Cliente Privato', 'Cliente Business'],
            isTemporary: true
          }
        ]);
      }
      else
      {
        setMessages(prevMessages => [
          ...prevMessages,
          { text: input, role: MessageRole.Human }
        ]);
        await sendMessage(input);
        setInput('');
      }
    }
  };

  const handleClientTypeSelection = async (selection: string) =>
  {
    let overrideIsBusiness: boolean | undefined;

    if (selection === 'Cliente Business')
    {
      setIsBusiness(true);
      overrideIsBusiness = true;
    }
    else if (selection === 'Cliente Privato')
    {
      setIsBusiness(false);
      overrideIsBusiness = false;
    }

    setMessages(prevMessages =>
      prevMessages.filter(msg => !msg.isTemporary)
    );

    if (pendingMessage)
    {
      await sendMessage(pendingMessage, overrideIsBusiness);
      setPendingMessage('');
    }
  };

  const sendMessage = async (message: string, overrideIsBusiness?: boolean) =>
  {
    setLoading(true);
    setErrorMessage(null);

    const sendRequest: GenAIChatSendRequest<WindTreSendRequest> = {
      prompt: message,
      streamResponse: false,
      info: {
        isBusiness: overrideIsBusiness !== undefined ? overrideIsBusiness : isBusiness ?? false,
        chatId: chatId || null,
      }
    };

    try
    {
      const response = await windtreChatController.send(storeId, sendRequest);

      if (response && response.data)
      {
        const chatResponse = response.data as ChatResponseWithoutSSE;
        const newChatId = chatResponse.info.chatId;

        if (newChatId)
        {
          setChatId(newChatId);
        }
        else
        {
          console.warn("chatId is null in response");
        }

        const newMessages = chatResponse.newMessages || [];
        const botMessages = newMessages.filter(msg => msg.role == MessageRole.Assistant).map((msg: ChatMessage) => ({
          text: msg.message ?? '',
          role: MessageRole.Assistant
        }));

        setMessages(prevMessages => [
          ...prevMessages,
          ...botMessages,
        ]);
      }
      else
      {
        throw new Error("No response from server");
      }
    }
    catch (error)
    {
      console.error("Error sending message:", error);
      setErrorMessage("Si è verificato un errore nell'invio del messaggio. Riprova.");
    }
    finally
    {
      setLoading(false);
    }
  };

  const handleVoiceInput = (text: string) =>
  {
    setInput(prevInput => prevInput + ' ' + text);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) =>
  {
    if (e.key === 'Enter' && !e.shiftKey)
    {
      e.preventDefault();
      if (pendingMessage.length === 0)
      {
        handleSend();
      }
    }
  };

  const handleTypeSelection = async (isBusinessUser: boolean) =>
  {
    setIsBusiness(isBusinessUser);
    setShowTypeButtons(false);
    setLoading(true);

    try
    {
      const response = await windtreChatController.getCategories(storeId, { isBusiness: isBusinessUser });

      if (Array.isArray(response.data))
      {
        setCategories(response.data);
        setIsBusiness(isBusinessUser);

        const categoryNames = response.data.map(category => category.name);
        const aiMessage = `Grazie. Ecco le categorie disponibili per i clienti ${isBusinessUser ? 'business' : 'privati'}. Quale categoria ti interessa?`;

        if (!isTextInput)
        {
          setMessages(prevMessages => [
            ...prevMessages,
            {
              text: aiMessage,
              role: MessageRole.Assistant,
              isSelectionPrompt: true,
              options: categoryNames
            }
          ]);
        }
      }
      else
      {
        //
      }
    }
    catch (error)
    {
      console.error("Error fetching categories:", error);
      setErrorMessage("Si è verificato un errore nel caricamento delle categorie. Riprova.");
    }
    finally
    {
      setLoading(false);
    }
  };

  const handleCategorySelection = (categoryName: string) =>
  {
    const selectedCategory = categories.find(cat => cat.name === categoryName);
    if (selectedCategory)
    {
      setCurrentCategory(selectedCategory);
      const offerNames = selectedCategory.offers.map(offer => offer.name);
      const aiMessage = `Che offerta stai cercando?`;

      setMessages(prevMessages => [
        ...prevMessages,
        { text: aiMessage, role: MessageRole.Assistant, isSelectionPrompt: true, options: offerNames }
      ]);
    }
  };

  const handleOfferSelection = (offerName: string) =>
  {
    if (currentCategory)
    {
      const selectedOffer = currentCategory.offers.find(off => off.name === offerName);
      if (selectedOffer)
      {
        setCurrentOffer(selectedOffer);
        const aiMessage = `Ecco i dettagli dell'offerta ${selectedOffer.name}: ${selectedOffer.description} ${selectedOffer.slug}`;

        setMessages(prevMessages => [
          ...prevMessages,
          { text: aiMessage, role: MessageRole.Assistant }
        ]);
      }
    }
  };

  const handleCercavoAltro = () =>
  {
    if (currentOffer)
    {
      setCurrentOffer(null);
      const categoryMessage: WindTreChat = {
        text: `Ecco le offerte disponibili in ${currentCategory?.name}. Quale offerta ti interessa?`,
        role: MessageRole.Assistant,
        isSelectionPrompt: true,
        options: currentCategory?.offers.map(offer => offer.name) || []
      };
      setMessages(prevMessages => [...prevMessages, categoryMessage]);
      setIsTextInput(false);
    }
    else if (currentCategory)
    {
      setCurrentCategory(null);
      const categoriesMessage: WindTreChat = {
        text: `Ecco le categorie disponibili. Quale categoria ti interessa?`,
        role: MessageRole.Assistant,
        isSelectionPrompt: true,
        options: categories.map(category => category.name)
      };
      setMessages(prevMessages => [...prevMessages, categoriesMessage]);
    }
    else
    {
      handleResetChat();
    }
  };

  const handleResetChat = () =>
  {
    setMessages([{ text: initialMessage, role: MessageRole.Assistant }]);
    setIsBusiness(null);
    setShowTypeButtons(true);
    setErrorMessage(null);
    setCategories([]);
    setCurrentCategory(null);
    setCurrentOffer(null);
    setIsFirstInteraction(true);
    setIsTextInput(false);
    setPendingMessage('');
    setChatId('');
  };

  const handleRetry = () =>
  {
    if (messages.length > 0)
    {
      const lastHumanMessage = [...messages].reverse().find(msg => msg.role === 'Human');
      if (lastHumanMessage)
      {
        sendMessage(lastHumanMessage.text);
      }
    }
    setErrorMessage(null);
  };

  const toggleSettings = () =>
  {
    setShowSettings(!showSettings);
  };

  const toggleTokenUsage = () => 
  {
    setShowTokenUsage(!showTokenUsage);
  };
  
  return (
    <div className="fixed inset-0 sm:inset-auto sm:bottom-4 sm:right-4 w-full sm:w-96 bg-gradient-to-b from-orange-50 to-white rounded-lg shadow-2xl overflow-hidden flex flex-col min-h-[450px] sm:min-h-[600px] max-h-[600px]">
      <div className="bg-gradient-to-r from-orange-400 to-orange-600 text-white p-4 flex justify-between items-center">
        <div className="flex space-x-2">
          <RoleBasedElement as="button" requiredRoles={Role.Admin} title="Impostazioni" onClick={toggleSettings} className="text-white hover:text-orange-200 hover:scale-110 transition-colors">
            <Settings size={24} />
          </RoleBasedElement>
        </div>
        <h3 className="font-bold text-lg">Windy</h3>
        <div className="flex space-x-2">
          { chatId && (
            <button 
              title="Visualizza utilizzo token" 
              onClick={toggleTokenUsage}
              className="text-white hover:text-orange-200 hover:scale-110 hover:shadow-lg transition-all duration-300"
            >
              <BarChart size={24} />
            </button>
          )}
          <button title="Resetta chat" onClick={handleResetChat} className="text-white hover:text-orange-200 hover:scale-110 hover:shadow-lg transition-colors">
            <RefreshCw size={24} />
          </button>
          <button title="Chiudi chat" onClick={onClose} className="text-white hover:text-orange-200 hover:scale-110 transition-colors">
            <X size={24} />
          </button>
        </div>
      </div>
      {showTokenUsage ? (
        <TokenUsageDialogue chatId={chatId} storeId={storeId} onClose={toggleTokenUsage} chatbotIcon={chatbotIcon}/>
      ) : showSettings ? (
        <WindBotSettings onClose={toggleSettings} storeId={storeId} />
      ) : (
        <>
          <div ref={chatContainerRef}
            className="flex-grow overflow-y-auto p-4 bg-transparent scrollbar-thin scrollbar-thumb-orange-200 scrollbar-track-transparent">
            <style>
              {`
              a {
                color: #ffffff;
                text-decoration: none; 
                text-color: #fffff
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.3rem 0.60rem; 
                background: linear-gradient(to right, #fbbf24, #f97316); 
                border-radius: 0.5rem;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
                transition: background-color 0.3s; 
                width: 100%;
                font-size: 0.875rem;
              }
              a:hover {
                background: linear-gradient(to right, #fb923c, #ef4444); 
                text-decoration: underline; 
              }
              a:focus {
                outline: none;
                box-shadow: 0 0 0 2px rgba(251, 191, 36, 0.5);
              }
            `}
            </style>

            {messages.map((msg, index) => (
              <MessageBubble
                key={index}
                storeId={storeId}
                currentOffer={currentOffer}
                message={msg}
                chatbotIcon={chatbotIcon}
                onSelectOption={(option) =>
                {
                  if (currentCategory)
                  {
                    handleOfferSelection(option);
                  }
                  else if (isBusiness === null)
                  {
                    handleClientTypeSelection(option);
                  }
                  else
                  {
                    handleCategorySelection(option);
                  }
                }}
                loading={loading}
                onCercavoAltro={handleCercavoAltro}
                isFirstMessage={index === 0}
                isLastMessage={index === messages.length - 1}
              />
            ))}
            {loading && (
              <div className="flex justify-start mb-4">
                <img
                  src={chatbotIcon}
                  alt="Windy"
                  className="w-8 h-8 rounded-full flex-shrink-0 mr-2 shadow-md"
                />
                <div className="max-w-[70%] p-3 bg-orange-100 rounded-tr-xl rounded-tl-xl rounded-br-xl break-words relative shadow-md">
                  <div className="text-gray-700">
                    Sto pensando
                    <span className="typing ml-1 inline-block">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </div>
                  <div className="absolute w-3 h-3 bg-orange-100 transform rotate-45 left-[-6px] top-3"></div>
                </div>
              </div>
            )}
            {showTypeButtons && !isTextInput && (
              <div className="flex  justify-center space-x-2">
                <button
                  onClick={() => handleTypeSelection(false)}
                  className="px-4 py-2 bg-gradient-to-r from-orange-400 to-orange-600 text-white rounded-lg hover:from-orange-500 hover:to-orange-700 transition-colors shadow-md"
                >
                  Cliente Privato
                </button>
                <button
                  onClick={() => handleTypeSelection(true)}
                  className="px-4 py-2 bg-gradient-to-r from-orange-400 to-orange-600 text-white rounded-lg hover:from-orange-500 hover:to-orange-700 transition-colors shadow-md"
                >
                  Cliente Business
                </button>
              </div>
            )}
            {errorMessage && (
              <div className="flex items-center justify-between bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg relative mb-4 shadow-md">
                <span>{errorMessage}</span>
                <button title='retry' onClick={handleRetry} className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded-full shadow-sm">
                  <RotateCcw size={16} />
                </button>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="border-t border-orange-200 p-4 bg-white">
            <div className="relative bg-gray-100 rounded-2xl overflow-hidden shadow-inner">
              <textarea
                ref={textareaRef}
                value={input}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Scrivi un messaggio..."
                className="w-full p-4 pr-24 bg-transparent border-none focus:outline-none resize-none overflow-hidden"
                style={{ minHeight: '60px', maxHeight: '150px' }}
                disabled={!!(loading || errorMessage !== null)}
              />
              <div className="absolute top-2 right-2 flex items-center space-x-2">
                {/*
                <span title="Caratteri permessi" className="text-xs text-gray-500 bg-white px-2 py-1 rounded-full shadow-sm">
                  {input.length}/{MAX_CHARACTERS}
                </span>
                */}
                <SpeechToText
                  onTextChange={handleVoiceInput}
                  buttonBackgroundColor="bg-gradient-to-r from-orange-400 to-orange-600"
                  buttonListeningBackgroundColor="bg-red-500"
                  microphoneColor="text-white"
                  buttonSize="p-2"
                />

                <button
                  title="Invia messaggio"
                  onClick={handleSend}
                  className="bg-gradient-to-r from-orange-400 to-orange-600 text-white p-2 rounded-full hover:from-orange-500 hover:to-orange-700 transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed shadow-md"
                  disabled={!!(loading || errorMessage !== null || pendingMessage.length > 0)}
                >
                  <Send size={20} />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};