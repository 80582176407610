import { useContext } from "react";
import { RoutesContext } from "../Routing/RoutesContext";

export const useRoutes = () =>
{
  const context = useContext(RoutesContext);
  if (context === undefined)
  {
    throw new Error('useRoutes must be used within a RoutesProvider');
  }
  return context;
};