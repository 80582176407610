import React from 'react';
import { 
  GenAIChatMessage, 
  GenAIProviderModel, 
  IGenAITemplate,
  GenAITokenUsage 
} from '../Api/Types/GenAIInterface';
import { 
  GenAITemplateStatus, 
  GenAITemplateKind, 
  GenAIMessageRole 
} from '../Api/Types/GenAIEnums';
import { GenAIMessageResponse } from '../Api/Types/GenAIResponse';

export interface ChatConversation 
{
  id: string;
  aiChatId: string;
  name: string;
  messages: ChatMessage[];
  createdAt: number;
  updatedAt: number;
}

export interface ChatMessage 
{
  id: string;
  role: GenAIMessageRole;
  content: string;
  timestamp: number;
}

export interface ChatStorageState 
{
  conversations: ChatConversation[];
  currentConversationId: string | null;
}
// Base State and Context Types
export interface ChatbotState 
{
  messages: GenAIChatMessage[];
  chatId: string;
  isLoading: boolean;
  error: string | null;
  blockMessage: boolean;
  providerModel?: GenAIProviderModel;
  currentTemplate?: IGenAITemplate;
  status: GenAITemplateStatus;
  kind: GenAITemplateKind;
  tokenUsage?: GenAITokenUsage;
}

export type ChatbotAction =
  | { type: 'FETCH_START' }
  | { type: 'FETCH_SUCCESS' }
  | { type: 'FETCH_ERROR'; payload: string }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string | null }
  | { type: 'ADD_MESSAGE'; payload: GenAIChatMessage }
  | { type: 'RESET_CHAT'; payload: GenAIChatMessage }
  | { type: 'SET_CHAT_ID'; payload: string }
  | { type: 'SET_BLOCK_MESSAGE'; payload: boolean }
  | { type: 'SET_MESSAGES'; payload: GenAIChatMessage[] }
  | { type: 'SET_PROVIDER_MODEL'; payload: GenAIProviderModel }
  | { type: 'SET_CURRENT_TEMPLATE'; payload: IGenAITemplate }
  | { type: 'SET_STATUS'; payload: GenAITemplateStatus }
  | { type: 'SET_TOKEN_USAGE'; payload: GenAITokenUsage }
  | { type: 'CLEAR_ERROR' };

export interface ChatbotContextType 
{
  state: ChatbotState;
  dispatch: React.Dispatch<ChatbotAction>;
}

// Service Types
export interface ChatbotServiceConfig 
{
  maxTokens: number;
  temperature: number;
  streamResponse: boolean;
}

export interface ChatbotCallbacks 
{
  onMessage?: (message: string) => void;
  onComplete?: (data: GenAIMessageResponse) => void;
  onError?: (error: Error) => void;
}

export interface ChatbotServiceInstance 
{
  sendMessage: (
    message: string,
    chatId: string,
    config: ChatbotServiceConfig,
    callbacks: ChatbotCallbacks
  ) => Promise<void>;
  createMessage: (role: GenAIMessageRole, message: string, isSelectionPrompt?: boolean, options?: string[]) => GenAIChatMessage;
}

// Component Props Types
export interface ChatbotProviderProps 
{
  children: React.ReactNode;
  chatId?: string;
  initialTemplate?: IGenAITemplate;
  config?: Partial<ChatbotServiceConfig>;
}

// UI Component Types
export interface ChatbotColorScheme 
{
  primary: string;
  secondary: string;
  background: string;
  text: string;
  error: string;
  success: string;
  warning: string;
  info: string;
}

export interface StyleCustomization 
{
  typingDotColor: string;
  loadingRingColor: string;
  thinkingBubbleBackgroundColor: string;
  headerGradient: string;
  headerIconHoverColor: string;
  bodyBackgroundColor: string;
  aiBubbleColor: string;
  humanBubbleColor: string;
  footerTextareaFocusColor: string;
  scrollButtonColor: string;
  scrollButtonHoverColor: string;
  speechToTextMicrophoneColor: string;
  speechToTextButtonColor: string;
  speechToTextButtonListeningColor: string;
  speakerColor: string;
  speakerBackgroundColor: string;
  speakerPlayingBackgroundColor: string;
  sendButtonColor: string;
  sendButtonHoverColor: string;
  colorScheme?: ChatbotColorScheme;
  chatWindowHeader?: string;
  chatWindowFooter?: string;
  chatWindowFooterContainer?: string;
}

export interface AnimationConfig 
{
  duration: number;
  delay: number;
  easing: string;
}

export interface AnimationCustomization 
{
  initial?: Record<string, number | string>;
  animate?: Record<string, number | string>;
  exit?: Record<string, number | string>;
  transition?: {
    type?: string;
    stiffness?: number;
    damping?: number;
    duration?: number;
    ease?: string;
  };
}

export interface WidgetChatbotProps 
{
  chatbotIcon: string;
  chatbotName: string;
  chatbotLogic: ChatbotLogic;
  styleCustomization: StyleCustomization;
  animationCustomization?: {
    icon?: AnimationCustomization;
    window?: AnimationCustomization;
  };
  adminRoutePath?: string;
  isFloatingChatbot?: boolean;
}

export interface ChatMessageProps 
{
  message: GenAIChatMessage;
  isAI: boolean;
  style: StyleCustomization;
  animation?: AnimationCustomization;
}

export interface ChatInputProps 
{
  onSend: (message: string) => Promise<void>;
  disabled: boolean;
  style: StyleCustomization;
}

// Logic Types
export interface ChatbotLogic 
{
  handleSend: (message: string) => Promise<void>;
  handleSelection?: (selection: string) => void;
  errorMessage: string | null;
  messages: GenAIChatMessage[];
  chatId: string;
  loading: boolean;
  handleResetChat: () => void;
  blockMessage: boolean;
}

// Animation Defaults
export const defaultIconAnimation: AnimationCustomization = {
  initial: { scale: 0.6, opacity: 0 },
  animate: { scale: 1, opacity: 1, y: -10 },
  transition: { type: 'spring', stiffness: 200, damping: 25 }
};

export const defaultWindowAnimation: AnimationCustomization = {
  initial: { opacity: 0, scale: 0.5, translateY: '50%', translateX: '50%' },
  animate: { opacity: 1, scale: 1, translateY: '0%', translateX: '0%' },
  exit: { opacity: 0, scale: 0.5, translateY: '50%', translateX: '100%' },
  transition: { type: 'spring', stiffness: 300, damping: 30 }
};