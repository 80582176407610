export const rememberUser = (email: string, password: string) => 
{
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 30);

  const userData = {
    email: email,
    password: btoa(password), 
  };

  const cookieValue = JSON.stringify(userData);

  document.cookie = `rememberMe=${encodeURIComponent(cookieValue)}; expires=${expirationDate.toUTCString()}; path=/; secure; samesite=strict`;
};

export const getRememberedUser = (): { email: string; password: string } | null => 
{
  const cookieValue = getCookie('rememberMe');
  if (cookieValue) 
  {
    try 
    {
      const userData = JSON.parse(decodeURIComponent(cookieValue));
      return {
        email: userData.email,
        password: atob(userData.password),
      };
    }
    catch (error) 
    {
      console.error('Error parsing remembered user cookie:', error);
      return null;
    }
  }
  return null;
};

export const getCookie = (name: string) => 
{
  const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return cookieValue ? cookieValue.pop() : '';
};

export const clearRememberedUser = () =>
{
  document.cookie = 'rememberMe=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};