import React, { useState } from 'react';

import { ProfileBody } from './Partials/ProfileBody';
import { ProfileSidebar } from './Partials/ProfileSidebar';

export const Profile: React.FC = () => 
{

  const [profileSidebarOpen, setProfileSidebarOpen] = useState<boolean>(false);

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-slate-900">

        {/* Site header */}

        <main className="grow">
          <div className="relative flex">

            {/* Profile sidebar */}
            <ProfileSidebar profileSidebarOpen={profileSidebarOpen} setProfileSidebarOpen={setProfileSidebarOpen} />

            {/* Profile body */}
            <ProfileBody profileSidebarOpen={profileSidebarOpen} setProfileSidebarOpen={setProfileSidebarOpen} />

          </div>
        </main>
      </div>
    </div>
  );
};
