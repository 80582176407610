import React, { useContext } from 'react';

import { getIntl } from '../../ReactIntl/IntlConfig';
import { AccountSettingsPageTitleLocale } from '../../ReactIntl/LocaleInterfaces';
import { SettingsSidebar } from './Partials/SettingsSidebar';
import { AppsPanel } from './Partials/AppsPanel';
import {LanguageContext} from '../../Contexts/LanguageContext';

export const Apps: React.FC = () => 
{

  const _languageCtx = useContext(LanguageContext);
  const intl = getIntl<AccountSettingsPageTitleLocale>('accountSettingsPageTitle');

  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-8">
              {/* Title */}
              <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">{intl.accountSettings} ✨</h1>
            </div>

            {/* Content */}
            <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <SettingsSidebar />
                <AppsPanel />
              </div>
            </div>

          </div>
        </main>
        
      </div>

    </div>
  );
};
