// @ts-nocheck
import React, { useRef, useEffect, useState } from 'react';
import { useThemeProvider } from '../Utils/ThemeContext';
import { ChartProps } from './Charts';
import { chartColors } from './ChartjsConfig';
import {
  Chart, LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';

// Import utilities
import { formatValue } from '../Utils/Utils';

Chart.register(LineController, LineElement, Filler, PointElement, LinearScale, TimeScale, Tooltip);

export const LineChart01: React.FC<ChartProps> = ({ data, width, height }) => 
{

  const [chart, setChart] = useState<Chart | null>(null);
  const canvas = useRef<HTMLCanvasElement>(null);
  const { currentTheme } = useThemeProvider();
  const darkMode = currentTheme === 'dark';
  const { tooltipBodyColor, tooltipBgColor, tooltipBorderColor, chartAreaBg } = chartColors;

  useEffect(() => 
  {
    const ctx = canvas.current!;

    const newChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        chartArea: {
          backgroundColor: darkMode ? chartAreaBg.dark : chartAreaBg.light,
        },
        layout: {
          padding: 20,
        },
        scales: {
          y: {
            display: false,
            beginAtZero: true,
          },
          x: {
            type: 'time',
            time: {
              parser: 'MM-DD-YYYY',
              unit: 'month',
            },
            display: false,
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: () => false, // Disable tooltip title
              label: (context) => formatValue(context.parsed.y),
            },
            bodyColor: darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light,
            backgroundColor: darkMode ? tooltipBgColor.dark : tooltipBgColor.light,
            borderColor: darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light,
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        maintainAspectRatio: false,
        resizeDelay: 200,
      },
    });
    setChart(newChart);
    return () => newChart.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => 
  {
    if (!chart) return;
    // Update tooltip colors
    const tooltip = chart.options?.plugins?.tooltip;
    if (tooltip) 
    {
      tooltip.bodyColor = darkMode ? tooltipBodyColor.dark : tooltipBodyColor.light;
      tooltip.backgroundColor = darkMode ? tooltipBgColor.dark : tooltipBgColor.light;
      tooltip.borderColor = darkMode ? tooltipBorderColor.dark : tooltipBorderColor.light;
    }

    // Update chart area background dynamically
    if (chart.options.chartArea) 
    {
      chart.options.chartArea.backgroundColor = darkMode ? chartAreaBg.dark : chartAreaBg.light;
    }

    chart.update('none'); // Update chart without animation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTheme, chart, tooltipBodyColor, tooltipBgColor, tooltipBorderColor, chartAreaBg]);

  return (
    <canvas ref={canvas} width={width} height={height}></canvas>
  );
};
