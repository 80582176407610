import { validationSchema } from "../../../../../../ReactHookForm/Utils/Validation/Schemas/ValidationSchema";
import { wizardValidationMessages } from "../Messages/WizardValidationMessages";
import * as yup from 'yup';
import { GenAITemplateInputKind, GenAITemplateKind, GenAITemplateStatus } from "../../../../Api/Types/GenAIEnums";

const inputsAiWriterSchema = yup.object().shape({
  inputs: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().optional(),
        name: yup
          .string()
          .required(wizardValidationMessages.required)
          .matches(/^[a-zA-Z0-9 ]+$/, wizardValidationMessages.inputName),
        kind: yup
          .mixed<GenAITemplateInputKind>()
          .oneOf(Object.values(GenAITemplateInputKind))
          .not([GenAITemplateInputKind.Unknown], 'Must select a valid input type')
          .required(wizardValidationMessages.required),
        description: yup.string().optional(),
        possibleValues: yup
          .array()
          .of(yup.string())
          .default([])
          .when('kind', {
            is: GenAITemplateInputKind.Dropdown,
            then: schema => schema.min(1, 'At least one option is required for dropdown')
          })
      })
    )
    .required()
    .min(1, wizardValidationMessages.minInputs)
    .test({
      name: 'dropdown-values-check',
      message: 'Each dropdown kind must have at least one possible value',
      test: function(inputs) 
      {
        if (!inputs) return true;
    
        // Trova l'indice dell'elemento che non ha valori possibili
        const dropdownWithoutValuesIndex = inputs.findIndex(
          (input) =>
            input.kind === GenAITemplateInputKind.Dropdown &&
            (!input.possibleValues || input.possibleValues.length === 0)
        );
    
        // Se trovi un elemento senza valori possibili, crea un errore
        if (dropdownWithoutValuesIndex !== -1) 
        {
          // Usa il percorso dinamico per far apparire l'errore nel punto giusto
          return this.createError({
            path: `inputs[${dropdownWithoutValuesIndex}].possibleValues`,
            message: 'Dropdown type inputs must have at least one possible value',
          });
        }
    
        return true;
      }
    })
    
    .test({
      name: 'unique-names',
      message: wizardValidationMessages.uniqueInputName,
      test: function(inputs) 
      {
        if (!inputs) return true;
        
        const names = inputs.map(input => input.name);
        const duplicates = names.filter((name, index) => 
          name && names.indexOf(name) !== index
        );
        
        if (duplicates.length > 0) 
        {
          //For each duplicate input name, create an error in the context
          inputs.forEach((input, index) => 
          {
            if (input.name && duplicates.includes(input.name)) 
            {
              throw this.createError({
                path: `inputs[${index}].name`,
                message: wizardValidationMessages.uniqueInputName
              });
            }
          });
          return false;
        }
        
        return true;
      }
    })
});

const colorSchema = yup
  .string()
  .optional()
  .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, wizardValidationMessages.hex);

const iconSchema = yup
  .string()
  .optional()
  .test('is-svg', 'Must be valid SVG markup', value => 
  {
    if (!value || value.trim() === '') return true;
    return value.includes('<svg') && value.includes('</svg>');
  });

export const wizardValidationSchema = yup.object().shape({
  ...validationSchema.fields, //Shared schema imported

  data: yup.object().shape({
    ...inputsAiWriterSchema.fields,
    prompt: yup
      .string()
      .required(wizardValidationMessages.required)
      .min(10, wizardValidationMessages.minLength(10))
      .test('has-template', wizardValidationMessages.promptTemplate, (value) => 
      {
        if (value && value.includes('<<')) 
        {
          return /<<[^>>]+>>/.test(value);
        }
        return true;
      }),
    //TODO: add Training validation schema for AI Chat Template using interfaces
    /* template: yup. */
  }),
  
  modalCategoryName: validationSchema.fields.name,

  kind: yup
    .mixed<GenAITemplateKind>()
    .oneOf(Object.values(GenAITemplateKind))
    .required(wizardValidationMessages.required),
  
  modalCategoryDescription: yup
    .string(),

  templateIcon: iconSchema,
  modalCategoryIcon: iconSchema,
  categoryIcon: iconSchema,
    
  templateColor: colorSchema,
  modalCategoryColor: colorSchema,
  categoryColor: colorSchema,

  categoriesId: yup
    .array()
    .of(yup.string())
    .min(1, wizardValidationMessages.categories) //Feature message imported
    .required(wizardValidationMessages.required), //Shared message imported
  
  status: yup
    .mixed<GenAITemplateStatus>()
    .oneOf(Object.values(GenAITemplateStatus))
    .required(wizardValidationMessages.required),

  floatingSettings: yup.object().shape({
    isFloatingChatbot : yup.boolean(),
    chatbotAvatar: iconSchema,
    backgroundColor: colorSchema,
    userColor: colorSchema,
    answersColor: colorSchema,
    textColor: colorSchema,
    backgroundImage: yup.string().url(wizardValidationMessages.url)
  })
});