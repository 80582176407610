import React, { createContext, useReducer, ReactNode } from 'react';
import { ChatbotAction, ChatbotContextType, ChatbotState } from './ChatbotTypes';
import { GenAIMessageRole, GenAITemplateStatus, GenAITemplateKind, GenAIProviderName } from '../Api/Types/GenAIEnums';

const initialState: ChatbotState = {
  messages: [{
    role: GenAIMessageRole.Assistant,
    message: "Ciao, di cosa hai bisogno?",
    isSelectionPrompt: false,
    options: []
  }],
  chatId: '',
  isLoading: true,
  error: null,
  blockMessage: false,
  providerModel: {
    provider: GenAIProviderName.Unspecified,
    models: []
  },
  currentTemplate: undefined,
  status: GenAITemplateStatus.Enabled,
  kind: GenAITemplateKind.AIChat,
  tokenUsage: undefined
};

export const ChatbotContext = createContext<ChatbotContextType | undefined>(undefined);

const chatbotReducer = (state: ChatbotState, action: ChatbotAction): ChatbotState => 
{
  switch (action.type) 
  {
    case 'FETCH_START':
      return { ...state, isLoading: true, error: null };
      
    case 'FETCH_SUCCESS':
      return { ...state, isLoading: false };
      
    case 'FETCH_ERROR':
      return { ...state, isLoading: false, error: action.payload };
      
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
      
    case 'SET_ERROR':
      return { ...state, error: action.payload };
      
    case 'ADD_MESSAGE':
      return { ...state, messages: [...state.messages, action.payload] };
      
    case 'RESET_CHAT':
      return { ...state, messages: [action.payload], error: null };
      
    case 'SET_CHAT_ID':
      return { ...state, chatId: action.payload };
      
    case 'SET_BLOCK_MESSAGE':
      return { ...state, blockMessage: action.payload };
      
    case 'SET_PROVIDER_MODEL':
      return { ...state, providerModel: action.payload };
      
    case 'SET_CURRENT_TEMPLATE':
      return { ...state, currentTemplate: action.payload };
      
    case 'SET_STATUS':
      return { ...state, status: action.payload };
      
    case 'SET_TOKEN_USAGE':
      return { ...state, tokenUsage: action.payload };
      
    case 'CLEAR_ERROR':
      return { ...state, error: null };
      
    default:
      return state;
  }
};

export const ChatbotProvider: React.FC<{ children: ReactNode }> = ({ children }) => 
{
  const [state, dispatch] = useReducer(chatbotReducer, initialState);

  return (
    <ChatbotContext.Provider value={{ state, dispatch }}>
      {children}
    </ChatbotContext.Provider>
  );
};