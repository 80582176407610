import React, { useContext } from "react";
import { Button } from "../../../Components/Actions/Buttons/Buttons";
import { DynamicToolbar } from "./DynamicToolbar";
import { useFileSystem } from "../Hooks/UseFileSystem";
import { useProgress } from "../Hooks/UseProgress";
import { useFileSystemOperations } from "../Hooks/UseFileSystemOperations";
import { useToolbarAction } from "../Hooks/UseHandleToolbarAction";
import { getIntl } from "../../../ReactIntl/IntlConfig";
import { MultiToolbarLocale } from "../../../ReactIntl/LocaleInterfaces";
import { LanguageContext } from "../../../Contexts/LanguageContext";
import { motion } from "framer-motion";

export const MultiToolbar: React.FC = () => 
{
  const { selectedItem } = useFileSystem();
  const { addExternalFile } = useFileSystemOperations();
  const { toggleProgressSidebar } = useProgress();
  const intl = getIntl<MultiToolbarLocale>("multiToolbarLocale");
  const _languageCtx = useContext(LanguageContext);

  const handleToolbarAction = useToolbarAction();

  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <>
      <div className="border-b py-3 flex flex-wrap items-center justify-start gap-5 relative border-gray-300 dark:border-slate-500">

        {!selectedItem ? (
          <motion.div
            className="flex flex-wrap items-center justify-start gap-5 relative"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <motion.div
              variants={itemVariants}>
              <Button
                variant="primary"
                size="l"
                type="add"
                onClick={() => handleToolbarAction('addFolder')}
              >
                {intl.addFolder}
              </Button>

            </motion.div>

            <motion.div variants={itemVariants}>
              <Button
                variant="primary"
                size="l"
                type="upload"
                onClick={() => document.getElementById('file-upload')?.click()}
              >
                {intl.uploadFile}
              </Button>

              <input
                id="file-upload"
                type="file"
                className="hidden"
                onChange={(e) => 
                {
                  if (e.target.files && e.target.files.length > 0) 
                  {
                    addExternalFile(e.target.files[0]);
                  }
                }}
              />
            </motion.div>

          </motion.div>

        ) : (
          <DynamicToolbar />
        )}

        <button
          className="btn hover:text-white ml-auto toggle-progress-btn shadow-none"
          onClick={toggleProgressSidebar}
        >
          {/* Toggle Progress */}
          <span className="sr-only">Toggle Progress Sidebar</span>
          <svg
            className="w-6 h-6 fill-current rotate-180"
            viewBox="0 0 24 24"
          >
            <path
              className="text-slate-400"
              d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
            />
            <path className="text-slate-600" d="M3 23H1V1h2z" />
          </svg>
        </button>
      </div>
    </>
  );
};