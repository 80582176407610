import React, { useCallback, useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  $isParagraphNode,
  RangeSelection,
  LexicalNode,
  UNDO_COMMAND,
  REDO_COMMAND,
} from 'lexical';
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, $isListNode } from '@lexical/list';
import { $isHeadingNode, HeadingTagType } from '@lexical/rich-text';
import { $isCodeNode, getCodeLanguages, getDefaultCodeLanguage } from '@lexical/code';
import { $isQuoteNode } from '@lexical/rich-text';

import { convertToNode } from '../Utils/LexicalUtils';
import {
  BoldIcon,
  ItalicIcon,
  AlignLeftIcon,
  AlignRightIcon,
  Code as CodeIcon,
} from 'lucide-react';
import {
  UnderlineIcon,
  AlignCenterIcon,
  JustifyIcon,
  Heading1Icon,
  CodeBlockIcon,
  ParagraphIcon,
  OrderedListIcon,
  UnorderedListIcon,
  UndoIcon,
  RedoIcon
} from '../Utils/Icons';
import { CustomSelect } from '../Components_/Toolbar/CustomSelect';
import { ToolbarButton } from '../Components_/Toolbar/ToolbarButton';
import { ToolbarDivider } from '../Components_/Toolbar/ToolbarDivider';
import { ToolbarGroup } from '../Components_/Toolbar/ToolbarGroup';

const LowPriority = 1;

const BLOCK_OPTIONS = [
  { value: 'paragraph', label: 'Paragraph', icon: <ParagraphIcon /> },
  { value: 'h1', label: 'Heading 1', icon: <Heading1Icon /> },
  { value: 'h2', label: 'Heading 2', icon: <Heading1Icon /> },
  { value: 'h3', label: 'Heading 3', icon: <Heading1Icon /> },
  { value: 'h4', label: 'Heading 4', icon: <Heading1Icon /> },
  { value: 'h5', label: 'Heading 5', icon: <Heading1Icon /> },
  { value: 'code', label: 'Code Block', icon: <CodeBlockIcon /> },
  { value: 'bullet', label: 'Bullet List', icon: <UnorderedListIcon /> },
  { value: 'number', label: 'Numbered List', icon: <OrderedListIcon /> },
];

const ALIGNMENT_OPTIONS = [
  { value: 'left', label: 'Align Left', icon: <AlignLeftIcon /> },
  { value: 'center', label: 'Align Center', icon: <AlignCenterIcon /> },
  { value: 'right', label: 'Align Right', icon: <AlignRightIcon /> },
  { value: 'justify', label: 'Justify', icon: <JustifyIcon /> },
];

const CODE_LANGUAGES = getCodeLanguages().map(lang => ({
  value: lang,
  label: lang.charAt(0).toUpperCase() + lang.slice(1)
}));

type BlockType =
  | 'paragraph'
  | HeadingTagType
  | 'code'
  | 'quote'
  | 'bullet'
  | 'number';

export const ToolbarPlugin: React.FC = () => 
{
  const [editor] = useLexicalComposerContext();
  const [isCodeBlock, setIsCodeBlock] = useState(false);
  const [alignment, setAlignment] = useState('left');
  const [codeLanguage, setCodeLanguage] = useState(getDefaultCodeLanguage());
  const [activeFormats, setActiveFormats] = useState({
    isBold: false,
    isItalic: false,
    isUnderline: false,
    isCode: false,
    isStrikethrough: false,
    blockType: 'paragraph' as BlockType,
  });

  const getParentOfType = (node: LexicalNode, typeCheckers: Array<(node: LexicalNode) => boolean>): LexicalNode | null => 
  {
    let currentNode: LexicalNode | null = node;

    while (currentNode !== null) 
    {
      if (typeCheckers.some(checker => checker(currentNode as LexicalNode))) 
      {
        return currentNode;
      }
      currentNode = currentNode.getParent();
    }

    return null;
  };

  const isInsideCodeBlock = useCallback((selection: RangeSelection): boolean => 
  {
    const anchorNode = selection.anchor.getNode();
    const codeNode = getParentOfType(anchorNode, [$isCodeNode]);
    return codeNode !== null;
  }, []);

  const getDetailedBlockType = useCallback((selection: RangeSelection): BlockType => 
  {
    const anchorNode = selection.anchor.getNode();
    const parentNode = getParentOfType(anchorNode, [
      $isCodeNode,
      $isHeadingNode,
      $isQuoteNode,
      $isListNode,
      $isParagraphNode,
    ]);

    if (!parentNode) return 'paragraph';

    if ($isCodeNode(parentNode)) return 'code';
    if ($isHeadingNode(parentNode)) return parentNode.getTag();
    if ($isQuoteNode(parentNode)) return 'quote';
    if ($isListNode(parentNode)) return parentNode.getListType() === 'bullet' ? 'bullet' : 'number';
    if ($isParagraphNode(parentNode)) return 'paragraph';

    return 'paragraph';
  }, []);

  const updateCodeLanguage = (newLanguage: string) => 
  {
    editor.update(() => 
    {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;

      const currentNode = selection.anchor.getNode();
      const codeNode = getParentOfType(currentNode, [$isCodeNode]);

      if ($isCodeNode(codeNode)) 
      {
        codeNode.setLanguage(newLanguage);
      }
    });
    setCodeLanguage(newLanguage);
  };

  const handleNodeChange = (value: string) => 
  {
    editor.update(() => 
    {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;

      switch (value) 
      {
        case 'bullet':
          editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
          break;
        case 'number':
          editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
          break;
        case 'code':
          convertToNode(editor, 'code');
          setCodeLanguage(getDefaultCodeLanguage());
          break;
        case 'quote':
          convertToNode(editor, 'quote');
          break;
        default:
          convertToNode(editor, value as HeadingTagType | 'paragraph');
      }
    });
  };

  const handleAlignmentChange = (value: string) => 
  {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, value as 'left' | 'center' | 'right' | 'justify');
    setAlignment(value);
  };

  const updateToolbar = useCallback(() => 
  {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) return;

    const isInCode = isInsideCodeBlock(selection);
    setIsCodeBlock(isInCode);

    if (isInCode) 
    {
      const currentNode = selection.anchor.getNode();
      const codeNode = getParentOfType(currentNode, [$isCodeNode]);

      if ($isCodeNode(codeNode)) 
      {
        setCodeLanguage(codeNode.getLanguage() || getDefaultCodeLanguage());
      }
    }

    setActiveFormats({
      isBold: selection.hasFormat('bold'),
      isItalic: selection.hasFormat('italic'),
      isUnderline: selection.hasFormat('underline'),
      isCode: selection.hasFormat('code'),
      isStrikethrough: selection.hasFormat('strikethrough'),
      blockType: getDetailedBlockType(selection),
    });
  }, [getDetailedBlockType, isInsideCodeBlock]);

  useEffect(() => 
  {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => 
      {
        updateToolbar();
        return false;
      },
      LowPriority
    );
  }, [editor, updateToolbar]);

  return (
    <div className="flex flex-wrap items-center gap-2 p-2 border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
      <ToolbarGroup>
        <ToolbarButton
          onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
          icon={<UndoIcon />}
          label="Undo"
        />
        <ToolbarButton
          onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
          icon={<RedoIcon />}
          label="Redo"
        />
      </ToolbarGroup>

      <ToolbarDivider />

      <ToolbarGroup>
        <CustomSelect
          value={activeFormats.blockType}
          onChange={handleNodeChange}
          options={BLOCK_OPTIONS}
          label="Block Type"
        />
      </ToolbarGroup>

      {isCodeBlock ? (
        <>
          <ToolbarDivider />

          <ToolbarGroup>
            <CustomSelect
              value={codeLanguage}
              onChange={updateCodeLanguage}
              options={CODE_LANGUAGES}
              label="Language"
            />
          </ToolbarGroup>
        </>
      ) : (
        <>
          <ToolbarDivider />

          <ToolbarGroup>
            <ToolbarButton
              onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
              icon={<BoldIcon />}
              label="Bold"
              isActive={activeFormats.isBold}
            />
            <ToolbarButton
              onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
              icon={<ItalicIcon />}
              label="Italic"
              isActive={activeFormats.isItalic}
            />
            <ToolbarButton
              onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')}
              icon={<UnderlineIcon />}
              label="Underline"
              isActive={activeFormats.isUnderline}
            />

            <ToolbarButton
              onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')}
              icon={<CodeIcon />}
              label="Inline Code"
              isActive={activeFormats.isCode}
            />
          </ToolbarGroup>

          <ToolbarDivider />

          <ToolbarGroup>
            <CustomSelect
              value={alignment}
              onChange={handleAlignmentChange}
              options={ALIGNMENT_OPTIONS}
              label="Text Alignment"
            />
          </ToolbarGroup>
        </>
      )}
    </div>
  );
};