import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { rolesManager } from './RoleManager';
import { getIntl } from '../../ReactIntl/IntlConfig';
import { RoleSelectorLocale } from '../../ReactIntl/LocaleInterfaces';
import Transition from '../../Utils/Transition';

interface RoleSelectorProps 
{
  roles: string[];
  onRolesChange: (newRoles: string[]) => void;
}

export const RoleSelector: React.FC<RoleSelectorProps> = ({ roles, onRolesChange }) => 
{
  const [allRoles, setAllRoles] = useState<string[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const trigger = useRef<HTMLButtonElement>(null);

  const intl = getIntl<RoleSelectorLocale>('roleSelector');

  const fetchRoles = useCallback(async () => 
  {
    const cachedRoles = localStorage.getItem('cachedRoles');
    if (cachedRoles) 
    {
      setAllRoles(JSON.parse(cachedRoles));
    } 
    else 
    {
      try 
      {
        await rolesManager.fetchRoles();
        const fetchedRoles = rolesManager.getRoles();
        setAllRoles(fetchedRoles);
      } 
      catch (error) 
      {
        console.error('Error fetching roles:', error);
      }
    }
  }, []);

  useEffect(() => 
  {
    fetchRoles();
  }, [fetchRoles]);

  useEffect(() => 
  {
    if (roles.length === 0 && allRoles.length > 0) 
    {
      onRolesChange([allRoles[0]]);
    }
  }, [roles, onRolesChange, allRoles]);

  const handleAddRole = useCallback(() => 
  {
    onRolesChange([...roles, '']);
  }, [roles, onRolesChange]);

  const handleRemoveRole = useCallback((index: number) => 
  {
    onRolesChange(roles.filter((_, i) => i !== index));
  }, [roles, onRolesChange]);

  const handleRoleChange = useCallback((index: number, value: string) => 
  {
    onRolesChange(roles.map((role, i) => (i === index ? value : role)));
    setDropdownOpen(null);
  }, [roles, onRolesChange]);

  const normalizeRole = useMemo(() => (role: string) => role.trim().toLowerCase(), []);
  const capitalizeRole = useMemo(() => (role: string) => 
  {
    if (!role) return '';
    return role.charAt(0).toUpperCase() + role.slice(1).toLowerCase();
  }, []);

  const getAvailableRoles = useCallback(
    (selectedRoles: string[], allRoles: string[]) => 
    {
      const selectedRolesSet = new Set(selectedRoles.map(normalizeRole));
      return Array.from(new Set(allRoles.map(normalizeRole).filter(role => !selectedRolesSet.has(role))));
    },
    [normalizeRole]
  );

  const availableRoles = useMemo(() => getAvailableRoles(roles, allRoles), [roles, allRoles, getAvailableRoles]);
  const canAddRole = useMemo(() => availableRoles.length > 0 && roles.every(role => role !== ''), [availableRoles, roles]);

  return (
    <div className="w-full">
      <label className="block text-sm font-medium mb-1" htmlFor="editRole">
        {intl.roleLabel}
      </label>
      {roles.map((role, index) => (
        <div key={index} className="relative flex items-center space-x-2 mb-4"> {/* Flex layout per allineare la X e il dropdown */}

          {roles.length > 1 && (
            <button
              type="button"
              onClick={() => handleRemoveRole(index)}
              className="text-red-500 hover:text-red-700"
            >
              ✕
            </button>
          )}

          <div className="relative flex-grow">
            <button
              ref={trigger}
              type="button"
              className="w-full flex items-center justify-between px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-white dark:bg-slate-800 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              aria-haspopup="true"
              onClick={(e) => 
              {
                e.stopPropagation(); 
                setDropdownOpen(dropdownOpen === index ? null : index);
              }}
              aria-expanded={dropdownOpen === index}
            >
              <span>{role ? capitalizeRole(role) : intl.dummyOptionSelect}</span>
              <svg className="shrink-0 ml-1 fill-current text-slate-400 dark:text-gray-300" width="11" height="7" viewBox="0 0 11 7">
                <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
              </svg>
            </button>

            <Transition
              show={dropdownOpen === index}
              tag="div"
              className="z-10 absolute top-full left-0 w-full bg-white dark:bg-slate-800 border border-gray-300 dark:border-slate-700 rounded shadow-lg overflow-hidden mt-1"
              enter="transition ease-out duration-100 transform"
              enterStart="opacity-0 -translate-y-2"
              enterEnd="opacity-100 translate-y-0"
              leave="transition ease-out duration-100"
              leaveStart="opacity-100"
              leaveEnd="opacity-0"
            >
              <div className="font-medium text-sm text-slate-600 dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                {availableRoles.map(availableRole => (
                  <button
                    key={availableRole}
                    className={`w-full text-left py-2 px-3 cursor-pointer hover:bg-slate-50 dark:hover:bg-slate-700/20 ${availableRole === role ? 'text-indigo-500' : ''}`}
                    onClick={() => handleRoleChange(index, availableRole.toUpperCase())}
                  >
                    {capitalizeRole(availableRole)}
                  </button>
                ))}
                {role && !availableRoles.includes(normalizeRole(role)) && (
                  <button
                    className="w-full text-left py-2 px-3 cursor-pointer text-indigo-500"
                    onClick={() => setDropdownOpen(null)}
                  >
                    {capitalizeRole(normalizeRole(role))}
                  </button>
                )}
              </div>
            </Transition>
          </div>
        </div>
      ))}

      <button
        type="button"
        onClick={handleAddRole}
        className={`mt-2 ${!canAddRole ? 'text-gray-500 cursor-not-allowed' : 'text-green-500 hover:text-green-700'}`}
        disabled={!canAddRole}
      >
        + {intl.addRoleBtn}
      </button>
    </div>
  );
};
