import { UserListAction, UserListActions, UserListState } from "./UserListAction";

export const initialUserListState: UserListState = {
  basicModalOpen: false,
  selectedItems: [],
  currentPage: 0,
  itemsPerPage: 10,
  totalUsers: 0,
  usersList: [],
  searchQuery: '',
  searchMode: 'email',
  sortField: 'email',
  sortOrder: 'asc',
  formData: {
    email: '',
    givenName: '',
    familyName: '',
    streetAddress: '',
    locality: '',
    region: '',
    postalCode: '',
    country: '',
    locale: 'en-EN',
    timeZone: 'UTC',
  },
  dropdownFields: [],
  newUserRoles: [],
  isSubmitting: false,
  error: null,
  isLoading: true,
};

export function userListReducer(state: UserListState, action: UserListAction): UserListState 
{
  switch (action.constructor) 
  {
    case UserListActions.SetState:
      return { ...state, ...(action as InstanceType<typeof UserListActions.SetState>).payload };
    case UserListActions.SetError:
      return { ...state, error: (action as InstanceType<typeof UserListActions.SetError>).payload };
    case UserListActions.ResetForm:
      return {
        ...state,
        formData: initialUserListState.formData,
        newUserRoles: [],
        isSubmitting: false,
      };
    case UserListActions.SetSubmitting:
      return { ...state, isSubmitting: (action as InstanceType<typeof UserListActions.SetSubmitting>).payload };
    default:
      return state;
  }
}