import React, {FC, useRef, useState} from "react";
import { useUIOperations } from "./Hooks/UseUiOperations";
import { Folder, FileItem, DragData } from "./FileManager.types";
import { ProgressItem } from "./Contexts/ProgressContext";
import { useProgress } from "./Hooks/UseProgress";
import { FolderGridItem } from "./FolderGridItem";
import { motion } from "framer-motion";

interface FolderGridProps 
{
  selectedItem: Folder | FileItem | null,
  setSelectedItem: (item: Folder | FileItem | null) => void,
  selectedFolder: Folder,
  moveItem: (itemId: string, sourceId: string, targetId: string, type: "folder" | "file") => void,
  findItem: (folder: Folder, id: string, type: "folder" | "file" | "grid") => Folder | FileItem | undefined,
  findFolderPath: (folder: Folder, folderId: string) => string,
  addOrShowProgressItem: (item: ProgressItem) => void,
  navigateToFolder: (folder: Folder) => void,
  rootFolder: Folder,
  addFile: (name: string, parentId: string) => void
}

const containerVariants = {
  hidden: {
    opacity: 0,
    x: 300,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.2
    },
  },
};

export const FolderGrid: FC<FolderGridProps> = ({...props}) => 
{
  const {openContextMenu} = useUIOperations();
  const _progressCtx = useProgress(); //TODO: not update when progress changes fix selectedFolder state
  const dragCounter = useRef(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isExternalDrag, setIsExternalDrag] = useState<boolean>(false);
  const handleRightClick = (e: React.MouseEvent, item: Folder | FileItem | null, type: 'folder' | 'file' | "grid") => 
  {
    e.preventDefault();
    e.stopPropagation();
    console.log('right click', item, type);
    const estimatedWidth = 120;  // estimated width of the menu
    const estimatedHeight = 300; // estimated height of the menu
    
    const x = (e.clientX + estimatedWidth > window.innerWidth) ? (window.innerWidth - estimatedWidth - 10) : e.clientX;
    const y = (e.clientY + estimatedHeight > window.innerHeight) ? (window.innerHeight - estimatedHeight - 10) : e.clientY;
    
    openContextMenu({ x, y, item: item || props.selectedFolder, type });
  };
    
  const handleLeftClick = (item: Folder | FileItem) => 
  {
    props.setSelectedItem(item);
  };
    
  const handleClearSelection = () => 
  {
    props.setSelectedItem(null);
  };
    
  const handleDragStart = (e: React.DragEvent, item: Folder | FileItem) => 
  {
    e.stopPropagation();
    const dragData: DragData = {
      id: item.id,
      type: 'subfolders' in item ? 'folder' : 'file',
      parentId: (item as Folder).parentId || 'root'
    };
    
    e.dataTransfer.setData('text/plain', JSON.stringify(dragData));
    e.dataTransfer.effectAllowed = 'move';
  };
    
  const handleDragOver = (e: React.DragEvent) => 
  {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };  
    
  // TODO: FIX item moving to itself if dragged over ---- FIXED but still weird solution
  const handleDrop = (e: React.DragEvent, targetFolder: Folder) => 
  {
    e.preventDefault();
    
    const data = e.dataTransfer.getData('text/plain');
    const { id, type, parentId }: DragData = JSON.parse(data);
    console.log("dataTransfered: ", { id, type, parentId });
    console.log("targetFolder: ", targetFolder);
    
    // need check
    if (id === parentId) 
    {
      e.dataTransfer.dropEffect = 'none';
    }
    else if (id === targetFolder.id && parentId !== targetFolder.id) 
    {
      e.dataTransfer.dropEffect = 'none';
    }
    else if (id !== targetFolder.id && parentId === targetFolder.id) 
    {
      e.dataTransfer.dropEffect = 'none';
    }
    else if (id !== targetFolder.id && parentId !== targetFolder.id) 
    {
      props.moveItem(id, parentId, targetFolder.id, type);
      const item = props.findItem(props.rootFolder, id, type);
      props.addOrShowProgressItem({
        type: "move",
        itemName: item && item.name || "",
        itemType: type,
        fromLocation: props.findFolderPath(props.rootFolder, id),
        toLocation: props.findFolderPath(props.rootFolder, targetFolder.id),
        timestamp: new Date()
      });
    }
    
  };

  const handleFileDragEnter = (e: React.DragEvent<HTMLDivElement>) => 
  {
    e.preventDefault();
    e.stopPropagation();
  
    dragCounter.current++;
    // Check if the drag event is coming from external source
    if (e.dataTransfer.types.includes('Files')) 
    {
      setIsExternalDrag(true);
      setIsDragging(true);
    }
  };
  
  const handleFileDragLeave = (e: React.DragEvent<HTMLDivElement>) => 
  {
    e.preventDefault();
    e.stopPropagation();
  
    dragCounter.current--;
    if (dragCounter.current === 0) 
    {
      setIsDragging(false);
      setIsExternalDrag(false);
    }
  };
  
  const handleFileDragOver = (e: React.DragEvent<HTMLDivElement>) => 
  {
    e.preventDefault();
    e.stopPropagation();
  };
  
  const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => 
  {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    setIsExternalDrag(false);
  
    dragCounter.current = 0;
  
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) 
    {
      const droppedFiles = Array.from(e.dataTransfer.files);
      droppedFiles.forEach(file => props.addFile(file.name, props.selectedFolder.id));
      e.dataTransfer.clearData();
    }
  };

  return (
    <div
      className="flex-1 overflow-y-auto p-4 "
      onContextMenu={(e) => handleRightClick(e, null, "grid")}
      onDragEnter={handleFileDragEnter}
      onDragLeave={handleFileDragLeave}
      onDragOver={handleFileDragOver}
      onDrop={handleFileDrop}
    >
      <motion.div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >    
        {props.selectedFolder.subfolders.map((subfolder : Folder) => (
          <FolderGridItem
            key={subfolder.id}
            item={subfolder}
            type="folder"
            selectedItem={props.selectedItem}
            handleLeftClick={handleLeftClick}
            handleRightClick={handleRightClick}
            navigateToFolder={props.navigateToFolder}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            handleClearSelection={handleClearSelection}
          />
        ))}
        {props.selectedFolder.files.map((file) => (
          <FolderGridItem
            key={file.id}
            item={file}
            type="file"
            selectedItem={props.selectedItem}
            handleLeftClick={handleLeftClick}
            handleRightClick={handleRightClick}
            navigateToFolder={props.navigateToFolder}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            handleClearSelection={handleClearSelection}
          />
        ))}
      </motion.div>

      {isDragging && isExternalDrag && (
        <div className='absolute inset-0 bg-indigo-500 bg-opacity-30 flex items-center justify-center'>
          <div className='bg-white dark:bg-slate-600 p-8 rounded-lg shadow-lg'>
            <p className='text-xl font-bold text-black dark:text-white'>Drop files here to upload</p>
          </div>
        </div>
      )}
    </div>
  );
};