// CustomSelect.tsx
import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';

interface SelectOption 
{
  value: string;
  label: string;
  icon?: React.ReactNode;
}

interface CustomSelectProps 
{
  value: string;
  onChange: (value: string) => void;
  options: SelectOption[];
  label: string;
  className?: string;
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
  value,
  onChange,
  options,
  label,
  className = ''
}) => 
{
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const selectedOption = options.find(opt => opt.value === value);

  useEffect(() => 
  {
    const handleClickOutside = (event: MouseEvent) => 
    {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) 
      {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={selectRef} className="relative">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`
          flex items-center gap-2 px-3 py-2 rounded-md bg-white dark:bg-gray-700
          border border-gray-200 dark:border-gray-600
          hover:bg-gray-50 dark:hover:bg-gray-600
          text-sm font-medium text-gray-700 dark:text-gray-200
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
          ${className}
        `}
        aria-label={label}
      >
        {selectedOption?.icon && (
          <span className="w-4 h-4 flex items-center justify-center">
            {selectedOption.icon}
          </span>
        )}
        <span className="truncate">{selectedOption?.label}</span>
        <ChevronDown className="w-4 h-4 ml-2" />
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-max min-w-full max-h-60 overflow-auto rounded-md bg-white dark:bg-gray-700 shadow-lg border border-gray-200 dark:border-gray-600">
          {options.map((option) => (
            <button
              key={option.value}
              onClick={() => 
              {
                onChange(option.value);
                setIsOpen(false);
              }}
              className={`
                w-full flex items-center gap-2 px-3 py-2 text-sm
                ${value === option.value ? 'bg-gray-100 dark:bg-gray-600' : ''}
                hover:bg-gray-50 dark:hover:bg-gray-600
                text-gray-700 dark:text-gray-200
              `}
            >
              {option.icon && (
                <span className="w-4 h-4 flex items-center justify-center">
                  {option.icon}
                </span>
              )}
              <span className="truncate">{option.label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};