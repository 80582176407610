export interface ProductResponse
{
  data: ProductDto[];
}
export interface FeatureResponse
{
  data: FeatureDto[];
}
export interface PriceResponse
{
  data: PriceDto[];
}
export interface CouponResponse
{
  data: CouponDto[];
}
export interface ProductDto 
{
  id?: string;
  name: string;
  description: string;
  active?: boolean;
  marketing_features?: { name: string }[];
  metadata?: Record<string, string>;
  product?: string;
}

export interface ProductCreateDto extends ProductDto 
{
  id: string;
  monthlyPrice: number;
  yearlyPrice: number;
  currency: string;
  series: string;
}
export interface ProductUpdateDto extends ProductDto
{
  monthlyPrice: number;
  yearlyPrice: number;
  currency: string;
  series: string;
}

export interface PriceDto
{
  id: string;
  active: boolean;
  name: string;
  product: string;
  recurring: { interval: string }
  unit_amount: number;
  currency: string;
}
export interface PriceUpdateDto
{
  unit_amount: number;
  currency: string;
}
export interface SubResponseDto 
{
  sessionId: string;
}

export interface FeatureDto
{
  id: string,
  active: boolean,
  livemode: boolean,
  lookup_key: string,
  name: string
}
export function getCurrencySymbol(currency: string): string
{
  const currencySymbols: { [key: string]: string } = {
    USD: '\u0024',
    GBP: '\u00A3',
    EUR: '\u20AC',
  };
  return currencySymbols[currency.toUpperCase()];
}

export interface ProductWithPrices extends ProductDto
{
  id?: string | undefined;
  monthlyPrice: PriceDto | null;
  yearlyPrice: PriceDto | null;
  marketing_features: FeatureDto[];
  getCurrencySymbol?: (price: PriceDto) => string ;
}

export interface CouponDto
{
  id: string;
  percentOff: number;
  duration: string;
  name: string;
  maxRedemptions: number;
  redeemBy: Date;
}
