import zxcvbn from "zxcvbn";
import { PasswordRequirementsLocale } from "../ReactIntl/LocaleInterfaces";
export interface PasswordCriteria 
{
  hasSpecialChar: boolean;
  hasUpperCase: boolean;
  hasNumber: boolean;
  isLengthValid: boolean;
}

export const getPasswordCriteriaDisplay = (
  passwordCriteria: PasswordCriteria,
  intlPass: PasswordRequirementsLocale,
  focusedInput: string
) => 
{
  const criteriaDisplay = [
    {
      key: "hasSpecialChar",
      message: passwordCriteria.hasSpecialChar
        ? intlPass.specialTrue
        : intlPass.specialFalse,
    },
    {
      key: "hasUpperCase",
      message: passwordCriteria.hasUpperCase
        ? intlPass.upperTrue
        : intlPass.upperFalse,
    },
    {
      key: "hasNumber",
      message: passwordCriteria.hasNumber
        ? intlPass.numberTrue
        : intlPass.numberFalse,
    },
    {
      key: "isLengthValid",
      message: passwordCriteria.isLengthValid
        ? intlPass.lengthTrue
        : intlPass.lengthFalse,
    },
  ];

  const containerClasses = `mt-1 w-30 bg-white dark:bg-slate-900 ${
    focusedInput === "password" ? "block" : "hidden"
  }`;

  const criteriaElements = criteriaDisplay.map(({ key, message }) => ({
    classes: `text-xs ${
      passwordCriteria[key as keyof PasswordCriteria]
        ? "text-green-500"
        : "text-red-500"
    }`,
    message,
  }));

  return { containerClasses, criteriaElements };
};

export const checkPasswordCriteria = (password: string): PasswordCriteria => 
{
  const hasSpecialChar = /[!@#$%^&*()_+={}[\]:;"'|<>?,.]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const isLengthValid = password.length >= 6 && password.length <= 24;

  return {
    hasSpecialChar,
    hasUpperCase,
    hasNumber,
    isLengthValid,
  };
};

export const doPasswordsMatch = (
  password: string,
  confirmPassword: string
): boolean => 
{
  return password === confirmPassword;
};

export const getPasswordCriteriaMessages = (criteria: PasswordCriteria, intlPsw: PasswordRequirementsLocale) => 
{
  return {
    hasSpecialChar: criteria.hasSpecialChar
      ? intlPsw.specialTrue
      : intlPsw.specialFalse,
    hasUpperCase: criteria.hasUpperCase
      ? intlPsw.upperTrue
      : intlPsw.upperFalse,
    hasNumber: criteria.hasNumber ? intlPsw.numberTrue : intlPsw.numberFalse,
    isLengthValid: criteria.isLengthValid
      ? intlPsw.lengthTrue
      : intlPsw.lengthFalse,
  };
};

export const getPasswordStrengthScore = (password: string): number => 
{
  const result = zxcvbn(password);
  return result.score;
};

export const getPasswordStrengthBarColor = (score: number): string => 
{
  switch (score) 
  {
    case 0:
      return "bg-red-500";
    case 1:
      return "bg-orange-500";
    case 2:
      return "bg-yellow-500";
    case 3:
      return "bg-green-500";
    case 4:
      return "bg-emerald-500";
    default:
      return "bg-gray-300";
  }
};

export async function handleErrors(response: Response) 
{
  if (!response.ok) 
  {
    const responseData = await response.json();
    const errorMessages: string[] = [];

    if (responseData.errors) 
    {
      if (responseData.errors.DuplicateEmail) 
      {
        errorMessages.push(responseData.errors.DuplicateEmail[0]);
      }
      if (responseData.errors.DuplicateUserName) 
      {
        errorMessages.push(responseData.errors.DuplicateUserName[0]);
      }
      if (responseData.errors.PasswordRequiresNonAlphanumeric) 
      {
        errorMessages.push(
          responseData.errors.PasswordRequiresNonAlphanumeric[0]
        );
      }
      if (responseData.errors.PasswordRequiresDigit) 
      {
        errorMessages.push(responseData.errors.PasswordRequiresDigit[0]);
      }
      if (responseData.errors.PasswordRequiresUpper) 
      {
        errorMessages.push(responseData.errors.PasswordRequiresUpper[0]);
      }
      if (responseData.errors.Password) 
      {
        errorMessages.push(responseData.errors.Password[0]);
      }
      if (responseData.errors.NewPassword) 
      {
        errorMessages.push(responseData.errors.NewPassword[0]);
      }
    }

    throw new Error(
      errorMessages.length ? errorMessages.join(" ") : "Operation failed"
    );
  }

  return response;
}
