import * as yup from "yup";
import { validationMessages } from "../Messages/ValidationMessages";

// Basic schema for form validation
export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .min(3, validationMessages.minLength(3))
    .max(50, validationMessages.maxLength(50))
    .required(validationMessages.required)
    .trim(),

  description: yup
    .string()
    .min(10, validationMessages.minLength(10))
    .max(500, validationMessages.maxLength(500))
    .required(validationMessages.required)
    .trim(),

  email: yup
    .string()
    .email(validationMessages.email)
    .required(validationMessages.required),

  password: yup
    .string()
    .min(8, validationMessages.minLength(8))
    .max(24, validationMessages.maxLength(24))
    .matches(/[A-Z]/, validationMessages.oneUppercase)
    .matches(/[a-z]/, validationMessages.oneLowercase)
    .matches(/[0-9]/, validationMessages.oneNumber)
    .matches(/[@$!%*?&]/, validationMessages.oneSpecialChar)
    .required(validationMessages.required),
});
